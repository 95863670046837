import { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { ListTwoTone } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

const MonitorToolbar = ({
  signupUser,
  registeredUser,
  approvedUser,
  nonApprovedUser,
  selectedOption,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuToggle = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const menuItems = [
    { label: "SignUp", action: signupUser, key: "registeredAndSignUp" },
    { label: "Registered", action: registeredUser, key: "getAllRegistration" },
    { label: "Approved", action: approvedUser, key: "approved" },
    { label: "Non Approved", action: nonApprovedUser, key: "nonApproved" },
  ];

  return (
    <GridToolbarContainer>
      <Typography variant="h5" color="primary" marginRight={5}>
        Monitor
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        variant="text"
        size="small"
        startIcon={<ListTwoTone />}
        onClick={handleMenuToggle}
      >
        View Users
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems.map(({ label, action, key }) => (
          <MenuItem
            key={key}
            sx={{
              color: selectedOption === key ? "secondary.main" : "inherit",
            }}
            onClick={action}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
      <div style={{ flex: 1 }} />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

export default MonitorToolbar;
