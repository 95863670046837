export default function ScheduleStatusColumns() {
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 80,
    },
    {
      field: "jobName",
      headerName: "Job Name",
      width: 100,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 130,
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 130,
    },
    {
      field: "scheduleType",
      headerName: "Schedule Type",
      width: 100,
    },
    {
      field: "scheduleTime",
      headerName: "Schedule Time",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    {
      field: "nextRunDate",
      headerName: "Next Run Date",
      width: 100,
    },
    {
      field: "nextRunTime",
      headerName: "Next Run Time",
      width: 100,
    },
  ];
}
