import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { format } from "date-fns";

const Columns = (
  dynamicColumns,
  handleEdit,
  handleDelete,
  roleAccess,
  isNavigateToAllErrorsActive
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Beneficiary" && item.writeAccess
  );
  // Regular expression to identify fields that contain "date"
  const dateFieldRegex = /date/i;
  // Map over dynamicColumns to add valueFormatter to fields that match the date regex
  const formattedDynamicColumns = dynamicColumns.map((column) => {
    if (dateFieldRegex.test(column.field)) {
      return {
        ...column,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(new Date(value), "dd-MM-yyyy");
          }
          return "";
        },
      };
    }
    return column;
  });
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "userId",
      headerName: "User ID",
      width: 80,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.userId} // Add the title attribute for the tooltip
            onClick={
              !isNavigateToAllErrorsActive
                ? handleEdit(row.id, true, false)
                : handleEdit(row.id, true, true) //error record
            }
          >
            {row.userId}
          </div>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      field: "jobCategory",
      headerName: "Job Category",
      width: 150,
    },
    {
      field: "territory",
      headerName: "Territory",
      width: 150,
    },
    {
      field: "reportingToName",
      headerName: "Reporting To",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "employeeType",
      headerName: "Emp Type",
      width: 150,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 150,
    },
    {
      field: "eligible",
      headerName: "Eligible",
      type: "boolean",
      width: 150,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 150,
    },
    isNavigateToAllErrorsActive && {
      field: "description",
      headerName: "Error Description",
      width: 200,
    },
    ...formattedDynamicColumns,
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id, row }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Beneficiary" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Beneficiary" && item.deleteAccess
        );
        const isAdmin = row.role === "Admin";
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={
                editAccess && !isNavigateToAllErrorsActive
                  ? handleEdit(id, false, false)
                  : handleEdit(id, false, true)
              }
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess && !isAdmin) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess && !isAdmin ? "Delete" : "No Delete Access"}
            />
          );
        }

        return actions;
      },
    },
  ].filter(Boolean);
};

export default Columns;
