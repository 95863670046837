import { Box } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";

const Columns = (handleEdit, handleDelete, roleAccess) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Commission" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "commissionName",
      headerName: "Commission Name",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Box
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.commissionName} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true)}
          >
            {row.commissionName}
          </Box>
        );
      },
    },
    {
      field: "credit",
      headerName: "Credit",
      width: 150,
    },
    {
      field: "conditioned",
      headerName: "Condition",
      width: 200,
    },
    {
      field: "calculateFormula",
      headerName: "Calculate",
      width: 150,
      renderCell: ({ row }) => {
        const { calculateFormula } = row;

        if (Array.isArray(calculateFormula) && calculateFormula.length > 0) {
          const displayText = calculateFormula
            .map((formulaObj) => {
              const key = Object.keys(formulaObj)[0];
              const value = formulaObj[key];
              return `${key}: ${value}`;
            })
            .join(", ");

          return (
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
              }}
              title={displayText}
            >
              {displayText}
            </Box>
          );
        } else {
          return <Box>Formula not available</Box>;
        }
      },
    },
    {
      field: "selectFrequency",
      headerName: "Frequency",
      width: 100,
    },
    {
      field: "rounding",
      headerName: "Rounding",
      width: 100,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Commission" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Commission" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={editAccess ? handleEdit(id) : undefined}
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }
        return actions;
      },
    },
  ];
};

export default Columns;
