import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  ExpandMoreTwoTone,
  Search,
} from "@mui/icons-material";
import { axisClasses, BarChart, LineChart } from "@mui/x-charts";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import DateRangePicker from "../../common/DateRangePicker";
import { convertCamelToTitle } from "../../config/fieldConfig";
import { DashboardService } from "../../service/dashboardService";

export default function Dashboard() {
  const {
    // Total Commissions
    totalCommission,
    //Trends Commission
    trendsLabelsCom,
    trendsValuesCom,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    // Commission by Product
    productNames,
    totalNetProductPayouts,
    // Commission by Customers
    customerNames,
    totalNetCustomerPayouts,
    // Date
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    curName,
    timeZone,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetMyDetailsApplyClick,
    reportingTo,
    stopRemount,
    menu,
  } = DashboardService();

  // commission by products
  const [dynamicMaxValue, setDynamicMaxValue] = useState(null); // State to store dynamic max value

  const tickValuesRef = useRef([]); // Ref to store tick values
  // Update dynamicMaxValue based on data
  useEffect(() => {
    if (totalNetProductPayouts.length > 0) {
      const maxDataValue = Math.max(...totalNetProductPayouts);

      // Calculate the tick values and update dynamicMaxValue
      if (tickValuesRef.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef.current.length; i++) {
          differences.push(
            tickValuesRef.current[i] - tickValuesRef.current[i - 1]
          );
        }

        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNetProductPayouts, tickValuesRef.current]);

  //commission trends
  const [dynamicMaxValue1, setDynamicMaxValue1] = useState(null); // State to store dynamic max value
  const tickValuesRef1 = useRef([]); // Ref to store tick values

  useEffect(() => {
    if (trendsLabelsCom.length > 0) {
      const maxDataValue = Math.max(...trendsValuesCom);

      if (tickValuesRef1.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef1.current.length; i++) {
          differences.push(
            tickValuesRef1.current[i] - tickValuesRef1.current[i - 1]
          );
        }

        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue1(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue1(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendsValuesCom, tickValuesRef1.current]);

  //commission by customer
  const [dynamicMaxValue2, setDynamicMaxValue2] = useState(null); // State to store dynamic max value
  const tickValuesRef2 = useRef([]); // Ref to store tick values

  useEffect(() => {
    if (totalNetCustomerPayouts.length > 0) {
      const maxDataValue = Math.max(...totalNetCustomerPayouts);
      if (tickValuesRef2.current.length > 1) {
        const differences = [];
        for (let i = 1; i < tickValuesRef2.current.length; i++) {
          differences.push(
            tickValuesRef2.current[i] - tickValuesRef2.current[i - 1]
          );
        }
        // Calculate tick interval
        const tickInterval = Math.min(...differences);
        const calculatedMaxValue =
          Math.ceil(maxDataValue / tickInterval) * tickInterval + tickInterval;
        setDynamicMaxValue2(calculatedMaxValue);
      }
    } else {
      setDynamicMaxValue2(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNetCustomerPayouts, tickValuesRef2.current]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [checkedItems, setCheckedItems] = useState(new Set());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState(menu[0]);
  const [isReporteeMenuOpen, setIsReporteeMenuOpen] = useState(false);

  const handleMenuItemClick = (item, event) => {
    setSelectedDropdown(item);
    if (item === "My Reportees") {
      setIsReporteeMenuOpen(false); // Toggle checkbox menu
      handleGetMyReportee();
    } else {
      handleGetMyDetails();
      setCheckedItems(new Set()); // Uncheck all items
    }
    handleMenuClose();
  };

  const handleMenuItemChange = (item, event) => {
    // Handle nested menu item change logic here
    const newCheckedItems = new Set(checkedItems);
    if (newCheckedItems.has(item.userId)) {
      newCheckedItems.delete(item.userId);
    } else {
      newCheckedItems.add(item.userId);
    }
    setCheckedItems(newCheckedItems);
  };

  const handleApplyClick = () => {
    if (Array.from(checkedItems).length !== 0) {
      // Handle apply action here, e.g., save the selected items
      handleGetMyDetailsApplyClick(Array.from(checkedItems));
      handleMenuClose();
    }
  };

  const handleCancelClick = () => {
    setCheckedItems(new Set()); // Uncheck all items
    handleMenuClose(); // Close the menu
    setIsReporteeMenuOpen(false);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation(); // Prevent triggering the main click event
    setIsReporteeMenuOpen(!isReporteeMenuOpen); // Toggle checkbox menu
  };

  // Inside your component
  const [searchQuery, setSearchQuery] = useState("");
  const filteredReportees = reportingTo.filter((reportee) =>
    `${reportee.userId} - ${reportee.userName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Box
        sx={{
          padding: 2,
          height: "auto",
        }}
        maxWidth={"xl"}
      >
        <Grid container spacing={2}>
          {/* First Container */}
          <Grid container sx={{ mt: 1, pt: 1 }}>
            <Grid
              item
              xs={12}
              md={8.36}
              sx={{
                pl: { xs: 2, sm: 2, md: 0 },
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }} // Column layout on extra-small screens, row layout on small screens and up
                spacing={2} // Space between items
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  mx: { xs: 0, sm: 0, md: 2 },
                  p: 1,
                  boxShadow:
                    "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                  borderRadius: 2,
                  transition: "box-shadow 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
              >
                <Typography variant="body1">Currency : {curName}</Typography>
                <Typography variant="body1">Time Zone : {timeZone}</Typography>
              </Stack>
            </Grid>

            <Grid
              item
              xs={12}
              md={3.64}
              sx={{
                pt: { xs: 2, sm: 2, md: 0.15 },
                pl: { xs: 2, sm: 2, md: 0 },
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }} // Column layout on extra-small screens, row layout on small screens and up
                spacing={2} // Space between items
                alignItems="center"
                justifyContent={"space-between"}
                sx={{
                  p: { xs: 0.5, sm: 1, md: 0.52 },
                  boxShadow:
                    "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                  borderRadius: 2,
                  whiteSpace: "nowrap", // Prevent the text from wrapping to a new line
                  overflow: "hidden",
                  textOverflow: "ellipsis", // Show ellipsis for truncated text
                  transition: "box-shadow 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
              >
                {reportingTo.length > 0 ? (
                  <Button
                    variant="text"
                    size="small"
                    onClick={handleMenuClick}
                    endIcon={<ExpandMoreTwoTone />}
                    sx={{
                      padding: "3px 8px",
                      fontSize: "0.85rem",
                      minWidth: "unset",
                      display: "flex", // Make sure the button content is flexbox for proper alignment
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        maxWidth: 120, // Adjust based on your needs
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                      }}
                    >
                      {convertCamelToTitle(selectedDropdown)}
                    </Box>
                  </Button>
                ) : (
                  <span></span>
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={handleMenuClose}
                  sx={{
                    "& .MuiPaper-root": {
                      minWidth: "170px",
                      maxHeight: "500px", // Set the maximum height for the entire menu
                      maxWidth: "200px", // Set the maximum width for the menu
                      overflow: "hidden", // Ensure no overflow for the whole menu
                    },
                  }}
                >
                  {menu.map((item) => {
                    // Conditionally skip "My Reportees" if reportingTo is empty
                    if (item === "My Reportees" && reportingTo.length === 0) {
                      return null;
                    }
                    return (
                      <Box key={item}>
                        <MenuItem
                          onClick={(event) => handleMenuItemClick(item, event)}
                          selected={selectedDropdown === item}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "3px 10px",
                          }}
                        >
                          <Tooltip title={item.length > 20 ? item : ""} arrow>
                            <Typography
                              color={
                                selectedDropdown === item
                                  ? "secondary"
                                  : "inherit"
                              }
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                                display: "block",
                                flexGrow: 1,
                              }}
                            >
                              {item}
                            </Typography>
                          </Tooltip>

                          {/* Add a dropdown icon for "My Reportees" */}
                          {item === "My Reportees" && (
                            <IconButton
                              size="small"
                              onClick={handleDropdownIconClick}
                              sx={{ marginLeft: "auto" }}
                            >
                              {isReporteeMenuOpen ? (
                                <ArrowDropUpTwoTone />
                              ) : (
                                <ArrowDropDownTwoTone />
                              )}
                            </IconButton>
                          )}
                        </MenuItem>

                        {/* Render the checkboxes when the dropdown is open */}
                        {item === "My Reportees" && isReporteeMenuOpen && (
                          <Box
                            sx={{
                              maxHeight: 300,
                              maxWidth: 170,
                              overflowY: "auto",
                              marginTop: 0,
                            }}
                          >
                            {/* Search field */}
                            <TextField
                              variant="standard"
                              size="small"
                              placeholder="Search..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyDown={(e) => e.stopPropagation()} // Prevent the event from reaching the main menu
                              sx={{ transform: "scale(0.8)", padding: 0 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            {/* Scrollable checkbox list */}
                            <Box sx={{ maxHeight: 150, overflowY: "auto" }}>
                              {filteredReportees.length > 0 ? (
                                filteredReportees.map((reportee) => (
                                  <MenuItem
                                    key={reportee.userId}
                                    onClick={(event) =>
                                      handleMenuItemChange(reportee, event)
                                    }
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "3px 5px",
                                    }}
                                  >
                                    <Checkbox
                                      checked={checkedItems.has(
                                        reportee.userId
                                      )}
                                      size="small"
                                      sx={{
                                        transform: "scale(0.6)",
                                        padding: 0,
                                      }}
                                    />
                                    <Tooltip
                                      title={
                                        `${reportee.userId} - ${reportee.userName}`
                                          .length > 9
                                          ? `${reportee.userId} - ${reportee.userName}`
                                          : ""
                                      }
                                      arrow
                                    >
                                      <Typography
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: "200px",
                                          display: "block",
                                        }}
                                      >
                                        {`${reportee.userId} - ${reportee.userName}`}
                                      </Typography>
                                    </Tooltip>
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>No results found</MenuItem>
                              )}
                            </Box>

                            {/* OK and Clear buttons */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                borderTop: "1px solid #ddd",
                                paddingTop: 1,
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                onClick={handleApplyClick}
                                sx={{
                                  padding: "2px 8px",
                                  fontSize: "0.75rem",
                                  minWidth: "unset",
                                }}
                              >
                                OK
                              </Button>
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={handleCancelClick}
                                sx={{
                                  padding: "2px 8px",
                                  fontSize: "0.75rem",
                                  minWidth: "unset",
                                  marginLeft: "8px", // Add space between OK and Clear
                                  marginRight: "8px",
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Menu>

                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  stopRemount={stopRemount}
                />
              </Stack>
            </Grid>
          </Grid>

          {/*Second Container */}
          <Grid item xs={12} md={8.2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission Trends
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LineChart
                      series={[
                        {
                          data: trendsValuesCom,
                          area: true,
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: trendsLabelsCom,
                          // tickLabelStyle: {
                          //   angle: -45,
                          //   textAnchor: 'end',
                          // },
                        },
                      ]}
                      yAxis={[
                        {
                          scaleType: "linear",
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0,
                          max:
                            dynamicMaxValue1 !== null ? dynamicMaxValue1 : null, // Default max value if dynamicMaxValue1 is not set
                        },
                      ]}
                      tooltip={
                        trendsValuesCom?.length === 0 &&
                        trendsLabelsCom?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={850}
                      height={300}
                      sx={{
                        ".MuiLineElement-root": {
                          display: "none",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        p: 1,
                        "& .css-ddfs93": {
                          fontSize: "0.895rem", // Default size for desktops and larger screens
                          "@media (max-width: 1024px)": {
                            fontSize: "1.1rem", // Adjusts for smaller laptops and large tablets
                          },
                          "@media (max-width: 768px)": {
                            fontSize: "1.3rem", // Adjusts for tablets
                          },
                          "@media (max-width: 570px)": {
                            fontSize: "1.7rem", // Adjusts for mobile devices
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission by Products
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <BarChart
                      xAxis={[
                        {
                          id: "topSalesReps",
                          scaleType: "band",
                          data: productNames,
                          valueFormatter: (value, context) =>
                            context.location === "tick"
                              ? value.length > 5
                                ? `${value.slice(0, 5)}...`
                                : value
                              : value,
                          tickLabelStyle: {
                            angle: -45,
                            textAnchor: "end",
                            fontSize: 15,
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          label: `(${curName})`,
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0, // Set the minimum value of the y-axis
                          max: dynamicMaxValue, // Set the dynamic maximum value of the y-axis
                        },
                      ]}
                      series={[
                        {
                          data: totalNetProductPayouts,
                          color: "#8884d8",
                        },
                      ]}
                      tooltip={
                        productNames?.length === 0 &&
                        totalNetProductPayouts?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={450}
                      height={300}
                      sx={{
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: "translate(-10px, 0)",
                        },
                        [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                          transform: "translate(-20px, 10px)",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        pl: 2,
                        "& .css-ddfs93": {
                          fontSize: "0.975rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" p={1}>
                      Commission by Customers
                    </Typography>
                  </Box>

                  <Divider variant="middle" />

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LineChart
                      series={[{ data: totalNetCustomerPayouts }]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: customerNames,
                          tickLabelStyle: {
                            angle: -45,
                            textAnchor: "end",
                            fontSize: 15,
                          },
                          valueFormatter: (value, context) =>
                            context.location === "tick"
                              ? value.length > 5
                                ? `${value.slice(0, 5)}...`
                                : value
                              : value,
                        },
                      ]}
                      yAxis={[
                        {
                          scaleType: "linear",
                          tickLabelStyle: {
                            fontSize: 15,
                          },
                          label: `(${curName})`,
                          valueFormatter: (value) => {
                            return value >= 1000 ? `${value / 1000}k` : value;
                          },
                          min: 0, // Set the minimum value of the y-axis
                          max: dynamicMaxValue2,
                        },
                      ]}
                      tooltip={
                        customerNames?.length === 0 &&
                        totalNetCustomerPayouts?.length === 0
                          ? { trigger: "none" }
                          : {}
                      }
                      width={450}
                      height={300}
                      sx={{
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: "translate(-10px, 0)",
                        },
                        ".MuiLineElement-root, .MuiMarkElement-root": {
                          strokeWidth: 1,
                        },
                        ".MuiLineElement-series-pvId": {
                          strokeDasharray: "5 5",
                        },
                        ".MuiLineElement-series-uvId": {
                          strokeDasharray: "3 4 5 2",
                        },
                        ".MuiMarkElement-root:not(.MuiMarkElement-highlighted)":
                          {
                            fill: "#fff",
                          },
                        "& .MuiMarkElement-highlighted": {
                          stroke: "none",
                        },
                        // bottomAxis Line Styles
                        "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        // leftAxis Line Styles
                        "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                          stroke: "grey",
                          strokeWidth: 0.4,
                        },
                        pl: 1,
                        "& .css-ddfs93": {
                          fontSize: "0.975rem",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Third Container */}
          <Grid item xs={12} md={3.8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                    color: "white",
                    bgcolor: "#2196f3",
                    p: 2,
                    pb: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pr: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Total Commission
                    </Typography>
                  </Box>

                  <Divider color="white" />

                  <Typography
                    variant="h4"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      py: 3,
                      px: 1,
                      fontWeight: "light",
                    }}
                  >
                    {totalCommission}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Box
                  sx={{
                    boxShadow: 2,
                    borderRadius: 3,
                    transition: "box-shadow 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      boxShadow: 5,
                    },
                    pb: 3,
                  }}
                >
                  <Typography variant="h6" gutterBottom sx={{ p: 2, pb: 1 }}>
                    Commission by SalesRep
                  </Typography>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DataGrid
                      columns={salesRepsColumns}
                      rows={salesRepsRows}
                      sx={{
                        width: 500,
                        height: 430,
                        // removing the cell border
                        "& .MuiDataGrid-cell:focus-within": {
                          outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeader:focus-within": {
                          outline: "none !important",
                        },
                      }}
                      density="compact"
                      disableColumnMenu
                      slots={{ noRowsOverlay: EmptyBox }}
                      slotProps={{
                        footer: {
                          style: {
                            display: "none",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
