import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import BillingToolbar from "../Billing/toolbar";
import { PaymentHistoryService } from "../../service/paymentHistory";

export default function PaymentHistory() {
  const { columns, rows } = PaymentHistoryService();

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      slots={{
        noRowsOverlay: EmptyBox,
        pagination: CustomPagination,
        toolbar: BillingToolbar,
      }}
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      pageSizeOptions={[10, 25, 50]}
      density="compact"
      disableRowSelectionOnClick
      autoHeight={Object.keys(rows).length >= 10}
      sx={{
        p: 1,
        height: 530,
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none !important",
        },
      }}
    />
  );
}
