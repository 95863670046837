export const FormFields = [
  {
    name: "frequencyName",
    label: "Frequency Name",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "fiscalYear",
    label: "Fiscal Year",
    type: "date",
    shrink: true,
    required: true,
  },
  {
    name: "startDate",
    label: "Start Date",
    type: "date",
    shrink: true,
    required: true,
  },
  {
    name: "frequency",
    label: "Frequency",
    type: "autocomplete",
    required: true,
  },
  {
    name: "currentPeriod",
    label: "Current Period",
    type: "number",
    required: true,
  },
  {
    name: "current",
    label: "Is Current",
    type: "boolean",
  },
];
