import { useEffect, useRef, useState } from "react";
import { format, startOfToday, subDays, subMonths } from "date-fns";
import ApiService from "../../apis/ApiService";
import { addSerialNumber, formatDateTime } from "../config/fieldConfig";
import { JWT_TOKEN } from "../config/sessionStorage";
import UserActivityColumns from "../pages/UserActivity/columns";

export const UserActivityService = () => {
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state is true

  // eDate should represent yesterday's date
  const eDate = subDays(startOfToday(), 1); // Yesterday

  // sDate should be exactly one month before eDate
  const sDate = subMonths(eDate, 1); // One month before yesterday

  // Format dates
  const formattedEndDate = format(eDate, "yyyy-MM-dd");
  const formattedStartDate = format(sDate, "yyyy-MM-dd");

  // States for start and end dates
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();

    try {
      const res = await ApiService.getAllUserActivityForOrganization(
        TOKEN,
        startDate,
        endDate
      );
      console.log("userActivityRes", res?.data);

      const formattedData = res?.data.map((item) => ({
        ...item,
        date: formatDateTime(item?.date), // replace `item.date` with the actual date field
      }));
      setTableData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const rows = addSerialNumber(data);

  const columns = UserActivityColumns();

  return {
    rows,
    columns,
    loading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    stopRemount,
  };
};
