import { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { LoginTwoTone } from "@mui/icons-material";

const settings = [
  {
    text: "Free Register",
    link: "/register",
  },
  {
    text: "Sign In",
    link: "/sign_in",
  },
];

export default function HeaderTopBar({ imgLogoTrans }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => setDrawerOpen(true);
  const handleCloseDrawer = () => setDrawerOpen(false);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        sx={{
          p: 1.3,
          px: { xs: 2, md: 13.5 },
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        elevation={0}
      >
        <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
          <img
            src={imgLogoTrans}
            alt="compFact logo"
            style={{
              height: "auto",
              maxHeight: "66px",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />

          {/* Mobile view */}
          <Box
            sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
          >
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenDrawer}
              color="inherit"
              sx={{ display: "flex" }}
            >
              <GridMenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleCloseDrawer}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <List>
                {settings.map((page) => (
                  <ListItemButton
                    key={page.text}
                    component={Link}
                    to={page.link}
                    onClick={handleCloseDrawer}
                    sx={{ ":hover": { color: "secondary.main" } }}
                  >
                    {page.text === "Sign In" && (
                      <LoginTwoTone fontSize="small" />
                    )}
                    <ListItemText>
                      <Typography textAlign="center">{page.text}</Typography>
                    </ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Drawer>
          </Box>

          {/* Desktop View */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              ml: "auto",
            }}
          >
            {settings.map((page) => (
              <Button
                key={page.text}
                component={Link}
                href={page.link}
                size="large"
                variant={
                  page.text === "Free Register" ? "outlined" : "contained"
                }
                sx={{
                  backgroundImage:
                    page.text === "Free Register"
                      ? "transparent"
                      : "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                  borderRadius: "20px",
                  ml: 2,
                  height: "40px",
                  borderWidth: "2px",
                  borderColor:
                    page.text === "Free Register"
                      ? "rgb(59 130 246 / 0.5)"
                      : "white",
                  color: page.text === "Free Register" ? "black" : "white",
                  backgroundColor:
                    page.text === "Free Register"
                      ? "white"
                      : "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)",
                  border: page.text === "Sign In" ? "solid" : null,
                  ":hover": {
                    backgroundColor:
                      page.text === "Free Register"
                        ? "linear-gradient(180deg, #0066FF 0%, #7C2AE7 100%)"
                        : null,
                    color: page.text === "Free Register" ? "white" : null,
                    border: page.text === "Free Register" ? "solid" : null,
                  },
                }}
              >
                {page.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
