import { Box, CssBaseline } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import MonitorToolbar from "./toolbar";
import { MonitorService } from "../../service/monitorService";

export default function Monitor() {
  const {
    rows,
    columns,
    selectedOption,
    signupUser,
    registeredUser,
    approvedUser,
    nonApprovedUser,
    isTableLoading,
  } = MonitorService();

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: () => (
              <MonitorToolbar
                signupUser={signupUser}
                registeredUser={registeredUser}
                approvedUser={approvedUser}
                nonApprovedUser={nonApprovedUser}
                selectedOption={selectedOption}
              />
            ),
            pagination: CustomPagination,
          }}
          autoHeight={Object.keys(rows).length >= 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          density="compact"
          loading={isTableLoading}
          sx={{
            padding: 1,
            height: 550,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />
      </Box>
    </>
  );
}
