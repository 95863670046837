// Function to check if access is granted to a resource
export function hasAccessWrapper(arrayOfObjects, resource) {
  const result =
    Array.isArray(arrayOfObjects) &&
    arrayOfObjects.some(
      (item) => item.resource === resource && item.menuManagement
    );
  return result;
}

// Function to generate the menu items based on roles and permissions
const menus = ({ arrayOfObjects, role, isMonitor, isSuperAdmin }) => {
  return [
    {
      text: "Dashboard",
      link: "dashboard",
      children: null,
      isAccess: hasAccessWrapper(arrayOfObjects, "Dashboard"),
    },
    {
      text: "Settings",
      link: "#",
      children: [
        {
          text: "Company Setup",
          link: "companySetup",
          isAccess: hasAccessWrapper(arrayOfObjects, "CompanySetup"),
        },
        {
          text: "Role",
          link: "role",
          isAccess: hasAccessWrapper(arrayOfObjects, "Role"),
        },
        {
          text: "Type",
          link: "type",
          isAccess: hasAccessWrapper(arrayOfObjects, "Type"),
        },
        {
          text: "Field Mapping",
          link: "fieldMapping",
          isAccess: hasAccessWrapper(arrayOfObjects, "FieldMapping"),
        },
        {
          text: "Page Access",
          link: "access",
          isAccess: role === "Admin",
        },
        {
          text: "Audit Log",
          link: "auditLog",
          isAccess: role === "Admin",
        },
      ],
    },
    {
      text: "Master",
      link: "#",
      children: [
        {
          text: "Beneficiaries",
          link: "beneficiary",
          isAccess: hasAccessWrapper(arrayOfObjects, "Beneficiary"),
        },
        {
          text: "Customers",
          link: "customer",
          isAccess: hasAccessWrapper(arrayOfObjects, "Customer"),
        },
        {
          text: "Products",
          link: "product",
          isAccess: hasAccessWrapper(arrayOfObjects, "Product"),
        },
      ],
    },
    {
      text: "Transactions",
      link: "transaction",
      children: null,
      isAccess: hasAccessWrapper(arrayOfObjects, "Transaction"),
    },
    {
      text: "Comm Settings",
      link: "#",
      children: [
        {
          text: "Pay Frequencies",
          link: "payFrequency",
          isAccess: hasAccessWrapper(arrayOfObjects, "PayFrequency"),
        },
        {
          text: "Variables",
          link: "variable",
          isAccess: hasAccessWrapper(arrayOfObjects, "Variable"),
        },
        {
          text: "Commissions",
          link: "commission",
          isAccess: hasAccessWrapper(arrayOfObjects, "Commission"),
        },
        {
          text: "Assign Commissions",
          link: "assignCommission",
          isAccess: hasAccessWrapper(arrayOfObjects, "AssignCommission"),
        },
      ],
    },
    {
      text: "Scheduler",
      link: "#",
      children: [
        {
          text: "Schedule",
          link: "schedule",
          isAccess: hasAccessWrapper(arrayOfObjects, "Schedule"),
        },
        {
          text: "Schedule Status",
          link: "scheduleStatus",
          isAccess: hasAccessWrapper(arrayOfObjects, "ScheduleStatus"),
        },
      ],
    },
    {
      text: "Report",
      link: "#",
      children: [
        {
          text: "Comm Report",
          link: "report",
          isAccess: hasAccessWrapper(arrayOfObjects, "Report"),
        },
        {
          text: "ReportR",
          link: "reportR",
          isAccess: hasAccessWrapper(arrayOfObjects, "ReportR"),
        },
        {
          text: "Other Reports",
          link: "otherReports",
          isAccess: hasAccessWrapper(arrayOfObjects, "OtherReports"),
        },
      ],
    },
    {
      text: "Billing",
      link: "#",
      children: [
        {
          text: "Subscription",
          link: "billing",
          isAccess: hasAccessWrapper(arrayOfObjects, "Billing"),
        },
        {
          text: "Pricing",
          link: "pricing",
          isAccess: hasAccessWrapper(arrayOfObjects, "Pricing"),
        },
      ],
    },
    {
      text: "Monitor",
      link: "monitor",
      children: null,
      isAccess: isMonitor === "true",
    },
    {
      text: "Plan",
      link: "plan",
      children: null,
      isAccess: isSuperAdmin === "true",
    },
    {
      text: "Payment History",
      link: "paymentHistory",
      children: null,
      isAccess: isSuperAdmin === "true",
    },
    {
      text: "User Activity",
      link: "userActivity",
      children: null,
      isAccess: isMonitor === "true" || isSuperAdmin === "true",
    },
    {
      text: "Dispute",
      link: "#",
      children: [
        {
          text: "Tickets",
          link: "ticket",
          isAccess:
            isMonitor === "true" ||
            isSuperAdmin === "true" ||
            hasAccessWrapper(arrayOfObjects, "Ticket"),
        },
      ],
    },
  ];
};

// Static settings array
const settings = [
  {
    text: "Profile",
    link: "#",
  },
  {
    text: "Reset Password",
    link: "/reset_pwd",
  },
  {
    text: "Logout",
    link: "/sign_in",
  },
];

export { menus, settings };
