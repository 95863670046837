import { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import Columns from "./columns";
import CustomerToolbar from "./toolbar";
import CustomerForm from "./customerForm";
import { CustomerService } from "../../service/customerService";
import FullScreenLoader from "../../common/LoadingScreen";

export default function Customer() {
  const fileInputRef = useRef(null);

  const {
    rows,
    dynamicColumns,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    dynamicFormFields,
    handleAddCustomField,
    fields,
    openCustomFieldDialog,
    setOpenCustomFieldDialog,
    handleUploadFile,
    open,
    addFormOpen,
    addFormClose,
    roleAccess,
    newFields,
    setFields,
    viewData,
    setViewData,
    apiEndpoint,
    handleGetAll,
    handleAllError,
    triggerNavigateToAllErrors,
    isNavigateToAllErrorsActive,
    addCustomerType,
    relationshipTypeOptions,
    customerIdCheck,
    isErrorColor,
    uploading,
    setUploadInProgress,
    isEData,
    setIsEData,
    relationshipTypeOptionsWithId,
    loading,
    selectedOption,
    handleSelectOption,
    isLoading,
    isTableLoading,
  } = CustomerService(fileInputRef);

  const columns = Columns(
    dynamicColumns,
    handleEdit,
    handleDelete,
    roleAccess,
    isNavigateToAllErrorsActive,
    isEData,
    setIsEData
  );

  const [page, setPage] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // 0-based index for the first page
    pageSize: 10,
  });

  //  Reset pagination to the first page when selectedRole changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    setPage(false);
  }, [apiEndpoint]);

  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  const hasData = rows.length > 0;

  // Memoize column visibility model to prevent recreation on every render
  const columnVisibilityModel = useMemo(
    () => ({
      fullCustomerName: false,
      customerLevel: false,
      subCustomerOf: false,
      leadSource: false,
      startDate: false,
      endDate: false,
      renewalDate: false,
      active: false,
      ...dynamicColumns.reduce((acc, column) => {
        acc[column.field] = false;
        return acc;
      }, {}),
    }),
    [dynamicColumns]
  );

  // Initial state with column visibility model
  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel,
      },
      pagination: { paginationModel: { pageSize: 10 } },
    }),
    [columnVisibilityModel]
  );

  const apiRef = useGridApiRef();

  useEffect(() => {
    // Set the column visibility model after the initial render
    if (apiRef.current) {
      apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
    }
  }, [columnVisibilityModel, apiRef]);

  return (
    <>
      <FullScreenLoader loading={isLoading} />

      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          autoHeight={Object.keys(rows).length >= 10}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: () => (
              <CustomerToolbar
                handleUploadFile={handleUploadFile}
                addFormOpen={addFormOpen}
                roleAccess={roleAccess}
                fileInputRef={fileInputRef}
                handleGetAll={handleGetAll}
                handleAllError={handleAllError}
                triggerNavigateToAllErrors={triggerNavigateToAllErrors}
                hasData={hasData}
                apiEndpoint={apiEndpoint}
                uploading={loading ? loading : uploading}
                setUploadInProgress={setUploadInProgress}
                selectedOption={selectedOption}
                handleSelectOption={handleSelectOption}
              />
            ),
            pagination: CustomPagination,
          }}
          initialState={initialState}
          onPaginationModelChange={!page ? setPaginationModel : undefined}
          paginationModel={!page ? paginationModel : undefined}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          loading={isTableLoading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            ...(isErrorColor && {
              "& .MuiDataGrid-row": {
                color: "error.main",
              },
            }),
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />

        {/* Handle both and and edit form display */}
        <CustomerForm
          open={editFormOpen || open}
          onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
          mode={editFormOpen ? "edit" : "add"}
          editItemId={editFormOpen ? editItemId : undefined}
          editFormData={editFormOpen ? editFormData : undefined}
          onEditForm={editFormOpen ? onEditForm : undefined}
          onAddForm={!editFormOpen ? onAddForm : undefined}
          dynamicFormFields={dynamicFormFields}
          handleAddCustomField={handleAddCustomField}
          fields={fields}
          openCustomFieldDialog={openCustomFieldDialog}
          setOpenCustomFieldDialog={setOpenCustomFieldDialog}
          newFields={newFields}
          setFields={setFields}
          viewData={viewData}
          setViewData={setViewData}
          isNavigateToAllErrorsActive={isNavigateToAllErrorsActive}
          addCustomerType={addCustomerType}
          relationshipTypeOptions={relationshipTypeOptions}
          roleAccess={roleAccess}
          customerIdCheck={customerIdCheck}
          isEData={isEData}
          setIsEData={setIsEData}
          relationshipTypeOptionsWithId={relationshipTypeOptionsWithId}
        />
      </Box>
    </>
  );
}
