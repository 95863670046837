import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { groupBy } from "lodash";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ORG_NAME, USER_ID } from "../config/sessionStorage";
import toast from "react-hot-toast";
import { No_PERIOD_ACCESS, No_USER_ACCESS } from "../config/toastMessage";
import { adjustmentOptions } from "../config/fieldConfig";
import { ReportColumns } from "../pages/Report/columns";

export const ReportService = () => {
  const [token, setToken] = useState(null);
  const [orgName, setOrgName] = useState(""); // Used at pdf
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});
  const [formData, setFormData] = useState({});
  const [beneficiary, setBeneficiary] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [tableData, setTableData] = useState({});
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [beneficiariesOptions, setBeneficiariesOptions] = useState([]);
  const [beneficiariesNameId, setBeneficiariesNameId] = useState([]);
  const [frequenciesOptions, setFrequenciesOptions] = useState([]);
  const [fullYearOptions, setFullYearOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [notCalculatedPeriods, setNotCalculatedPeriods] = useState([]);
  const [selectPeriod, setSelectPeriod] = useState();
  const [selectYear, setSelectYear] = useState();
  const [selectFrequencies, setSelectFrequencies] = useState();
  const [commissionAmount, setCommissionAmount] = useState("");
  const [otherAdjustment, setOtherAdjustment] = useState("");
  const [netPayoutAmount, setNetPayoutAmount] = useState("");
  const [capAdjustment, setCapAdjustment] = useState("");
  const [advanceAdjustment, setAdvanceAdjustment] = useState("");
  const [minPayAdjustment, setMinPayAdjustment] = useState("");
  const [adjustmentPayout, setAdjustmentPayout] = useState("");
  const [recoveryPayout, setRecoveryPayout] = useState("");
  const [showForm, setShowForm] = useState(false);

  const location = useLocation();
  const { viewData } = location.state || {}; // Access viewData from location state
  const { SingleData } = location.state || {}; // Access viewData from location state
  const { IconImage } = location.state || {}; // Access viewData from location state

  const [logoImage, setLogoImage] = useState("");
  const [currency, setCurrency] = useState("");

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();

      const USERID = USER_ID();
      // Adjust the condition accordingly
      setBeneficiary(USERID);
      setSelectedBeneficiary(USERID);

      if (viewData) {
        fetchData_1(
          formData,
          setShowForm,
          showForm,
          viewData,
          SingleData,
          IconImage
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Added an empty dependency array to run effect only once on mount

  //set change and set the frequency
  useEffect(() => {
    setFormData(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  //change and set the period
  useEffect(() => {
    const filteredRecord = filteredRecords.filter(
      (record) => record.frequency === selectFrequencies
    );
    // Extract paymentPeriods
    const formattedPeriods = [];
    const notCalculatedPeriods = [];

    filteredRecord.forEach((record) => {
      record.paymentPeriod.forEach((period) => {
        const startDate = new Date(period.startDate)
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "/");
        const endDate = new Date(period.endDate)
          .toISOString()
          .split("T")[0]
          .replace(/-/g, "/");
        const formattedPeriod = `${period.periodNumber} [${startDate} - ${endDate}]`;

        formattedPeriods.push(formattedPeriod);

        if (!period.calculated) {
          notCalculatedPeriods.push(formattedPeriod);
        }
      });
    });

    setPeriodOptions(formattedPeriods);

    if (viewData) {
      const fiscalPeriod = SingleData.matchingCalculation;
      const formattedPeriod = `${
        fiscalPeriod.periodNumber
      } [${fiscalPeriod.startDate.replace(
        /-/g,
        "/"
      )} - ${fiscalPeriod.endDate.replace(/-/g, "/")}]`;
      setSelectPeriod(formattedPeriod);
    } else {
      setSelectPeriod(formattedPeriods[0]);
    }
    setNotCalculatedPeriods(notCalculatedPeriods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFrequencies, filteredRecords]);

  //set change and set the frequency
  useEffect(() => {
    const filteredRecord = fullYearOptions.filter(
      (record) => record.fiscalYear === selectYear
    );
    setFilteredRecords(filteredRecord);

    const FrequenciesName = filteredRecord.map((payFreq) => payFreq.frequency);
    setFrequenciesOptions(FrequenciesName);
    if (!viewData) {
      setSelectFrequencies(FrequenciesName[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectYear, fullYearOptions, setFilteredRecords]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    const ORGNAME = ORG_NAME();

    setOrgName(ORGNAME);
    setToken(TOKEN);

    try {
      // Fetch beneficiaries using ApiService
      const beneficiaries = await ApiService.getActiveBeneficiaries(TOKEN);

      // Map over the beneficiaries array and extract the userName and id values
      const beneficiariesName_Id = beneficiaries.map((item) => ({
        userName: `${item.userId} - ${item.userName}`,
        id: item.userId,
      }));
      setBeneficiariesNameId(beneficiariesName_Id);
      // set the extracted beneficiariesName array
      setBeneficiariesOptions(beneficiariesName_Id);

      // Fetch payFrequency using ApiService
      const payFrequency = await ApiService.getPaymentFrequencies(TOKEN);
      setFullYearOptions(payFrequency);

      // Map over the beneficiaries array and extract the userName values
      const fiscalYear = payFrequency.map((payFreq) => payFreq.fiscalYear);

      // Remove duplicates using Set
      const uniqueFiscalYears = [...new Set(fiscalYear)];
      // Set fiscalYear and paymentPeriods
      setYearOptions(uniqueFiscalYears);

      if (SingleData) {
        setSelectYear(SingleData.matchingCalculation.fiscalYear);
      } else {
        setSelectYear(uniqueFiscalYears[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData_1 = async (
    formData,
    setShowForm,
    showForm,
    viewData,
    singleData,
    IconImage
  ) => {
    try {
      const TOKEN = JWT_TOKEN();
      let data;

      if (viewData === false) {
        const iconImage = await ApiService.getImageBytes(TOKEN);
        setLogoImage(iconImage);

        data = await ApiService.getCreditTransactions(TOKEN, formData);
      } else {
        setSelectedBeneficiary(SingleData.matchingBeneficiary.userId);
        setBeneficiary(
          `${SingleData.matchingBeneficiary.userId} - ${singleData.matchingBeneficiary.userName}`
        );
        setSelectYear(singleData.matchingCalculation.fiscalYear);
        setSelectFrequencies(singleData.frequencies);
        setLogoImage(IconImage);

        data = singleData;
      }

      const creditTransCurrency = data.currency;
      setCurrency(creditTransCurrency);

      if (data.filteredTransactions.length !== 0) {
        setShowForm(!showForm);
        setUserId(data.matchingBeneficiary.userId);
        setUser(data.matchingBeneficiary);
        setNetPayoutAmount(data.matchingCalculation.netPayout);
        setCommissionAmount(data.matchingCalculation.grossPayout);

        const result = groupBy(
          data.filteredTransactions,
          ({ commissionName }) => commissionName
        );
        setTableData(result);

        data.matchingAdjustments.forEach((adjustment) => {
          const caption = adjustment.caption;
          const formattedAmount = parseFloat(
            adjustment.amount.replace(/[^\d.-]/g, "")
          );

          switch (caption) {
            case adjustmentOptions[0]:
              setCapAdjustment(formattedAmount);
              break;
            case adjustmentOptions[1]:
              setAdvanceAdjustment(formattedAmount);
              break;
            case adjustmentOptions[2]:
              setMinPayAdjustment(formattedAmount);
              break;
            case adjustmentOptions[3]:
              setOtherAdjustment(formattedAmount);
              break;
            case adjustmentOptions[4]:
              setAdjustmentPayout(formattedAmount);
              break;
            case adjustmentOptions[5]:
              setRecoveryPayout(formattedAmount);
              break;
            default:
              break;
          }
        });
      } else {
        toast.error(No_USER_ACCESS);
      }
    } catch (error) {
      console.log(error);
      toast.error(No_PERIOD_ACCESS);
    }
  };

  const columns = ReportColumns();

  return {
    beneficiariesOptions,
    frequenciesOptions,
    yearOptions,
    periodOptions,
    tableData,
    setBeneficiary,
    userId,
    user,
    setSelectPeriod,
    setSelectYear,
    setSelectFrequencies,
    commissionAmount,
    otherAdjustment,
    netPayoutAmount,
    orgName,
    setFormData,
    formData,
    token,
    logoImage,
    columns,
    stopRemount,
    fetchData_1,
    capAdjustment,
    advanceAdjustment,
    minPayAdjustment,
    adjustmentPayout,
    recoveryPayout,
    currency,
    notCalculatedPeriods,
    setCommissionAmount,
    setOtherAdjustment,
    setNetPayoutAmount,
    setCapAdjustment,
    setAdvanceAdjustment,
    setMinPayAdjustment,
    setAdjustmentPayout,
    setRecoveryPayout,
    selectYear,
    selectFrequencies,
    selectPeriod,
    beneficiary,
    viewData,
    SingleData,
    showForm,
    setShowForm,
    beneficiariesNameId,
    setPeriodOptions,
    selectedBeneficiary,
    setSelectedBeneficiary,
  };
};
