import { useLocation } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography, Stack } from "@mui/material";
import {
  AccountBalanceWalletTwoTone,
  AddModeratorTwoTone,
  AssignmentTwoTone,
  AssuredWorkloadTwoTone,
  CreditScoreTwoTone,
  DoubleArrowTwoTone,
  GroupAddTwoTone,
  GroupsTwoTone,
  HomeTwoTone,
  LocalMallTwoTone,
  MapTwoTone,
  MonetizationOnTwoTone,
  ReportTwoTone,
  ScheduleSendTwoTone,
  SettingsAccessibilityTwoTone,
  SpaceDashboardTwoTone,
} from "@mui/icons-material";

// Map breadcrumb text to specific icons
const iconMap = {
  access: <SettingsAccessibilityTwoTone sx={{ mr: 0.5 }} />,
  assignCommission: <AssignmentTwoTone sx={{ mr: 0.5 }} />,
  beneficiary: <GroupAddTwoTone sx={{ mr: 0.5 }} />,
  fieldMapping: <MapTwoTone sx={{ mr: 0.5 }} />,
  commission: <MonetizationOnTwoTone sx={{ mr: 0.5 }} />,
  customer: <GroupsTwoTone sx={{ mr: 0.5 }} />,
  dashboard: <SpaceDashboardTwoTone sx={{ mr: 0.5 }} />,
  product: <LocalMallTwoTone sx={{ mr: 0.5 }} />,
  payFrequency: <AccountBalanceWalletTwoTone sx={{ mr: 0.5 }} />,
  report: <ReportTwoTone sx={{ mr: 0.5 }} />,
  role: <AssuredWorkloadTwoTone sx={{ mr: 0.5 }} />,
  schedule: <ScheduleSendTwoTone sx={{ mr: 0.5 }} />,
  transaction: <CreditScoreTwoTone sx={{ mr: 0.5 }} />,
  variable: <AddModeratorTwoTone sx={{ mr: 0.5 }} />,
};

// Function to get breadcrumb trail based on the current path
const getBreadcrumbs = (menuItems, currentPath) => {
  const breadcrumbTrail = [{ text: "CompFact", link: "/" }];
  const pathSegments = currentPath.replace(/^\//, "").split("/");
  let pathAccumulator = "";

  const findBreadcrumbs = (items, path, trail) => {
    for (const item of items) {
      if (item.link === path) {
        trail.push(item);
        return true;
      }
      if (item.children) {
        trail.push(item);
        for (const child of item.children) {
          if (findBreadcrumbs([child], path, trail)) return true;
        }
        trail.pop();
      }
    }
    return false;
  };

  pathSegments.forEach((segment, index) => {
    pathAccumulator += (index > 0 ? "/" : "") + segment;
    findBreadcrumbs(menuItems, pathAccumulator, breadcrumbTrail);
  });

  return breadcrumbTrail;
};

export default function BreadcrumbBar({ menus }) {
  const location = useLocation();
  const currentPath = location.pathname;

  // Define the base menu
  const baseMenu = menus({
    arrayOfObjects: [], // Provide the actual array of objects if needed
    role: "",
    isMonitor: "",
    isSuperAdmin: "",
  });

  // Get the breadcrumb trail
  const breadcrumbItems = getBreadcrumbs(baseMenu, currentPath);

  return (
    <Box sx={{ boxShadow: 3, p: 2, mx: 2, my: 1 }}>
      <Breadcrumbs
        separator=""
        aria-label="breadcrumb"
        // sx={{
        //   ".MuiBreadcrumbs-separator": {
        //     margin: 0, // Remove any margin between items
        //   },
        // }}
      >
        {breadcrumbItems.map((item, index) => {
          const isLastItem = index === breadcrumbItems.length - 1;
          const icon =
            index === 0 ? (
              <HomeTwoTone sx={{ mr: 0.5 }} />
            ) : (
              iconMap[item.link] || <DoubleArrowTwoTone sx={{ mr: 0.5 }} />
            );

          return (
            <Stack direction="row" alignItems="center" key={index}>
              {icon}
              {isLastItem ? (
                <Typography color="text.primary">{item.text}</Typography>
              ) : (
                <Link underline="none" color="inherit">
                  {item.text}
                </Link>
              )}
            </Stack>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}
