import ReCAPTCHA from "react-google-recaptcha";
import axiosClient from "../../apis/Api";

export default function Captcha({ onCaptchaChange, recaptchaRef }) {
  const siteKey = axiosClient.defaults.apiUrl_Recaptcha;

  function onChange(value) {
    const isChecked = value !== null;
    onCaptchaChange && onCaptchaChange(isChecked, value);
  }

  function onLoadCallback() {
    console.log("ReCAPTCHA loaded successfully.");
  }

  function onErrorCallback() {
    console.error("ReCAPTCHA error occurred.");
  }

  return (
    <>
      <ReCAPTCHA
        sitekey={siteKey}
        className="mt-4 mb-5"
        ref={recaptchaRef}
        onChange={onChange}
        onLoad={onLoadCallback}
        onError={onErrorCallback}
        // theme="dark"
        // size="invisible"
      />
    </>
  );
}
