export const FormFields = [
  {
    name: "beneficiaries",
    label: "Beneficiaries",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "calculate",
    label: "Calculate",
  },
  {
    isHead: true,
    isDivider: true,
    isSubtitle: true,
    subtitle: "Terms:",
    isTerms: true,
  },
  {
    isHead: true,
    isDivider: true,
    isSubtitle: true,
    subtitle: "Commission Transfer List:",
    isTransfer: true,
  },
  {
    isHead: true,
    isDivider: true,
    isSubtitle: true,
    subtitle: "Adjustments:",
    isAdjustment: true,
  },
  {
    isHead: true,
    isDivider: true,
    isSubtitle: true,
    subtitle: "Descriptions:",
    isDescriptions: true,
  },
];

export const AdjustFields = [
  {
    name: "adjustmentLabel",
    label: "Adjustment Label",
    type: "text",
    placeholder: "Enter adjustment label",
    required: false,
  },
  {
    name: "adjustmentValue",
    label: "Adjustment Value",
    type: "text",
    placeholder: "+ve or -ve adjustment value",
    required: false,
  },
  {
    name: "sDate",
    label: "Start Date",
    type: "date",
    shrink: true,
    required: false,
  },
  {
    name: "eDate",
    label: "End Date",
    type: "date",
    shrink: true,
    required: false,
  },
  {
    isButton: true,
  },
];
