import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import PlanForm from "./planForm";
import PlanToolbar from "./toolbar";
import PlanService from "../../service/planService";

export default function Plan() {
  const {
    rows,
    columns,
    planFieldNames,
    editFormOpen,
    editItemId,
    editFormData,
    viewData,
    showForm,
    reset,
    toggleForm,
    onAddForm,
    onEditForm,
    setViewData,
    featureData,
    setFeatureData,
  } = PlanService();

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />}
            onClick={() => {
              toggleForm();
            }}
          >
            Add Plan
          </Button>
        </Box>

        {showForm && (
          <Paper sx={{ px: 3, py: 2 }}>
            <Typography variant="h6" gutterBottom mb={6}>
              Add Plan
            </Typography>
            {/* Add Form */}
            <PlanForm
              mode="add"
              onClose={toggleForm}
              onAddForm={onAddForm}
              planFieldNames={planFieldNames}
              viewData={viewData}
              setViewData={setViewData}
              featureData={featureData}
              setFeatureData={setFeatureData}
            />
          </Paper>
        )}
      </Box>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: () => <PlanToolbar />,
            pagination: CustomPagination,
            loadingOverlay: LinearProgress,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 25, 50]}
          density="compact"
          // loading
          disableRowSelectionOnClick
          autoHeight={Object.keys(rows).length >= 10}
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />
        <Dialog open={editFormOpen} maxWidth="lg">
          <DialogTitle>
            {viewData && editItemId ? "View" : "Edit"}
            &nbsp;Plan
            {viewData ? (
              <IconButton
                aria-label="edit"
                style={{ position: "absolute", top: 10, right: 45 }}
                color="primary"
                title="Edit"
                onClick={() => setViewData(false)}
              >
                <EditTwoTone />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "8px", right: "8px" }}
              onClick={reset}
            >
              <CloseTwoTone />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* Edit Form */}
            <PlanForm
              mode="edit"
              onClose={reset}
              onEditForm={onEditForm}
              planFieldNames={planFieldNames}
              viewData={viewData}
              setViewData={setViewData}
              editItemId={editItemId}
              editFormData={editFormData}
              featureData={featureData}
              setFeatureData={setFeatureData}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
