import { Button } from "@mui/material";
import { AddTwoTone } from "@mui/icons-material";

export default function AddButton({ dialogType, addFormOpen, roleAccess }) {
  // Function to check if the user has read access for the given dialogType
  const hasReadAccess = (dialogType, roleAccess) => {
    return roleAccess.some(
      (item) => item.resource === dialogType && item.writeAccess
    );
  };

  // Check if the user has read access for the given dialogType
  const canAdd = hasReadAccess(dialogType, roleAccess);

  if (!canAdd) {
    // If the user doesn't have read access, don't render the button
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      startIcon={<AddTwoTone />}
      onClick={addFormOpen}
    >
      Add
    </Button>
  );
}
