import { DataGrid } from "@mui/x-data-grid";
import { AuditLogService } from "../../service/auditLogService";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import AuditLogToolBar from "./toolbar";

export default function AuditLog() {
  const {
    rows,
    columns,
    isTableLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedOption,
    handleGetAll,
    handleGetMyDetails,
    handleGetMyReportee,
    stopRemount,
  } = AuditLogService();

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: EmptyBox,
          toolbar: () => (
            <AuditLogToolBar
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              selectedOption={selectedOption}
              handleGetAll={handleGetAll}
              handleGetMyDetails={handleGetMyDetails}
              handleGetMyReportee={handleGetMyReportee}
              stopRemount={stopRemount}
            />
          ),
          pagination: CustomPagination,
        }}
        disableRowSelectionOnClick
        autoHeight={Object.keys(rows).length >= 10}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        pageSizeOptions={[10, 25, 50]}
        loading={isTableLoading}
        density="compact"
        sx={{
          p: 1,
          height: 530,
          // removing the cell border
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
      />
    </>
  );
}
