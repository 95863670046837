import { Button } from "@mui/material";

const MonitorColumns = ({ approvalStatus, toggleApproval, registeredUser }) => {
  const columns = [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      width: 200,
    },
    // Conditionally add the roleName column
    ...(!registeredUser
      ? [
          {
            field: "roleName",
            headerName: "Role",
            width: 150,
          },
        ]
      : []),
    {
      field: "createdDate",
      headerName: "Join Date",
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    // Conditionally add the action column
    ...(!registeredUser
      ? [
          {
            field: "action",
            headerName: "Action",
            align: "center",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => {
              const { email, beneficiaryId } = params.row;
              const isApproved = approvalStatus[beneficiaryId] || false;

              return (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    bgcolor: isApproved ? "error.main" : "success.main",
                    "&:hover": {
                      bgcolor: isApproved ? "error.dark" : "success.dark", // Adjust hover state if needed
                    },
                  }}
                  onClick={() => toggleApproval(email, beneficiaryId)} // Toggle approval status on click
                >
                  {isApproved ? "Suspend" : "Approved"}
                </Button>
              );
            },
          },
        ]
      : []),
  ];

  return columns; // Ensure the columns array is returned
};

export default MonitorColumns;
