import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import {
  handleNumberFieldKeyDownInRole,
  isEmpty,
  isValueContainsSplChars,
  onError,
} from "../../config/fieldConfig";
import { SPACE_ERROR } from "../../config/toastMessage";

export default function RoleForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  dynamicOptions,
  onAddForm,
  onEditForm,
  fields,
  roleConfigNames,
  // loading,
  apiCall,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Set form data with the provided editFormData
      fields.forEach((field) => {
        setValue(field.name, editFormData[field.name]);
      });
    }
  }, [
    dynamicOptions,
    editFormData,
    editItemId,
    fields,
    mode,
    reset,
    setValue,
    open,
  ]);

  useEffect(() => {
    if (mode === "add") {
      apiCall("", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onSubmit = (formData) => {
    const value = dynamicOptions.find(
      (option) => option.roleName === formData.reportingTo
    );
    formData.reportingTo = value?.roleName ? value?.roleName : "";
    if (mode === "add") {
      onAddForm(formData);
    } else if (mode === "edit") {
      formData.id = editItemId;
      onEditForm(formData);
    }
    onClose();
  };

  const handleRoleNameChange = (enteredValue) => {
    const trimmedEnteredValue = enteredValue.trim();
    const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();
    if (enteredValue !== trimmedEnteredValue) {
      // Leading or trailing spaces detected
      return SPACE_ERROR;
    }

    if (
      mode === "add" &&
      roleConfigNames.some(
        (name) => name.toLowerCase() === lowercasedEnteredValue
      )
    ) {
      return "Role name already exists";
    } else if (
      mode === "edit" &&
      roleConfigNames.some(
        (name) => name.toLowerCase() === lowercasedEnteredValue
      ) &&
      editFormData?.roleName?.toLowerCase() !== lowercasedEnteredValue
    ) {
      return "Role name already exists";
    } else {
      return true; // Return true for a valid input
    }
  };

  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"}
        &nbsp;Role
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoToneIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            onError(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={index} marginTop={1}>
                {field.name === "reportingTo" ? (
                  <Autocomplete
                    options={dynamicOptions}
                    fullWidth
                    getOptionLabel={(option) => option.roleName}
                    isOptionEqualToValue={(option, value) =>
                      option.roleName === value.roleName
                    }
                    value={
                      dynamicOptions.find(
                        (option) => option.roleName === watch(field.name)
                      ) || null
                    }
                    onChange={(_, newValue) => {
                      setValue(field.name, newValue?.roleName || null); // Update form value
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name); // Trigger validation on blur
                      }, 200);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.label}
                        fullWidth
                        variant="outlined"
                        size="small"
                        required={field.required}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        onChange={(e) => {
                          const value = e.target.value;
                          setValue(field.name, value?.roleName || null); // Update form value
                        }}
                        error={!!errors[field.name] && !params.inputProps.value} // Check if field is empty
                        helperText={
                          errors[field.name]?.message &&
                          (!params.inputProps.value
                            ? errors[field.name]?.message
                            : "")
                        } // Show helper text only when there's an error and field is empty
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Set ref
                      />
                    )}
                  />
                ) : (
                  <TextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    autoFocus={field.autoFocus}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      validate: {
                        handleRoleNameChange: (value) =>
                          field.name === "roleName"
                            ? handleRoleNameChange(value)
                            : true,
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        isValueContainsSplChars: (value) =>
                          isValueContainsSplChars(value),
                        minValue: (value) =>
                          field.type === "number" && value < 1
                            ? `${field.label} must be at least 1`
                            : true,
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name); // Trigger validation on blur
                      }, 200);
                    }}
                    inputProps={
                      field.type === "number" ? { min: 1, step: 1 } : {}
                    }
                    onKeyDown={(e) => {
                      if (field.type === "number") {
                        handleNumberFieldKeyDownInRole(e);
                      }
                    }}
                    inputRef={(el) => {
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Set ref
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <DialogActions className="mt-3">
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
