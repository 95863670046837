import axiosClient from "./Api";

// Define a function to get the IP address (for demonstration purposes; replace with actual implementation)
const getIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return "";
  }
};

const getSessionUser = async (email) => {
  try {
    const response = await axiosClient.get("/userSession/getSessionUser", {
      headers: {
        email: email,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const loginSessionUser = async (sessionValues) => {
  try {
    const response = await axiosClient.post(
      "/userSession/loginSessionUser",
      sessionValues
    );
    return response;
  } catch (error) {
    return error;
  }
};

const logoutSessionUser = async (sessionLogoutValues) => {
  try {
    const response = await axiosClient.post(
      "/userSession/logoutSessionUser",
      sessionLogoutValues
    );
    return response;
  } catch (error) {
    return error;
  }
};

// User Activity log Api's

const getAllUserActivityForOrganization = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/userActivity/getAllUserActivityForOrganization",
      {
        headers: {
          Authorization: token,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Audit log Api's
const getAuditLogsWithHierarchy = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/auditLog/getAuditLogsWithHierarchy",
      {
        headers: {
          Authorization: token,
          startDate: startDate, // Pass startDate in the headers
          endDate: endDate, // Pass endDate in the headers
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error in API call:", error);
    return error;
  }
};

const getMyAuditLog = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get("/auditLog/getMyAuditLog", {
      headers: {
        Authorization: token,
        startDate: startDate,
        endDate: endDate,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getAuditLogForReportees = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/auditLog/getAuditLogForReportees",
      {
        headers: {
          Authorization: token,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Field Mapping Api's

const uploadMaster = async (formData, token, fileType, value) => {
  try {
    const response = await axiosClient.post(
      `/products/uploadMaster`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Important header for file upload
          authorization: token,
          fileType: fileType,
          pojoType: value,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const uploadMappingTemplate = async (file, token, fileType, value) => {
  try {
    const response = await axiosClient.post(
      `/products/uploadMappingTemplate`,
      file,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Important header for file upload
          authorization: token,
          fileType: fileType,
          pojoType: value,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getMappingFile = async (token, value) => {
  try {
    const response = await axiosClient.get(`/products/getMappingFile`, {
      headers: {
        "Content-Type": "multipart/form-data", // Important header for file upload
        authorization: token,
        pojoType: value,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const downloadMappingTemplate = async (token, value) => {
  try {
    const response = await axiosClient.get(
      `/products/downloadMappingTemplate`,
      {
        headers: {
          authorization: token,
          pojoType: value,
        },
        responseType: "blob", // Ensure the response is handled as a Blob
      }
    );

    return response; // This will contain the Blob data
  } catch (error) {
    console.error("Error fetching file:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

// Employee Type

const addType = async (values, token) => {
  try {
    const response = await axiosClient.post("/type/addType", values, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateType = async (values, token) => {
  try {
    const response = await axiosClient.put("/type/updateType", values, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteType = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/type/deleteType`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        id: id,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getAllTypes = async (token, typeName) => {
  try {
    const response = await axiosClient.get("/type/getAllTypes", {
      headers: {
        typeName: typeName,
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

// add ticket
const addTicket = async (values, token, image) => {
  try {
    const payload = {
      ...values, // Spread the values object to include its properties in the payload
      image: image,
    };
    const response = await axiosClient.post("/ticket/addTicket", payload, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getTickets = async (token) => {
  try {
    const response = await axiosClient.get("/ticket/getTickets", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateTicket = async (values, token, image) => {
  try {
    const payload = {
      ...values, // Spread the values object to include its properties in the payload
      image: image,
    };
    const response = await axiosClient.put("/ticket/updateTicket", payload, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteTicket = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/ticket/deleteTicket`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        id: id,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Add Form Api's

const addUserAccess = async (values, token) => {
  try {
    const response = await axiosClient.post(
      "/userAccess/addUserAccess",
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addAssignCommission = async (values, token) => {
  try {
    const response = await axiosClient.post(
      `/assignCommission/addAssignCommission`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addBeneficiary = async (values, token, adminUserName) => {
  try {
    const response = await axiosClient.post(
      "/beneficiary/addBeneficiary",
      values,
      {
        headers: {
          "Content-Type": "application/json",
          userName: adminUserName,
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addCommission = async (values, token) => {
  try {
    const response = await axiosClient.post(
      `/commission/addCommission`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addCustomer = async (values, token) => {
  try {
    const response = await axiosClient.post("/customers/addCustomer", values, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const addPayFrequencies = async (values, token) => {
  try {
    const response = await axiosClient.post(
      `/payments/addPaymentFrequency`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addProduct = async (values, token) => {
  try {
    const response = await axiosClient.post(`/products/addProduct`, values, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const addRole = async (values, token) => {
  try {
    const response = await axiosClient.post(`/roles/addRole`, values, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const addScheduler = async (values, token) => {
  try {
    const response = await axiosClient.post("/scheduler/addScheduler", values, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const addTransaction = async (values, token) => {
  try {
    const response = await axiosClient.post(
      `/transactions/addTransaction`,
      values,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const addVariable = async (values, token) => {
  try {
    const response = await axiosClient.post(`/variables/addVariable`, values, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Update Form Api's

const updateAssignCommission = async (values, token) => {
  try {
    const response = await axiosClient.put(
      `/assignCommission/updateAssignCommission`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateBeneficiary = async (formData, token) => {
  try {
    const response = await axiosClient.put(
      `/beneficiary/updateBeneficiary`,
      formData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateCommission = async (mainFormData, token) => {
  try {
    const response = await axiosClient.put(
      `/commission/updateCommission`,
      mainFormData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateCustomer = async (mainFormData, token) => {
  try {
    const response = await axiosClient.put(
      `/customers/updateCustomer`,
      mainFormData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updatePayFrequency = async (mainFormData, token) => {
  try {
    const response = await axiosClient.put(
      `/payments/updatePaymentFrequency`,
      mainFormData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateProduct = async (formData, token) => {
  try {
    const response = await axiosClient.put(
      `/products/updateProduct`,
      formData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateRole = async (formData, token) => {
  try {
    const response = await axiosClient.put(`/roles/updateRole`, formData, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateScheduler = async (scheduleData, token) => {
  try {
    const response = await axiosClient.put(
      "/scheduler/updateScheduler",
      scheduleData,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateTransaction = async (formData, token) => {
  try {
    const response = await axiosClient.put(
      `/transactions/updateTransaction`,
      formData,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateUserAccess = async (values, token) => {
  try {
    const response = await axiosClient.put(
      "/userAccess/updateUserAccess",
      values,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updateVariable = async (values, token) => {
  try {
    const response = await axiosClient.put(
      "/variables/updateVariable",
      values,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Delete Form Api's

const deleteAssignCommission = async (id, token) => {
  try {
    const response = await axiosClient.delete(
      `/assignCommission/deleteAssignCommission`,
      {
        headers: {
          "Content-Type": "application/json",
          id: id,
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteBeneficiary = async (id, token) => {
  try {
    const response = await axiosClient.delete(
      `/beneficiary/deleteBeneficiary`,
      {
        headers: {
          id: id,
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteCommission = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/commission/deleteCommission`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        id: id,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteCustomer = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/customers/deleteCustomer`, {
      headers: {
        id: id,
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deletePayFrequency = async (id, token) => {
  try {
    const response = await axiosClient.delete(
      `/payments/deletePaymentFrequency`,
      {
        headers: {
          id: id,
          authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteProduct = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/products/deleteProduct`, {
      headers: {
        id: id,
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteRole = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/roles/deleteRole`, {
      headers: {
        id: id,
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteScheduler = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/scheduler/deleteScheduler`, {
      headers: {
        "Content-Type": "application/json",
        authorization: token,
        id: id,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const deleteTransaction = async (id, token) => {
  try {
    const response = await axiosClient.delete(
      `/transactions/deleteTransaction`,
      {
        headers: {
          id: id,
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deleteVariable = async (id, token) => {
  try {
    const response = await axiosClient.delete(`/variables/deleteVariable`, {
      headers: {
        id: id,
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Outer Screen Api's

const forgotPassword = async (user, url) => {
  try {
    const response = await axiosClient.post("/register/forgotPassword", null, {
      headers: {
        userEmail: user.userEmail,
        url: url,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const jwtVerifiedToken = async (token, sessionId, ipAddress) => {
  return axiosClient
    .get(`/user/verifyToken`, {
      headers: {
        authorization: token,
        sessionId: sessionId,
        ipAddress: ipAddress,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const postUserData = async (
  user,
  domain,
  selectedTimezone,
  recaptchaResponse
) => {
  try {
    const response = await axiosClient.post(`/register/save`, user, {
      headers: {
        domain: domain,
        timeZone: selectedTimezone,
      },
      params: {
        "g-recaptcha-response": recaptchaResponse,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const recoverPassword = async (user, resetToken) => {
  try {
    const response = await axiosClient.post("/register/setPassword", null, {
      params: {
        token: resetToken,
        password: user.password,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const resetPassword = async (userId, orgId, oldPassword, currentPassword) => {
  try {
    const response = await axiosClient.post(`/register/resetPassword`, null, {
      headers: {
        userId: userId,
        orgId: orgId,
        oldPassword: oldPassword,
        currentPassword: currentPassword,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const SignIn = async (user, isRecaptcha, recaptchaResponse) => {
  try {
    const response = await axiosClient.post(`/user/login`, user, {
      headers: {
        isRecaptcha: isRecaptcha.toString(),
      },
      params: {
        "g-recaptcha-response": recaptchaResponse,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

const getAllUserEmails = async () => {
  try {
    const response = await axiosClient.get(`/register/getAllUserEmails`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const SignUp = async (user, verifyToken) => {
  try {
    const response = await axiosClient.post(
      `/register/completeUserRegistration`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
          token: verifyToken,
          orgNameWithId: "null",
          orgName: "null",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const privateAddUserAccess = async (combinedData, orgId, userName) => {
  try {
    const response = await axiosClient.post(
      `/userAccess/privateAddUserAccess`,
      combinedData,
      {
        headers: {
          "Content-Type": "application/json",
          orgId: orgId,
          userName: userName,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const verifiedToken = async (verifyToken) => {
  return axiosClient
    .get(`/register/verifyToken`, {
      params: {
        token: verifyToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const verifyResetPasswordToken = async (token) => {
  try {
    const response = await axiosClient.get(
      "/register/verifyResetPasswordToken",
      {
        params: { token: token },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Inner Screen Api's

const calculateCommissionForSingleUser = async (id, token) => {
  try {
    const response = await axiosClient.get(
      "/creditTransaction/calculateCommissionForSingleUser",
      {
        headers: {
          beneficiaryId: id,
          Authorization: token,
          frequencyName: "Monthly",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const downloadAssignCommissionPdf = async (token, value) => {
  try {
    const response = await axiosClient.get(
      "/assignCommission/downloadAssignCommissionPdf",
      {
        headers: {
          Authorization: token,
          beneficiaryId: value.row.beneficiaries,
          beneficiaryName: value.row.beneficiaryName,
        },
        responseType: "arraybuffer", // Ensure response is treated as binary data
      }
    );
    return response; // Return the response data
  } catch (error) {
    return error;
  }
};

const getAssignedCommWithHierarchy = async (token) => {
  try {
    const response = await axiosClient.get(
      "/assignCommission/getAssignedCommWithHierarchy",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAssignedCommissionsForReportees = async (token) => {
  try {
    const response = await axiosClient.get(
      "/assignCommission/getAssignedCommissionsForReportees",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getMyAssignedCommissions = async (token) => {
  try {
    const response = await axiosClient.get(
      "/assignCommission/getMyAssignedCommissions",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getBeneficiariesWithHierarchy = async (token) => {
  try {
    const response = await axiosClient.get(
      "/beneficiary/getBeneficiariesWithHierarchy",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getActiveBeneficiaries = async (token) => {
  try {
    const response = await axiosClient.get(
      "/beneficiary/getActiveBeneficiaries",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCommissions = async (token) => {
  try {
    const response = await axiosClient.get("/commission/getCommissions", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCommissionNames = async (token) => {
  try {
    const response = await axiosClient.get("/commission/getCommissionNames", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getPaymentFrequencies = async (token) => {
  try {
    const response = await axiosClient.get("/payments/getPaymentFrequencies", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getFrequencies = async (token) => {
  try {
    const response = await axiosClient.get("/frequencyData/getFrequencies", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getProducts = async (token) => {
  try {
    const response = await axiosClient.get("/products/getProducts", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getRoles = async (token) => {
  try {
    const response = await axiosClient.get("/roles/getRoles", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getSchedules = async (token) => {
  try {
    const response = await axiosClient.get("/scheduler/getSchedules", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAllJobProgress = async (token) => {
  try {
    const response = await axiosClient.get("/scheduler/getAllJobProgress", {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getTransByHierarchy = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/transactions/getTransByHierarchy",
      {
        headers: {
          Authorization: token,
          startDate: startDate,
          endDate: endDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAllTransactions = async (token) => {
  try {
    const response = await axiosClient.get("/transactions/getAllTransactions", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAllUserAccess = async (token) => {
  try {
    const response = await axiosClient.get(`/userAccess/getAllUserAccess`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getVariables = async (token) => {
  try {
    const response = await axiosClient.get("/variables/getVariables", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCustomers = async (token) => {
  try {
    const response = await axiosClient.get("/customers/getCustomers", {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getLastPaymentFrequency = async (token) => {
  try {
    const response = await axiosClient.get(
      "/payments/getLastPaymentFrequency",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getMappingsByPojo = async (selectedScreen, token) => {
  try {
    const response = await axiosClient.get(`/products/getMappingsByPojo`, {
      headers: {
        pojoType: selectedScreen,
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getFieldsForPojo = async (selectedScreen) => {
  try {
    const response = await axiosClient.get(`/products/getFieldsForPojo`, {
      params: {
        pojo: selectedScreen,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getTermsDescription = async () => {
  try {
    const response = await axiosClient.get(
      "/termsDescription/getTermsDescription"
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const saveMappings = async (values, selectedOption, token) => {
  try {
    const response = await axiosClient.post(`/products/saveMappings`, values, {
      headers: {
        authorization: token,
        pojoType: selectedOption,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getUser = async (token) => {
  try {
    const response = await axiosClient.get(`/companySetUp/getUser`, {
      headers: {
        authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const updateRegistration = async (token, values) => {
  try {
    const response = await axiosClient.put(
      `/companySetUp/updateRegistration`,
      values,
      {
        headers: {
          authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const uploadImage = async (token, logoImage) => {
  try {
    const payload = {
      image: logoImage, // Set the image data in the payload object
    };

    const response = await axiosClient.post(
      `/companySetUp/uploadImage`,
      JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getImageBytes = async (token) => {
  try {
    const response = await axiosClient.get(`/companySetUp/getImageBytes`, {
      headers: {
        authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const updateUserWithImage = async (
  token,
  file,
  data,
  currency,
  selectedTimezone,
  gst,
  email
) => {
  try {
    const payload = {
      image: file,
    };

    const response = await axiosClient.post(
      `/companySetUp/updateUserWithImage`,
      payload,
      {
        headers: {
          Authorization: token,
          currency: currency,
          timeZone: selectedTimezone,
          gst: gst,
          email: email,
        },
        params: {
          user: JSON.stringify(data), // Convert the 'data' object to JSON string
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

const generateOtpForEmail = async (email, orgId) => {
  try {
    const response = await axiosClient.post(
      `/companySetUp/generateOtpForEmail`,
      null,
      {
        headers: {
          email: email,
          orgId: orgId,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const otpVerifications = async (email, value, token) => {
  try {
    const response = await axiosClient.post(`/companySetUp/verifyOtp`, null, {
      headers: {
        email: email,
        otp: value,
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Dashboard Api's

const getCreditTransactions = async (token, formData) => {
  try {
    const response = await axiosClient.get(
      "/creditTransaction/getCreditTransactions",
      {
        headers: {
          Authorization: token,
          beneficiaryId: formData.beneficiaries,
          fiscalYear: formData.year,
          period: formData.period,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const fetchCommissionBySalesRep = async (
  endpoint,
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) => {
  try {
    const response = await axiosClient.get(endpoint, {
      headers: {
        Authorization: token,
        salesRepId: salesRep,
        orgId: orgId,
      },
      params: {
        order,
        count,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getCreditTransForUser = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionBySalesRep(
    "/creditTransaction/getCreditTransForUser",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getCreditTransForReportees = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionBySalesRep(
    "/creditTransaction/getCreditTransForReportees",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getTotalCreditForDateRange = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/creditTransaction/getTotalCreditForDateRange",
      {
        headers: {
          Authorization: token,
        },
        params: { startDate: startDate, endDate: endDate },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getBeneficiariesForDirectReportees = async (config) => {
  try {
    const response = await axiosClient.get(
      "/beneficiary/getBeneficiariesForDirectReportees",
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const fetchCommissionProducts = async (
  endpoint,
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) => {
  try {
    const response = await axiosClient.get(endpoint, {
      headers: {
        Authorization: token,
        salesRepId: salesRep,
        orgId: orgId,
      },
      params: {
        order,
        count,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getIndividualSalesByProduct = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionProducts(
    "/creditTransaction/getIndividualSalesByProduct",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getReporteesSalesByProduct = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionProducts(
    "/creditTransaction/getReporteesSalesByProduct",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const fetchCommissionCustomers = async (
  endpoint,
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) => {
  try {
    const response = await axiosClient.get(endpoint, {
      headers: {
        Authorization: token,
        salesRepId: salesRep,
        orgId: orgId,
      },
      params: {
        order,
        count,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getIndividualSalesByCustomer = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionCustomers(
    "/creditTransaction/getIndividualSalesByCustomer",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getReporteesSalesByCustomer = (
  token,
  order,
  count,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchCommissionCustomers(
    "/creditTransaction/getReporteesSalesByCustomer",
    token,
    order,
    count,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const fetchTotalCommission = async (
  endpoint,
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) => {
  try {
    const response = await axiosClient.get(endpoint, {
      headers: {
        Authorization: token,
        salesRepId: salesRep,
        orgId: orgId,
      },
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getIndividualTotalCommission = (
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchTotalCommission(
    "/creditTransaction/getIndividualTotalCommission",
    token,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getReporteesTotalCommission = (
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchTotalCommission(
    "/creditTransaction/getReporteesTotalCommission",
    token,
    startDate,
    endDate,
    salesRep,
    orgId
  );

// Individual Dashboard Api's

const fetchNotCalculatedTransaction = async (
  endpoint,
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) => {
  try {
    const response = await axiosClient.get(endpoint, {
      headers: {
        Authorization: token,
        salesRepId: salesRep,
        orgId: orgId,
      },
      params: {
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getIndividualUnProcessedTrans = (
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchNotCalculatedTransaction(
    "/creditTransaction/getIndividualUnProcessedTrans",
    token,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getReporteesUnProcessedTrans = (
  token,
  startDate,
  endDate,
  salesRep,
  orgId
) =>
  fetchNotCalculatedTransaction(
    "/creditTransaction/getReporteesUnProcessedTrans",
    token,
    startDate,
    endDate,
    salesRep,
    orgId
  );

const getCommissionTrends = async (token, startDate, endDate) => {
  try {
    const response = await axiosClient.get(
      "/creditTransaction/getCommissionTrends",
      {
        headers: {
          Authorization: token,
        },
        params: { startDate: startDate, endDate: endDate },
      }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

//download files
const downloadCommissionPDF = async (token, values) => {
  try {
    const response = await axiosClient.get(
      "creditTransaction/downloadCommissionPDF",
      {
        headers: {
          Authorization: token,
          beneficiaryId: values.beneficiaries,
          year: values.year,
          period: values.period,
        },
        responseType: "arraybuffer", // Ensure response is treated as binary data
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const downloadCommissionExcel = async (token, values) => {
  try {
    const response = await axiosClient.get(
      "creditTransaction/downloadCommissionExcel",
      {
        headers: {
          Authorization: token,
          beneficiaryId: values.beneficiaries,
          year: values.year,
          period: values.period,
        },
        responseType: "arraybuffer", // Ensure response is treated as binary data
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Monitor Api
const getAllUsersBasedOnType = async (token, type) => {
  try {
    const response = await axiosClient.get("register/getAllUsersBasedOnType", {
      headers: {
        Authorization: token,
        type: type,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const getAllRegistrationsBasedOnType = async (token, type) => {
  try {
    const response = await axiosClient.get(
      "register/getAllRegistrationsBasedOnType",
      {
        headers: {
          Authorization: token,
          type: type,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const editUser = async (token, values) => {
  try {
    const response = await axiosClient.put("/register/editUser", values, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Pricing Api's
const getPricingPlans = async (token) => {
  try {
    const response = await axiosClient.get("pricingPlans/getPricingPlans", {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const addPricingPlan = async ({ token, formData }) => {
  try {
    const response = await axiosClient.post(
      "pricingPlans/addPricingPlan",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const updatePricingPlan = async ({ token, editFormData, userName }) => {
  try {
    const response = await axiosClient.put(
      "pricingPlans/updatePricingPlan",
      editFormData,
      {
        headers: {
          Authorization: token,
          userName: userName,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const deletePricingPlan = async (id) => {
  try {
    const response = await axiosClient.delete(
      "pricingPlans/deletePricingPlan",
      {
        headers: {
          id: id,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Stripe Payment Api's
const createCardToken = async (token) => {
  try {
    const response = await axiosClient.post("stripe/createCardToken", token);
    return response;
  } catch (error) {
    return error;
  }
};

const charge = async (values) => {
  try {
    const response = await axiosClient.post("stripe/charge", values);
    return response;
  } catch (error) {
    return error;
  }
};

// Razorpay Payment Api's

const createOrder = async (amount, currency) => {
  try {
    const response = await axiosClient.post("stripe/createOrder", null, {
      headers: {
        amount: amount,
        currency: currency,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

const capturePayment = async (values) => {
  try {
    const response = await axiosClient.post("stripe/capturePayment", null, {
      headers: values,
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Get Payment Api's
const getPaymentHistoryForOrgId = async (token, orgId) => {
  try {
    const response = await axiosClient.get("stripe/getPaymentHistoryForOrgId", {
      headers: {
        Authorization: token,
        orgId: orgId,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

const getPaymentHistory = async (token) => {
  try {
    const response = await axiosClient.get("stripe/getPaymentHistory", {
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// MFA Api's

const verifyOtp = async (userEmail, otpCode) => {
  try {
    const response = await axiosClient.post("mfa/verifyOtp", null, {
      headers: {
        userEmail: userEmail,
        otpCode: otpCode,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

// Invoice Api's

const downloadInvoicePDF = async (token, id) => {
  try {
    const response = await axiosClient.get("/invoice/downloadInvoicePDF", {
      headers: {
        Authorization: token,
        id: id,
      },
      responseType: "arraybuffer", // Ensure response is treated as binary data
    });
    return response; // Return the response data
  } catch (error) {
    return error;
  }
};

const ApiService = {
  // Session Ip
  getIpAddress,
  getSessionUser,
  loginSessionUser,
  logoutSessionUser,

  // User Activity Api's
  getAllUserActivityForOrganization,

  // Audit log Api's
  getAuditLogsWithHierarchy,
  getMyAuditLog,
  getAuditLogForReportees,

  // Field Mapping Api's
  uploadMaster,
  uploadMappingTemplate,
  getMappingFile,
  downloadMappingTemplate,

  // Master Type Api's
  addType,
  updateType,
  deleteType,
  getAllTypes,

  // Ticket Api's
  addTicket,
  getTickets,
  updateTicket,
  deleteTicket,

  // Add Form Api's
  addUserAccess,
  addAssignCommission,
  addBeneficiary,
  addCommission,
  addCustomer,
  addPayFrequencies,
  addProduct,
  addRole,
  addScheduler,
  addTransaction,
  addVariable,

  // Update Form Api's
  updateAssignCommission,
  updateBeneficiary,
  updateCommission,
  updateCustomer,
  updatePayFrequency,
  updateProduct,
  updateRole,
  updateScheduler,
  updateTransaction,
  updateUserAccess,
  updateVariable,

  // Delete Form Api's
  deleteAssignCommission,
  deleteBeneficiary,
  deleteCommission,
  deleteCustomer,
  deletePayFrequency,
  deleteProduct,
  deleteRole,
  deleteScheduler,
  deleteTransaction,
  deleteVariable,

  // Outer Screen Api's
  forgotPassword,
  jwtVerifiedToken,
  postUserData,
  recoverPassword,
  resetPassword,
  SignIn,
  getAllUserEmails,
  SignUp,
  privateAddUserAccess,
  verifiedToken,
  verifyResetPasswordToken,

  // Inner Screen Api's
  calculateCommissionForSingleUser,
  downloadAssignCommissionPdf,
  getAssignedCommWithHierarchy,
  getAssignedCommissionsForReportees,
  getMyAssignedCommissions,
  getBeneficiariesWithHierarchy,
  getActiveBeneficiaries,
  getCommissions,
  getCommissionNames,
  getPaymentFrequencies,
  getFrequencies,
  getProducts,
  getRoles,
  getSchedules,
  getAllJobProgress,
  getTransByHierarchy,
  getAllTransactions,
  getAllUserAccess,
  getVariables,
  getCustomers,
  getLastPaymentFrequency,
  getMappingsByPojo,
  getFieldsForPojo,
  getTermsDescription,
  saveMappings,
  uploadImage,
  getUser,
  updateRegistration,
  getImageBytes,
  updateUserWithImage,

  // companySetUp otp
  generateOtpForEmail,
  otpVerifications,

  // Dashboard
  getCreditTransactions,
  getCommissionTrends,

  getCreditTransForUser,
  getCreditTransForReportees,
  getTotalCreditForDateRange,
  getBeneficiariesForDirectReportees,

  getIndividualSalesByProduct,
  getReporteesSalesByProduct,
  getIndividualSalesByCustomer,
  getReporteesSalesByCustomer,

  getIndividualTotalCommission,
  getReporteesTotalCommission,

  // Individual Dashboard Api's
  getIndividualUnProcessedTrans,
  getReporteesUnProcessedTrans,

  //download files
  downloadCommissionPDF,
  downloadCommissionExcel,

  // Monitor user
  getAllUsersBasedOnType,
  getAllRegistrationsBasedOnType,
  editUser,

  // Pricing Api's
  getPricingPlans,
  addPricingPlan,
  updatePricingPlan,
  deletePricingPlan,

  // Stripe Payment Api's
  createCardToken,
  charge,

  // Razorpay Payment Api's
  createOrder,
  capturePayment,

  // Get Payment Api's
  getPaymentHistoryForOrgId,
  getPaymentHistory,

  // MFA Verify Api's
  verifyOtp,

  // Invoice Api's
  downloadInvoicePDF,
};

export default ApiService;
