export const FormFields = [
  {
    name: "variableName",
    label: "Variable Name",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "variable",
    label: "Variable",
    type: "text",
    required: false,
  },
  {
    name: "scope",
    label: "Scope",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    multiline: true,
    rows: 3,
    required: true,
  },
];
