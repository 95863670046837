import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import VariableColumns from "./columns";
import VariableToolbar from "./toolbar";
import VariableForm from "./variableForm";
import { VariableService } from "../../service/variableService";

export default function Variables() {
  const {
    rows,
    open,
    editFormOpen,
    editItemId,
    editFormData,
    setEditFormOpen,
    addFormOpen,
    addFormClose,
    onAddForm,
    onEditForm,
    handleEdit,
    handleDelete,
    fields,
    roleAccess,
    loading,
    data,
  } = VariableService();

  const columns = VariableColumns(handleEdit, handleDelete, roleAccess);

  const [showDataGrid, setShowDataGrid] = useState(false);
  useEffect(() => {
    // // Set a timeout to show the DataGrid after 2 seconds (2000 milliseconds)
    // const timer = setTimeout(() => {
    setShowDataGrid(true);
    // }, 2000);

    // // Clean up the timeout if the component unmounts before the timeout completes
    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box>
        {showDataGrid ? (
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              noRowsOverlay: EmptyBox,
              toolbar: () => (
                <VariableToolbar
                  addFormOpen={addFormOpen}
                  roleAccess={roleAccess}
                />
              ),
              pagination: CustomPagination,
            }}
            disableRowSelectionOnClick
            autoHeight={Object.keys(rows).length >= 10}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 25, 50]}
            loading={loading}
            density="compact"
            sx={{
              p: 1,
              height: 530,
              // removing the cell border
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        ) : (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        {/* Handle both and and edit form display */}
        <VariableForm
          open={editFormOpen || open}
          onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
          mode={editFormOpen ? "edit" : "add"}
          editItemId={editFormOpen ? editItemId : undefined}
          editFormData={editFormOpen ? editFormData : undefined}
          onEditForm={editFormOpen ? onEditForm : undefined}
          onAddForm={!editFormOpen ? onAddForm : undefined}
          fields={fields}
          variableData={data}
        />
      </Box>
    </>
  );
}
