import { useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import UpgradeDialog from "../Pricing/upgradeDialog";
import { PricingService } from "../../service/pricingService";
import { formatDateDDMMYYYY } from "../../config/fieldConfig";

export default function Subscriptions({
  subscriptionStartDate,
  subscriptionEndDate,
  renewalPrice,
  renewalPlan,
  renewalMonth,
  noOfBeneficiary,
  currentCurrency,
  fetchData,
}) {
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const { pricingPlans } = PricingService(); // Fetch pricing plans
  console.log(pricingPlans); // Verify the fetched plans

  const handleClickOpen = (planTitle) => {
    // Find the plan by plan
    const plan = pricingPlans.find((p) => p.title === planTitle);
    setSelectedPlan(plan); // Set the selected plan object
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(null);
  };

  return (
    <>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6} md={1.5}>
          <Typography gutterBottom>Current Plan:</Typography>
          <Typography gutterBottom>{renewalPlan || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>No of Beneficiary:</Typography>
          <Typography gutterBottom>{noOfBeneficiary || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>Start Date:</Typography>
          <Typography gutterBottom>
            {formatDateDDMMYYYY(subscriptionStartDate) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>End Date:</Typography>
          <Typography gutterBottom>
            {formatDateDDMMYYYY(subscriptionEndDate) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography gutterBottom>{`Renewal Cost (${
            currentCurrency || "INR"
          }):`}</Typography>
          <Typography gutterBottom>{renewalPrice || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2.5}>
          <Button variant="contained" href={"/pricing"} sx={{ mt: 2, mr: 2 }}>
            Upgrade
          </Button>

          <Button
            variant="outlined"
            onClick={() => handleClickOpen(renewalPlan)} // Example: passing the plan
            sx={{ mt: 2 }}
          >
            Renew now
          </Button>
          {open && selectedPlan && (
            <Box>
              <UpgradeDialog
                open={open}
                handleClose={handleClose}
                plan={selectedPlan} // Pass the selected plan object
                noOfBeneficiary={noOfBeneficiary}
                renewalMonth={renewalMonth}
                fetchData={fetchData}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
