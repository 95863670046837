import { useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import { CustomNavigate } from "../config/navigation";
import { addSerialNumber, handleApiCall } from "../config/fieldConfig";
import { FormFields } from "../pages/Schedule/formFields";
import useFetchOnMount from "../config/useFetchOnMount";

export const ScheduleService = () => {
  const [token, setToken] = useState("");
  const [data, setTableData] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [selectedZoneTime, setSelectedZoneTime] = useState();
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const { navigateError403 } = CustomNavigate();

  const roleAccess = ROLE_ACCESS();

  const [open, setOpen] = useState(false);

  const addFormOpen = () => {
    setOpen(true);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  const fields = [...FormFields];

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const getAllSchedule = await ApiService.getSchedules(TOKEN);
      setTableData(getAllSchedule);

      const res = await ApiService.getUser(TOKEN);
      const regData = res?.data;

      if (res?.status === 200 && regData) {
        const firstElement = regData[0];
        setSelectedZoneTime(firstElement?.organization?.timeZone);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const rows = addSerialNumber(data);

  const handleEdit = (id) => () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id);
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addScheduler,
        edit: ApiService.updateScheduler,
        delete: ApiService.deleteScheduler,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this schedule?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  return {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    fields,
    onAddForm,
    onEditForm,
    handleEdit,
    setEditFormOpen,
    handleDelete,
    open,
    addFormOpen,
    addFormClose,
    roleAccess,
    selectedZoneTime,
    loading,
  };
};
