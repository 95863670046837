import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../apis/ApiService";
import { CustomNavigate } from "../config/navigation";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import {
  addSerialNumber,
  handleApiCall,
  PAGE_OPTIONS,
} from "../config/fieldConfig";
import { FormFields } from "../pages/Type/formFields";

export const TypeService = () => {
  // select the screen
  const [selectedOption, setSelectedOption] = useState(PAGE_OPTIONS[0]);
  const [token, setToken] = useState(null);
  const [data, setData] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([1]);
  const [loading, setLoading] = useState(true); // Initial loading state is true

  //checkBox model
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const { navigateError403 } = CustomNavigate();

  const roleAccess = ROLE_ACCESS();
  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      setLoading(true);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const typeData = await ApiService.getAllTypes(TOKEN, selectedOption); //config values
      setData(typeData);

      // Extract the productType from each object and store them in an array
      const employeeTypes = typeData.map((employee) => employee.typeValue);
      setProductTypeOptions(employeeTypes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => async () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id);
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addType,
        edit: ApiService.updateType,
        delete: ApiService.deleteType,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this type?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  // Apply the formatting and update table data
  const rows = addSerialNumber(data);

  const fields = FormFields;

  return {
    fields,
    PAGE_OPTIONS,
    stopRemount,
    setSelectedOption,
    productTypeOptions,
    selectedOption,
    // isEdit,
    roleAccess,
    rows,
    onAddForm,
    onEditForm,
    handleEdit,
    handleDelete,
    editFormData,
    editFormOpen,
    editItemId,
    setEditFormOpen,
    loading,
  };
};
