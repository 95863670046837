import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { DownloadTwoTone } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import "jspdf-autotable";
import ApiService from "../../../apis/ApiService";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import { formatCurrency, formatDateDDMMYYYY } from "../../config/fieldConfig";
import { FormFields } from "./formFields";
import { ReportService } from "../../service/reportService";

const ReportScreen = () => {
  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();

  const {
    beneficiariesOptions,
    frequenciesOptions,
    yearOptions,
    periodOptions,
    tableData,
    setBeneficiary,
    setSelectYear,
    user,
    setSelectPeriod,
    setSelectFrequencies,
    commissionAmount,
    otherAdjustment,
    netPayoutAmount,
    setFormData,
    formData,
    token,
    logoImage,
    columns,
    stopRemount,
    fetchData_1,
    capAdjustment,
    advanceAdjustment,
    minPayAdjustment,
    adjustmentPayout,
    recoveryPayout,
    currency,
    notCalculatedPeriods,
    setCommissionAmount,
    setOtherAdjustment,
    setNetPayoutAmount,
    setCapAdjustment,
    setAdvanceAdjustment,
    setMinPayAdjustment,
    setAdjustmentPayout,
    setRecoveryPayout,
    selectYear,
    selectFrequencies,
    selectPeriod,
    beneficiary,
    viewData,
    SingleData,
    showForm,
    setShowForm,
    beneficiariesNameId,
    selectedBeneficiary,
    setSelectedBeneficiary,
  } = ReportService();

  const [groupNames, setGroupNames] = useState();

  var logoImage1 = `data:image/png;base64,${logoImage}`;

  const fields = FormFields(
    beneficiariesOptions,
    yearOptions,
    frequenciesOptions,
    periodOptions
  );

  useEffect(() => {
    if (!viewData) {
      setValue("beneficiaries", beneficiary);
      setValue("year", selectYear || ""); // Set default value to empty string if selectYear is undefined
      setValue("frequencies", selectFrequencies || ""); // Set default value to empty string if selectFrequencies is undefined
      setValue("period", selectPeriod || ""); // Set default value to empty string if selectPeriod is undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    beneficiary,
    selectYear,
    selectFrequencies,
    selectPeriod,
    setFormData,
  ]);

  useEffect(() => {
    if (viewData) {
      const fiscalPeriod = SingleData.matchingCalculation;

      const formattedPeriod = `${
        fiscalPeriod.periodNumber
      } [${fiscalPeriod.startDate.replace(
        /-/g,
        "/"
      )} - ${fiscalPeriod.endDate.replace(/-/g, "/")}]`;

      setSelectPeriod(formattedPeriod);
      setValue("beneficiaries", SingleData.matchingBeneficiary.userId);
      setValue("year", SingleData.matchingCalculation.fiscalYear || ""); // Set default value to empty string if selectYear is undefined
      setValue("frequencies", SingleData.frequencies || ""); // Set default value to empty string if selectFrequencies is undefined
      setValue("period", formattedPeriod || ""); // Set default value to empty string if selectPeriod is undefined

      const formattedData = {
        beneficiaries: SingleData.matchingBeneficiary.userId,
        year: SingleData.matchingCalculation.fiscalYear || "", // Set default value to empty string if selectYear is undefined
        frequencies: SingleData.frequencies || "", // Set default value to empty string if selectFrequencies is undefined
        period: formattedPeriod || "", // Set default value to empty string if selectPeriod is undefined
      };

      if (formattedPeriod) {
        const Period = formattedPeriod.replace(/\s+\[/g, "[");

        const updatedFormData = {
          ...formattedData,
          period: Period,
        };
        setFormData(updatedFormData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, viewData, beneficiariesNameId]);

  const onSubmit = (formData) => {
    try {
      // Remove space in the period field
      formData.period = formData.period.replace(/\s+\[/g, "[");

      // Remove space before the opening square bracket
      const cleanedPeriod = selectPeriod.replace(/\s*\[/, "[");
      // Create a new object with the updated beneficiaries field
      const updatedFormData = {
        ...formData,
        period: cleanedPeriod,
        beneficiaries: beneficiary,
      };

      // Print the updated formData object
      setFormData(updatedFormData);
      fetchData_1(updatedFormData, setShowForm, showForm, false, null);
      setCommissionAmount("");
      setOtherAdjustment("");
      setNetPayoutAmount("");
      setCapAdjustment("");
      setAdvanceAdjustment("");
      setMinPayAdjustment("");
      setAdjustmentPayout("");
      setRecoveryPayout("");
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Compute groupNames separately
  useEffect(() => {
    const names = Object.keys(tableData).join(", ");
    setGroupNames(names);
  }, [tableData]);

  let sno = 1; // Initialize serial number counter

  const flattenData = Object.entries(tableData).flatMap(
    ([groupName, groupRecords]) => {
      const initialSno = sno; // Store the initial sno value for this group
      const flattenedGroupRecords = groupRecords.map((record) => ({
        ...record,
        date: formatDateDDMMYYYY(record.date),
        sno: sno++, // Add sno property and increment the counter
      }));

      const groupTotal = flattenedGroupRecords.reduce(
        (acc, record) => {
          acc.salesAmount += record.salesAmount || 0;
          acc.creditAmount += record.creditAmount || 0;
          acc.commissionAmount += record.commissionAmount || 0;
          return acc;
        },
        { salesAmount: 0, creditAmount: 0, commissionAmount: 0 }
      );

      // Format the values to two decimal places
      groupTotal.salesAmount = formatCurrency(groupTotal.salesAmount);
      groupTotal.creditAmount = formatCurrency(groupTotal.creditAmount);
      groupTotal.commissionAmount = formatCurrency(groupTotal.commissionAmount);
      sno = initialSno + groupRecords.length; // Update sno counter for the next group

      return [
        {
          id: `${groupName}_header`,
          transactionId: `${groupName}`, // Using transactionId as part of the ID
        },
        ...flattenedGroupRecords.map((record) => ({
          ...record,
          // Format the values to two decimal places in records
          salesAmount: formatCurrency(record.salesAmount),
          creditAmount: formatCurrency(record.creditAmount),
          commissionRate: formatCurrency(record.commissionRate),
          commissionAmount: formatCurrency(record.commissionAmount),
        })),
        {
          id: `${groupName}_total`,
          productName: "Total",
          groupName: `${groupName} Total`,
          ...groupTotal,
        },
      ];
    }
  );

  const rows = flattenData;

  const gridRef = useRef();

  const downloadAsPdf = async () => {
    try {
      const response = await ApiService.downloadCommissionPDF(token, formData);

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data");
        return;
      }
      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      // a.download = "Report.pdf";
      a.download = `${user.userName}.pdf`;
      a.click();

      // Revoke object URL after download
      window.URL.revokeObjectURL(url);
      handleMenuClose();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const downloadAsExcel = async () => {
    try {
      const res = await ApiService.downloadCommissionExcel(token, formData);
      if (res instanceof Error) {
        // Handle error response
        console.error("Error downloading Excel:", res);
        return;
      }

      // Create a Blob from the response data
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      // a.download = "Report.xlsx"; // File name
      a.download = `${user.userName}.xlsx`;
      document.body.appendChild(a);
      a.click();

      // Revoke the object URL after download
      window.URL.revokeObjectURL(url);
      handleMenuClose();
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const [formattedPeriod, setFormattedPeriod] = useState("");

  useEffect(() => {
    if (SingleData && SingleData.matchingCalculation) {
      const fiscalPeriod = SingleData.matchingCalculation;

      const formattedStartDate = fiscalPeriod.startDate.replace(/-/g, "/");

      const formattedEndDate = fiscalPeriod.endDate.replace(/-/g, "/");

      const periodNumber = fiscalPeriod.periodNumber;

      setFormattedPeriod(
        `${periodNumber} [${formattedStartDate} - ${formattedEndDate}]`
      );
    }
  }, [SingleData]);

  const formatPeriod = (period) => {
    // Extract the date range within the brackets
    const match = period.match(/\[(.*?)\]/);
    if (match) {
      const [startDate, endDate] = match[1].split(" - ");
      const formattedStartDate = formatDateDDMMYYYY(startDate);
      const formattedEndDate = formatDateDDMMYYYY(endDate);

      // If either date is invalid, return "Invalid format"
      if (!formattedStartDate || !formattedEndDate) {
        return "Invalid format";
      }

      // Replace the original date range with the formatted one
      return period.replace(
        match[1],
        `${formattedStartDate} - ${formattedEndDate}`
      );
    }
    return "Invalid format"; // If the pattern doesn't match, return "Invalid format"
  };

  return (
    <Box
      sx={{ p: 2 }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" gutterBottom>
          Commission Report
        </Typography>
        {!showForm && (
          <Button variant="contained" color="primary" type="submit">
            Generate Report
          </Button>
        )}
      </Grid>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            {field.type === "autocomplete" ? (
              <Autocomplete
                size="small"
                name={field.name}
                getOptionDisabled={(option) =>
                  notCalculatedPeriods.includes(option)
                }
                disableClearable
                getOptionLabel={(option) =>
                  field.name === "beneficiaries"
                    ? option.userName // For beneficiaries, use the userName property
                    : field.name === "period"
                    ? formatPeriod(option) // Format date for period
                    : option
                }
                options={field.options || []}
                value={
                  field.name === "beneficiaries"
                    ? beneficiariesOptions.find(
                        (option) => option.id === selectedBeneficiary
                      ) || null
                    : field.name === "year"
                    ? yearOptions.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                    : field.name === "frequencies"
                    ? frequenciesOptions.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                    : field.name === "period"
                    ? periodOptions.includes(selectPeriod) &&
                      !notCalculatedPeriods.includes(selectPeriod)
                      ? selectPeriod
                      : null
                    : null
                }
                onChange={(e, value) => {
                  stopRemount.current = true;
                  if (field.name !== "beneficiaries") {
                    setValue(field.name, value);
                  }
                  setShowForm(false);
                  if (field.name === "beneficiaries") {
                    setValue(field.name, value.id);
                    setSelectedBeneficiary(value.id);
                    setBeneficiary(value.id); // Set the beneficiaries value when the beneficiaries Autocomplete field is changed
                  }
                  if (field.name === "period") {
                    setBeneficiary(selectedBeneficiary);
                    setSelectPeriod(value); // Set the period value when the period Autocomplete field is changed
                  }
                  if (field.name === "frequencies") {
                    setBeneficiary(selectedBeneficiary);
                    setSelectFrequencies(value); // Set the frequencies value when the frequencies Autocomplete field is changed
                  }
                  if (field.name === "year") {
                    setBeneficiary(selectedBeneficiary);
                    setSelectYear(value); // Set the year value when the year Autocomplete field is changed
                  }
                }}
                onBlur={() => {
                  trigger(field.name); // Trigger validation on blur
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={field.label}
                    required={field.required}
                    {...register(field.name, {
                      required: field.required && `${field.label} is required`,
                    })}
                    error={!!errors[field.name] && !params.inputProps.value}
                    helperText={
                      params.inputProps.value ? "" : errors[field.name]?.message
                    }
                  />
                )}
              />
            ) : null}
          </Grid>
        ))}
        {/* Download file */}
        {showForm && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            py={1.5}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="small"
                startIcon={<DownloadTwoTone />}
                onClick={handleMenuOpen}
              >
                Download
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={downloadAsPdf}>Download as PDF</MenuItem>
                <MenuItem onClick={downloadAsExcel}>Download as Excel</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </Grid>
      {showForm && (
        <>
          <Box sx={{ boxShadow: 1, px: 3, py: 2 }}>
            <Grid container alignItems="center" pb={2}>
              <Grid item xs={9}>
                <Typography variant="h6" gutterBottom className="text-center">
                  {user.organizationName}
                </Typography>
              </Grid>

              <Grid item xs={3} container justifyContent="flex-end">
                {logoImage1 && !logoImage1.includes("Image Not Found") ? (
                  <img
                    src={logoImage1}
                    alt="Company Logo"
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "10vw",
                      maxHeight: "13vh",
                    }}
                  />
                ) : (
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: "10vw",
                      height: "10vh",
                    }}
                  >
                    {user.organizationName ? user.organizationName[0] : ""}
                  </Avatar>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 100, display: "inline-block" }}>
                    ID
                  </span>
                  <span style={{ fontWeight: 350 }}>: {user.userId}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 100, display: "inline-block" }}>
                    Name
                  </span>
                  <span style={{ fontWeight: 350 }}>: {user.userName}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 100, display: "inline-block" }}>
                    Territory ID
                  </span>
                  <span style={{ fontWeight: 350 }}>: {user.territory}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 100, display: "inline-block" }}>
                    Currency
                  </span>
                  <span style={{ fontWeight: 350 }}>: {currency}</span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 90, display: "inline-block" }}>
                    Period
                  </span>
                  <span style={{ fontWeight: 350 }}>
                    :{" "}
                    {viewData
                      ? formatPeriod(formattedPeriod)
                      : formatPeriod(formData.period)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 90, display: "inline-block" }}>
                    Position
                  </span>
                  <span style={{ fontWeight: 350 }}>: {user.roleName}</span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 90, display: "inline-block" }}>
                    Fiscal Year
                  </span>
                  <span style={{ fontWeight: 350 }}>
                    :{" "}
                    {viewData
                      ? SingleData?.matchingCalculation?.fiscalYear
                      : formData.year}
                  </span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Gross Payout Amt
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof commissionAmount === "number"
                      ? formatCurrency(commissionAmount)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Draw/Adv Adjust
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof advanceAdjustment === "number"
                      ? formatCurrency(advanceAdjustment)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Cap Adjust
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof capAdjustment === "number"
                      ? formatCurrency(capAdjustment)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Min Pay Adjust
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof minPayAdjustment === "number"
                      ? formatCurrency(minPayAdjustment)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Other Adjust
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof otherAdjustment === "number"
                      ? formatCurrency(otherAdjustment)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Adjust To Payout
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof adjustmentPayout === "number"
                      ? formatCurrency(adjustmentPayout)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 170, display: "inline-block" }}>
                    Recov From Payout
                  </span>
                  <span
                    style={{
                      fontWeight: 350,
                      minWidth: 170,
                      display: "inline-block",
                    }}
                  >
                    :{" "}
                    {typeof recoveryPayout === "number"
                      ? formatCurrency(recoveryPayout)
                      : formatCurrency(0)}
                  </span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 120, display: "inline-block" }}>
                    Balance
                  </span>
                  <span style={{ fontWeight: 350 }}>
                    :{" "}
                    {typeof minPayAdjustment === "number"
                      ? formatCurrency(0)
                      : formatCurrency(0)}
                  </span>
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                  <span style={{ minWidth: 100, display: "inline-block" }}>
                    Net Payout Amt
                  </span>
                  <span style={{ fontWeight: 350 }}>
                    :{" "}
                    {typeof netPayoutAmount === "number"
                      ? formatCurrency(netPayoutAmount)
                      : formatCurrency(0)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container>
            <Box sx={{ width: "100%" }}>
              <DataGrid
                ref={gridRef}
                rows={rows}
                density="compact"
                slots={{
                  noRowsOverlay: EmptyBox,
                  pagination: CustomPagination,
                }}
                columns={columns.map((column) => {
                  if (column.field === "transactionId") {
                    return {
                      ...column,
                      renderCell: (params) => (
                        <div
                          title={params.value} // Set the full text as the title attribute
                          style={{
                            fontWeight: groupNames
                              .split(",")
                              .map((name) => name.trim())
                              .includes(params.value)
                              ? 600
                              : "normal",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {params.value}
                        </div>
                      ),
                    };
                  } else if (column.field === "productName") {
                    return {
                      ...column,
                      renderCell: (params) => (
                        <span
                          title={params.value} // Set the full text as the title attribute
                          style={{
                            fontWeight:
                              params.value === "Total" ? 600 : "normal",
                          }}
                        >
                          {params.value}
                        </span>
                      ),
                    };
                  }
                  return column;
                })}
                disableRowSelectionOnClick
                autoHeight={Object.keys(rows).length >= 10}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                // loading
                sx={{
                  p: 1,
                  height: 530,
                  // removing the cell border
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ReportScreen;
