import { useEffect, useMemo, useRef, useState } from "react";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { Error } from "@mui/icons-material";
import axiosClient from "../../apis/Api";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import {
  PRODUCT_SCREEN,
  addSerialNumber,
  areLabelsDuplicates,
  formatCurrency,
  handleMasterApiCall,
  showToastWithMessage,
  transformToObject,
  transformToObjectFromObject,
} from "../config/fieldConfig";
import {
  FILE_TYPE_ERROR,
  FILE_TYPE_XLXS,
  IMPORT_DISABLE,
} from "../config/toastMessage";
import { FormFields } from "../pages/Product/formFields";
import { CustomNavigate } from "../config/navigation";

export const ProductService = (fileInputRef) => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [customFieldsArrayObject, setCustomFieldsArrayObject] = useState({});
  const [fieldMappingArrayObject, setFieldMappingArrayObject] = useState([]);
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false);
  const [viewData, setViewData] = useState(false); // view purpose only
  const [isEData, setIsEData] = useState(false); // edit error records
  const [resObject, setResObject] = useState({});
  const [response, setResponse] = useState({});
  const [fieldsLabel, setFieldsLabel] = useState();
  const [configObj, setConfigObj] = useState();
  const [productIdCheck, setProductIdCheck] = useState("");
  const [productNameCheck, setProductNameCheck] = useState("");
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productTypeOptionsWithId, setProductTypeOptionsWithId] = useState([]);
  const [boolValue, setBoolValue] = useState("false");
  const [isNavigateToAllErrorsActive, setIsNavigateToAllErrorsActive] =
    useState(false);
  const [isErrorColor, setIsErrorColor] = useState(false);
  const [uploading, setUploadInProgress] = useState(false);
  const { navigateError403 } = CustomNavigate();
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const roleAccess = ROLE_ACCESS();

  const [apiEndpoint, setApiEndpoint] = useState("/products/getProducts");

  const [selectedOption, setSelectedOption] = useState("Get All");

  // Function to handle menu item clicks
  const handleSelectOption = (option) => {
    console.log(option); // Debug: Check what option is being selected
    setSelectedOption(option);
  };

  const handleGetAll = () => {
    if (selectedOption === "Get All") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/products/getProducts");
    stopRemount.current = true;
    resetNavigateToAllErrorsActive();
    handleSelectOption("Get All");
  };

  let navigateToAllErrorsCallback = null;

  const handleAllError = (navigateCallback, bValue) => {
    if (selectedOption === "All Errors") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/products/getErrorProducts");
    stopRemount.current = true;
    navigateToAllErrorsCallback = navigateCallback;
    setBoolValue(bValue); // Set boolValue based on the provided parameter
    setIsNavigateToAllErrorsActive(true); // Set the navigate scenario active
    console.log("boolValue", boolValue);
    handleSelectOption("All Errors");
    // fetchData(); // Fetch data after setting boolValue
  };

  const resetNavigateToAllErrorsActive = () => {
    setIsNavigateToAllErrorsActive(false);
  };

  const triggerNavigateToAllErrors = () => {
    if (navigateToAllErrorsCallback) {
      navigateToAllErrorsCallback();
    }
  };

  const [newFields, setNewFields] = useState([]);
  const [open, setOpen] = useState(false);

  const addFormOpen = () => {
    setOpen(true);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, productTypeOptions, boolValue]);

  useEffect(() => {
    setConfigObj(configObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configObj]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const productTypeData = await ApiService.getAllTypes(
        TOKEN,
        PRODUCT_SCREEN
      );

      const extractedData = productTypeData.map((productType) => ({
        typeValue: productType.typeValue,
        id: productType.id,
      }));
      setProductTypeOptionsWithId(extractedData);

      // Extract the productType from each object and store them in an array
      const productTypes = productTypeData.map((product) => product.typeValue);
      setProductTypeOptions(productTypes);

      const result = await ApiService.getMappingsByPojo(PRODUCT_SCREEN, TOKEN); //config values

      let res;

      // Check if the result is an array and has the expected structure
      if (
        Array.isArray(result) &&
        result.length > 0 &&
        result[0].customFields
      ) {
        res = transformToObject(result[0].customFields, result[0].mappingValue);
      }
      // Check if the result is an object with the expected structure
      else if (typeof result === "object" && !Array.isArray(result)) {
        // Transform the object as per your needs
        res = transformToObjectFromObject(result);
      } else {
        throw new Error("Unexpected data structure");
      }
      setConfigObj(result);

      const resKey = Object.keys(res);
      setFieldsLabel(resKey);

      const getPojo = await ApiService.getFieldsForPojo(PRODUCT_SCREEN); //config values

      // Fetch all Product data
      const pdtData = await ApiService.getProducts(TOKEN);

      // Fetch all product Id
      const prodId = pdtData?.map((product) => product.productId);
      setProductIdCheck(prodId);

      // Fetch all product name
      const prodName = pdtData?.map((product) => product.productName);
      setProductNameCheck(prodName);

      let config = {
        headers: {
          Authorization: TOKEN,
        },
      };

      // For handleAllError, add isCurrentError=true to headers
      if (apiEndpoint === "/products/getErrorProducts") {
        config.headers["isCurrentError"] = boolValue;
      }

      const response = await axiosClient.get(apiEndpoint, config);

      console.log("Response:", response.data);

      const productData = response.data;

      // Map over transferData and replace employeeType ID with typeValue
      const updatedTransferData = productData.map((transfer) => {
        // Find the corresponding employeeTypeData entry
        const matchedProductType = productTypeData.find(
          (productType) => productType.id === parseInt(transfer.productTypeId) // Ensure both are numbers
        );

        // Return a new object with the updated employeeType
        return {
          ...transfer,
          productType: matchedProductType
            ? matchedProductType.typeValue
            : transfer.productType, // Use typeValue or fallback to original
        };
      });

      setTableData(updatedTransferData);

      // Initialize an array to store custom field values of each row
      const customFieldsArray = [];
      const customFieldsArrayObject = []; // Initialize an array to store the desired output

      // Loop through each row and accumulate custom field values for each
      productData.forEach((currentItem) => {
        const customFieldValuesOfCurrentItem =
          extractCustomFieldValues(currentItem);
        customFieldsArray.push(customFieldValuesOfCurrentItem);

        // Push the desired output structure to customFieldsArrayObject
        customFieldsArrayObject.push(currentItem.customFields || {});
      });
      setCustomFieldsArrayObject(customFieldsArrayObject);

      // Merge all custom field objects from the customFieldsArray into a single object
      const mergedCustomFields = customFieldsArray.reduce((acc, current) => {
        return { ...acc, ...current };
      }, {});

      // To display custom field at form
      customFieldsArrayObject.push(mergedCustomFields);

      // Find keys that exist in res but not in getPojo
      const nonMatchingFields = Object.keys(res).filter(
        (key) => !getPojo.hasOwnProperty(key)
      );

      // To send pojo field name for mapping fields
      const matchingFields = {};
      for (const key in res) {
        if (getPojo.hasOwnProperty(key)) {
          matchingFields[key] = res[key];
        }
      }
      setResponse(res);
      setResObject(matchingFields);

      // Find non-matching fields in nonMatchingFields that are not present in mergedCustomFields
      const nonMatchingInMerged = nonMatchingFields.filter(
        (field) => !mergedCustomFields.hasOwnProperty(field)
      );

      setFieldMappingArrayObject(nonMatchingInMerged);
      // Iterate over nonMatchingInMerged and add each field to mergedCustomFields
      nonMatchingInMerged.forEach((field) => {
        mergedCustomFields[field] = "";
      });

      // Convert mergedCustomFields into dynamic columns
      const dynamicColumnsArray = Object.keys(mergedCustomFields).map((key) => {
        // Capitalize the first letter of the key for headerName
        const capitalizedHeader =
          key.charAt(0).toUpperCase() +
          key.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");

        return {
          field: key, // Field name will be the key from mergedCustomFields
          headerName: capitalizedHeader, // Header will also be the key
          width: 150, // Adjust the width as per your requirement
          valueGetter: (params) => {
            return params.row.customFields[key]
              ? params.row.customFields[key][key] ||
                  (params.row.customFields[key] &&
                    (params.row.customFields[key][key] === ""
                      ? ""
                      : params.row.customFields[key]))
              : ""; // Access nested value based on the column key
          },
        };
      });

      setDynamicColumns(dynamicColumnsArray);
      //to check and pass the value for red color
      if (isNavigateToAllErrorsActive) {
        setIsErrorColor(true);
      } else {
        setIsErrorColor(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsTableLoading(false);
    setLoading(false);
  };

  // Extract custom field values from a single object
  const extractCustomFieldValues = (obj) => {
    const customFieldValues = {};

    for (const value of Object.values(obj)) {
      if (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
      ) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          customFieldValues[nestedKey] = nestedValue[nestedKey];
        }
      }
    }

    return customFieldValues;
  };

  // Function to format specific fields in each object of productData
  function formatFields(data) {
    return data.map((item) => ({
      ...item,
      productCost: formatCurrency(item.productCost),
      productPrice: formatCurrency(item.productPrice),
      commissionRate: formatCurrency(item.commissionRate),
      commissionAmount: formatCurrency(item.commissionAmount),
      discountAmount: formatCurrency(item.discountAmount),
      discountPercentage: formatCurrency(item.discountPercentage),
    }));
  }

  // Apply the formatting and update table data
  const formattedData = formatFields(data);

  const rows = addSerialNumber(formattedData);

  const handleEdit = (id, viewData, isEData) => () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id); // Set the edit form state and data
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    setViewData(viewData);
    setIsEData(isEData);
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action, transformedObject) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addProduct,
        edit: ApiService.updateProduct,
        delete: ApiService.deleteProduct,
      }[action];

      // Update transformedObject with values from response
      for (const key in transformedObject) {
        if (
          transformedObject.hasOwnProperty(key) &&
          response.hasOwnProperty(key)
        ) {
          transformedObject[key][key] = response[key];
        }
      }

      // Call the API action and handle the response
      const transformedObjects = {
        mappingValue: resObject,
        customFields: transformedObject,
      };

      // Call handleApiCall with the appropriate API action and parameters
      await handleMasterApiCall(
        transformedObjects,
        PRODUCT_SCREEN,
        token,
        apiAction(action === "delete" ? formData.id : formData, token),
        // Generate the action message (e.g., "Added", "Updated", "Deleted")
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass formData only if action is not 'delete'
        action !== "delete" ? formData : null,
        fetchData, // Wrap fetchData in a function
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData, transformedObject) =>
    onSubmitForm(formData, "add", transformedObject);

  // Function to handle edit form submission
  const onEditForm = (formData, transformedObject) =>
    onSubmitForm(formData, "edit", transformedObject);

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  // Function to handle add product type
  const addProductType = async (value, setValue) => {
    try {
      const res = await ApiService.addType(value, token);
      console.log(res);
      if (res?.status === 200) {
        toast.success(res?.data);
        setProductTypeOptions((prevOptions) => {
          // Check if the new relationship type already exists
          const lowerCasePrevOptions = prevOptions.map((option) =>
            option.toLowerCase()
          );
          if (!lowerCasePrevOptions.includes(value.typeValue.toLowerCase())) {
            return [...prevOptions, value.typeValue];
          }
          return prevOptions;
        });
        setValue("productType", value.typeValue); // Set the new value in the form
      } else {
        toast.error("Please try again Later");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Assuming customFieldsArrayObject is an array of objects
  const dynamicFormFields = useMemo(() => {
    return Array.isArray(customFieldsArrayObject)
      ? customFieldsArrayObject
          .reduce((maxFields, currentObject) => {
            const currentFields = Object.entries(currentObject).map(
              ([propertyName, fieldData]) => {
                // Init cap display field at table
                const labelName =
                  propertyName.charAt(0).toUpperCase() +
                  propertyName.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");
                if (fieldData) {
                  return {
                    name: propertyName,
                    label: labelName,
                    type: fieldData.dataType,
                    required: fieldData.isMandatory,
                    isCustom: true, // Indicate that it's a custom field
                  };
                } else {
                  // Handle the case where the property is missing or null
                  if (fieldMappingArrayObject) {
                    return {
                      name: propertyName,
                      label: labelName,
                      isCustom: true, // Indicate that it's a custom field
                    };
                  } else {
                    return null; // or provide a default value
                  }
                  // return null; // or provide a default value
                }
              }
            );
            if (currentFields.length > maxFields.length) {
              const fieldsArray = [];
              // Ensure configObj is defined and is an array before processing
              if (Array.isArray(configObj)) {
                configObj.forEach((obj) => {
                  if (obj.customFields) {
                    Object.keys(obj.customFields).forEach((key) => {
                      const field = obj.customFields[key];
                      // fieldsArray.push(field);
                      fieldsArray.push({
                        name: key,
                        label: key,
                        type: field.dataType || "text",
                        required: field.isMandatory || false,
                        isCustom: true,
                      });
                    });
                  }
                });
              } else {
                console.log("configObj is not an array or is undefined");
              }
              return fieldsArray;
            } else {
              return maxFields;
            }
          }, [])
          .filter(Boolean) // Remove null values
      : [];
  }, [configObj, customFieldsArrayObject, fieldMappingArrayObject]);

  const handleAddCustomField = (customFieldData) => {
    try {
      // Check if the field with the same name already exists
      // Check if any label in labelVariations is a duplicate of key
      const isDuplicate = fieldsLabel.some(
        (label) =>
          areLabelsDuplicates(label, customFieldData.labelName) ||
          fields.some(
            (field) =>
              field.name &&
              field.name.toLowerCase() !== label.toLowerCase() &&
              field.name.toLowerCase() ===
                customFieldData.labelName.toLowerCase()
          )
      );

      const InitCap =
        customFieldData.labelName.charAt(0).toUpperCase() +
        customFieldData.labelName.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");

      if (isDuplicate) {
        // Handle duplicate field name scenario (e.g., show an error message)
        toast.error(`"${customFieldData.labelName}" already exists.`);
        return;
      }

      const newField = {
        name: customFieldData.labelName,
        label: InitCap,
        type: customFieldData.dataType, // This should be set based on user input
        required: customFieldData.isMandatory, // This should be set based on user input
        isCustom: true, // Flag to identify as a custom field
      };

      // Update your state or context to include the new custom field
      setFields([...fields, newField]);
      setNewFields([...newFields, newField]);

      // Close the custom field dialog
      setOpenCustomFieldDialog(false);

      // Show success message
      toast.success(
        `Custom Field "${customFieldData.labelName}" added successfully!`
      );
    } catch (error) {
      // Handle any unexpected errors here
      console.error("An error occurred while adding custom field:", error);
      // Optionally, you can display an error message to the user or handle it in a different way.
      toast.error(
        "An error occurred while adding custom field. Please try again later."
      );
    }
  };

  // To upload File for import
  const handleUploadFile = (event) => {
    return new Promise((resolve, reject) => {
      try {
        sessionStorage.setItem("isFileUpload", true);
        setIsLoading(true);
        const file = event.target.files[0];

        if (!file) return;

        const formData = new FormData();

        let fileType;

        switch (file.type) {
          case FILE_TYPE_XLXS:
            fileType = "xlsx";
            break;
          case "text/csv":
            fileType = "csv";
            break;
          default:
            setIsLoading(false);
            showToastWithMessage(FILE_TYPE_ERROR, true);
            setTimeout(() => {
              sessionStorage.setItem("isFileUpload", false);
              reject(FILE_TYPE_ERROR); // Reject with error message
            }, 5000); // Simulate 5 second delay
            return;
        }
        fileInputRef.current.value = ""; // Reset file input regardless of success or failure

        formData.append("file", file);
        formData.append("fileType", fileType);

        ApiService.uploadMaster(formData, token, fileType, PRODUCT_SCREEN)
          .then((res) => {
            console.log("File Upload Response:", res);
            if (res?.status === 200 && res?.data) {
              // Assuming res.data is like "File uploaded successfully. Total count: 1, Success count: 0, Error count: 1"
              const data = res.data;

              // Extract error count from data using a regular expression
              const match = data.match(/Error count: (\d+)/);
              let errorCount = 0;
              if (match) {
                errorCount = parseInt(match[1], 10);
              }
              setIsLoading(false);
              if (errorCount > 0) {
                // Display success toast with a button to view details
                toast(
                  (t) => (
                    <div>
                      {data}
                      <Button
                        onClick={() => {
                          handleAllError(triggerNavigateToAllErrors, "true");
                          toast.dismiss(t.id); // Close the toast when the button is clicked
                          setIsLoading(false);
                        }}
                      >
                        View Errors
                      </Button>
                      <div style={{ fontSize: "12px", color: "#6c757d" }}>
                        {IMPORT_DISABLE}
                      </div>
                    </div>
                  ),
                  {
                    icon: <Error sx={{ color: "#017BFF" }} />,
                  }
                );
                // Dismiss the toast after the specified timeout
                setTimeout(() => {
                  toast.dismiss();
                  sessionStorage.setItem("isFileUpload", false);
                }, 5000);
                fetchData(); // Assuming fetchData is a function to fetch updated data
              } else if (errorCount === 0) {
                setIsLoading(false);
                // Display success toast with data
                showToastWithMessage(data);
                fetchData(); // Fetch updated data
              }
            } else {
              setIsLoading(false);
              // Error response from the server
              showToastWithMessage(res.response.data, true);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            // Network error or other unexpected errors during upload
            showToastWithMessage(error, true);
            console.error("errorMessage", error);
          })
          .finally(() => {
            setIsLoading(false);
            // Reset file input regardless of success or failure
            fileInputRef.current.value = "";
          });
        // Simulating API call with setTimeout
        setTimeout(() => {
          sessionStorage.setItem("isFileUpload", false);
          // Resolve for success
          resolve("File uploaded successfully.");
        }, 5000); // Simulate 5 second delay
      } catch (error) {
        setIsLoading(false);
        fileInputRef.current.value = "";
        // File-related error
        showToastWithMessage(error.message, true);
        console.error("File Error:", error);
      }
    });
  };

  const [fields, setFields] = useState([...FormFields, ...dynamicFormFields]);

  return {
    rows,
    dynamicColumns,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    dynamicFormFields,
    handleAddCustomField,
    fields,
    openCustomFieldDialog,
    setOpenCustomFieldDialog,
    handleUploadFile,
    open,
    addFormOpen,
    addFormClose,
    roleAccess,
    newFields,
    setFields,
    viewData,
    setViewData,
    apiEndpoint,
    isNavigateToAllErrorsActive,
    triggerNavigateToAllErrors,
    handleGetAll,
    handleAllError,
    addProductType,
    productTypeOptions,
    productIdCheck,
    productNameCheck,
    isErrorColor,
    uploading,
    setUploadInProgress,
    isEData,
    setIsEData,
    setIsErrorColor,
    fetchData,
    setApiEndpoint,
    setIsNavigateToAllErrorsActive,
    setProductTypeOptions,
    productTypeOptionsWithId,
    loading,
    selectedOption,
    handleSelectOption,
    isLoading,
    isTableLoading,
  };
};
