import { useState } from "react";
import { CustomNavigate } from "../config/navigation";
import { JWT_TOKEN, USER_NAME } from "../config/sessionStorage";
import ApiService from "../../apis/ApiService";
import useFetchOnMount from "../config/useFetchOnMount";
import { addSerialNumber, handleApiCall } from "../config/fieldConfig";
import Swal from "sweetalert2";
import { PlanColumns } from "../pages/Plan/columns";
import { FormFields } from "../pages/Plan/formFields";

export default function PlanService() {
  const [tableData, setTableData] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [viewData, setViewData] = useState(true); // view purpose only
  const [showForm, setShowForm] = useState(false);
  const [featureData, setFeatureData] = useState({});

  const { navigateError403 } = CustomNavigate();

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const reset = () => {
    setEditFormOpen(false);
  };

  const userName = USER_NAME();

  const fetchData = async () => {
    try {
      const token = JWT_TOKEN();

      const res = await ApiService.getPricingPlans(token);
      setTableData(res?.data || []);

      console.log(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (id, viewData) => async () => {
    const rowData = tableData.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id);
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    setViewData(viewData);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      const token = JWT_TOKEN();

      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addPricingPlan,
        edit: ApiService.updatePricingPlan,
        delete: ApiService.deletePricingPlan,
      }[action];

      // Define parameters based on the action type
      let params;
      switch (action) {
        case "delete":
          params = formData.id; // Only id
          break;
        case "edit":
          params = { token, editFormData: formData, userName }; // Both formData and userName
          break;
        case "add":
          params = { token, formData }; // Only formData
          break;
        default:
          throw new Error("Invalid action type");
      }

      // Call the API action and handle the response
      await handleApiCall(
        apiAction(params),
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this Plan?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  const planFieldNames = FormFields;

  const rows = addSerialNumber(tableData);

  const columns = PlanColumns(handleEdit, handleDelete);

  return {
    rows,
    columns,
    planFieldNames,
    editFormOpen,
    editItemId,
    editFormData,
    viewData,
    showForm,
    reset,
    toggleForm,
    onAddForm,
    onEditForm,
    setViewData,
    featureData,
    setFeatureData,
  };
}
