import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "../../../apis/ApiService";
import { RAZORPAY_KEY_ID } from "../../config/envConfig";
import {
  EMAIL_ID,
  ORG_ID,
  ORG_NAME,
  USER_NAME,
} from "../../config/sessionStorage";
import { CfTransBlueLogo } from "../../config/imageConfig";

export default function useRazorpay({ payData }) {
  console.log("payData1", payData);

  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderReceiptId, setOrderReceiptId] = useState("");

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Razorpay SDK failed to load"));
      document.body.appendChild(script);
    });
  };

  const createOrder = async (payData) => {
    console.log("payData", payData);
    try {
      const amount = payData?.amountToPay; // Amount in paise, for example,50000 -> ₹500
      const currency = payData?.currency;
      const result = await ApiService.createOrder(amount, currency);
      if (result?.status === 200) {
        setOrderId(result?.data?.id);
        setOrderReceiptId(result?.data?.receipt);
      }
      return result;
    } catch (err) {
      toast.error("Failed to create order. Please try again.");
      console.error("Order creation error:", err);
      throw err; // Rethrow the error to be handled by the calling function
    }
  };

  const navigate = useNavigate();

  const resetState = () => {
    setOrderId("");
    setOrderReceiptId("");
    setIsLoading(false); // Hide loading spinner
  };

  const displayRazorpay = async (payData, fetchData, handleClose) => {
    setIsLoading(true);

    try {
      const scriptLoaded = await loadRazorpayScript();

      if (!scriptLoaded) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        setIsLoading(false);
        return;
      }

      const order = await createOrder(payData);
      if (!order) {
        toast.error("Failed to create order. Please try again.");
        setIsLoading(false);
        return;
      }

      // Update orderId and orderReceiptId
      setOrderId(order?.data?.id);
      setOrderReceiptId(order?.data?.receipt);

      const razorpay = new window.Razorpay({
        key: RAZORPAY_KEY_ID, // Replace with your actual Razorpay API key
        amount: payData?.amountToPay * 100, // Amount in paise
        currency: payData?.currency,
        name: "CompFact",
        description: "Payment for CompFact",
        image: CfTransBlueLogo, // Your logo
        order_id: orderId, // Use the order ID from the response
        handler: async (response) => {
          try {
            const values = {
              userName: USER_NAME(),
              paymentId: response?.razorpay_payment_id,
              orgName: ORG_NAME(),
              orgId: ORG_ID(),
              currency: payData?.currency,
              month: payData?.month,
              pricingPlan: payData?.pricingPlan,
              orderId: orderId,
              orderReceiptId: orderReceiptId,
              beneficiary: payData?.beneficiary,
              renewalDate: payData?.renewalDate,
              cgst: payData?.cgst,
              sgst: payData?.sgst,
              totalAmount: payData?.totalAmount,
              discountAmount: payData?.discountAmount,
              discount: payData?.discountPercentage,
              amountToPay: payData?.amountToPay,
            };

            const result = await ApiService.capturePayment(values);
            console.log("capturePayment", result);

            if (result?.status === 200 && result?.data?.status === "Success") {
              handleClose();
              navigate("/billing"); // Navigate to the billing page
              fetchData();
              toast.success("Payment successful!");
            } else {
              toast.error("Failed to process payment");
            }
          } catch (error) {
            toast.error("Payment capture failed");
            console.error("Payment capture error:", error);
          } finally {
            resetState(); // Reset states after payment process is done
          }
        },

        prefill: {
          name: USER_NAME() || "",
          email: EMAIL_ID() || "",
          contact: "8838660266",
        },
        notes: {
          address: "CompFact Corporate Office",
        },
        theme: {
          color: "#F37254",
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: "All payment methods",
                instruments: [
                  { method: "upi" },
                  { method: "card" },
                  { method: "wallet" },
                  { method: "netbanking" },
                ],
              },
            },
            sequence: ["block.banks"],
            preferences: {
              show_default_blocks: false,
            },
          },
        },
        modal: {
          ondismiss: () => {
            resetState(); // Reset states when the modal is closed
          },
        },
      });

      razorpay.open();
    } catch (err) {
      resetState();
      toast.error(err.message);
      console.error("Razorpay initialization error:", err);
    }
  };

  return { displayRazorpay, isLoading };
}
