import { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  LinearProgress,
  List,
  ListItem,
  Stack,
  Button,
  Menu,
  MenuItem,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  ArrowDropDownTwoTone,
  ArrowDropUpTwoTone,
  ExpandMoreTwoTone,
  Search,
} from "@mui/icons-material";
import { PieChart } from "@mui/x-charts";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import DateRangePicker from "../../common/DateRangePicker";
import {
  convertCamelToTitle,
  formatCurrencySymbol,
} from "../../config/fieldConfig";
import { IndividualDashboardService } from "../../service/individualDashboard";

export default function IndividualDashboard() {
  const {
    // Date
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    // Total Commission SalesRep
    totalCommSalesRep,
    // Top Commission Product SalesRep
    topCommissionProductsSalesRep,
    // Top Commission Customer SalesRep
    customerData,
    // Not calculated table data
    rows,
    columns,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    totalTransCount,
    processTransCount,
    currency,
    timeZone,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetMyDetailsApplyClick,
    reportingTo,
    menu,
    stopRemount,
  } = IndividualDashboardService();

  const [checkedItems, setCheckedItems] = useState(new Set());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDropdown, setSelectedDropdown] = useState(menu[0]);
  const [isReporteeMenuOpen, setIsReporteeMenuOpen] = useState(false);

  // Find min and max values
  const findMinMax = (data) => {
    if (data.length === 0) {
      return { min: 0, max: 100 }; // Default values when no data is available
    }
    const values = data.map((item) => item.totalNetPayout);
    return {
      min: Math.min(...values),
      max: Math.max(...values),
    };
  };

  // Normalize value to percentage
  const normalizeValue = (value, min, max) => {
    if (max === min) return 100; // Prevent division by zero
    if (max === 0 && min === 0) return 0; // Handle the case where all values are 0
    return ((value - min) / (max - min)) * 100;
  };

  const { min, max } = findMinMax(customerData);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const truncatedData = topCommissionProductsSalesRep.map((item) => ({
    ...item,
    label: item.label.length > 9 ? item.label.slice(0, 9) + "..." : item.label,
  }));

  // Sort data by value in descending order
  const sortedData = truncatedData.sort((a, b) => b.value - a.value);

  // Get top 5 items
  const top5Items = sortedData.slice(0, 5);

  // Prepare the final data for the chart
  let finalData = top5Items;

  // Add the 'Others' category only if there are more than 5 items
  if (topCommissionProductsSalesRep.length > 5) {
    // Calculate the sum of the remaining values
    const othersSum = sortedData
      .slice(5)
      .reduce((acc, item) => acc + item.value, 0);

    finalData = [...top5Items, { value: othersSum, label: "Others" }];
  }

  const handleMenuItemClick = (item, event) => {
    setSelectedDropdown(item);
    if (item === "My Reportees") {
      setIsReporteeMenuOpen(false); // Toggle checkbox menu
      handleGetMyReportee();
    } else {
      handleGetMyDetails();
      setCheckedItems(new Set()); // Uncheck all items
    }
    handleMenuClose();
  };

  const handleMenuItemChange = (item, event) => {
    // Handle nested menu item change logic here
    const newCheckedItems = new Set(checkedItems);
    if (newCheckedItems.has(item.userId)) {
      newCheckedItems.delete(item.userId);
    } else {
      newCheckedItems.add(item.userId);
    }
    setCheckedItems(newCheckedItems);
  };

  const handleApplyClick = () => {
    if (Array.from(checkedItems).length !== 0) {
      // Handle apply action here, e.g., save the selected items
      handleGetMyDetailsApplyClick(Array.from(checkedItems));
      handleMenuClose();
    }
  };

  const handleCancelClick = () => {
    setCheckedItems(new Set()); // Uncheck all items
    handleMenuClose(); // Close the menu
    setIsReporteeMenuOpen(false);
  };

  const handleDropdownIconClick = (event) => {
    event.stopPropagation(); // Prevent triggering the main click event
    setIsReporteeMenuOpen(!isReporteeMenuOpen); // Toggle checkbox menu
  };

  // Inside your component
  const [searchQuery, setSearchQuery] = useState("");
  const filteredReportees = reportingTo.filter((reportee) =>
    `${reportee.userId} - ${reportee.userName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Box maxWidth="xl" sx={{ px: 2, py: 2 }}>
      {/* First Grid */}
      <Grid container spacing={2}>
        <Grid container sx={{ mt: 1, pt: 1 }}>
          <Grid
            item
            xs={12}
            md={8.15}
            sx={{
              pl: { xs: 2, sm: 2, md: 0 },
            }}
          >
            {/* Currency and Time Zone Grid */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{
                mx: { xs: 0, sm: 0, md: 2 },
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow:
                  "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                borderRadius: 2,
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                "&:hover": {
                  boxShadow: 3,
                },
                px: 2,
                py: 1,
              }}
            >
              <Typography variant="body1">
                Currency : {currency || ""}
              </Typography>
              <Typography variant="body1">
                Time Zone : {timeZone || ""}
              </Typography>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={3.85}
            sx={{
              pt: { xs: 2, sm: 2, md: 0.25 },
              pl: { xs: 2, sm: 2, md: 0 },
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }} // Column layout on extra-small screens, row layout on small screens and up
              spacing={2} // Space between items
              alignItems="center"
              justifyContent={"space-between"}
              sx={{
                p: { xs: 0.5, sm: 1, md: 0.52 },
                boxShadow:
                  "0px -4px 6px -2px rgba(0,0,0,0.1), 0px 4px 6px -2px rgba(0,0,0,0.2)", // Top and bottom shadow effect
                borderRadius: 2,
                whiteSpace: "nowrap", // Prevent the text from wrapping to a new line
                overflow: "hidden",
                textOverflow: "ellipsis", // Show ellipsis for truncated text
                transition: "box-shadow 0.3s", // Add transition for smooth effect
                "&:hover": {
                  boxShadow: 3,
                },
              }}
            >
              {reportingTo.length > 0 ? (
                <Button
                  variant="text"
                  size="small"
                  onClick={handleMenuClick}
                  endIcon={<ExpandMoreTwoTone />}
                  sx={{
                    padding: "3px 8px",
                    fontSize: "0.85rem",
                    minWidth: "unset",
                    display: "flex", // Make sure the button content is flexbox for proper alignment
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      maxWidth: 120, // Adjust based on your needs
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "inline-block",
                    }}
                  >
                    {convertCamelToTitle(selectedDropdown)}
                  </Box>
                </Button>
              ) : null}
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
                sx={{
                  "& .MuiPaper-root": {
                    minWidth: "170px",
                    maxHeight: "500px", // Set the maximum height for the entire menu
                    maxWidth: "200px", // Set the maximum width for the menu
                    overflow: "hidden", // Ensure no overflow for the whole menu
                  },
                }}
              >
                {menu.map((item) => {
                  // Conditionally skip "My Reportees" if reportingTo is empty
                  if (item === "My Reportees" && reportingTo.length === 0) {
                    return null;
                  }
                  return (
                    <Box key={item}>
                      <MenuItem
                        onClick={(event) => handleMenuItemClick(item, event)}
                        selected={selectedDropdown === item}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "3px 10px",
                        }}
                      >
                        <Tooltip title={item.length > 20 ? item : ""} arrow>
                          <Typography
                            color={
                              selectedDropdown === item
                                ? "secondary"
                                : "inherit"
                            }
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "200px",
                              display: "block",
                              flexGrow: 1,
                            }}
                          >
                            {item}
                          </Typography>
                        </Tooltip>

                        {/* Add a dropdown icon for "My Reportees" */}
                        {item === "My Reportees" && (
                          <IconButton
                            size="small"
                            onClick={handleDropdownIconClick}
                            sx={{ marginLeft: "auto" }}
                          >
                            {isReporteeMenuOpen ? (
                              <ArrowDropUpTwoTone />
                            ) : (
                              <ArrowDropDownTwoTone />
                            )}
                          </IconButton>
                        )}
                      </MenuItem>

                      {/* Render the checkboxes when the dropdown is open */}
                      {item === "My Reportees" && isReporteeMenuOpen && (
                        <Box
                          sx={{
                            maxHeight: 300,
                            maxWidth: 170,
                            overflowY: "auto",
                            marginTop: 0,
                          }}
                        >
                          {/* Search field */}
                          <TextField
                            variant="standard"
                            size="small"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => e.stopPropagation()} // Prevent the event from reaching the main menu
                            sx={{ transform: "scale(0.8)", padding: 0 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                          />

                          {/* Scrollable checkbox list */}
                          <Box sx={{ maxHeight: 150, overflowY: "auto" }}>
                            {filteredReportees.length > 0 ? (
                              filteredReportees.map((reportee) => (
                                <MenuItem
                                  key={reportee.userId}
                                  onClick={(event) =>
                                    handleMenuItemChange(reportee, event)
                                  }
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "3px 5px",
                                  }}
                                >
                                  <Checkbox
                                    checked={checkedItems.has(reportee.userId)}
                                    size="small"
                                    sx={{ transform: "scale(0.6)", padding: 0 }}
                                  />
                                  <Tooltip
                                    title={
                                      `${reportee.userId} - ${reportee.userName}`
                                        .length > 9
                                        ? `${reportee.userId} - ${reportee.userName}`
                                        : ""
                                    }
                                    arrow
                                  >
                                    <Typography
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "200px",
                                        display: "block",
                                      }}
                                    >
                                      {`${reportee.userId} - ${reportee.userName}`}
                                    </Typography>
                                  </Tooltip>
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No results found</MenuItem>
                            )}
                          </Box>

                          {/* OK and Clear buttons */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              borderTop: "1px solid #ddd",
                              paddingTop: 1,
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={handleApplyClick}
                              sx={{
                                padding: "2px 8px",
                                fontSize: "0.75rem",
                                minWidth: "unset",
                              }}
                            >
                              OK
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={handleCancelClick}
                              sx={{
                                padding: "2px 8px",
                                fontSize: "0.75rem",
                                minWidth: "unset",
                                marginLeft: "8px", // Add space between OK and Clear
                                marginRight: "8px",
                              }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Menu>

              <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                stopRemount={stopRemount}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {/* Commission by Customers Grid */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: { xs: 1, sm: 2 },
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  boxShadow: 3,
                  borderRadius: 2,
                  ":hover": { boxShadow: 5 },
                }}
              >
                <Typography variant="h6" component="div" gutterBottom>
                  Commission by Customers
                </Typography>
                <Box
                  sx={{
                    height: "auto",
                    minHeight: 300,
                    position: "relative",
                  }}
                >
                  {customerData.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="caption"
                        paragraph
                        sx={{ fontSize: 14 }}
                      >
                        No data to display
                      </Typography>
                    </Box>
                  ) : (
                    <List>
                      {customerData.map((item, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            py: { xs: 1, sm: 2 },
                            borderBottom: 1,
                            borderColor: "divider",
                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: "100%", sm: "75%" },
                              mb: { xs: 1, sm: 0 },
                              overflow: "hidden", // Ensure the Box doesn't overflow
                            }}
                          >
                            <Tooltip
                              title={item.customerName}
                              arrow
                              sx={{
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "white", // Set tooltip background color to white
                                  color: "black", // Set tooltip text color to black for contrast
                                },
                                "& .MuiTooltip-arrow": {
                                  color: "white", // Set the arrow color to white to match the background
                                },
                              }}
                            >
                              <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflow text
                                  textOverflow: "ellipsis", // Add ellipsis for truncation
                                  width: "100%", // Ensure Typography takes full width
                                }}
                              >
                                {item.customerName}
                              </Typography>
                            </Tooltip>
                            <LinearProgress
                              variant="determinate"
                              color="success"
                              value={normalizeValue(
                                item.totalNetPayout,
                                min,
                                max
                              )}
                              sx={{ width: "100%" }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: { xs: "100%", sm: "25%" },
                              textAlign: { xs: "left", sm: "right" },
                            }}
                          >
                            <Typography variant="body2" component="div">
                              {formatCurrencySymbol(item.totalNetPayout || 0)}
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Commission by Products Grid */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: { xs: 1, sm: 2 },
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  boxShadow: 3,
                  borderRadius: 2,
                  ":hover": { boxShadow: 5 },
                }}
              >
                <Typography variant="h6" component="div" gutterBottom>
                  Commission by Products
                </Typography>
                <Box
                  sx={{
                    height: {
                      xs: 355,
                      sm: 200,
                      md: "auto",
                    },
                    minHeight: 300,
                    marginTop: { xs: -10, sm: -2, md: -2 },
                    position: "relative",
                  }}
                >
                  <PieChart
                    margin={{ right: 5, top: -40 }}
                    series={[
                      {
                        data: finalData, // Use the processed data
                        innerRadius: 70,
                        outerRadius: 100,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      paddingY: { xs: 5, sm: 0, md: 0 },
                    }}
                    slotProps={{
                      legend: {
                        direction: "row",
                        data: finalData,
                        position: {
                          vertical: "bottom",
                          horizontal: "middle",
                        },
                        labelStyle: {
                          fontSize: 14,
                        },
                        itemMarkWidth: 9,
                        itemMarkHeight: 9,
                        markGap: 5,
                        itemGap: 5,
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              p: { xs: 1, sm: 2 },
              display: "flex",
              flexDirection: "column",
              height: "100%",
              boxShadow: 3,
              borderRadius: 2,
              ":hover": { boxShadow: 5 },
            }}
          >
            {/* Content Boxes */}
            <Grid container spacing={3}>
              {/* Total Earnings */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    p: { xs: 1, sm: 2, md: 3 },
                    borderRadius: 2,
                    ":hover": { boxShadow: 5 },
                    bgcolor: "#BBE9FF",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" component="div" gutterBottom>
                    Total Earnings
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    {totalCommSalesRep}
                  </Typography>
                </Box>
              </Grid>

              {/* Records of Resolve */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    boxShadow: 2,
                    p: { xs: 1, sm: 2, md: 3 },
                    borderRadius: 2,
                    ":hover": { boxShadow: 5 },
                    bgcolor: "#FFE9D0",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6" component="div" gutterBottom>
                    Records of Resolve
                  </Typography>
                  <Typography variant="h4" component="div" gutterBottom>
                    {processTransCount}/{totalTransCount}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Second Grid */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                slots={{
                  noRowsOverlay: EmptyBox,
                  pagination: CustomPagination,
                  loadingOverlay: LinearProgress,
                  toolbar: () => (
                    <>
                      <Typography
                        variant="h6"
                        color={"primary"}
                        sx={{ px: 2, pt: 1 }}
                        gutterBottom
                      >
                        Records to payout
                      </Typography>
                    </>
                  ),
                }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                density="compact"
                disableRowSelectionOnClick
                autoHeight={rows.length >= 10}
                sx={{
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none !important",
                  },
                  p: 1,
                  height: { xs: 400, sm: 500, md: 530 },
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              transition: "box-shadow 0.3s", // Add transition for smooth effect
              "&:hover": {
                boxShadow: 5,
              },
              pb: 3,

              display: "flex",
              flexDirection: "column",
              height: "100%",
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ p: 2, pb: 1 }}>
              Commission by SalesRep
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DataGrid
                columns={salesRepsColumns}
                rows={salesRepsRows}
                sx={{
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none !important",
                  },
                  p: 1,
                  height: { xs: 310, sm: 410, md: 440 },
                }}
                density="compact"
                disableColumnMenu
                slots={{ noRowsOverlay: EmptyBox }}
                slotProps={{
                  footer: {
                    style: {
                      display: "none",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
