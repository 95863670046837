import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";
import {
  handleNumberFieldKeyDown,
  isBeyond50Words,
  isEmpty,
  isValueContainsSplChars,
} from "../../config/fieldConfig";
import FeaturesBox from "./features";
import SubscriptionPeriodsBox from "./subscriptionPeriods";

export default function PlanForm({
  mode,
  open,
  onClose,
  onAddForm,
  onEditForm,
  planFieldNames,
  viewData,
  setViewData,
  editItemId,
  editFormData,
  featureData,
  setFeatureData,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dynamicFields: [], // Initialize dynamicFields with an empty array
      subscriptionPeriods: [], // Initialize subscriptionPeriods with an empty array
      frequency: "Month", // Default frequency set to "Month"
    },
  });

  const [selectedFrequency, setSelectedFrequency] = useState("Month");

  const planFrequencyOptions = ["Month", "Year"];

  const subscriptionFieldNames = [
    "duration",
    "actualAmount",
    "discountPercentage",
    "discountAmount",
    "totalAmount",
  ];

  useEffect(() => {
    if (mode === "edit" && editFormData) {
      setValue(
        "dynamicFields",
        editFormData.features.map((feature) => ({ name: feature }))
      );
      setValue(
        "subscriptionPeriods",
        editFormData.subscriptionPeriods.map((period) => ({
          duration: period.duration,
          amount: period.amount,
          discountPercentage: period.discountPercentage,
          discountAmount: period.discountAmount,
          totalAmount: period.totalAmount,
        }))
      );
      // Also, set other form values
      planFieldNames.forEach((field) =>
        setValue(field.name, editFormData[field.name])
      );
      setSelectedFrequency(editFormData.frequency || "Month"); // Set frequency state
    } else if (mode === "add") {
      // Reset for add mode
      setValue("dynamicFields", [{ name: "" }]);
      setValue("subscriptionPeriods", [
        {
          duration: "",
          amount: "",
          discountPercentage: "",
          discountAmount: "",
          totalAmount: "",
        },
      ]);
      setSelectedFrequency("Month"); // Set default frequency to "Month"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, mode, setValue]);

  // Watch for subscription periods duration changes
  const subscriptionPeriods = watch("subscriptionPeriods");
  useEffect(() => {
    console.log("Subscription Periods Duration Values:", subscriptionPeriods);
  }, [subscriptionPeriods]);

  const onSubmit = (formData) => {
    // Transform dynamic fields into an array
    const featuresArray = formData.dynamicFields.map((field) => field.name);

    // Transform subscription periods into an array
    const subscriptionArray = formData.subscriptionPeriods.map((period) => ({
      duration: period.duration,
      amount: period.actualAmount,
      discountPercentage: period.discountPercentage,
      discountAmount: period.discountAmount,
      totalAmount: period.totalAmount,
    }));

    // Create the transformed data object
    const transformedData = {
      description: formData.description,
      features: featuresArray,
      notes: formData.notes,
      price: formData.price,
      title: formData.title,
      frequency: formData.frequency,
      subscriptionPeriods: subscriptionArray,
    };

    console.log(transformedData);

    // Handle form submission here (e.g., send `transformedData` to the server)
    if (mode === "add") {
      onAddForm(transformedData);
    } else if (mode === "edit") {
      transformedData.id = editItemId;
      onEditForm(transformedData);
    }
    onClose();
  };

  const handleDurationChange = (durations) => {
    console.log("Updated Durations:", durations);
    // Perform necessary updates based on new duration values
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        mt={mode === "edit" ? 1 : -7}
      >
        <Grid container spacing={2}>
          {planFieldNames.map((field, index) => (
            <Grid item xs={12} sm={6} key={index} marginTop={1}>
              {field.name === "frequency" ? (
                <Autocomplete
                  options={planFrequencyOptions}
                  disableClearable
                  value={selectedFrequency}
                  onChange={(event, newValue) => {
                    setSelectedFrequency(newValue);
                    setValue(field.name, newValue);
                  }}
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={field.name}
                      label={field.label}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      variant="outlined"
                      size="small"
                      fullWidth
                      InputLabelProps={{
                        style: { pointerEvents: "none" },
                      }}
                      {...register(field.name, {
                        required:
                          field.required && mode !== "edit"
                            ? `${field.label} is required`
                            : false,
                      })}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                    />
                  )}
                />
              ) : (
                <TextField
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  required={field.required}
                  variant="outlined"
                  size="small"
                  multiline={field.multiline}
                  rows={field.rows}
                  // autoFocus={field.autoFocus}
                  fullWidth
                  sx={
                    field.name === "description"
                      ? {
                          "& .MuiInputBase-root": {
                            resize: "vertical",
                            overflow: "auto",
                          },
                        }
                      : {}
                  }
                  onWheel={(event) => {
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  inputProps={{
                    ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                  }}
                  InputProps={{
                    style: {
                      pointerEvents: viewData && editItemId ? "none" : "auto",
                    },
                    pattern: field.type === "number" ? "\\d*" : undefined,
                  }}
                  InputLabelProps={{
                    shrink: field.type === "date" ? true : field.shrink,
                    style: {
                      pointerEvents: "none",
                    },
                  }}
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    validate: {
                      isEmpty: (value) =>
                        field.required ? isEmpty(value) : true,
                      isValueContainsSplChars: (value) =>
                        field.type === "text" && field.type !== "number"
                          ? isValueContainsSplChars(value)
                          : true,
                      isBeyond50Words: (value) =>
                        field.name === "description"
                          ? isBeyond50Words(value)
                          : true,
                    },
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  onChange={(e) => {
                    setValue(field.name, e.target.value);
                    trigger(field.name);
                  }}
                  onBlur={() => {
                    setTimeout(() => trigger(field.name), 100);
                  }}
                  onKeyDown={(e) => {
                    if (field.type === "number") {
                      handleNumberFieldKeyDown(e);
                    }
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>

        {/* Features */}
        <FeaturesBox
          viewData={viewData}
          editItemId={editItemId}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          trigger={trigger}
        />

        {/* Subscription Periods */}
        <SubscriptionPeriodsBox
          key={watch("price")} // Force re-render when price changes
          subscriptionFieldNames={subscriptionFieldNames}
          viewData={viewData}
          editItemId={editItemId}
          register={register}
          errors={errors}
          selectedFrequency={selectedFrequency}
          setValue={setValue}
          price={watch("price")}
          getValues={getValues}
          control={control}
          watch={watch}
          setError={setError}
          onDurationChange={handleDurationChange} // Pass the callback
          clearErrors={clearErrors}
        />

        {viewData && editItemId ? null : (
          <DialogActions className="mt-3">
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
