import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import {
  AddCircleOutlineTwoTone,
  CancelTwoTone,
  CloseTwoTone,
  EditTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import CustomFieldDialog from "../../common/customFieldDialog";
import {
  transformFields,
  isBeyond50Words,
  isDuplicate,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  handleNumberFieldKeyDown,
  fData,
  onError,
} from "../../config/fieldConfig";

export default function ProductForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  onAddForm,
  onEditForm,
  dynamicFormFields,
  handleAddCustomField,
  fields,
  openCustomFieldDialog,
  setOpenCustomFieldDialog,
  newFields,
  setFields,
  viewData,
  setViewData,
  roleAccess,
  addProductType,
  productTypeOptions,
  isNavigateToAllErrorsActive,
  productIdCheck,
  productNameCheck,
  isEData,
  productTypeOptionsWithId,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm();

  const [isActiveChecked, setIsActiveChecked] = useState(false); // Default to false for add mode
  const [isAddingProductType, setIsAddingProductType] = useState(false);
  const [newProductType, setNewProductType] = useState("");

  const editAccess = roleAccess.some(
    (item) => item.resource === "Product" && item.writeAccess
  );

  useEffect(() => {
    reset();
    setIsActiveChecked(false);
    setIsAddingProductType(false);

    if (mode === "add") {
      setViewData();
    }

    // Filter fields based on newFields
    const filteredFields = fields.filter((field) => {
      return !newFields.some((newField) => newField.name === field.name);
    });

    setFields(filteredFields);

    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Set form data with the provided editFormData
      fields.forEach((field) => {
        setValue(field.name, editFormData[field.name]);
      });

      // Set the isActiveChecked state based on the value from editFormData
      setIsActiveChecked(editFormData.active === true);

      // Set form data with the provided editFormData for dynamic fields
      dynamicFormFields.forEach((field) => {
        const nestedField = editFormData.customFields[field.name];
        setValue(field.name, nestedField);

        // Check if the nestedField is an object and has the property with the same name
        if (nestedField && nestedField.hasOwnProperty(field.name)) {
          setValue(field.name, nestedField[field.name]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dynamicFormFields,
    editFormData,
    editItemId,
    mode,
    reset,
    setValue,
    open,
  ]);

  const onSubmit = (formData) => {
    const mainFormData = { ...formData }; // Copy all form data to mainFormData
    const customFields = {}; // To store custom fields

    // Now you have structured mainFormData and customFields objects as per your requirements
    const TypeWithId = productTypeOptionsWithId.find(
      (productType) => productType.typeValue === formData.productType
    );
    if (TypeWithId) {
      mainFormData.productTypeId = TypeWithId.id;
    } else {
      mainFormData.productTypeId = null;
    }

    // Loop through the formData entries to find custom fields
    Object.entries(formData).forEach(([key, value]) => {
      const customField =
        fields.find((field) => field.name === key) ||
        dynamicFormFields.find((field) => field.name === key);

      if (customField && customField.isCustom) {
        customFields[key] = {
          [key]: value,
          dataType: customField.type, // Assuming type property in the field object represents dataType
          isMandatory: customField.required, // Assuming required property in the field object represents isMandatory
        };

        // Remove the custom field from mainFormData
        delete mainFormData[key];
      }
    });

    // Embed the customFields object within the mainFormData object
    if (Object.keys(customFields).length > 0) {
      mainFormData.customFields = customFields;
    }

    // Creating the desired object
    const transformedFields = transformFields(customFields);

    // Creating the desired object
    const transformedData = fData(mainFormData);

    // check if the type form is closed
    if (isAddingProductType === false) {
      // You can now proceed to handle the transformedData as required, such as sending it to the server
      if (mode === "add") {
        onAddForm(transformedData, transformedFields);
      } else if (mode === "edit") {
        transformedData.id = editItemId;
        onEditForm(transformedData, transformedFields);
      }
      onClose();
    } else {
      setError("addNewProductType", {
        type: "duplicate",
        message: "Please save or close the New Product Type",
      });
    }
  };

  const handleAddProductType = () => {
    setIsAddingProductType(true);
    setNewProductType("");
  };

  const handleCancelAdd = () => {
    setIsAddingProductType(false);
    setNewProductType("");
    clearErrors("addNewProductType");
  };

  const handleSaveProductType = async (event) => {
    event.preventDefault(); // Prevent form submission

    const isValid = await trigger("addNewProductType"); // Trigger validation for the field

    if (!isValid) {
      return; // If validation fails, do not proceed
    }

    // Convert all Product types to lowercase for case-insensitive comparison
    const lowerCaseOptions = productTypeOptions.map((type) =>
      type.toLowerCase()
    );
    const lowerCaseNewProductType = newProductType.toLowerCase();

    // Check if newProductType already exists (case insensitive)
    if (newProductType && lowerCaseOptions.includes(lowerCaseNewProductType)) {
      setError("addNewProductType", {
        type: "duplicate",
        message: `"${newProductType}" already exists`,
      });
      return; // Exit function if it's a duplicate
    }

    // Proceed with adding the new Product type
    const value = { typeValue: newProductType, typeName: "Product" };
    console.log(value);
    await addProductType(value, setValue);

    // Clear the input and close the adding state after adding
    setNewProductType("");
    setIsAddingProductType(false);
    clearErrors("addNewProductType");
  };

  useEffect(() => {
    if (editItemId && isEData) {
      // console.log("inSide useEffect",errors);
      // Trigger validation for userId and email on error record form load
      trigger(["productId", "productName"]).then(() => {
        // Focus on the first field with an error
        const errorFields = Object.keys(errors);
        // console.log("jk",errorFields);
        if (errorFields.length > 0) {
          setFocus(errorFields[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, setFocus, errors]);

  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;Product
        {viewData && editAccess ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            onError(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index} marginTop={1}>
                {field.name === "active" ? (
                  <FormControl
                    error={!!errors[field.name]}
                    component="fieldset"
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={isActiveChecked} // Use the state variable here
                          onChange={(e) => setIsActiveChecked(e.target.checked)} // Update the state when the checkbox changes
                          disabled={viewData} // Disable the checkbox based on viewData condition
                        />
                      }
                      label={field.label}
                      required={field.required}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                    />
                    <FormHelperText>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                ) : field.name === "productType" ? (
                  isAddingProductType ? (
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        <TextField
                          label="Add New Product Type"
                          name="addNewProductType"
                          variant="outlined"
                          size="small"
                          autoFocus
                          fullWidth
                          required
                          value={newProductType}
                          {...register("addNewProductType", {
                            required: "Add New Product Type is required",
                            validate: {
                              isEmpty: (value) => isEmpty(value),
                              isValueContainsSplChars: (value) =>
                                isValueContainsSplChars(value),
                              isValueStartsWithNumber: (value) =>
                                isValueStartsWithNumber(value, field.label),
                              isDuplicate: (value) => {
                                if (field.name === "productType") {
                                  return isDuplicate(
                                    productTypeOptions,
                                    field.label,
                                    value,
                                    mode,
                                    [],
                                    field.name,
                                    isEData
                                  );
                                } else {
                                  return true;
                                }
                              },
                            },
                          })}
                          error={!!errors.addNewProductType}
                          helperText={errors.addNewProductType?.message}
                          onChange={(e) => {
                            setNewProductType(e.target.value);
                            setValue("addNewProductType", e.target.value);
                            setError("addNewProductType");
                            trigger("addNewProductType");
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              trigger("addNewProductType");
                            }, 100);
                          }}
                          inputRef={(el) => {
                            if (el) {
                              fieldRefs.current[field.name] = el;
                            }
                          }} // Set ref
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <IconButton
                          aria-label="save"
                          color="success"
                          title="Save"
                          onClick={handleSaveProductType}
                        >
                          <SaveTwoTone />
                        </IconButton>
                        <IconButton
                          aria-label="cancel"
                          color="primary"
                          title="Cancel"
                          onClick={handleCancelAdd}
                        >
                          <CancelTwoTone />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={10}>
                        <Autocomplete
                          name={field.name}
                          options={productTypeOptions}
                          value={
                            productTypeOptions.includes(watch(field.name))
                              ? watch(field.name)
                              : null
                          }
                          onChange={(event, value) => {
                            setValue(field.name, value);
                          }}
                          style={{
                            pointerEvents:
                              viewData && editItemId ? "none" : "auto",
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              trigger(field.name);
                            }, 200); // Trigger validation on blur
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={field.label}
                              variant="outlined"
                              required={field.required}
                              size="small"
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  pointerEvents: "none",
                                },
                              }}
                              {...register(field.name, {
                                required: field.required
                                  ? `${field.label} is required`
                                  : false,
                              })}
                              error={
                                !!errors[field.name] && !params.inputProps.value
                              } // Check if field is empty
                              helperText={
                                errors[field.name]?.message &&
                                (!params.inputProps.value
                                  ? errors[field.name]?.message
                                  : "")
                              } // Show helper text only when there's an error and field is empty
                              inputRef={(el) => {
                                if (el) {
                                  fieldRefs.current[field.name] = el;
                                }
                              }} // Set ref
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {!viewData && editAccess ? (
                          <IconButton
                            aria-label="add"
                            color="primary"
                            title="Add Field"
                            onClick={handleAddProductType}
                          >
                            <AddCircleOutlineTwoTone />
                          </IconButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  )
                ) : (
                  <TextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    variant="outlined"
                    size="small"
                    multiline={field.multiline}
                    rows={field.rows}
                    autoFocus={editItemId && isEData ? true : field.autoFocus}
                    fullWidth
                    //textarea showing two line
                    sx={
                      field.name === "productDescription"
                        ? {
                            "& .MuiInputBase-root": {
                              resize: "vertical", // Ensure resizing is allowed
                              overflow: "auto", // Ensure overflow is handled properly
                            },
                          }
                        : {}
                    }
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    inputProps={{
                      pattern: field.type === "number" ? "\\d*" : undefined,
                      max: "9999-12-31",
                      style: {
                        textTransform:
                          field.type === "date" ? "uppercase" : "none",
                      },
                      ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                    }}
                    InputProps={{
                      style: {
                        pointerEvents: viewData && editItemId ? "none" : "auto",
                      }, // remove the hover effect
                      pattern: field.type === "number" ? "\\d*" : undefined,
                    }}
                    InputLabelProps={{
                      shrink: field.type === "date" ? true : field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      ...(field.type === "email" && {
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        },
                      }),
                      validate: {
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        isValueContainsSplChars: (value) =>
                          field.type === "text" &&
                          field.type !== "email" &&
                          field.type !== "number"
                            ? isValueContainsSplChars(value)
                            : true,
                        isBeyond50Words: (value) =>
                          field.name === "productDescription"
                            ? isBeyond50Words(value)
                            : true,
                        isDuplicate: (value) => {
                          if (field.name === "productId") {
                            return isDuplicate(
                              productIdCheck,
                              field.label,
                              value,
                              mode,
                              editFormData,
                              field.name,
                              isEData
                            );
                          } else if (field.name === "productName") {
                            return isDuplicate(
                              productNameCheck,
                              field.label,
                              value,
                              mode,
                              editFormData,
                              field.name,
                              isEData
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name);
                      }, 200); // Trigger validation on blur
                    }}
                    onKeyDown={(e) => {
                      if (field.type === "number") {
                        handleNumberFieldKeyDown(e);
                      }
                    }}
                    inputRef={(el) => {
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Set ref
                  />
                )}
              </Grid>
            ))}
            {dynamicFormFields.map((field, index) => (
              <Grid item xs={12} sm={6} key={`dynamic-${index}`} marginTop={1}>
                <TextField
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  required={field.required}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onWheel={(event) => {
                    // Check if the input type is number to prevent scrolling
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  InputProps={{
                    style: {
                      pointerEvents: viewData && editItemId ? "none" : "auto",
                    }, // remove the hover effect
                    inputProps: {
                      max: field.type === "date" ? "9999-12-31" : undefined,
                      style: {
                        textTransform:
                          field.type === "date" ? "uppercase" : "none",
                      },
                    },
                    pattern: field.type === "number" ? "\\d*" : undefined,
                  }}
                  InputLabelProps={{
                    shrink: field.type === "date" ? true : field.shrink,
                    style: {
                      pointerEvents: "none",
                    },
                  }}
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    ...(field.type === "email" && {
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    }),
                    validate: {
                      isEmpty: (value) =>
                        field.required ? isEmpty(value) : true,
                      isValueContainsSplChars: (value) =>
                        field.type === "text" &&
                        field.type !== "email" &&
                        field.type !== "number"
                          ? isValueContainsSplChars(value)
                          : true,
                    },
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      trigger(field.name);
                    }, 200); // Trigger validation on blur
                  }}
                  onKeyDown={(e) => {
                    if (field.type === "number") {
                      handleNumberFieldKeyDown(e);
                    }
                  }}
                  inputRef={(el) => {
                    if (el) {
                      fieldRefs.current[field.name] = el;
                    }
                  }} // Set ref
                />
              </Grid>
            ))}
          </Grid>
          {viewData && editItemId ? null : (
            <DialogActions className="mt-3">
              <Button
                onClick={() => setOpenCustomFieldDialog(true)}
                variant="outlined"
                color="primary"
              >
                Add Custom Field
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          )}
        </Box>
      </DialogContent>

      {/* Custom Field Dialog */}
      <CustomFieldDialog
        open={openCustomFieldDialog}
        onClose={() => setOpenCustomFieldDialog(false)}
        onAddCustomField={handleAddCustomField}
      />
    </Dialog>
  );
}
