import { useState } from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import BillingToolbar from "./toolbar";
import TopSection from "./topSection";
import Subscriptions from "./subscriptions";
import { BillingService } from "../../service/billingService";

export default function Billing() {
  const {
    columns,
    rows,
    currentCurrency,
    subscriptionStartDate,
    subscriptionEndDate,
    renewalPrice,
    pricingPlan,
    pricingMonth,
    noOfBeneficiary,
    fetchData,
  } = BillingService();

  const [selectedDropdown, setSelectedDropdown] = useState("subscription");

  const billingScreens = ["subscription", "paymentHistory"];

  return (
    <Box sx={{ p: 2 }}>
      <TopSection
        billingScreens={billingScreens}
        selectedDropdown={selectedDropdown}
        setSelectedDropdown={setSelectedDropdown}
      />

      {selectedDropdown === "subscription" && (
        <Box sx={{ my: 2 }}>
          <Subscriptions
            subscriptionStartDate={subscriptionStartDate}
            subscriptionEndDate={subscriptionEndDate}
            renewalPrice={renewalPrice}
            renewalPlan={pricingPlan}
            renewalMonth={pricingMonth}
            noOfBeneficiary={noOfBeneficiary}
            currentCurrency={currentCurrency}
            fetchData={fetchData}
          />
        </Box>
      )}

      {selectedDropdown === "paymentHistory" && (
        <DataGrid
          columns={columns}
          rows={rows}
          slots={{
            noRowsOverlay: EmptyBox,
            pagination: CustomPagination,
            toolbar: BillingToolbar,
          }}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          density="compact"
          disableRowSelectionOnClick
          autoHeight={Object.keys(rows).length >= 10}
          sx={{
            p: 1,
            height: 530,
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />
      )}
    </Box>
  );
}
