import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN } from "../config/sessionStorage";
import { addSerialNumber } from "../config/fieldConfig";
import { EXCEPTION_MESSAGE } from "../config/toastMessage";
import MonitorColumns from "../pages/Monitor/columns";

export const MonitorService = () => {
  const [data, setTableData] = useState([]); // State for table data
  const [type, setType] = useState("registeredAndSignUp"); // State for user type
  const [approvalStatus, setApprovalStatus] = useState({}); // State to track approval status for each row
  const [selectedOption, setSelectedOption] = useState("registeredAndSignUp");
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const stopRemount = useRef(true); // Ref to prevent remounting

  // Function to fetch data based on user type
  const fetchData = async (userType) => {
    try {
      setIsTableLoading(true); // Set loading state to true before fetching data
      const token = JWT_TOKEN();
      let res;

      if (userType === "getAllRegistration") {
        res = await ApiService.getAllRegistrationsBasedOnType(token, userType);
        // Assign registrationId as id for each item in the response
        res = res.map((item) => ({
          ...item,
          id: item.registrationId, // Assign registrationId as id
          userName: item.name,
        }));
      } else {
        res = await ApiService.getAllUsersBasedOnType(token, userType);
      }
      setTableData(res);

      // Initialize approval status based on fetched data
      const initialApprovalStatus = res.reduce((acc, user) => {
        acc[user.beneficiaryId] = user.approved;
        return acc;
      }, {});
      setApprovalStatus(initialApprovalStatus);

      console.log(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsTableLoading(false); // Set loading state to false after fetching data
    }
  };

  // useEffect to fetch data on initial mount
  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData(type); // Initial fetch with default type
    }
  }, [type]);

  // Function to set user type and fetch data
  const setUserType = (userType) => {
    setSelectedOption(userType);
    setType(userType);
    fetchData(userType);
  };

  // Function to send data to your API endpoint
  const sendDataToEndpoint = async (data) => {
    try {
      // Code to send data to your API endpoint
      console.log("Data sent to endpoint:", data);

      const token = JWT_TOKEN();

      const res = await ApiService.editUser(token, data);
      console.log(res);
      if (res?.status === 200) {
        toast.success(res?.data);
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(res?.response?.data || EXCEPTION_MESSAGE);
      }
      // Refetch data after edit to reflect changes
      fetchData(type);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  // Function to toggle approval status
  const toggleApproval = (email, beneficiaryId) => {
    console.log(email, beneficiaryId);
    setApprovalStatus((prevStatus) => {
      const newStatus = {
        ...prevStatus,
        [beneficiaryId]: !prevStatus[beneficiaryId],
      };

      // Send data based on approval status
      const data = {
        email: email,
        beneficiaryId: beneficiaryId,
        approved: newStatus[beneficiaryId], // New approval status
      };

      console.log(data);

      // Send data to your API endpoint
      sendDataToEndpoint(data);

      return newStatus;
    });
  };

  // Determine whether the current type is 'registeredUser'
  const isRegisteredUser = type === "getAllRegistration";

  return {
    rows: addSerialNumber(data), // Adding serial number to data rows
    columns: MonitorColumns({
      approvalStatus,
      toggleApproval,
      registeredUser: isRegisteredUser,
    }), // Passing toggleApproval and registeredUser to columns
    selectedOption,
    signupUser: () => setUserType("registeredAndSignUp"), // Fetching data for signup users
    registeredUser: () => setUserType("getAllRegistration"), // Fetching data for registered users
    approvedUser: () => setUserType("approved"), // Fetching data for approved users
    nonApprovedUser: () => setUserType("nonApproved"), // Fetching data for non-approved users
    isTableLoading, // Table loading state
  };
};
