import { useRef, useState } from "react";
import toast from "react-hot-toast";
import ApiService from "../../apis/ApiService";
import useFetchOnMount from "../config/useFetchOnMount";
import { JWT_TOKEN } from "../config/sessionStorage";
import {
  EXCEPTION_MESSAGE,
  IMAGE_FILE_TYPE_ERROR,
} from "../config/toastMessage";
import FormFields from "../pages/CompanySetup/formFields";

export const CompanySetupService = () => {
  const [token, setToken] = useState("");
  const [registeredData, setRegisteredData] = useState(null); // Initialize as null instead of empty string
  const [selectedFileLogo, setSelectedFileLogo] = useState("");
  const [viewData, setViewData] = useState(false); // view purpose only
  const [selectedImage, setSelectedImage] = useState(null); // show the logo in screen
  const [verifyOtp, setVerifyOtp] = useState(false); // show otp text box
  const [isOtpVerify, setIsOtpVerify] = useState(true); //otp verification call
  const [isVerify, setIsVerify] = useState(false); //opt verification result
  const [isEditable, setIsEditable] = useState(false); //email editing
  const [emailCheck, setEmailCheck] = useState([]); //email editing

  const fileInputRef = useRef(null);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const res = await ApiService.getUser(TOKEN);
      const regData = res?.data;

      const allUsers = await ApiService.getBeneficiariesWithHierarchy(TOKEN);

      if (Array.isArray(allUsers)) {
        const adminEmails = allUsers
          .filter((user) => user.role === 1) // Filter users with the "Admin" role
          .map((admin) => admin.email); // Extract only the email property

        setEmailCheck(adminEmails);
      }

      const iconImage = await ApiService.getImageBytes(TOKEN);

      // Construct the base64 image URL
      const logo = `data:image/*;base64,${iconImage}`;
      setSelectedImage(logo);

      if (logo.includes("Not Found")) {
        setSelectedFileLogo({});
      } else {
        setSelectedFileLogo({ base64Content: logo });
      }

      if (res?.status === 200) {
        setRegisteredData(regData);
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message);
      } else {
        toast.error(EXCEPTION_MESSAGE);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // You might want to handle the error here, e.g., show an error message to the user
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const handleFileChangeLogo = (e) => {
    // Check if the event and file input are valid
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      // Get the selected file
      const logoFile = e.target.files[0];
      let fileType;
      switch (logoFile.type) {
        case "image/png":
          fileType = "png";
          break;
        case "image/jpeg":
          fileType = "jpeg";
          break;
        default:
          fileInputRef.current.value = "";
          toast.error(IMAGE_FILE_TYPE_ERROR);
          return;
      }
      console.log("fileType", fileType);
      // Create a new FileReader object to read the file content
      const reader = new FileReader();

      // Callback function executed when file reading is completed
      reader.onload = function (event) {
        // Get the base64-encoded content of the file
        const base64Content = event.target.result;

        // Set the selected file and its base64 content in the state
        setSelectedFileLogo({
          file: logoFile,
          base64Content: base64Content,
        });
        setSelectedImage(base64Content);

        // Reset file input after processing
        if (fileInputRef && fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      };

      // Read the content of the selected file as a data URL (base64-encoded)
      reader.readAsDataURL(logoFile);
    } else {
      console.error("Invalid file input event:", e);
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.log("File input ref does not exist"); // Debugging log
    }
  };

  const onCompanySetup = async (
    value,
    currency,
    selectedTimezone,
    gst,
    email,
    countryName,
    country
  ) => {
    try {
      const res = await ApiService.updateUserWithImage(
        token,
        selectedFileLogo.base64Content,
        value,
        currency,
        selectedTimezone,
        gst,
        email
      );

      if (res?.status === 200) {
        sessionStorage.setItem("countryName", countryName);
        sessionStorage.setItem("countryObject", JSON.stringify(country));
        sessionStorage.setItem("currencyName", currency);
        sessionStorage.setItem("timeZone", selectedTimezone);

        toast.success(res?.data);
      } else if (res?.code === "ERR_NETWORK") {
        toast.error(res?.message); // Network server error
      } else {
        toast.error(res?.response?.data || EXCEPTION_MESSAGE);
      }
      fetchData();
    } catch (error) {
      console.error("Error updateRegister:", error);
      toast.error(EXCEPTION_MESSAGE);
    }
  };

  const generateOtpForEmail = async (email, orgId) => {
    try {
      const res = await ApiService.generateOtpForEmail(email, orgId);
      console.log(res);
      if (res && res?.status === 200) {
        console.log(res);
      }
    } catch (error) {
      console.error(error);
      setIsOtpVerify(false);
    }
  };

  const fields = FormFields;

  return {
    onCompanySetup,
    registeredData,
    handleFileChangeLogo,
    fields,
    viewData,
    setViewData,
    selectedImage,
    setSelectedImage,
    setSelectedFileLogo,
    handleAvatarClick,
    fileInputRef,
    // otpVerification,
    verifyOtp,
    setVerifyOtp,
    isOtpVerify,
    setIsOtpVerify,
    isVerify,
    setIsVerify,
    isEditable,
    setIsEditable,
    generateOtpForEmail,
    token,
    emailCheck,
    fetchData,
  };
};
