import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { format } from "date-fns";
import { CURRENCY_NAME } from "../../config/sessionStorage";

const Columns = (
  dynamicColumns,
  handleEdit,
  handleDelete,
  roleAccess,
  isNavigateToAllErrorsActive
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Product" && item.writeAccess
  );

  // Regular expression to identify fields that contain "date"
  const dateFieldRegex = /date/i;

  // Map over dynamicColumns to add valueFormatter to fields that match the date regex
  const formattedDynamicColumns = dynamicColumns.map((column) => {
    if (dateFieldRegex.test(column.field)) {
      return {
        ...column,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(new Date(value), "dd-MM-yyyy");
          }
          return "";
        },
      };
    }
    return column;
  });

  // Session storage to taken currency value
  const curName = CURRENCY_NAME();

  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "productId",
      headerName: "Prod ID",
      width: 80,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.productId} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true, false)}
          >
            {row.productId}
          </div>
        );
      },
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 150,
    },
    {
      field: "productDescription",
      headerName: "Prod Description",
      width: 150,
    },
    {
      field: "subProductOf",
      headerName: "Sub Prod Of",
      width: 130,
    },
    {
      field: "productCost",
      headerName: `Prod Cost (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "productType",
      headerName: "Prod Type",
      width: 130,
    },
    {
      field: "commissionRate",
      headerName: `Comm Rate (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "discountPercentage",
      headerName: `Discount % (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "fullProductName",
      headerName: "Full Prod Name",
      width: 130,
    },
    {
      field: "productLevel",
      headerName: "Prod Level",
      width: 100,
    },
    {
      field: "productPrice",
      headerName: `Prod Price (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 130,
    },
    {
      field: "commissionAmount",
      headerName: `Comm Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "discountAmount",
      headerName: `Discount Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "preferredVendor",
      headerName: "Preferred Vendor",
      width: 180,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
    isNavigateToAllErrorsActive && {
      field: "description",
      headerName: "Error Description",
      width: 200,
    },
    ...formattedDynamicColumns,
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Product" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Product" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={
                editAccess && !isNavigateToAllErrorsActive
                  ? handleEdit(id, false, false)
                  : handleEdit(id, false, true)
              }
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }

        return actions;
      },
    },
  ].filter(Boolean);
};

export default Columns;
