import { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { CheckCircleOutlineTwoTone } from "@mui/icons-material";
import { PricingService } from "../../service/pricingService";
import UpgradeDialog from "./upgradeDialog";
import { BillingService } from "../../service/billingService";

const Pricing = () => {
  const { pricingPlans } = PricingService();
  const { pricingPlan, noOfBeneficiary, fetchData } = BillingService();

  // Determine the current plan object and its ID
  const currentPlan = pricingPlans.find((plan) => plan.title === pricingPlan);
  const currentPlanId = currentPlan ? currentPlan.id : null;

  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleOpen = (plan) => {
    setSelectedPlan(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(null);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Pricing Plans
      </Typography>

      <Grid container spacing={4} sx={{ py: 2, justifyContent: "center" }}>
        {pricingPlans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                ...plan.cardStyle,
                border: `1px solid ${plan.titleBgColor}`,
                borderRadius: "8px",
                textAlign: "center",
                padding: "16px",
                boxShadow: 1,
                ":hover": {
                  boxShadow: 3,
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    backgroundColor: plan.titleBgColor,
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {plan.title}
                </Typography>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  gutterBottom
                  sx={{
                    backgroundColor: plan.priceBgColor,
                    color: "white",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  {plan.price}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  paragraph
                  gutterBottom
                >
                  {plan.description}
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    listStyle: "none",
                    "& li": {
                      display: "flex",
                      alignItems: "flex-start", // Align items at the start
                      marginBottom: "8px",
                      maxWidth: "100%", // Prevent items from stretching too wide
                    },
                  }}
                >
                  {plan.features.map((feature, index) => (
                    <ListItem key={index} sx={{ padding: 0, display: "block" }}>
                      <CheckCircleOutlineTwoTone color="success" />
                      <Typography variant="body2" gutterBottom>
                        {feature}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  paragraph
                  gutterBottom
                >
                  {plan.notes}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    borderRadius: 7,
                    p: "10px 20px",
                  }}
                  style={{
                    background:
                      currentPlanId && plan.id <= currentPlanId
                        ? "#757575"
                        : plan.buttonBgColor,
                    color: "white",
                    "&:hover": {
                      background:
                        currentPlanId && plan.id <= currentPlanId
                          ? "lightgrey"
                          : plan.buttonBgColor,
                    },
                  }}
                  onClick={() => handleOpen(plan)}
                  disabled={currentPlanId && plan.id <= currentPlanId} // Disable button for current and lower ID plans
                >
                  {currentPlanId && plan.id < currentPlanId
                    ? "Existing Plan"
                    : currentPlanId && plan.id === currentPlanId
                    ? "Current Plan"
                    : "Upgrade"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedPlan && (
        <UpgradeDialog
          open={open}
          handleClose={handleClose}
          plan={selectedPlan}
          noOfBeneficiary={Number(noOfBeneficiary)}
          fetchData={fetchData}
        />
      )}
    </Box>
  );
};

export default Pricing;
