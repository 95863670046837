import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import RoleColumns from "./columns";
import RoleToolbar from "./toolbar";
import RoleForm from "./roleForm";
import { RoleService } from "../../service/roleService";

export default function Role() {
  const {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    dynamicOptions,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    open,
    addFormOpen,
    addFormClose,
    fields,
    roleAccess,
    roleConfigNames,
    loading,
    apiCall,
  } = RoleService();

  const columns = RoleColumns(handleEdit, handleDelete, roleAccess);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: () => (
              <RoleToolbar addFormOpen={addFormOpen} roleAccess={roleAccess} />
            ),
            pagination: CustomPagination,
          }}
          aautoHeight={Object.keys(rows).length >= 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          density="compact"
          loading={loading}
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />

        {/* Handle both and and edit form display */}
        <RoleForm
          open={editFormOpen || open}
          onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
          mode={editFormOpen ? "edit" : "add"}
          editItemId={editFormOpen ? editItemId : undefined}
          editFormData={editFormOpen ? editFormData : undefined}
          onEditForm={editFormOpen ? onEditForm : undefined}
          onAddForm={!editFormOpen ? onAddForm : undefined}
          fields={fields}
          dynamicOptions={dynamicOptions}
          roleConfigNames={roleConfigNames}
          apiCall={apiCall}
        />
      </Box>
    </>
  );
}
