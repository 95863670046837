import { useState, useEffect, useRef } from "react";
import { format, startOfToday, subDays, subMonths } from "date-fns";
import { addSerialNumber } from "../config/fieldConfig";
import { JWT_TOKEN } from "../config/sessionStorage";
import AuditLogColumns from "../pages/AuditLog/columns";
import axiosClient from "../../apis/Api";

export const AuditLogService = () => {
  const [data, setTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const [apiEndpoint, setApiEndpoint] = useState(
    "/auditLog/getAuditLogsWithHierarchy"
  );

  const [selectedOption, setSelectedOption] = useState("Get All");

  // Function to handle menu item clicks
  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const handleGetAll = () => {
    if (selectedOption === "Get All") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/auditLog/getAuditLogsWithHierarchy");
    stopRemount.current = true;
    handleSelectOption("Get All");
  };

  const handleGetMyDetails = () => {
    if (selectedOption === "My Data") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/auditLog/getMyAuditLog");
    stopRemount.current = true;
    handleSelectOption("My Data");
  };

  const handleGetMyReportee = () => {
    if (selectedOption === "My Reportee") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/auditLog/getAuditLogForReportees");
    stopRemount.current = true;
    handleSelectOption("My Reportee");
  };

  // Yesterday's date
  const eDate = subDays(startOfToday(), 1);

  // One month before yesterday
  const sDate = subMonths(eDate, 1);

  // Format dates
  const formattedEndDate = format(eDate, "yyyy-MM-dd");
  const formattedStartDate = format(sDate, "yyyy-MM-dd");

  // States for start and end dates
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  console.log(`Start Date & End Date: ${startDate} - ${endDate}`);

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, startDate, endDate]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();

    try {
      console.log("Fetching data with:", startDate, endDate);

      const res = await axiosClient.get(apiEndpoint, {
        headers: {
          Authorization: TOKEN,
          startDate: startDate,
          endDate: endDate,
        },
      });
      console.log("auditLogRes", res?.data);
      setTableData(res?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsTableLoading(false);
    }
  };

  const rows = addSerialNumber(data);

  const columns = AuditLogColumns();

  return {
    rows,
    columns,
    isTableLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedOption,
    handleGetAll,
    handleGetMyDetails,
    handleGetMyReportee,
    stopRemount,
  };
};
