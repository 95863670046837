import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import CommissionToolbar from "./toolbar";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import CommissionForm from "./commissionForm";
import { CommissionService } from "../../service/commissionService";
import Columns from "./columns";
import { addSerialNumber } from "../../config/fieldConfig";

export default function Commission() {
  const {
    rows,
    selectFrequencyOption,
    commissionConfigNames,
    fields,
    onAddForm,
    onEditForm,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    setEditFormOpen,
    variableNames,
    conditionFields,
    calculationFields,
    checkBoxFields,
    getConditionOptions,
    getCalcOptions,
    handleAddRow,
    addCalcRow,
    RemoveConditionRow,
    RemoveCalcRow,
    row,
    setRow,
    calcRow,
    setCalcRow,
    handleCondition,
    handleCalculation,
    outputStrings,
    outputArray,
    splitRow,
    splitColumn,
    splitData,
    setSplitData,
    roleUpRow,
    roleUpColumn,
    roleData,
    setRoleData,
    tieredRow,
    tieredColumn,
    tieredData,
    setTieredData,
    calculationForm,
    conditionForm,
    roleAccess,
    formApiCall,
    productEntity,
    customerEntity,
    transactionEntity,
    conditionEntityNames,
    conditionOption,
    symbols,
    calcEntityNames,
    productNames,
    fullProductNames,
    customerNames,
    roleUpChecked,
    setRoleUpChecked,
    splitChecked,
    setSplitChecked,
    tieredChecked,
    setTieredChecked,
    isDisabled,
    viewData,
    setViewData,
    braces,
    frequencyOptionsWithId,
    percentageOption,
    rowString,
    loading,
  } = CommissionService();

  const columns = Columns(handleEdit, handleDelete, roleAccess);

  const rowsWithSNo = addSerialNumber(rows);

  const readAccess = roleAccess.some(
    (item) => item.resource === "FieldMapping" && item.writeAccess
  );

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
    reset();
  };

  const reset = () => {
    setEditFormOpen(false);
    setRoleData("");
    setSplitData("");
    setTieredData([]);
    setRoleUpChecked(false);
    setSplitChecked(false);
    setTieredChecked(false);
    setRow([
      {
        conDescription: "",
        conditionEntityName: conditionEntityNames[0],
        conditionOption: "",
        selection: "",
        symbols: symbols[0],
      },
    ]);
    setCalcRow([
      {
        calcDescription: "",
        calcEntityName: calcEntityNames[0],
        calcOption: "",
        calcField: "",
        calcSymbols: symbols[0],
      },
    ]);
  };

  const editAccess = roleAccess.some(
    (item) => item.resource === "Commission" && item.writeAccess
  );

  const [showDataGrid, setShowDataGrid] = useState(false);
  useEffect(() => {
    // // Set a timeout to show the DataGrid after 2 seconds (2000 milliseconds)
    // const timer = setTimeout(() => {
    setShowDataGrid(true);
    // }, 2000);

    // // Clean up the timeout if the component unmounts before the timeout completes
    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {readAccess && (
        <Box sx={{ m: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={
                showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
              }
              onClick={() => {
                toggleForm();
                if (!showForm) {
                  formApiCall();
                }
              }}
            >
              Add Commission
            </Button>
          </Box>
          {showForm && (
            <Paper sx={{ px: 3, py: 2 }}>
              <Typography variant="h6" gutterBottom mb={6}>
                Add Commission
              </Typography>
              <CommissionForm
                mode="add"
                onClose={toggleForm}
                onClick={formApiCall}
                productEntity={productEntity}
                customerEntity={customerEntity}
                transactionEntity={transactionEntity}
                selectFrequencyOption={selectFrequencyOption}
                onAddForm={onAddForm}
                onEditForm={onEditForm}
                fields={fields}
                variableNames={variableNames}
                conditionFields={conditionFields}
                calculationFields={calculationFields}
                checkBoxFields={checkBoxFields}
                getConditionOptions={getConditionOptions}
                getCalcOptions={getCalcOptions}
                handleAddRow={handleAddRow}
                addCalcRow={addCalcRow}
                RemoveConditionRow={RemoveConditionRow}
                RemoveCalcRow={RemoveCalcRow}
                row={row}
                setRow={setRow}
                calcRow={calcRow}
                setCalcRow={setCalcRow}
                handleCondition={handleCondition}
                handleCalculation={handleCalculation}
                outputStrings={outputStrings}
                outputArray={outputArray}
                splitRow={splitRow}
                splitColumn={splitColumn}
                splitData={splitData}
                setSplitData={setSplitData}
                roleUpRow={roleUpRow}
                roleUpColumn={roleUpColumn}
                roleData={roleData}
                setRoleData={setRoleData}
                tieredRow={tieredRow}
                tieredColumn={tieredColumn}
                tieredData={tieredData}
                setTieredData={setTieredData}
                calculationForm={calculationForm}
                conditionForm={conditionForm}
                commissionConfigNames={commissionConfigNames}
                conditionEntityNames={conditionEntityNames}
                conditionOption={conditionOption}
                symbols={symbols}
                calcEntityNames={calcEntityNames}
                productNames={productNames}
                fullProductNames={fullProductNames}
                customerNames={customerNames}
                roleUpChecked={roleUpChecked}
                setRoleUpChecked={setRoleUpChecked}
                splitChecked={splitChecked}
                setSplitChecked={setSplitChecked}
                tieredChecked={tieredChecked}
                setTieredChecked={setTieredChecked}
                viewData={viewData}
                setViewData={setViewData}
                braces={braces}
                frequencyOptionsWithId={frequencyOptionsWithId}
                percentageOption={percentageOption}
                rowString={rowString}
              />
            </Paper>
          )}
        </Box>
      )}

      <Box>
        {showDataGrid ? (
          <DataGrid
            rows={rowsWithSNo}
            columns={columns}
            slots={{
              noRowsOverlay: EmptyBox,
              toolbar: CommissionToolbar,
              pagination: CustomPagination,
            }}
            autoHeight={Object.keys(rowsWithSNo).length >= 10}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            density="compact"
            loading={loading}
            sx={{
              p: 1,
              height: 530,
              // removing the cell border
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        ) : (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        <Dialog open={editFormOpen} maxWidth="lg">
          <DialogTitle>
            {viewData && editItemId ? "View" : "Edit"}
            &nbsp;Commission
            {viewData && editAccess ? (
              <IconButton
                aria-label="edit"
                style={{ position: "absolute", top: 10, right: 45 }}
                color="primary"
                title="Edit"
                onClick={() => setViewData(false)}
              >
                <EditTwoTone />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "8px", right: "8px" }}
              onClick={reset}
            >
              <CloseTwoTone />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <CommissionForm
              mode="edit"
              onClose={reset}
              editItemId={editItemId}
              editFormData={editFormData} // Pass the edit form data to ProductFormEdit
              selectFrequencyOption={selectFrequencyOption}
              onEditForm={onEditForm}
              onClick={formApiCall}
              productEntity={productEntity}
              customerEntity={customerEntity}
              fields={fields}
              variableNames={variableNames}
              conditionFields={conditionFields}
              calculationFields={calculationFields}
              checkBoxFields={checkBoxFields}
              getConditionOptions={getConditionOptions}
              getCalcOptions={getCalcOptions}
              handleAddRow={handleAddRow}
              addCalcRow={addCalcRow}
              RemoveConditionRow={RemoveConditionRow}
              RemoveCalcRow={RemoveCalcRow}
              row={row}
              setRow={setRow}
              calcRow={calcRow}
              setCalcRow={setCalcRow}
              handleCondition={handleCondition}
              handleCalculation={handleCalculation}
              outputStrings={outputStrings}
              outputArray={outputArray}
              splitRow={splitRow}
              splitColumn={splitColumn}
              splitData={splitData}
              setSplitData={setSplitData}
              roleUpRow={roleUpRow}
              roleUpColumn={roleUpColumn}
              roleData={roleData}
              setRoleData={setRoleData}
              tieredRow={tieredRow}
              tieredColumn={tieredColumn}
              tieredData={tieredData}
              setTieredData={setTieredData}
              calculationForm={calculationForm}
              conditionForm={conditionForm}
              commissionConfigNames={commissionConfigNames}
              transactionEntity={transactionEntity}
              productNames={productNames}
              fullProductNames={fullProductNames}
              customerNames={customerNames}
              roleUpChecked={roleUpChecked}
              setRoleUpChecked={setRoleUpChecked}
              splitChecked={splitChecked}
              setSplitChecked={setSplitChecked}
              tieredChecked={tieredChecked}
              setTieredChecked={setTieredChecked}
              isDisabled={isDisabled}
              viewData={viewData}
              setViewData={setViewData}
              braces={braces}
              frequencyOptionsWithId={frequencyOptionsWithId}
              percentageOption={percentageOption}
              rowString={rowString}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
