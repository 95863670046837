import { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { ListTwoTone } from "@mui/icons-material";
import AddButton from "../../common/AddButton";

export default function AssignCommissionToolbar({
  addFormOpen,
  roleAccess,
  handleGetMyReportee,
  handleGetMyDetails,
  handleGetAll,
  selectedOption,
  handleSelectOption,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Define menu items
  const menuItems = [
    { label: "Get All", action: handleGetAll, key: "Get All" },
    { label: "My Data", action: handleGetMyDetails, key: "My Data" },
    { label: "My Reportee", action: handleGetMyReportee, key: "My Reportee" },
  ];

  return (
    <GridToolbarContainer>
      <Typography variant="h5" color="primary">
        Assign Commissions
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        variant="text"
        size="small"
        startIcon={<ListTwoTone />}
        onClick={handleMenuOpen}
        aria-label="View Options"
      >
        View
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, action, key }) => (
          <MenuItem
            key={key}
            onClick={() => {
              action();
              handleMenuClose();
            }}
            selected={selectedOption === key}
            sx={{
              color: selectedOption === key ? "secondary.main" : "inherit",
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>

      <div style={{ flex: 1 }} />

      <GridToolbarQuickFilter />
      <AddButton
        dialogType={"AssignCommission"}
        addFormOpen={addFormOpen}
        roleAccess={roleAccess}
      />
    </GridToolbarContainer>
  );
}
