import { Box, Typography } from "@mui/material";
import { CustomTooltip } from "../../config/fieldConfig";

export default function AuditLogColumns() {
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 90,
    },
    {
      field: "beneficiaryName",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "entityType",
      headerName: "Screen Name",
      width: 150,
    },
    {
      field: "changes",
      headerName: "Changes",
      width: 200,
      sortComparator: (v1, v2) => {
        const toStr = (arr) =>
          Array.isArray(arr)
            ? arr
                .map(({ field, newValue }) => `${field}: ${newValue}`)
                .join(", ")
            : "";
        return toStr(v1).localeCompare(toStr(v2));
      },
      renderCell: ({ value }) =>
        Array.isArray(value) && value.length ? (
          <CustomTooltip
            content={
              <Box
                sx={{
                  maxWidth: { xs: "90vw", sm: "70vw", md: "50vw", lg: "40vw" },
                  maxHeight: "200px",
                  overflowY: "auto",
                  "& table": {
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  },
                  "& th, & td": {
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                    maxWidth: "200px",
                  },
                  "& th": { backgroundColor: "#f4f4f4", fontWeight: "bold" },
                  "& thead th, & tbody td": {
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                  },
                  "& tbody tr:last-child td": {
                    borderBottom: "1px solid black",
                  },
                  "& tbody td:last-child, & thead th:last-child": {
                    borderRight: "1px solid black",
                  },
                }}
              >
                <table>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.map(({ field, oldValue, newValue }, index) => (
                      <tr key={index}>
                        <td>{field}</td>
                        <td>{oldValue}</td>
                        <td>{newValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            }
          >
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
                width: { xs: 180, sm: 200 },
              }}
            >
              {value
                .map(({ field, newValue }) => `${field}: ${newValue}`)
                .join(" | ")}
            </Typography>
          </CustomTooltip>
        ) : (
          ""
        ),
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      width: 170,
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      width: 200,
    },
  ];
}
