import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import JoditEditor from "jodit-react";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import toast from "react-hot-toast";
import {
  excludePatterns,
  transformHTML,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  decodeHTML,
  areBracesBalanced,
  highlightUnbalancedBraces,
  handleNumberFieldKeyDownInRole,
  onError,
} from "../../config/fieldConfig";
import EmptyBox from "../../common/EmptyBox";
import RoleUPForm from "./roleUpForm";
import SplitForm from "./splitForm";
import TieredForm from "./tieredForm";

export default function CommissionForm({
  mode,
  onClose,
  editItemId,
  editFormData,
  transactionEntity,
  selectFrequencyOption,
  onAddForm,
  onEditForm,
  fields,
  variableNames,
  conditionFields,
  calculationFields,
  checkBoxFields,
  getConditionOptions,
  getCalcOptions,
  handleAddRow,
  addCalcRow,
  RemoveConditionRow,
  RemoveCalcRow,
  row,
  setRow,
  calcRow,
  setCalcRow,
  handleCondition,
  handleCalculation,
  outputStrings,
  outputArray,
  splitRow,
  splitColumn,
  splitData,
  setSplitData,
  roleUpRow,
  roleUpColumn,
  roleData,
  setRoleData,
  tieredRow,
  tieredColumn,
  tieredData,
  setTieredData,
  calculationForm,
  conditionForm,
  commissionConfigNames,
  conditionEntityNames,
  conditionOption,
  symbols,
  calcEntityNames,
  productNames,
  fullProductNames,
  customerNames,
  roleUpChecked,
  setRoleUpChecked,
  splitChecked,
  setSplitChecked,
  tieredChecked,
  setTieredChecked,
  isDisabled,
  viewData,
  setViewData,
  productEntity,
  braces,
  frequencyOptionsWithId,
  percentageOption,
  rowString,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm();

  // Checkbox Form
  const [openRoleUpForm, setOpenRoleUpForm] = useState(false);
  const [openSplitForm, setOpenSplitForm] = useState(false);
  const [openTieredForm, setOpenTieredForm] = useState(false);

  // Checkbox
  const [isActiveChecked, setIsActiveChecked] = useState(false);
  // const [attainmentChecked, setAttainmentChecked] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors1, setErrors1] = useState({});

  useEffect(() => {
    reset();
    if (mode !== "edit") {
      setViewData();
      setRoleData("");
      setSplitData("");
      setTieredData([]);
      setRoleUpChecked(false);
      setSplitChecked(false);
      setTieredChecked(false);
      setRow([
        {
          conDescription: "",
          startBrace: braces[0],
          conditionEntityName: conditionEntityNames[0],
          conditionOption: "",
          field: "",
          symbols: symbols[0],
          endBrace: braces[0],
        },
      ]);
      setCalcRow([
        {
          calcDescription: "",
          calcEntityName: calcEntityNames[0],
          calcOption: "",
          calcField: "",
          calcSymbols: symbols[0],
          percentage: percentageOption[0],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Check if editFormData exists
      if (editFormData) {
        setValue("commissionDescription", editFormData.commissionDescription);
        // Check if editFormData.roleUp exists
        if (
          editFormData.roleUp &&
          Object.keys(editFormData.roleUp).length !== 0
        ) {
          setRoleUpChecked(true);
          setRoleData(editFormData.roleUp);
        }
        // Check if editFormData.split exists
        if (
          editFormData.split &&
          Object.keys(editFormData.split).length !== 0
        ) {
          setSplitChecked(true);
          setSplitData(editFormData.split);
        }
        if (editFormData.tiered && editFormData.tiered.length !== 0) {
          setTieredChecked(true);
          setTieredData(editFormData.tiered);
        }

        const outputArray = conditionForm(editFormData.conditionedName);
        setRow(outputArray);

        const value = calculationForm(editFormData.calculateFormula);
        setCalcRow(value);

        fields.forEach((field) => {
          const { name, type } = field;
          let value = editFormData[name];
          if (type === "text" || type === "number" || type === "boolean") {
            const creditString = editFormData.credit; // Assuming Key is 'salesAmount: 100'
            const [crediting, portion] = creditString
              .split(":")
              .map((item) => item.trim());
            setValue("credit", crediting);
            setValue("portion", portion);
            setValue(name, value);
          }
        });
        // Set the isActiveChecked state based on the value from editFormData
        setIsActiveChecked(editFormData.active === true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const onRoleData = (data) => {
    setRoleData(data);
  };

  const onSplitData = (data) => {
    setSplitData(data);
  };

  const onTieredData = (data) => {
    setTieredData(data);
  };

  // Wrap the conditionString with `if` and parentheses
  const outputStringValue = `if (${outputStrings.outputString})`;
  let highlightedString;

  const braceMap = areBracesBalanced(outputStrings.outputString1);
  highlightedString = highlightUnbalancedBraces(
    outputStrings.outputString1,
    braceMap
  );

  const onSubmit = (formData) => {
    formData.credit = {
      crediting: formData.credit,
      portion: formData.portion,
    };
    if (braceMap.size === 0) {
      console.log("The braces are balanced.");
    } else {
      toast.error("The braces are not balanced in the condition field.");
      return;
    }

    // Remove the 'if ' prefix
    const trimmedOutput = outputStringValue.startsWith("if ")
      ? outputStringValue.substring(3) // Remove 'if ' which is 3 characters
      : outputStringValue;

    formData.conditionedName = trimmedOutput;
    formData.calculateFormula = outputArray;
    formData.conditioned = rowString;
    // Now you have structured mainFormData and customFields objects as per your requirements
    const TypeWithId = frequencyOptionsWithId.find(
      (frequency) => frequency.frequency === formData.selectFrequency
    );
    if (TypeWithId) {
      formData.selectFrequencyId = TypeWithId.id;
    } else {
      formData.selectFrequencyId = null;
    }

    if (Object.keys(roleData).length > 0) {
      // Iterate through the object's properties
      for (const key in roleData) {
        // Check if the property value is a string containing a number
        if (!isNaN(roleData[key])) {
          // Convert the string to a number
          roleData[key] = parseFloat(roleData[key]);
        }
      }
      formData.roleUp = roleData;
    } else if (Object.keys(roleData).length === 0) {
      delete formData.roleUp;
    }
    if (Object.keys(splitData).length > 0) {
      // Iterate through the object's properties
      for (const key in splitData) {
        // Check if the property value is a string containing a number
        if (!isNaN(splitData[key])) {
          // Convert the string to a number
          splitData[key] = parseFloat(splitData[key]);
        }
      }
      formData.split = splitData;
    } else if (Object.keys(splitData).length === 0) {
      delete formData.split;
    }
    if (tieredData.length > 0) {
      formData.tiered = tieredData;
    } else if (tieredData.length === 0) {
      delete formData.tiered;
    }

    // remove unwanted values
    const filterFormData = (data) => {
      const filteredData = {};

      for (const key in data) {
        if (!excludePatterns.test(key)) {
          filteredData[key] = data[key];
        }
      }

      return filteredData;
    };
    // Get the content from the editor
    const content =
      formData.commissionDescription &&
      formData.commissionDescription.replace(/<[^>]*>/g, ""); // Remove HTML tags
    const contentWithoutSpaces = content && content.replace(/\s+/g, ""); // Remove spaces

    // Check if the content length exceeds the limit
    if (contentWithoutSpaces && contentWithoutSpaces.length > 1000) {
      return; // Prevent form submission
    }

    const filteredFormData = filterFormData(formData);

    // format the style for editor
    const descriptionHTMLFormate = transformHTML(
      filteredFormData.commissionDescription
    );

    filteredFormData.commissionDescription = descriptionHTMLFormate;

    // Only call handleSubmit if there are no validation errors
    if (mode === "add") {
      onAddForm(filteredFormData);
    } else if (mode === "edit") {
      filteredFormData.id = editItemId;
      onEditForm(filteredFormData);
    }
    onClose();
    setErrors1({});
  };

  useEffect(() => {
    if (mode === "add") {
      setRoleData(roleData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleData]);

  useEffect(() => {
    if (mode === "add") {
      setSplitData(splitData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitData]);

  useEffect(() => {
    if (mode === "add") {
      setTieredData(tieredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tieredData]);

  const isCheckeds = (field, checked) => {
    switch (field) {
      case "roleUp":
        if (Object.keys(roleData).length !== 0) {
          setRoleUpChecked(true);
          setOpenRoleUpForm(true);
        } else {
          setRoleUpChecked(checked);
          setOpenRoleUpForm(checked);
        }
        break;
      case "split":
        if (Object.keys(splitData).length !== 0) {
          setSplitChecked(true);
          setOpenSplitForm(true);
        } else {
          setSplitChecked(checked);
          setOpenSplitForm(checked);
        }
        break;
      case "tiered":
        if (tieredData.length !== 0) {
          setTieredChecked(true);
          setOpenTieredForm(true);
        } else {
          setTieredChecked(checked);
          setOpenTieredForm(checked);
        }
        break;
      // case "attainment":
      //   setAttainmentChecked(checked);
      //   break;
      default:
        break;
    }
  };

  const handleRoleUpFormClose = () => {
    setOpenRoleUpForm(false);
  };

  const handleSplitFormClose = () => {
    setOpenSplitForm(false);
  };

  const handleTieredFormClose = () => {
    setOpenTieredForm(false);
  };

  //default select in condition and calculation
  useEffect(() => {
    if (mode === "add") {
      if (productEntity.length > 0) {
        setRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            field: productEntity[0], // Set the field to the first item from productEntity
          }))
        );
        setCalcRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            calcField: productEntity[0], // Set the field to the first item from productEntity
          }))
        );
      }
      if (variableNames.length > 0) {
        setCalcRow((prevRows) =>
          prevRows.map((rowItem) => ({
            ...rowItem,
            calcOption: variableNames[0], // Set the field to the first item from productEntity
          }))
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEntity, mode, variableNames]);

  // totalCondition fields
  const TotalConditionTextField = () => {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          padding: "9px",
          borderRadius: "4px",
          width: "100%", // Ensure it takes up full width of its parent
          maxWidth: "100%", // Ensure it does not exceed its parent container
          boxSizing: "border-box", // Include padding and border in the element's total width and height
          overflowWrap: "break-word", // Handle long words or URLs that may overflow
          pointerEvents: "none", // Disable interaction
        }}
        dangerouslySetInnerHTML={{ __html: `if(${highlightedString})` }}
      />
    );
  };

  const TotalCalculationTextField = () => {
    return (
      <div
        style={{
          border: "1px solid #ddd",
          padding: "9px",
          borderRadius: "4px",
          width: "100%", // Ensure it takes up full width of its parent
          maxWidth: "100%", // Ensure it does not exceed its parent container
          boxSizing: "border-box", // Include padding and border in the element's total width and height
          overflowWrap: "break-word", // Handle long words or URLs that may overflow
          pointerEvents: "none", // Disable interaction
        }}
      >
        <>{JSON.stringify(outputArray)}</> {/* Pretty print JSON */}
      </div>
    );
  };

  // Ensure fullProductNames is initialized as an array
  const validFullProductNames = Array.isArray(fullProductNames)
    ? fullProductNames.filter((name) => name !== null)
    : [];

  const inputStyles = {
    height: "2.5rem", // Set a fixed height for the input box
    "& .MuiInputBase-input": {
      height: "2.5rem", // Ensure the input itself does not grow
      padding: "0.5rem", // Adjust padding if needed
    },
  };

  // condition fields
  const Conditions = () => {
    const errorInputRefs = useRef({});

    useEffect(() => {
      if (isSubmitted) {
        setTimeout(() => {
          const firstErrorField = Object.keys(errors)[0];

          if (firstErrorField && errorInputRefs.current[firstErrorField]) {
            errorInputRefs.current[firstErrorField].focus();
          }
          setIsSubmitted(false); // Reset after focusing
        }, 0); // Use a short delay to allow state update to propagate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted]);

    const handleRemoveConditionRow = (index, row) => {
      setValue(`field_${index}`, "");
      if (mode === "edit") {
        setValue(`conDescription_${index}`, "");
      }
      clearErrors(`field_${index}`, "");
      clearErrors(`conDescription_${index}`, "");
      RemoveConditionRow(index); // logic for removing the row
    };

    const isDuplicatedRow = (fieldName, value, rowIndex) => {
      if (!row || rowIndex >= row.length) {
        console.error(`Invalid rowIndex: ${rowIndex}`);
        return true; // or some appropriate default behavior
      }
      // Check if there are duplicate entries based on conDescription, conditionEntityName, field, and symbols
      const isDuplicate = row.some((r, index) => {
        if (index === rowIndex) return false; // Skip the current row

        const isDuplicateRow =
          r.conDescription === row[rowIndex].conDescription &&
          r.startBrace === row[rowIndex].startBrace &&
          r.conditionEntityName === row[rowIndex].conditionEntityName &&
          r.field === row[rowIndex].field &&
          r.symbols === row[rowIndex].symbols &&
          r.endBrace === row[rowIndex].endBrace;

        return isDuplicateRow;
      });
      // Return appropriate validation message or true if not duplicate
      return isDuplicate
        ? fieldName
          ? `${fieldName} must be unique`
          : "This field must be unique"
        : true;
    };

    // Function to handle clearing duplicate errors based on current validation results
    const clearDuplicateErrors = (
      errors,
      isDuplicatedRow,
      field,
      value,
      rowIndex,
      clearErrors
    ) => {
      // Iterate through errors to find the key with type 'isDuplicate'
      Object.keys(errors).forEach((key) => {
        if (errors[key].type === "isDuplicate") {
          const fieldName = key.split("_")[0]; // Extract field name from key
          const rowIndex = parseInt(key.split("_")[1]); // Extract rowIndex from key
          const validationResults = isDuplicatedRow(fieldName, value, rowIndex);

          if (validationResults === true) {
            clearErrors(key);
          } else {
            // Clear the specific error field if it exists in validationResults
            if (validationResults.startsWith(fieldName)) {
              clearErrors(validationResults);
            }
          }
        }
      });

      // Clear errors if no duplicate is found in the current field
      const validationResult = isDuplicatedRow(field.label, value, rowIndex);
      if (validationResult === true) {
        // clearErrors(`conDescription_${rowIndex}`);
        clearErrors(`conditionEntityName_${rowIndex}`);
        clearErrors(`field_${rowIndex}`);
        clearErrors(`symbols_${rowIndex}`);
      }

      return validationResult;
    };
    // State to track if the dropdown is open or focused
    const [isFocused, setIsFocused] = useState(false);

    return row.map((rows, rowIndex) => (
      <Grid
        container
        spacing={1}
        key={rowIndex}
        className="bg-gray-100 border"
        mt={rowIndex > 0 ? 1 : 0}
        pb={1}
      >
        {conditionFields.map((field, setIndex) => (
          <Grid
            item
            xs={12}
            sm={field.isConditionBtn ? 2 : field.isIf ? 5.5 : 5.5}
            md={field.isConditionBtn ? 2 : field.isIf ? 3.66 : 3.66}
            lg={
              field.isConditionBtn
                ? 1
                : field.isIf
                ? 0.4
                : field.name === "startBrace"
                ? 0.5
                : field.name === "endBrace"
                ? 0.5
                : field.name === "conditionOption"
                ? 1
                : field.name === "symbols"
                ? 1.3
                : field.name === "conDescription"
                ? 3.2
                : 2
            }
            p={1}
            key={setIndex}
          >
            {field.name === "conDescription" ? (
              rows.field === "productName" ||
              rows.field === "fullProductName" ||
              rows.field === "customerName" ? (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={rows[field.name] || ""}
                  rules={{
                    required:
                      mode === "edit"
                        ? rows[field.name] === null ||
                          rows[field.name] === undefined ||
                          (rows[field.name] === "" && field.required)
                          ? `${field.label} is required`
                          : false
                        : field.required
                        ? `${field.label} is required`
                        : false,

                    validate: {
                      isDuplicate: (value) => {
                        const validationResult = clearDuplicateErrors(
                          errors,
                          isDuplicatedRow,
                          field,
                          value,
                          rowIndex,
                          clearErrors
                        );
                        return validationResult;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={
                        rows.field === "productName"
                          ? productNames
                          : rows.field === "fullProductName"
                          ? validFullProductNames // Use the validated array
                          : customerNames
                      }
                      multiple={
                        rows.symbols === "IN" || rows.symbols === "NOT IN"
                      } // Enable multi-select
                      disableClearable
                      fullWidth
                      sx={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                        "& .MuiAutocomplete-inputRoot": {
                          height: isFocused ? "auto" : "2.5rem", // Enlarge height when focused or clicked
                          minHeight: "2.5rem", // Ensure a minimum height
                          transition: "height 0.2s ease-in-out", // Smooth transition effect
                          overflowY: isFocused ? "auto" : "hidden", // Show all selected items when focused
                          whiteSpace: "nowrap", // Prevent wrapping
                          overflow: "hidden", // Hide overflow content
                          textOverflow: "ellipsis", // Display ellipsis for overflow
                          backgroundColor: "#f0f0f0", // Background color
                          borderRadius: "4px", // Rounded corners for a nicer look
                        },
                        ...inputStyles,
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setIsFocused(false);
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }, 200);
                      }}
                      onFocus={() => setIsFocused(true)} // Set focus state
                      onOpen={() => setIsFocused(true)} // Also handle open state
                      onClose={() => setIsFocused(false)} // Reset focus when closing dropdown
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={field.label}
                          variant="outlined"
                          size="small"
                          required={field.required}
                          error={
                            !!error || !!errors1[`${field.name}_${rowIndex}`]
                          }
                          helperText={
                            error
                              ? error.message
                              : errors1[`${field.name}_${rowIndex}`]
                              ? errors1[`${field.name}_${rowIndex}`].message
                              : null
                          }
                          InputLabelProps={{
                            style: {
                              pointerEvents: "none",
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              height: "2.5rem", // Fixed height for the input
                            },
                          }}
                        />
                      )}
                      value={
                        rows.symbols === "IN" || rows.symbols === "NOT IN"
                          ? Array.isArray(rows[field.name])
                            ? rows[field.name]
                            : [] // Ensure value is an array for multi-select
                          : rows[field.name] !== undefined &&
                            rows[field.name] !== ""
                          ? rows[field.name]
                          : ""
                      }
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        handleCondition(rowIndex, field.name, newValue);
                        // Update the row with the new value (array of selected items)
                        rows[field.name] = newValue;

                        // Trigger validation for both fields
                        trigger(`${field.name}_${rowIndex}`).then(() => {
                          // Check if the other field needs validation
                          if (
                            mode === "edit" &&
                            field.name === "conditionEntityName" &&
                            rows.field !== undefined &&
                            rows.field !== null &&
                            rows.field !== ""
                          ) {
                            trigger(`field_${rowIndex}`);
                          } else if (
                            field.name === "conditionEntityName" &&
                            rows.conditionEntityName !== undefined &&
                            rows.conditionEntityName !== null &&
                            rows.conditionEntityName !== ""
                          ) {
                            clearErrors(`field_${rowIndex}`); // Clear error if the value is not a duplicate
                            setValue(`field_${rowIndex}`, rows.field);
                          }
                        });
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            size="small" // Set chip size to small
                          />
                        ))
                      }
                    />
                  )}
                />
              ) : (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={rows[field.name] || ""}
                  rules={{
                    required:
                      mode === "edit"
                        ? rows[field.name] === null ||
                          rows[field.name] === undefined ||
                          (rows[field.name] === "" && field.required)
                          ? `${field.label} is required`
                          : false
                        : field.required
                        ? `${field.label} is required`
                        : false,
                    validate: {
                      specialCharacter: (value) =>
                        isValueContainsSplChars(value),
                      isEmpty: (value) =>
                        field.required &&
                        value !== "" &&
                        field.name === `conDescription`
                          ? isEmpty(value)
                          : true,
                      isDuplicate: (value) => {
                        const validationResult = clearDuplicateErrors(
                          errors,
                          isDuplicatedRow,
                          field,
                          value,
                          rowIndex,
                          clearErrors
                        );
                        return validationResult;
                      },
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      name={`${field.name}_${rowIndex}`}
                      type={field.type}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={() => {
                        setTimeout(function () {
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }, 200);
                      }}
                      label={field.label}
                      required={field.required}
                      value={rows[field.name]}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleCondition(rowIndex, field.name, e.target.value);
                        trigger(`${field.name}_${rowIndex}`);
                      }}
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      error={!!error || !!errors1[`${field.name}_${rowIndex}`]}
                      helperText={
                        error
                          ? error.message
                          : errors1[`${field.name}_${rowIndex}`]
                          ? errors1[`${field.name}_${rowIndex}`].message
                          : null
                      }
                      inputRef={(ref) => {
                        errorInputRefs.current[`${field.name}_${rowIndex}`] =
                          ref;
                      }}
                    />
                  )}
                />
              )
            ) : field.isIf ? (
              <Typography variant="subtitle1" marginTop={0.5}>
                {rowIndex === 0 ? "if(" : ""}
              </Typography>
            ) : field.name === "conditionOption" && rowIndex === 0 ? (
              <Typography variant="subtitle1" marginTop={0.5}>
                Condition
              </Typography>
            ) : field.isConditionBtn ? (
              viewData && editItemId ? null : (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => handleAddRow(rowIndex)}
                    color={"success"}
                  >
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: "black" }}
                    >
                      {rowIndex === row.length - 1 && ")"}
                    </Typography>
                    <AddCircleOutlineTwoTone />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveConditionRow(rowIndex, rows)}
                    color={"error"}
                    disabled={row.length > 1 ? false : true}
                  >
                    <RemoveCircleOutlineTwoTone />
                  </IconButton>
                </Box>
              )
            ) : (
              <Controller
                name={`${field.name}_${rowIndex}`}
                control={control}
                defaultValue={rows[field.name] || ""}
                rules={{
                  required:
                    mode === "edit"
                      ? rows[field.name] === null ||
                        rows[field.name] === undefined ||
                        (rows[field.name] === "" &&
                          field.required &&
                          field.name !== "endBrace" &&
                          field.name !== "startBrace")
                        ? field.label
                          ? `${field.label} is required`
                          : "This field is required"
                        : false
                      : null,
                  validate: {
                    isDuplicate: (value) => {
                      const validationResult = clearDuplicateErrors(
                        errors,
                        isDuplicatedRow,
                        field,
                        value,
                        rowIndex,
                        clearErrors
                      );
                      return validationResult;
                    },
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={
                      field.name === "startBrace"
                        ? braces
                        : field.name === "endBrace"
                        ? braces
                        : getConditionOptions(field, rows.conditionEntityName)
                    }
                    fullWidth
                    disableClearable
                    onBlur={() => {
                      setTimeout(function () {
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        if (mode === "edit") {
                          trigger(`field_${rowIndex}`); // Trigger validation for selection_0 as well
                        }
                      }, 200);
                    }}
                    renderOption={(props, option) => {
                      const isDisabled =
                        (field.name === "startBrace" && option === ")") ||
                        (field.name === "endBrace" && option === "(");

                      return (
                        <li
                          {...props}
                          style={{ opacity: isDisabled ? 0.5 : 1 }}
                        >
                          <span
                            style={{ color: isDisabled ? "gray" : "inherit" }}
                          >
                            {option}
                          </span>
                        </li>
                      );
                    }}
                    getOptionDisabled={(option) =>
                      (field.name === "startBrace" && option === ")") ||
                      (field.name === "endBrace" && option === "(")
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      handleCondition(rowIndex, field.name, newValue);

                      // Update the rows with the new value
                      rows[field.name] = newValue;

                      // Trigger validation for both fields
                      trigger(`${field.name}_${rowIndex}`).then(() => {
                        // Check if the other field needs validation
                        if (
                          mode === "edit" &&
                          field.name === "conditionEntityName" &&
                          rows.field !== undefined &&
                          rows.field !== null &&
                          rows.field !== ""
                        ) {
                          trigger(`field_${rowIndex}`);
                        } else if (
                          field.name === "conditionEntityName" &&
                          rows.conditionEntityName !== undefined &&
                          rows.conditionEntityName !== null &&
                          rows.conditionEntityName !== ""
                        ) {
                          clearErrors(`field_${rowIndex}`); // Clear error if the value is not a duplicate
                          setValue(`field_${rowIndex}`, rows.field);
                        }
                      });
                    }}
                    style={{
                      pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        label={field.label}
                        required={field.required}
                        size="small"
                        error={
                          !!error || !!errors1[`${field.name}_${rowIndex}`]
                        }
                        helperText={
                          error
                            ? error.message
                            : errors1[`${field.name}_${rowIndex}`]
                            ? errors1[`${field.name}_${rowIndex}`].message
                            : null
                        }
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none",
                          },
                        }}
                        inputRef={(ref) => {
                          errorInputRefs.current[`${field.name}_${rowIndex}`] =
                            ref;
                        }}
                      />
                    )}
                    value={
                      rows[field.name] !== undefined &&
                      rows[field.name] !== "" &&
                      rows[field.name] !== null
                        ? rows[field.name]
                        : ""
                    }
                  />
                )}
              />
            )}
          </Grid>
        ))}
      </Grid>
    ));
  };

  const getOptionsStartsWith = (inputValue) => {
    return variableNames.filter((option) => option.startsWith(inputValue));
  };

  useEffect(() => {
    if (tieredData.length !== 0) {
      if (mode === "add") {
        setCalcRow([
          {
            calcDescription: "",
            calcEntityName: calcEntityNames[0],
            calcOption: "",
            calcField: "",
            calcSymbols: symbols[0],
            percentage: percentageOption[0],
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tieredData]);

  // calculation fields
  const Calculations = () => {
    const errorInputRefs = useRef({});

    useEffect(() => {
      if (isSubmitted) {
        setTimeout(() => {
          const firstErrorField = Object.keys(errors)[0];
          if (firstErrorField && errorInputRefs.current[firstErrorField]) {
            errorInputRefs.current[firstErrorField].focus();
          }
          setIsSubmitted(false); // Reset after focusing
        }, 0); // Use a short delay to allow state update to propagate
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted]);

    const handleRemoveCalcRow = (index, row) => {
      setValue(`calcField_${index}`, "");
      setValue(`calcDescription_${index}`, "");
      setValue(`calcOption_${index}`, "");
      clearErrors(`calcField_${index}`, "");
      clearErrors(`calcDescription_${index}`, "");
      clearErrors(`calcOption_${index}`, "");
      RemoveCalcRow(index); // logic for removing the row
    };

    return calcRow.map((row, rowIndex) => (
      <Grid
        container
        spacing={2}
        key={rowIndex}
        className="bg-gray-100 border"
        mt={rowIndex > 0 ? 1 : -1}
        pb={1}
      >
        {calculationFields.map((field, setIndex) => (
          <Grid
            item
            xs={12}
            sm={field.isCalculationBtn ? 2.5 : field.isEqual ? 5.5 : 5.5}
            md={field.isCalculationBtn ? 1 : field.isEqual ? 3.66 : 3.66}
            lg={
              field.isCalculationBtn
                ? 1
                : field.isEqual
                ? 1
                : field.name === "percentage"
                ? 1
                : field.name === "calcSymbols"
                ? 1
                : 2
            }
            key={setIndex}
            p={1}
          >
            {field.name === "calcDescription" ? (
              row[`isAutocomplete_${field.name}`] ||
              row.calcField === "productName" ||
              row.calcField === "fullProductName" ||
              row.calcField === "customerName" ? (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={row[field.name] || ""}
                  rules={
                    mode === "edit"
                      ? {
                          required:
                            row[field.name] === null ||
                            row[field.name] === undefined ||
                            (row[field.name] === "" &&
                              field.required &&
                              tieredData.length === 0)
                              ? `${field.label} is required`
                              : false,
                        }
                      : {
                          required:
                            field.required && tieredData.length === 0
                              ? `${field.label} is required`
                              : false,
                        }
                  }
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      options={
                        row.calcField === "productName"
                          ? productNames
                          : row.calcField === "fullProductName"
                          ? fullProductNames
                          : row.calcField === "customerName"
                          ? customerNames
                          : getOptionsStartsWith("$")
                      }
                      fullWidth
                      disableClearable
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      onBlur={() => {
                        setTimeout(function () {
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }, 200);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          disabled={tieredData.length === 0 ? false : true}
                          required={tieredData.length !== 0 ? false : true}
                          error={!!error}
                          helperText={error ? error.message : null}
                          inputRef={(ref) => {
                            errorInputRefs.current[
                              `${field.name}_${rowIndex}`
                            ] = ref;
                          }}
                          InputLabelProps={{
                            style: {
                              pointerEvents: "none",
                            },
                          }}
                          onChange={(e) => {
                            const userInput = e.target.value;

                            // Check if the input starts with '$' to show options
                            if (!userInput.startsWith("$")) {
                              const updatedRow = {
                                ...row,
                                [`isAutocomplete_${field.name}`]: false,
                              };
                              setCalcRow((prevState) =>
                                prevState.map((r, index) =>
                                  index === rowIndex ? updatedRow : r
                                )
                              );
                            }
                          }}
                        />
                      )}
                      value={
                        row[field.name] !== undefined && row[field.name] !== ""
                          ? row[field.name]
                          : null
                      }
                      onChange={(_, newValue) => {
                        onChange(newValue);
                        handleCalculation(rowIndex, field.name, newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option === value}
                    />
                  )}
                />
              ) : (
                <Controller
                  name={`${field.name}_${rowIndex}`}
                  control={control}
                  defaultValue={row[field.name] || ""}
                  rules={{
                    required:
                      mode === "edit"
                        ? row[field.name] === null ||
                          row[field.name] === undefined ||
                          (row[field.name] === "" &&
                            field.required &&
                            tieredData.length === 0)
                          ? `${field.label} is required`
                          : false
                        : field.required && tieredData.length === 0
                        ? `${field.label} is required`
                        : false,
                    validate: {
                      validateNotEmptySpace: (value) =>
                        field.required &&
                        value !== "" &&
                        field.name === `calcDescription`
                          ? isEmpty(value)
                          : true,
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      name={`${field.name}_${rowIndex}`}
                      type={field.type}
                      variant="outlined"
                      size="small"
                      fullWidth
                      onBlur={() => {
                        if (mode === "edit") {
                          setValue(`calcDescription_${rowIndex}`, "");
                        }
                        setTimeout(function () {
                          trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        }, 200);
                      }}
                      disabled={tieredData.length === 0 ? false : true}
                      label={field.label}
                      required={tieredData.length !== 0 ? false : true}
                      value={row[field.name]}
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      onChange={(e) => {
                        onChange(e.target.value);
                        const userInput = e.target.value;
                        handleCalculation(rowIndex, field.name, userInput);
                        // Check if the input starts with '$' to switch to autocomplete
                        if (userInput.startsWith("$")) {
                          const updatedRow = {
                            ...row,
                            [`isAutocomplete_${field.name}`]: true,
                          };
                          setCalcRow((prevState) =>
                            prevState.map((r, index) =>
                              index === rowIndex ? updatedRow : r
                            )
                          );
                        }
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                      }}
                      error={!!error}
                      helperText={error ? error.message : null}
                      inputRef={(ref) => {
                        errorInputRefs.current[`${field.name}_${rowIndex}`] =
                          ref;
                      }}
                    />
                  )}
                />
              )
            ) : field.isEqual ? (
              <Typography variant="h6" marginTop={0.5}>
                =
              </Typography>
            ) : field.isCalculationBtn ? (
              viewData && editItemId ? null : (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      addCalcRow(rowIndex);
                    }}
                    color={"success"}
                  >
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: "black" }}
                    ></Typography>
                    {tieredData.length === 0 ? (
                      <AddCircleOutlineTwoTone />
                    ) : null}
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveCalcRow(rowIndex, row)}
                    color={"error"}
                    disabled={calcRow.length > 1 ? false : true}
                  >
                    <Typography
                      variant="subtitle1"
                      component="span"
                      sx={{ color: "black" }}
                    ></Typography>
                    {tieredData.length === 0 ? (
                      <RemoveCircleOutlineTwoTone />
                    ) : null}
                  </IconButton>
                </Box>
              )
            ) : (
              <Controller
                name={`${field.name}_${rowIndex}`}
                control={control}
                defaultValue={row[field.name] || ""}
                rules={
                  mode === "edit"
                    ? {
                        required:
                          (field.required && row[field.name] === null) ||
                          (field.required && row[field.name] === undefined) ||
                          (row[field.name] === "" &&
                            field.required &&
                            tieredData.length === 0)
                            ? `${field.label} is required`
                            : false,
                      }
                    : null
                }
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={getCalcOptions(field, row.calcEntityName)}
                    fullWidth
                    disableClearable
                    style={{
                      pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                    }}
                    disabled={tieredData.length === 0 ? false : true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        label={field.label}
                        disabled={tieredData.length === 0 ? false : true}
                        required={tieredData.length !== 0 ? false : true}
                        InputLabelProps={{
                          style: {
                            pointerEvents: "none", // Disable pointer events for the label
                          },
                        }}
                        error={!!error}
                        helperText={error ? error.message : null}
                        inputRef={(ref) => {
                          errorInputRefs.current[`${field.name}_${rowIndex}`] =
                            ref;
                        }}
                      />
                    )}
                    value={
                      row[field.name] !== undefined && row[field.name] !== ""
                        ? row[field.name]
                        : null
                    }
                    onBlur={() => {
                      setTimeout(function () {
                        trigger(`${field.name}_${rowIndex}`); // Ensure the correct field name is used
                        if (mode === "edit") {
                          trigger(`calcField_${rowIndex}`); // Trigger validation for field_0 as well
                        }
                      }, 200);
                    }}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                      handleCalculation(rowIndex, field.name, newValue);

                      // Update the row with the new value
                      row[field.name] = newValue;

                      // Trigger validation for both fields
                      trigger(`${field.name}_${rowIndex}`).then(() => {
                        // Check if the other field needs validation
                        if (
                          mode === "edit" &&
                          field.name === "calcEntityName" &&
                          row.calcField !== undefined &&
                          row.calcField !== null &&
                          row.calcField !== ""
                        ) {
                          trigger(`calcField_${rowIndex}`);
                        } else if (
                          field.name === "calcEntityName" &&
                          row.calcEntityName !== undefined &&
                          row.calcEntityName !== null &&
                          row.calcEntityName !== ""
                        ) {
                          console.log(
                            `${field.name}_${rowIndex}`,
                            `calcField_${rowIndex}`,
                            newValue,
                            row
                          );
                          clearErrors(`calcField_${rowIndex}`); // Clear error if the value is not a duplicate
                          setValue(`calcField_${rowIndex}`, row.calcField);
                        }
                      });
                    }}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                )}
              />
            )}
          </Grid>
        ))}
      </Grid>
    ));
  };

  // crediting fields
  const Crediting = () => {
    return (
      <Grid container mt={-2}>
        {checkBoxFields.map((field, index) => (
          <Grid item xs={12} sm={3} key={index}>
            <FormControl
              error={!!errors[field.name]}
              component="fieldset"
              variant="outlined"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={field.name}
                    checked={
                      field.name === "roleUp"
                        ? roleUpChecked
                        : field.name === "split"
                        ? splitChecked
                        : field.name === "tiered"
                        ? tieredChecked
                        : null
                    } // Use the state variable here
                    onChange={(e) => isCheckeds(field.name, e.target.checked)}
                    disabled={viewData}
                  />
                }
                label={field.label}
                {...register(field.name, {
                  required: field.required
                    ? `${field.label} is required`
                    : false,
                })}
              />
              {field.name === "roleUp" && (
                <RoleUPForm
                  open={openRoleUpForm}
                  onClose={handleRoleUpFormClose}
                  onRoleData={onRoleData}
                  roleData={roleData}
                  setRoleUpChecked={setRoleUpChecked}
                  viewData={viewData}
                />
              )}
              {field.name === "split" && (
                <SplitForm
                  open={openSplitForm}
                  onClose={handleSplitFormClose}
                  onSplitData={onSplitData}
                  splitData={splitData}
                  transactionEntity={transactionEntity}
                  setSplitChecked={setSplitChecked}
                  viewData={viewData}
                />
              )}
              {field.name === "tiered" && (
                <TieredForm
                  open={openTieredForm}
                  onClose={handleTieredFormClose}
                  onTieredData={onTieredData}
                  tieredData={tieredData}
                  setTieredChecked={setTieredChecked}
                  viewData={viewData}
                />
              )}
              {/* table display */}
              {field.name === "roleUp" && roleUpRow.length !== 0 && (
                <DataGrid
                  rows={roleUpRow}
                  columns={roleUpColumn}
                  density="compact"
                  sx={{
                    width: {
                      sm: 120, // Full width on small screens
                      md: 220, // Fixed width on medium screens
                      lg: 280, // Fixed width on large screens
                    },
                    height: {
                      xs: 300, // Height on extra-small screens
                      sm: 400, // Height on small screens
                      md: 430, // Height on medium screens
                    },
                    mt: 1,
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                  }}
                  disableColumnMenu
                  disableRowSelectionOnClick={viewData} // This should already work for row field
                  slots={{ noRowsOverlay: EmptyBox }}
                  slotProps={{
                    footer: {
                      style: {
                        display: "none",
                      },
                    },
                  }}
                />
              )}
              {field.name === "split" && splitRow.length !== 0 && (
                <DataGrid
                  rows={splitRow}
                  columns={splitColumn}
                  density="compact"
                  sx={{
                    width: {
                      sm: 120, // Full width on small screens
                      md: 220, // Fixed width on medium screens
                      lg: 280, // Fixed width on large screens
                    },
                    height: {
                      xs: 300, // Height on extra-small screens
                      sm: 400, // Height on small screens
                      md: 430, // Height on medium screens
                    },
                    mt: 1,
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                  }}
                  disableColumnMenu
                  disableRowSelectionOnClick={viewData} // This disables row selection on click
                  slots={{ noRowsOverlay: EmptyBox }}
                  slotProps={{
                    footer: {
                      style: {
                        display: "none",
                      },
                    },
                  }}
                />
              )}
              {field.name === "tiered" && tieredRow.length !== 0 && (
                <DataGrid
                  rows={tieredRow}
                  columns={tieredColumn}
                  density="compact"
                  sx={{
                    width: {
                      sm: 120, // Full width on small screens
                      md: 220, // Fixed width on medium screens
                      lg: 280, // Fixed width on large screens
                    },
                    height: {
                      xs: 300, // Height on extra-small screens
                      sm: 400, // Height on small screens
                      md: 430, // Height on medium screens
                    },
                    mt: 1,
                    "& .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                      outline: "none",
                    },
                  }}
                  disableColumnMenu
                  disabled={viewData}
                  disableRowSelectionOnClick={viewData} // This disables row selection on click
                  slots={{ noRowsOverlay: EmptyBox }}
                  slotProps={{
                    footer: {
                      style: {
                        display: "none",
                      },
                    },
                  }}
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
    );
  };

  // to validate the commissionName
  const handleCommissionNameChange = (enteredValue) => {
    const trimmedEnteredValue = enteredValue.trim();
    const lowercasedEnteredValue = trimmedEnteredValue.toLowerCase();

    if (enteredValue !== trimmedEnteredValue) {
      // Leading or trailing spaces detected
      return "Please remove any leading or trailing spaces from the commission name.";
    }

    if (
      mode === "add" &&
      commissionConfigNames.some(
        (name) => name.toLowerCase() === lowercasedEnteredValue
      )
    ) {
      return "Commission name already exists";
    } else if (
      mode === "edit" &&
      commissionConfigNames.some(
        (name) => name.toLowerCase() === lowercasedEnteredValue
      ) &&
      editFormData.commissionName &&
      editFormData.commissionName.toLowerCase() !== lowercasedEnteredValue
    ) {
      return "Commission name already exists";
    } else {
      return true; // Return true for a valid input
    }
  };

  const joditConfig = useMemo(
    () => ({
      readonly: viewData,
      removeButtons: ["fullsize"],
      addNewLine: false,
      hidePoweredByJodit: true,
      events: {
        afterInit: (editor) => {
          const statusBar = editor.statusbar.container;
          const customMessage = document.createElement("div");
          customMessage.style.margin = "10px";
          customMessage.style.display = "inline";
          customMessage.textContent = `Content cannot exceed 1000 characters.`;

          customMessage.textContent = `Content cannot exceed ${
            editor.options.limitChars - 1
          } characters.`;
          statusBar.appendChild(customMessage);

          // Function to update message color and editor border based on content length
          const updateMessageColor = () => {
            const decodedText = decodeHTML(editor.value);
            // Remove HTML tags and spaces from plain text
            const plainText = decodedText.replace(/<[^>]*>/g, "");
            const plainTextWithoutSpaces = plainText.replace(/\s+/g, "");

            const editorContainer = editor.container; // Get the editor container

            if (plainTextWithoutSpaces.length > 1000) {
              customMessage.style.color = "red"; // Change message color when limit is reached
              editorContainer.style.borderColor = "red"; // Change border color when limit is reached
            } else {
              customMessage.style.color = ""; // Default message color
              editorContainer.style.borderColor = ""; // Reset to default border color
            }
          };
          // Attach event listeners to check the content length whenever it changes
          editor.events.on("change", updateMessageColor);
          editor.events.on("blur", updateMessageColor); // When focus is lost
          editor.events.on("keydown", updateMessageColor); // On key down

          // Initialize message color and border based on the current content and print length
          updateMessageColor();
        },
      },
    }),
    [viewData]
  );

  const fieldRefs = useRef({});

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit, (errors) =>
          onError(fields, errors, fieldRefs)
        )}
        mt={mode === "edit" ? 1 : -5}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              key={index}
              sm={
                field.isEditor ||
                field.isCondition ||
                field.isTotalCondition ||
                field.isTotalCalculation ||
                field.isCalculation ||
                field.isCrediting ||
                field.isDivider
                  ? 12
                  : field.name === "credit" || field.name === "portion"
                  ? 3
                  : 6
              }
            >
              {field.name === "active" ? (
                <FormControl
                  error={!!errors[field.name]}
                  component="fieldset"
                  variant="outlined"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={field.name}
                        checked={isActiveChecked} // Use the state variable here
                        onChange={(e) => setIsActiveChecked(e.target.checked)} // Update the state when the checkbox changes
                        disabled={viewData}
                      />
                    }
                    label={field.label}
                    required={field.required}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                    })}
                  />
                  <FormHelperText>{errors[field.name]?.message}</FormHelperText>
                </FormControl>
              ) : field.isEditor ? (
                <Controller
                  name="commissionDescription"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <JoditEditor
                        value={field.value}
                        config={joditConfig}
                        onChange={(content) => {
                          field.onChange(content); // Update content if within the limit
                        }}
                      />
                    </>
                  )}
                />
              ) : field.name === "selectFrequency" ||
                field.name === "credit" ? (
                <Autocomplete
                  name={field.name}
                  options={
                    field.name === "selectFrequency"
                      ? selectFrequencyOption
                      : transactionEntity
                  }
                  value={
                    (field.name === "selectFrequency" &&
                      selectFrequencyOption.includes(watch(field.name))) ||
                    (field.name === "credit" &&
                      transactionEntity.includes(watch(field.name)))
                      ? watch(field.name)
                      : null
                  }
                  // Ensure a default null value if no value is selected
                  onChange={(event, value) => {
                    setValue(field.name, value);
                  }} // Update the form value on change
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                  }}
                  onBlur={() => {
                    setTimeout(function () {
                      trigger(field.name); // Trigger validation on blur
                    }, 200);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.label}
                      variant="outlined"
                      required={field.required}
                      size="small"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                      error={!!errors[field.name] && !params.inputProps.value}
                      helperText={
                        errors[field.name]?.message &&
                        (!params.inputProps.value
                          ? errors[field.name]?.message
                          : "")
                      }
                    />
                  )}
                  inputRef={(el) => {
                    if (el) {
                      fieldRefs.current[field.name] = el;
                    }
                  }} // Set ref
                />
              ) : field.isCondition ? (
                Conditions()
              ) : field.isTotalCondition ? (
                TotalConditionTextField()
              ) : field.isTotalCalculation ? (
                TotalCalculationTextField()
              ) : field.isDivider ? (
                <Divider />
              ) : field.isCalculation ? (
                Calculations()
              ) : field.isSubtitle ? (
                <Typography variant="subtitle1" mt={-1}>
                  {field.subtitle}
                </Typography>
              ) : field.isCrediting ? (
                Crediting()
              ) : (
                <TextField
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  required={field.required}
                  autoFocus={field.autoFocus}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onWheel={(event) => {
                    // Check if the input type is number to prevent scrolling
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  inputProps={{
                    ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                  }}
                  InputLabelProps={{
                    style: {
                      pointerEvents:
                        (viewData && editItemId) ||
                        (isDisabled && field.name === "commissionName")
                          ? "none"
                          : "auto",
                    },
                  }}
                  style={{
                    pointerEvents:
                      (viewData && editItemId) ||
                      (isDisabled && field.name === "commissionName")
                        ? "none"
                        : "auto",
                  }}
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    validate: {
                      handleCommissionNameChange: (value) =>
                        field.name === "commissionName"
                          ? handleCommissionNameChange(value)
                          : true,
                      isEmpty: (value) =>
                        field.required ? isEmpty(value) : true,
                      isValueContainsSplChars: (value) =>
                        field.required && field.name === "commissionName"
                          ? isValueContainsSplChars(value)
                          : true,
                      isValueStartsWithNumber: (value) =>
                        field.required && field.name === "commissionName"
                          ? isValueStartsWithNumber(value, field.label)
                          : true,
                    },
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                  }}
                  onBlur={() => {
                    setTimeout(function () {
                      trigger(field.name); // Trigger validation on blur
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    if (field.type === "number") {
                      handleNumberFieldKeyDownInRole(e);
                    }
                  }}
                  inputRef={(el) => {
                    if (el) {
                      fieldRefs.current[field.name] = el;
                    }
                  }} // Set ref
                />
              )}
            </Grid>
          ))}
        </Grid>
        {viewData && editItemId ? null : (
          <DialogActions className="mt-3">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => {
                setIsSubmitted(true);
              }}
            >
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
