import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import PayFrequencyColumns from "./columns";
import PayFrequencyToolbar from "./toolbar";
import PayFrequencyForm from "./payFrequencyForm";
import { PayFrequencyService } from "../../service/payFrequencyService";

export default function PayFrequency() {
  const {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    open,
    addFormOpen,
    addFormClose,
    fields,
    roleAccess,
    frequencyNameCheck,
    frequencyCheck,
    viewData,
    setViewData,
    calculateViewData,
    setCalculateViewData,
    data,
    frequencyWithId,
    loading,
  } = PayFrequencyService();

  const columns = PayFrequencyColumns(handleEdit, handleDelete, roleAccess);

  const [showDataGrid, setShowDataGrid] = useState(false);
  useEffect(() => {
    // // Set a timeout to show the DataGrid after 2 seconds (2000 milliseconds)
    // const timer = setTimeout(() => {
    setShowDataGrid(true);
    // }, 2000);

    // // Clean up the timeout if the component unmounts before the timeout completes
    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {showDataGrid ? (
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              noRowsOverlay: EmptyBox,
              toolbar: () => (
                <PayFrequencyToolbar
                  addFormOpen={addFormOpen}
                  roleAccess={roleAccess}
                />
              ),
              pagination: CustomPagination,
            }}
            autoHeight={Object.keys(rows).length >= 10}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            loading={loading}
            density="compact"
            sx={{
              p: 1,
              height: 530,
              // removing the cell border
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        ) : (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        {/* Handle both and and edit form display */}
        <PayFrequencyForm
          open={editFormOpen || open}
          onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
          mode={editFormOpen ? "edit" : "add"}
          editItemId={editFormOpen ? editItemId : undefined}
          editFormData={editFormOpen ? editFormData : undefined}
          onEditForm={editFormOpen ? onEditForm : undefined}
          onAddForm={!editFormOpen ? onAddForm : undefined}
          fields={fields}
          frequencyNameCheck={frequencyNameCheck}
          frequencyCheck={frequencyCheck}
          viewData={viewData}
          setViewData={setViewData}
          calculateViewData={calculateViewData}
          setCalculateViewData={setCalculateViewData}
          roleAccess={roleAccess}
          data={data}
          frequencyWithId={frequencyWithId}
        />
      </Box>
    </>
  );
}
