import { Box } from "@mui/material";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const PlanColumns = (handleEdit, handleDelete) => [
  {
    field: "sNo",
    headerName: "S.No.",
    align: "center",
    headerAlign: "center",
    width: 70,
  },
  {
    field: "title",
    headerName: "Plan Name",
    align: "center",
    headerAlign: "center",
    width: 130,
    renderCell: ({ row }) => {
      return (
        <Box
          style={{
            cursor: "pointer",
            textDecoration: "underline", // Optional: Add underline to text
          }}
          title={row.title} // Add the title attribute for the tooltip
          onClick={handleEdit(row.id, true)}
        >
          {row.title}
        </Box>
      );
    },
  },
  {
    field: "price",
    headerName: "Cost/user",
    align: "right",
    headerAlign: "right",
    width: 130,
  },
  {
    field: "description",
    headerName: "Description",
    align: "center",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "features",
    headerName: "Features",
    align: "center",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "subscriptionPeriods",
    headerName: "Subscription Periods",
    align: "center",
    headerAlign: "center",
    width: 200,
    valueGetter: (params) => {
      const { subscriptionPeriods } = params.row;
      return subscriptionPeriods
        .map(
          (period) =>
            // `Duration: ${period.duration} months, Discount: ${
            //   period.discountPercentage
            // }%, Price: ${period.actualAmount.toFixed(2)}` // use this code later price changes format
            `Duration: ${period.duration} months, Discount: ${period.discountPercentage}%, Price: ${period.actualAmount}`
        )
        .join("; ");
    },
  },
  {
    field: "notes",
    headerName: "Notes",
    align: "center",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "actions",
    headerName: "Actions",
    type: "actions",
    align: "left",
    headerAlign: "left",
    cellClassName: "actions",
    width: 100,
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<EditTwoTone />}
          label="Edit"
          color="primary"
          onClick={handleEdit(id)}
          title={"Edit"}
        />,
        <GridActionsCellItem
          icon={<DeleteTwoTone />}
          label="Delete"
          color="error"
          onClick={handleDelete(id)}
          title={"Delete"}
        />,
      ];
    },
  },
];
