import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { format } from "date-fns";
import { CURRENCY_NAME } from "../../config/sessionStorage";

const Columns = (
  dynamicColumns,
  handleEdit,
  handleDelete,
  roleAccess,
  isNavigateToAllErrorsActive
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Transaction" && item.writeAccess
  );

  // Regular expression to identify fields that contain "date"
  const dateFieldRegex = /date/i;

  // Map over dynamicColumns to add valueFormatter to fields that match the date regex
  const formattedDynamicColumns = dynamicColumns.map((column) => {
    if (dateFieldRegex.test(column.field)) {
      return {
        ...column,
        valueFormatter: ({ value }) => {
          if (value) {
            return format(new Date(value), "dd-MM-yyyy");
          }
          return "";
        },
      };
    }
    return column;
  });

  // Session storage to taken currency value
  const curName = CURRENCY_NAME();

  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 70,
    },
    {
      field: "transactionId",
      headerName: "Trans ID",
      width: 90,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.transactionId} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true, false)}
          >
            {row.transactionId}
          </div>
        );
      },
    },
    {
      field: "cname",
      headerName: "Customer",
      width: 130,
    },
    {
      field: "pname",
      headerName: "Product",
      width: 130,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "transactionType",
      headerName: "Trans Type",
      width: 100,
    },
    {
      field: "projection",
      headerName: "Is Projection",
      type: "boolean",
      width: 100,
    },
    {
      field: "grossProfit",
      headerName: `Gross Profit (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "salesRepName",
      headerName: "Sales Rep",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "unitCost",
      headerName: `Unit Cost (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "salesAmount",
      headerName: `Sales Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "transactionLine",
      headerName: "Trans Line",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "transactionLineLevel",
      headerName: "Trans LineLevel",
      width: 100,
    },
    {
      field: "transactionStatus",
      headerName: "Trans Status",
      width: 100,
    },
    {
      field: "transactionLineType",
      headerName: "Trans LineType",
      width: 100,
    },
    {
      field: "transactionDate",
      headerName: "Trans Date",
      width: 100,
      valueFormatter: ({ value }) =>
        value && !isNaN(new Date(value).getTime())
          ? format(new Date(value), "dd-MM-yyyy")
          : "Invalid Date", // Handle invalid dates here
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      width: 100,
      valueFormatter: ({ value }) =>
        value && !isNaN(new Date(value).getTime())
          ? format(new Date(value), "dd-MM-yyyy")
          : "Invalid Date", // Handle invalid dates here
    },
    {
      field: "unitOfMeasure",
      headerName: "UOM",
      width: 130,
    },
    {
      field: "profitPercentage",
      headerName: `Profit % (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "discount",
      headerName: `Discount (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "commissionAmount",
      headerName: `Comm Amt (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    {
      field: "commissionRate",
      headerName: `Comm Rate (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 130,
    },
    isNavigateToAllErrorsActive && {
      field: "description",
      headerName: "Error Description",
      width: 200,
    },
    ...formattedDynamicColumns,
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Transaction" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Transaction" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={
                editAccess && !isNavigateToAllErrorsActive
                  ? handleEdit(id, false, false)
                  : handleEdit(id, false, true)
              }
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }

        return actions;
      },
    },
  ].filter(Boolean);
};

export default Columns;
