export const IndividualDashboardColumns = ({ currency }) => [
  {
    field: "sNo",
    headerName: "S.No.",
    align: "center",
    headerAlign: "center",
    width: 80,
  },
  {
    field: "transactionId",
    headerName: "Trans ID",
    width: 100,
  },
  {
    field: "transactionDate",
    headerName: "Trans Date",
    width: 100,
  },
  {
    field: "pname",
    headerName: "Prod Name",
    width: 170,
  },
  {
    field: "cname",
    headerName: "Cust Name",
    width: 170,
  },
  {
    field: "salesAmount",
    headerName: `Sales Amt (${currency})`,
    align: "right",
    headerAlign: "right",
    width: 130,
  },
];
