import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ORG_NAME, ROLE_ACCESS } from "../config/sessionStorage";
import { CustomNavigate } from "../config/navigation";
import { addSerialNumber, handleApiCall } from "../config/fieldConfig";
import { AdjustFields, FormFields } from "../pages/AssignCommission/formFields";
import axiosClient from "../../apis/Api";
import { No_USER_ACCESS } from "../config/toastMessage";

export const AssignCommissionService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [orgName, setOrgName] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [commissionData, setCommissionData] = useState([]);
  const [beneficiariesOption, setBeneficiariesOption] = useState([]);
  const [commissionListOptions, setCommissionListOptions] = useState([]);
  const [terms, setTerms] = useState("");
  const [description, setDescription] = useState("");
  const [beneficiaries, setBeneficiaries] = useState("");
  const [extractedData, setExtractedData] = useState([]);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);
  const [viewData, setViewData] = useState(true); // view purpose only
  const [adjustmentComponents, setAdjustmentComponents] = useState([0]); // Initialize adjustment components array with a single component
  const [loading, setLoading] = useState(true); // Initial loading state is true
  const [isTableLoading, setIsTableLoading] = useState(true); // Initial loading state is true

  const { navigateError403 } = CustomNavigate();

  const roleAccess = ROLE_ACCESS();

  const fields = FormFields;
  const adjFields = AdjustFields;

  const [open, setOpen] = useState(false);

  const addFormOpen = () => {
    setOpen(true);
    formApiCall(null);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  const [apiEndpoint, setApiEndpoint] = useState(
    "/assignCommission/getAssignedCommWithHierarchy"
  );

  const [selectedOption, setSelectedOption] = useState("Get All");

  // Function to handle menu item clicks
  const handleSelectOption = (option) => {
    setSelectedOption(option);
  };

  const handleGetMyReportee = () => {
    if (selectedOption === "My Reportee") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/assignCommission/getAssignedCommissionsForReportees");
    stopRemount.current = true;
    handleSelectOption("My Reportee");
  };

  const handleGetMyDetails = () => {
    if (selectedOption === "My Data") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/assignCommission/getMyAssignedCommissions");
    stopRemount.current = true;
    handleSelectOption("My Data");
  };

  const handleGetAll = () => {
    if (selectedOption === "Get All") {
      setIsTableLoading(false);
    } else {
      setIsTableLoading(true);
    }
    setApiEndpoint("/assignCommission/getAssignedCommWithHierarchy");
    stopRemount.current = true;
    handleSelectOption("Get All");
  };

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    try {
      const match = terms.match(/and\s+([^<]+)\s+regarding/i);

      const wordsAfterAndBeforeTerms = match ? match[1] : null;

      const formattedTerms = terms.replace(
        new RegExp(wordsAfterAndBeforeTerms, "g"),
        beneficiaries.userName
      );
      setTerms(formattedTerms);

      const formattedDesc = description.replace(
        new RegExp(wordsAfterAndBeforeTerms, "g"),
        beneficiaries.userName
      );
      setDescription(formattedDesc);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries, apiEndpoint]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    const ORGNAME = ORG_NAME();
    setToken(TOKEN);
    setOrgName(ORGNAME);

    try {
      const commissionData = await ApiService.getCommissions(TOKEN);
      setCommissionData(commissionData);

      // Fetch all assigned commissions using ApiService
      const response = await axiosClient.get(apiEndpoint, {
        headers: {
          Authorization: TOKEN,
        },
      });

      const getAllAssignCommission = response.data;

      // Function to map commission IDs to their respective names
      const mapIDsToNames = (ids, data) =>
        ids.map(
          (id) => data.find((item) => item.id === id)?.commissionName || null
        );

      // Transform data
      const transformedData = getAllAssignCommission.map((item) => ({
        ...item,
        commission: mapIDsToNames(item.commission, commissionData),
      }));

      // Set table data
      setTableData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsTableLoading(false);
    setLoading(false);
  };

  let navigate = useNavigate();

  const handleCalculation = async () => {
    // Disable interactions on the screen
    const overlay = document.createElement("div");

    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    // overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black overlay
    overlay.style.zIndex = "9999"; // Ensure the overlay is on top of other elements
    document.body.appendChild(overlay);

    // Show loading toast
    const loadingToastId = toast.loading(
      `Please wait until the commission is calculated for ${beneficiaries.userName}`,
      { duration: 0 }
    );

    try {
      const iconImage = await ApiService.getImageBytes(token);

      const res = await ApiService.calculateCommissionForSingleUser(
        beneficiaries.id,
        token
      );

      if (res?.status === 200 && iconImage) {
        if (
          res.data.filteredTransactions &&
          res.data.filteredTransactions.length > 0
        ) {
          navigate("/report", {
            state: {
              viewData: true,
              SingleData: res.data,
              IconImage: iconImage,
            },
          });
        } else {
          toast.error(No_USER_ACCESS);
        }
      } else {
        // Error response from the server
        toast.error(res.response.data);
      }
    } catch (error) {
      // Network error or other unexpected errors during upload
      toast.error("An error occurred while processing your request.");
      console.error("errorMessage", error);
    } finally {
      // Re-enable interactions on the screen and remove the overlay
      document.body.removeChild(overlay);
      toast.dismiss(loadingToastId); // Dismiss the loading toast when done
    }
  };

  const formApiCall = async (beneficiaryName) => {
    try {
      const getAllAssignCommissions =
        await ApiService.getAssignedCommWithHierarchy(token);

      const eligibleBen = await ApiService.getActiveBeneficiaries(token);

      const extractedData = eligibleBen.map((item) => ({
        userName: item.userName,
        id: item.userId,
        idWithName: `${item.userId} - ${item.userName}`,
      }));
      setExtractedData(extractedData);

      const valuesToRemove = getAllAssignCommissions.map(
        (item) => item.beneficiaries
      );

      const filteredOptions = extractedData.filter((option) => {
        return (
          !valuesToRemove.includes(option.id) ||
          option.userName === beneficiaryName
        );
      });
      setBeneficiariesOption(filteredOptions);

      const getAllCommissionNames = await ApiService.getCommissionNames(token);
      setCommissionListOptions(getAllCommissionNames);

      // Use regular expressions with String.replace() to replace placeholders
      const getTermsDescription = await ApiService.getTermsDescription();

      const formattedTerms = getTermsDescription.terms
        .replace(/\{ABC Inc\}/g, orgName)
        .replace(/\{Steve Rep\}/g, beneficiaryName);
      beneficiaryName === null && setTerms(formattedTerms);

      const formattedDescription = getTermsDescription.description
        .replace(/\{ABC Inc\}/g, orgName)
        .replace(/\{Steve Rep\}/g, beneficiaryName);
      beneficiaryName === null && setDescription(formattedDescription);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const rows = addSerialNumber(data);

  const handleEdit = (id, viewData) => () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id); // Set the edit form state and data
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    setViewData(viewData);
    formApiCall(rowData.beneficiaryName);
  };
  const handlePdfDownload = async (params) => {
    try {
      const response = await ApiService.downloadAssignCommissionPdf(
        token,
        params
      );

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data");
        return;
      }

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${params.row.beneficiaryName}.pdf`;
      a.click();

      // Revoke object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addAssignCommission,
        edit: ApiService.updateAssignCommission,
        delete: ApiService.deleteAssignCommission,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this assignCommission?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  return {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    fields,
    adjFields,
    commissionListOptions,
    commissionData,
    beneficiariesOption,
    terms,
    description,
    setBeneficiaries,
    right,
    setRight,
    left,
    setLeft,
    open,
    addFormOpen,
    addFormClose,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetAll,
    roleAccess,
    extractedData,
    beneficiaries,
    handlePdfDownload,
    viewData,
    setViewData,
    setTerms,
    setDescription,
    handleCalculation,
    adjustmentComponents,
    setAdjustmentComponents,
    apiEndpoint,
    loading,
    selectedOption,
    handleSelectOption,
    isTableLoading,
  };
};
