export const FormFields = [
  {
    name: "transactionId",
    label: "Transaction ID",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "transactionLine",
    label: "Transaction Line",
    type: "number",
    required: true,
  },
  {
    name: "transactionDate",
    label: "Transaction Date",
    type: "date",
    shrink: true,
    required: true,
  },
  {
    name: "effectiveDate",
    label: "Effective Date",
    type: "date",
    shrink: true,
    required: true,
  },
  {
    name: "transactionLineLevel",
    label: "Transaction Line Level",
    type: "text",
    required: false,
  },
  {
    name: "transactionStatus",
    label: "Transaction Status",
    type: "text",
    required: false,
  },
  {
    name: "unitOfMeasure",
    label: "Unit Of Measure",
    type: "text",
    required: false,
  },
  {
    name: "unitCost",
    label: "Unit Cost",
    type: "number",
    required: false,
  },
  {
    name: "transactionLineType",
    label: "Transaction Line Type",
    type: "text",
    required: false,
  },
  {
    name: "transactionType",
    label: "Transaction Type",
    type: "text",
    required: true,
  },
  {
    name: "salesAmount",
    label: "Sales Amount",
    type: "number",
    required: true,
  },
  {
    name: "commissionAmount",
    label: "Commission Amount",
    type: "number",
    required: false,
  },
  {
    name: "grossProfit",
    label: "Gross Profit",
    type: "number",
    required: false,
  },
  {
    name: "commissionRate",
    label: "Commission Rate",
    type: "number",
    required: false,
  },
  {
    name: "profitPercentage",
    label: "Profit Percentage",
    type: "number",
    required: false,
  },
  {
    name: "salesRep",
    label: "Sales Rep",
    type: "text",
    required: true,
  },
  {
    name: "discount",
    label: "Discount",
    type: "number",
    required: false,
  },
  {
    name: "quantity",
    label: "Quantity",
    type: "number",
    required: false,
  },
  {
    name: "productId",
    label: "Product",
    type: "text",
    required: true,
  },
  {
    name: "projection",
    label: "Is Projection",
    type: "checkbox",
    required: false,
  },
  {
    name: "customerId",
    label: "Customer",
    type: "text",
    required: true,
  },
];
