import { Link } from "react-router-dom";
import { Container, Typography, Grid, Box } from "@mui/material";
import { NewReleasesTwoTone } from "@mui/icons-material";
import { SESSION_MSG1 } from "../config/toastMessage";

export default function SessionExpired() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: "0 20px", // Add padding for responsiveness
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              md={2}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <NewReleasesTwoTone
                color="warning"
                sx={{ fontSize: { xs: 50, md: 100 } }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                // display: "flex",
                // alignItems: "center",
                // justifyContent: { xs: "center", md: "flex-start" },
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                variant="h6"
                //   sx={{ mr: 2 }}
              >
                {SESSION_MSG1}
              </Typography>
              <Link to="/sign_in" className="hover:underline text-blue-600">
                Sign In now...
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
