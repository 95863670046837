export default function UserActivityColumns() {
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "name",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "apiName",
      headerName: "Screen Name",
      width: 200,
    },
    {
      field: "organizationName",
      headerName: "Organization Name",
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      width: 170,
    },
    {
      field: "timeZone",
      headerName: "Time Zone",
      width: 200,
    },
  ];
}
