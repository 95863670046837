import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { ExpandLessTwoTone, ExpandMoreTwoTone } from "@mui/icons-material";
import CommissionToolbar from "./toolbar";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import Columns from "./columns";
import TypeForm from "./typeForm";
import { TypeService } from "../../service/typeService";

export default function Type() {
  const {
    rows,
    onAddForm,
    onEditForm,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    viewData,
    setViewData,
    fields,
    PAGE_OPTIONS,
    stopRemount,
    productTypeOptions,
    selectedOption,
    setSelectedOption,
    roleAccess,
    setEditFormOpen,
    loading,
  } = TypeService();

  const columns = Columns(handleEdit, handleDelete, roleAccess);

  const readAccess = roleAccess.some(
    (item) => item.resource === "Type" && item.writeAccess
  );

  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const editAccess = roleAccess.some(
    (item) => item.resource === "Type" && item.writeAccess
  );

  return (
    <>
      {readAccess && (
        <Box sx={{ m: 1 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  showForm ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
                }
                onClick={() => {
                  toggleForm();
                }}
              >
                Add Type
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={2.5}>
              <Autocomplete
                id="my-autocomplete"
                options={PAGE_OPTIONS}
                size="small"
                fullWidth
                sx={{ py: 1 }}
                value={selectedOption}
                onChange={(event, newValue) => {
                  stopRemount.current = true;
                  setSelectedOption(newValue);
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Choose Type Name" />
                )}
                renderOption={(props, option, { selected }) => (
                  <Typography
                    {...props}
                    key={props.key}
                    color={selected ? "secondary" : "inherit"}
                  >
                    {option}
                  </Typography>
                )}
              />
            </Grid>
          </Grid>

          {showForm && (
            <Paper sx={{ p: 3 }}>
              <TypeForm
                mode="add"
                onClose={toggleForm}
                productTypeOptions={productTypeOptions}
                editAccess={editAccess}
                fields={fields}
                onAddForm={onAddForm}
                selectedOption={selectedOption}
                setShowForm={setShowForm}
                setEditFormOpen={setEditFormOpen}
              />
            </Paper>
          )}
        </Box>
      )}

      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: CommissionToolbar,
            pagination: CustomPagination,
          }}
          autoHeight={Object.keys(rows).length >= 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          loading={loading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />
      </Box>

      <Dialog open={editFormOpen} maxWidth="lg">
        <DialogTitle>
          Edit Type
          {viewData && editAccess ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              style={{ position: "absolute", top: "14px", right: 50 }}
              onClick={() => setViewData(false)}
            >
              edit
            </Button>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <TypeForm
            mode="edit"
            onClose={toggleForm}
            editItemId={editItemId}
            editFormData={editFormData} // Pass the edit form data to ProductFormEdit
            onEditForm={onEditForm}
            fields={fields}
            productTypeOptions={productTypeOptions}
            editAccess={editAccess}
            selectedOption={selectedOption}
            setShowForm={setShowForm}
            setEditFormOpen={setEditFormOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
