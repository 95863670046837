export const FormFields = [
  {
    name: "screenName",
    label: "Screen Name",
    type: "text",
    required: true,
  },
  {
    name: "errorTime",
    label: "Error Time",
    type: "datetime-local",
    shrink: true,
    required: true,
  },
  {
    isEditor: true,
    isSubtitle: true,
    subtitle: "Descriptions:",
  },
];
