import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import UserActivityToolBar from "./toolbar";
import { UserActivityService } from "../../service/userActivityService";

export default function UserActivity() {
  const {
    rows,
    columns,
    loading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    stopRemount,
  } = UserActivityService();

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: EmptyBox,
          toolbar: () => (
            <UserActivityToolBar
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              stopRemount={stopRemount}
            />
          ),
          pagination: CustomPagination,
        }}
        disableRowSelectionOnClick
        autoHeight={Object.keys(rows).length >= 10}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        pageSizeOptions={[10, 25, 50]}
        loading={loading}
        density="compact"
        sx={{
          p: 1,
          height: 530,
          // removing the cell border
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
      />
    </>
  );
}
