import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import { convertCamelToTitle } from "../../config/fieldConfig";

export default function SubscriptionPeriodsBox({
  subscriptionFieldNames,
  viewData,
  editItemId,
  register,
  errors,
  selectedFrequency,
  price,
  getValues,
  setValue,
  control,
  watch,
  setError,
  clearErrors,
  onDurationChange, // Callback to handle duration change
}) {
  const {
    fields: subscriptionFields,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: "subscriptionPeriods",
  });

  const [durationOptions, setDurationOptions] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [userInteracted, setUserInteracted] = useState(false); // Track user interaction

  const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
  const yearOptions = Array.from({ length: 5 }, (_, i) => i + 1);

  useEffect(() => {
    const options = selectedFrequency === "Month" ? monthOptions : yearOptions;
    setDurationOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrequency]);

  useEffect(
    (event) => {
      const selectedDurations = subscriptionFields
        .map((_, index) => getValues(`subscriptionPeriods[${index}].duration`))
        .filter((value) => value !== null && value !== "")
        .map(Number); // Convert values to numbers

      console.log("Selected Durations:", selectedDurations);

      const filteredOptions = durationOptions.filter(
        (option) => !selectedDurations.includes(option)
      );

      console.log("Available Options:", filteredOptions);

      setAvailableOptions(filteredOptions);
      // Call handleDurationChange for each selected duration
      selectedDurations.forEach((duration, index) => {
        handleDurationChange(event, duration, index);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [durationOptions, subscriptionFields, getValues, price]
  );

  useEffect(() => {
    subscriptionFields.forEach((field, index) => {
      setValue(`subscriptionPeriods[${index}].duration`, field.duration);
      setValue(`subscriptionPeriods[${index}].actualAmount`, field.amount);
      setValue(
        `subscriptionPeriods[${index}].discountAmount`,
        field.discountAmount
      );
      setValue(
        `subscriptionPeriods[${index}].discountPercentage`,
        field.discountPercentage
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrequency, setValue]);

  const handleDurationChange = (event, newValue, index) => {
    const value = newValue === null ? null : Number(newValue);
    setUserInteracted(true); // Mark user interaction

    if (value !== null) {
      setValue(`subscriptionPeriods[${index}].duration`, value);
      const calculatedAmount = price * value;
      setValue(`subscriptionPeriods[${index}].actualAmount`, calculatedAmount);

      const discountPercentage =
        getValues(`subscriptionPeriods[${index}].discountPercentage`) || 0;
      const discountAmount = (calculatedAmount * discountPercentage) / 100;
      setValue(`subscriptionPeriods[${index}].discountAmount`, discountAmount);

      const totalAmount = calculatedAmount - discountAmount;
      setValue(`subscriptionPeriods[${index}].totalAmount`, totalAmount);

      // Clear errors for all relevant fields
      clearErrors([
        `subscriptionPeriods[${index}].duration`,
        `subscriptionPeriods[${index}].actualAmount`,
        `subscriptionPeriods[${index}].discountAmount`,
        `subscriptionPeriods[${index}].discountPercentage`,
        `subscriptionPeriods[${index}].totalAmount`,
      ]);
    } else {
      setValue(`subscriptionPeriods[${index}].actualAmount`, null);
      setValue(`subscriptionPeriods[${index}].discountAmount`, 0);
      setValue(`subscriptionPeriods[${index}].totalAmount`, null);

      // Clear errors for all relevant fields
      clearErrors([
        `subscriptionPeriods[${index}].duration`,
        `subscriptionPeriods[${index}].actualAmount`,
        `subscriptionPeriods[${index}].discountAmount`,
        `subscriptionPeriods[${index}].discountPercentage`,
        `subscriptionPeriods[${index}].totalAmount`,
      ]);
    }
  };

  const handleDiscountChange = (event, newValue, index) => {
    const discountPercentage = parseFloat(newValue) || 0;
    setValue(
      `subscriptionPeriods[${index}].discountPercentage`,
      discountPercentage || 0
    );

    const actualAmount =
      getValues(`subscriptionPeriods[${index}].actualAmount`) || 0;
    const discountAmount = (actualAmount * discountPercentage) / 100;
    setValue(`subscriptionPeriods[${index}].discountAmount`, discountAmount);

    const totalAmount = actualAmount - discountAmount;
    setValue(`subscriptionPeriods[${index}].totalAmount`, totalAmount);

    // Clear errors for all relevant fields
    clearErrors([
      `subscriptionPeriods[${index}].discountPercentage`,
      `subscriptionPeriods[${index}].discountAmount`,
      `subscriptionPeriods[${index}].actualAmount`,
      `subscriptionPeriods[${index}].totalAmount`,
    ]);
  };

  const renderDurationInput = (fieldKey, index) => (
    <Autocomplete
      options={availableOptions}
      getOptionLabel={(option) => option.toString()}
      onChange={(event, newValue) => {
        handleDurationChange(event, newValue, index);
      }}
      value={
        durationOptions.includes(
          Number(getValues(`subscriptionPeriods[${index}].duration`))
        )
          ? Number(getValues(`subscriptionPeriods[${index}].duration`))
          : null
      }
      isOptionEqualToValue={(option, value) => option === Number(value)}
      style={{
        pointerEvents: viewData && editItemId ? "none" : "auto",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Duration (${
            selectedFrequency === "Month" ? "month" : "year"
          })`}
          variant="outlined"
          size="small"
          fullWidth
          InputLabelProps={{
            style: { pointerEvents: "none" },
          }}
          {...register(`subscriptionPeriods[${index}].${fieldKey}`, {
            required: "Duration is required",
          })}
          error={!!errors.subscriptionPeriods?.[index]?.[fieldKey]}
          helperText={errors.subscriptionPeriods?.[index]?.[fieldKey]?.message}
        />
      )}
    />
  );

  useEffect(() => {
    const subscriptionPeriods = getValues("subscriptionPeriods");
    onDurationChange(subscriptionPeriods); // Notify parent component
  }, [getValues, onDurationChange]);

  useEffect(() => {
    // Check the price only if the user has interacted with the form
    if (userInteracted) {
      const currentPrice = watch("price");
      if (
        currentPrice === null ||
        currentPrice === undefined ||
        currentPrice === ""
      ) {
        setError("price", {
          type: "manual",
          message: "Please fill the cost per user.",
        });
      } else {
        // Clear the error if price is valid
        clearErrors("price", {
          type: "manual",
          message: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("price"), setError, userInteracted]);

  return (
    <Box className="bg-gray-100 border" sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Subscription Periods
      </Typography>
      {subscriptionFields.map((field, index) => (
        <Grid container spacing={2} pb={2} key={index}>
          {subscriptionFieldNames.map((fieldKey) => (
            <Grid item xs={12} sm={6} md={2.18} mt={1} key={fieldKey}>
              {fieldKey === "duration" ? (
                renderDurationInput(fieldKey, index)
              ) : (
                <TextField
                  name={`subscriptionPeriods[${index}].${fieldKey}`}
                  label={convertCamelToTitle(fieldKey)}
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  {...register(`subscriptionPeriods[${index}].${fieldKey}`, {
                    required: `${convertCamelToTitle(fieldKey)} is required`,
                  })}
                  error={!!errors.subscriptionPeriods?.[index]?.[fieldKey]}
                  helperText={
                    errors.subscriptionPeriods?.[index]?.[fieldKey]?.message
                  }
                  inputProps={{ min: 1, step: 1 }}
                  onWheel={(event) => {
                    event.target.blur();
                  }}
                  InputProps={{
                    readOnly:
                      fieldKey === "actualAmount" ||
                      fieldKey === "discountAmount" ||
                      fieldKey === "totalAmount",
                    style: {
                      pointerEvents: viewData && editItemId ? "none" : "auto",
                    }, // remove the hover effect
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { pointerEvents: "none" },
                  }}
                  onChange={(event) => {
                    if (fieldKey === "discountPercentage") {
                      handleDiscountChange(event, event.target.value, index);
                    }
                  }}
                />
              )}
            </Grid>
          ))}
          {viewData && editItemId ? null : (
            <Grid
              item
              xs={12}
              sm={6}
              md={1.1}
              mt={1}
              sx={{ textAlign: "right" }}
            >
              <IconButton
                onClick={() =>
                  insert(index + 1, {
                    duration: "",
                    actualAmount: "",
                    discountAmount: "",
                    discountPercentage: "",
                    totalAmount: "",
                  })
                }
                color="success"
              >
                <AddCircleOutlineTwoTone />
              </IconButton>
              <IconButton
                onClick={() => remove(index)}
                color="error"
                disabled={subscriptionFields.length > 1 ? false : true}
              >
                <RemoveCircleOutlineTwoTone />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
}
