import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import ScheduleStatusToolbar from "./toolbar";
import { ScheduleStatusService } from "../../service/scheduleStatusService";

export default function ScheduleStatus() {
  const { rows, columns, loading } = ScheduleStatusService();

  return (
    <>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: ScheduleStatusToolbar,
            pagination: CustomPagination,
          }}
          disableRowSelectionOnClick
          autoHeight={Object.keys(rows).length >= 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          loading={loading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />
      </Box>
    </>
  );
}
