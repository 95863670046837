import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import AssignCommissionColumns from "./columns";
import AssignCommissionToolbar from "./toolbar";
import { AssignCommissionService } from "../../service/assignCommissionService";
import AssignCommissionForm from "./assignCommissionForm";

export default function AssignCommission() {
  const {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    fields,
    adjFields,
    commissionListOptions,
    commissionData,
    beneficiariesOption,
    terms,
    description,
    setBeneficiaries,
    right,
    setRight,
    left,
    setLeft,
    open,
    addFormOpen,
    addFormClose,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetAll,
    roleAccess,
    extractedData,
    beneficiaries,
    handlePdfDownload,
    viewData,
    setViewData,
    setTerms,
    setDescription,
    handleCalculation,
    adjustmentComponents,
    setAdjustmentComponents,
    apiEndpoint,
    selectedOption,
    handleSelectOption,
    isTableLoading,
  } = AssignCommissionService();

  const columns = AssignCommissionColumns(
    handleEdit,
    handleDelete,
    roleAccess,
    handlePdfDownload
  );

  const [page, setPage] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // 0-based index for the first page
    pageSize: 10,
  });

  //  Reset pagination to the first page when selectedRole changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    setPage(false);
  }, [apiEndpoint]);

  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  const [showDataGrid, setShowDataGrid] = useState(false);
  useEffect(() => {
    // // Set a timeout to show the DataGrid after 2 seconds (2000 milliseconds)
    // const timer = setTimeout(() => {
    setShowDataGrid(true);
    // }, 2000);

    // // Clean up the timeout if the component unmounts before the timeout completes
    // return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {showDataGrid ? (
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              noRowsOverlay: EmptyBox,
              toolbar: () => (
                <AssignCommissionToolbar
                  addFormOpen={addFormOpen}
                  handleGetMyReportee={handleGetMyReportee}
                  handleGetMyDetails={handleGetMyDetails}
                  handleGetAll={handleGetAll}
                  roleAccess={roleAccess}
                  selectedOption={selectedOption}
                  handleSelectOption={handleSelectOption}
                />
              ),
              pagination: CustomPagination,
            }}
            autoHeight={Object.keys(rows).length >= 10}
            initialState={
              page
                ? { pagination: { paginationModel: { pageSize: 10 } } }
                : undefined
            }
            onPaginationModelChange={!page ? setPaginationModel : undefined}
            paginationModel={!page ? paginationModel : undefined}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            loading={isTableLoading}
            density="compact"
            sx={{
              p: 1,
              height: 530,
              // removing the cell border
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
          />
        ) : (
          <CircularProgress
            size={60}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </Box>

      {/* Handle both and and edit form display */}
      <AssignCommissionForm
        open={editFormOpen || open}
        onClose={
          editFormOpen
            ? () => {
                setEditFormOpen(false);
                setBeneficiaries("");
                setTerms("");
                setDescription("");
              }
            : () => {
                addFormClose();
                setBeneficiaries("");
                setTerms("");
                setDescription("");
              }
        }
        mode={editFormOpen ? "edit" : "add"}
        editItemId={editFormOpen ? editItemId : undefined}
        editFormData={editFormOpen ? editFormData : undefined}
        onEditForm={editFormOpen ? onEditForm : undefined}
        onAddForm={!editFormOpen ? onAddForm : undefined}
        fields={fields}
        adjFields={adjFields}
        commissionListOptions={commissionListOptions}
        commissionData={commissionData}
        beneficiariesOption={beneficiariesOption}
        terms={terms}
        description={description}
        setBeneficiaries={setBeneficiaries}
        beneficiaries={beneficiaries}
        right={right}
        setRight={setRight}
        left={left}
        setLeft={setLeft}
        extractedData={extractedData}
        viewData={viewData}
        setViewData={setViewData}
        setTerms={setTerms}
        setDescription={setDescription}
        handleCalculation={handleCalculation}
        adjustmentComponents={adjustmentComponents}
        setAdjustmentComponents={setAdjustmentComponents}
        roleAccess={roleAccess}
      />
    </>
  );
}
