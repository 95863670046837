// Registration Screen
export const CAPTCHA_FAIL = "reCAPTCHA verification failed";

// Register Success Screen
export const REGISTER_MSG1 = "Thank you for registering with CompFact.";
export const REGISTER_MSG2 =
  "Please use the verification link which has been sent to your email to complete the registration.";

// Signup Success Screen
export const SIGNUP_MSG1 = "Your signup is completed successfully, you can ";

// Forgot Success Screen
export const FORGOT_MSG1 =
  "Link to reset password has been sent to your inbox.";

// Access Denied Screen
export const ACCESS_MSG1 = "You don't have permission to access this page.";
export const ACCESS_MSG2 = "Please contact the system admin.";

// Session Logout Screen
export const SESSION_EXPIRED_MSG = "You have been logged out from compFact";

// session expired Screen
export const SESSION_MSG1 = "Session has expired.Please sign in to continue. ";

// Uncaught Error Screen
export const UNCAUGHT_ERROR =
  "An unexpected error occurred. Please try again or contact support for assistance.";

// Token Verified Screen
export const TOKEN_VERIFIED_MSG1 = "This link has been already verified.";
export const TOKEN_VERIFIED_MSG2 = "Please contact system admin or try ";

// No access error
export const No_PERIOD_ACCESS =
  "Commission cannot be calculated for this period";
export const No_USER_ACCESS =
  "No transaction are available to calculate the commission";

// Commission Split Messages
export const SPLIT_ENTITY_ERROR = "Split entity is required";
export const COMM_PERC_ERROR = "Commission % is required";

// File Uploading
export const FILE_TYPE_ERROR =
  "Invalid file type - Please provide an xlsx or csv file.";
export const IMAGE_FILE_TYPE_ERROR =
  "Invalid file type - Please provide an PNG or JPEG file.";
export const FILE_UPLOADING = "Please wait your file is uploading...";
export const FILE_TYPE_XLXS =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

// Outer Screen Messages

export const EXCEPTION_MESSAGE =
  "A system exception occurred, please contact your system admin";

export const PWD_NOT_MATCH =
  "Passwords do not match. Please make sure you've entered the same password in both fields.";

// Inner Screens

// Field Mapping
export const FIELD_MAP_FAILED1 =
  "Field mapping failed. Please try again later.";
export const FIELD_MAP_FAILED2 =
  "An error occurred during the field mapping process. Please try again later.";
export const FIELD_MAP_CONFIRM =
  "Already mapped fields will be removed, and new fields will be mapped. Do you wish to continue?";
export const EMPTY_FILE =
  "Cannot read properties of undefined (reading 'indexOf')";
export const FILE_UPLOAD_ADD =
  "The fields are mapped automatically, Please verify and submit.";
export const FIELD_DELETE_CONFIRM =
  "This field is mapped. Do you want to remove?";

// Common Messages
export const NETWORK_ERROR = "Network error, please try again later.";
export const SERVENET_ERROR =
  "Unable to connect to the server. Please check your internet connection and try again.";
export const IMPORT_DISABLE =
  "The import button is disabled for a few seconds.";

// ticket Messages
export const SCREENSHORT_ERROR = "Please upload a screenShort";
export const IMAGE = "Please upload a image or screenshort";

// Adjustment Messages
export const SDATE_ERROR = "Please provide the start date";
export const EDATE_ERROR = "Please provide the end date";
export const ADJUSTMENT_ERROR = "Please provide the adjustment value";
export const EDATE_LESSTHAN_ERROR =
  "End date must be greater than or equal to start date";
export const SDATE_GREATERTHAN_ERROR =
  "Start date must be less than or equal to end date";

// companySetup Screen
export const VERIFY_EMAIL = "Please verify the email";
export const VERIFY_ADMIN_EMAIL =
  "Please provide the admin email for your organization";

// TimeOut
export const SESSION_TIMEOUT =
  "This page is idle. You will be logged out in 59 seconds";

// Role
export const SPACE_ERROR =
  "Please remove any leading or trailing spaces from the Role name.";
