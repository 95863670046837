import { useState } from "react";
import ApiService from "../../apis/ApiService";
import useFetchOnMount from "../config/useFetchOnMount";
import { formatCurrencySymbol } from "../config/fieldConfig";

export const PricingService = () => {
  const [pricingPlans, setPricingPlans] = useState([]);

  const config = [
    {
      cardStyle: { backgroundColor: "#e1e4f7" }, // light purple
      titleBgColor: "#6a1b9a", // dark purple
      priceBgColor: "#ba68c8", // light purple
      buttonBgColor: "#6a1b9a", // dark purple
    },
    {
      cardStyle: { backgroundColor: "#fff3e0" }, // orange
      titleBgColor: "#f57c00", // dark orange
      priceBgColor: "#ffb74d", // light orange
      buttonBgColor: "#f57c00", // dark orange
    },
    {
      cardStyle: { backgroundColor: "#e8f5e9" }, // green
      titleBgColor: "#388e3c", // dark green
      priceBgColor: "#66bb6a", // light green
      buttonBgColor: "#388e3c", // dark green
    },
  ];

  const fetchData = async () => {
    try {
      const res = await ApiService.getPricingPlans("pricingPlan");
      if (res?.status === 200 && res?.data) {
        // Update pricing plans with styles and ensure color cycling
        const updatedPricingPlans = res.data.map((plan, index) => {
          // Calculate the color configuration index using modulo
          const configIndex = index % config.length;

          // Determine the appropriate suffix based on the frequency
          const frequencySuffix =
            plan.frequency === "Year" ? "Per User/Year" : "Per User/Month";

          // Append the appropriate suffix to the price
          const formattedPrice = `${formatCurrencySymbol(
            plan.price
          )} ${frequencySuffix}`;

          // Add the baseAmount field
          const baseAmount = plan.price;

          return {
            ...plan,
            price: formattedPrice,
            baseAmount, // Adding baseAmount to the plan object
            ...config[configIndex],
          };
        });
        console.log("updatedPricingPlans", updatedPricingPlans);
        setPricingPlans(updatedPricingPlans);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  return { pricingPlans };
};
