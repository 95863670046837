export const FormFields = [
  {
    name: "roleName",
    label: "Role Name",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "rank",
    label: "Level",
    type: "number",
    required: true,
  },
  {
    name: "reportingTo",
    label: "Reporting To",
    type: "text",
    required: false,
  },
];
