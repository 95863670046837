import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import {
  CloseTwoTone,
  EditTwoTone,
  ExpandLessTwoTone,
  ExpandMoreTwoTone,
} from "@mui/icons-material";
import EmptyBoxPayFreq from "../../common/EmptyBoxPayFreq";
import CustomPagination from "../../common/CustomPagination";
import {
  frequencyOptions,
  handleNumberFieldKeyDownInRole,
  isDuplicate,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  onError,
} from "../../config/fieldConfig";
import moment from "moment-timezone";

export default function PayFrequencyForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  onAddForm,
  onEditForm,
  fields,
  frequencyNameCheck,
  frequencyCheck,
  viewData,
  setViewData,
  calculateViewData,
  setCalculateViewData,
  roleAccess,
  data,
  frequencyWithId,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const [isCurrentChecked, setIsCurrentChecked] = useState(false); // Default to false for add mode
  const [showScheduler, setShowScheduler] = useState(false);
  const [scheduleRows, setScheduleRows] = useState([]);
  const [scheduleColumns, setScheduleColumns] = useState([]);
  const [initialFrequency, setInitialFrequency] = useState(null);

  // Function to calculate schedule dates based on start date and frequency
  const calculateScheduler = () => {
    let startDateObj = new Date(watch("startDate"));
    const scheduleList = [];

    let periodNumber = 1;

    // Function to get the end date for a given start date and frequency
    function getEndDate(startDate, daysToAdd) {
      let endDate = new Date(startDate);

      if (
        frequency === "Monthly" ||
        frequency === "Quarterly" ||
        frequency === "Half-Yearly" ||
        frequency === "Yearly"
      ) {
        endDate.setMonth(endDate.getMonth() + daysToAdd);
        // If the day is greater than 28, set the day to the last day of the month
        if (endDate.getDate() < startDate.getDate()) {
          endDate.setDate(0); // Set to the last day of the month
        } else {
          endDate.setDate(startDate.getDate() - 1);
        }
      } else {
        endDate.setDate(endDate.getDate() + daysToAdd);
      }
      return endDate;
    }

    // Function to calculate and print the dates based on frequency
    function calculateDates(startDateObj, daysToAdd, periods) {
      for (let i = 0; i < periods; i++) {
        let endDateObj = getEndDate(startDateObj, daysToAdd);

        const formattedStartDate = format(startDateObj, "yyyy-MM-dd");

        const formattedEndDate = format(endDateObj, "yyyy-MM-dd");
        scheduleList.push({
          id: periodNumber,
          periodNumber: periodNumber,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });

        // Set the start date for the next period to be the day after the current end date
        periodNumber++;
        startDateObj = new Date(endDateObj);
        startDateObj.setDate(startDateObj.getDate() + 1);
      }
    }

    const frequency = watch("frequency");

    if (startDateObj) {
      switch (frequency) {
        case "Monthly":
          calculateDates(startDateObj, 1, 12); // Calculate for 12 months
          break;
        case "Weekly":
          calculateDates(startDateObj, 6, 52); // Calculate for 1 year (52 periods of 6 days each)
          break;
        case "Bi-Weekly":
          calculateDates(startDateObj, 13, 26); // Calculate for 1 year (26 periods of 13 days each)
          break;
        case "Quarterly":
          calculateDates(startDateObj, 3, 4); // Calculate for 1 year (4 periods of 3 months each)
          break;
        case "Half-Yearly":
          calculateDates(startDateObj, 6, 2); // Calculate for 1 year (2 periods of 6 months each)
          break;
        case "Yearly":
          // calculateDates(startDateObj, 365, 1);  // Calculate for 1 year (1 period of 365 days)
          calculateDates(startDateObj, 12, 1);
          break;
        default:
          break;
      }
    }

    console.log(scheduleList);

    // Function to format a date string from 'yyyy-mm-dd' to 'dd-mm-yyyy'
    function formatDate(dateString) {
      const [year, month, day] = dateString.split("-");
      return `${day}-${month}-${year}`;
    }

    // Format the startDate and endDate of each schedule
    const formatDateScheduleList = scheduleList.map((schedule) => ({
      ...schedule,
      startDate: formatDate(schedule.startDate),
      endDate: formatDate(schedule.endDate),
    }));

    setScheduleRows(formatDateScheduleList);
    setScheduleColumns([
      {
        field: "periodNumber",
        headerName: "Pay Period",
        width: 250,
      },
      {
        field: "startDate",
        headerName: "Start Date",
        width: 250,
      },
      {
        field: "endDate",
        headerName: "End Date",
        width: 250,
      },
    ]);

    const formattedScheduleList = scheduleList.map(
      ({ periodNumber, startDate, endDate }) => ({
        periodNumber,
        startDate,
        endDate,
      })
    );

    // Return the formatted scheduleList
    return formattedScheduleList;
  };

  // Year Selector Textfield
  const [selectedYear, setSelectedYear] = useState(null);

  // Generate a range of years, change as per your requirement
  const years = Array.from({ length: 30 }, (_, i) => {
    return { label: new Date().getFullYear() + 1 - i };
  });

  useEffect(() => {
    reset();
    setIsCurrentChecked(false);
    setSelectedYear(null);
    setScheduleRows([]);
    setShowScheduler(false);

    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null && editFormData) {
      // Set form data with the provided editFormData
      fields.forEach((field) => {
        if (field.name === "fiscalYear") {
          // Set the selectedYear state to the value of the fiscalYear field
          setSelectedYear({ label: editFormData[field.name] });
          setValue("fiscalYear", editFormData[field.name]);
        } else {
          setValue(field.name, editFormData[field.name]);
        }
      });

      // Set the isCurrentChecked state based on the value from editFormData
      setIsCurrentChecked(editFormData.current === true);
    }
  }, [editFormData, editItemId, fields, mode, reset, setValue, open]);

  const onSubmit = (formData) => {
    // Calculate the scheduler data
    const paymentPeriod = calculateScheduler();

    // Concatenate the scheduler data with the form data
    const mainFormData = {
      ...formData,
      paymentPeriod: calculateViewData
        ? editFormData.paymentPeriod
        : paymentPeriod,
    };

    // Now you have structured mainFormData and customFields objects as per your requirements
    const TypeWithId = frequencyWithId.find(
      (frequency) => frequency.frequency === formData.frequency
    );

    if (TypeWithId) {
      mainFormData.frequencyId = TypeWithId.id;
    } else {
      mainFormData.frequencyId = null;
    }
    if (mode === "add") {
      onAddForm(mainFormData);
    } else if (mode === "edit") {
      mainFormData.id = editItemId;
      onEditForm(mainFormData);
    }
    onClose();
  };

  const handleSchedulerClick = () => {
    // Helper function to check if a field is empty
    const isEmpty = (field) => {
      const value = watch(field);
      return value === "" || value === null || value === undefined;
    };

    // Check for frequency errors
    const hasFrequencyError = errors.frequency;

    // Validate fields
    const isStartDateValid = !isEmpty("startDate");
    const isFrequencyValid = !isEmpty("frequency");

    // Display error messages if fields are empty
    if (!isStartDateValid) {
      setError("startDate", {
        type: "manual",
        message: "Start Date is required",
      });
    }

    if (!isFrequencyValid) {
      setError("frequency", {
        type: "manual",
        message: "Frequency is required",
      });
    }

    // Proceed only if there are no frequency errors and both fields are valid
    if (!hasFrequencyError && isStartDateValid && isFrequencyValid) {
      calculateScheduler(); // Calculate scheduler if valid
      setShowScheduler(!showScheduler); // Toggle showScheduler state
    }
  };

  const [page, setPage] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // 0-based index for the first page
    pageSize: 12,
  });

  const frequency = watch("frequency");

  //  Reset pagination to the first page when selectedRole changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    setPage(false);
  }, [frequency]);

  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  // Set the initial frequency value when the component mounts or when the value is loaded for editing
  useEffect(() => {
    if (mode === "add") {
      setViewData();
      setCalculateViewData(false);
      setInitialFrequency(null);
    }
    if (mode === "edit") {
      const currentFrequency = watch("frequency");
      setInitialFrequency(currentFrequency);
    }
    if (mode === "edit" && viewData === true) {
      calculateScheduler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, mode]);

  const handleFrequencyChange = (enteredValue) => {
    if (
      frequencyCheck.includes(enteredValue) &&
      enteredValue !== initialFrequency
    ) {
      setError("frequency", {
        type: "duplicate",
        message: "This frequency is already selected.",
      });
      setShowScheduler(false);
    } else {
      clearErrors("frequency");
    }
  };

  const validateFrequency = (value) => {
    if (frequencyCheck.includes(value) && value !== initialFrequency) {
      return "This frequency is already selected.";
    } else if (!value) {
      return "Frequency is required";
    }
    return true;
  };

  const editAccess = roleAccess.some(
    (item) => item.resource === "PayFrequency" && item.writeAccess
  );

  // Utility function to disable sorting for all columns
  const disableSortingForAllColumns = (columns) => {
    return columns.map((column) => ({
      ...column,
      sortable: viewData ? false : true,
    }));
  };

  const columnsWithoutSorting = disableSortingForAllColumns(scheduleColumns);

  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
        &nbsp;Pay Frequency
        {viewData && editAccess ? (
          <IconButton
            aria-label="edit"
            style={{ position: "absolute", top: 10, right: 45 }}
            color="primary"
            title="Edit"
            onClick={() => setViewData(false)}
          >
            <EditTwoTone />
          </IconButton>
        ) : null}
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            onError(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={6} key={index} marginTop={1}>
                {field.name === "current" ? (
                  <FormControl
                    error={!!errors[field.name]}
                    component="fieldset"
                    variant="outlined"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={isCurrentChecked} // Use the state variable here
                          onChange={(e) =>
                            setIsCurrentChecked(e.target.checked)
                          } // Update the state when the checkbox changes
                          disabled={viewData} // Disable the checkbox based on viewData condition
                        />
                      }
                      label={field.label}
                      required={field.required}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                    />
                    <FormHelperText>
                      {errors[field.name]?.message}
                    </FormHelperText>
                  </FormControl>
                ) : field.name === "frequency" ||
                  field.name === "fiscalYear" ? (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={null}
                    rules={{
                      validate: validateFrequency,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl error={!!error} fullWidth>
                        <Autocomplete
                          name={field.name}
                          options={
                            field.name === "frequency"
                              ? frequencyOptions
                              : years
                          }
                          getOptionLabel={(option) =>
                            field.name === "frequency" && option
                              ? option.toString()
                              : option?.label?.toString() ?? ""
                          }
                          value={
                            field.name === "frequency"
                              ? frequencyOptions.includes(watch(field.name))
                                ? watch(field.name)
                                : null
                              : selectedYear
                          }
                          readOnly={
                            (viewData && editItemId) ||
                            (calculateViewData &&
                              (field.name === "frequency" ||
                                field.name === "fiscalYear"))
                          }
                          style={{
                            pointerEvents:
                              (viewData && editItemId) ||
                              (calculateViewData &&
                                (field.name === "frequency" ||
                                  field.name === "fiscalYear"))
                                ? "none"
                                : "auto",
                          }}
                          onChange={(event, value) => {
                            if (field.name === "frequency") {
                              if (value) {
                                setValue(field.name, value);
                                handleFrequencyChange(value);
                                const hasFrequencyError = errors.frequency;
                                if (
                                  !hasFrequencyError &&
                                  watch("startDate") !== ""
                                ) {
                                  calculateScheduler(); // Recalculate table values
                                }
                              } else {
                                setShowScheduler(false);
                              }
                            } else {
                              setSelectedYear(value);
                              const startDateYear =
                                watch("startDate") !== "" &&
                                value &&
                                moment(watch("startDate")).year();
                              if (
                                startDateYear &&
                                startDateYear !== value.label
                              ) {
                                const newStartDate = moment(watch("startDate"))
                                  .set("year", value.label)
                                  .format("YYYY-MM-DD");
                                setValue("startDate", newStartDate);
                                const hasFrequencyError = errors.frequency;
                                if (
                                  !hasFrequencyError &&
                                  watch("startDate") !== ""
                                ) {
                                  calculateScheduler(); // Recalculate table values
                                }
                              }
                            }
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (field.name === "fiscalYear") {
                              return option.label === value?.label; // Compare label property
                            }
                            return option === value;
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              const currentFrequency = watch("frequency");
                              if (
                                validateFrequency(currentFrequency) === true
                              ) {
                                clearErrors("frequency");
                              }
                              trigger(field.name); // Trigger validation on blur
                            }, 200);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={field.label}
                              variant="outlined"
                              required={field.required}
                              size="small"
                              fullWidth
                              {...register(field.name, {
                                required: field.required
                                  ? `${field.label} is required`
                                  : false,
                              })}
                              InputLabelProps={{
                                style: {
                                  pointerEvents: "none",
                                },
                              }}
                              error={!!errors[field.name]} // Ensure error handling is consistent
                              helperText={errors[field.name]?.message ?? ""}
                              inputRef={(el) => {
                                if (el) {
                                  fieldRefs.current[field.name] = el;
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                ) : (
                  <TextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    autoFocus={field.autoFocus}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      validate: {
                        isEmpty: (value) =>
                          field.required && field.name === "frequencyName"
                            ? isEmpty(value)
                            : true,
                        isValueContainsSplChars: (value) =>
                          isValueContainsSplChars(value),
                        isValueStartsWithNumber: (value) =>
                          field.required && field.name === "frequencyName"
                            ? isValueStartsWithNumber(value, field.label)
                            : true,
                        isDuplicate: (value) => {
                          if (field.name === "frequencyName") {
                            return isDuplicate(
                              frequencyNameCheck,
                              field.label,
                              value,
                              mode,
                              editFormData,
                              field.name,
                              false
                            );
                          } else {
                            return true;
                          }
                        },
                      },
                    })}
                    inputProps={{
                      max: `${watch("fiscalYear")}-12-31`,
                      min: `${watch("fiscalYear")}-01-01`,
                      ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                    }}
                    InputProps={{
                      style: {
                        pointerEvents:
                          (viewData && editItemId) ||
                          (calculateViewData && field.name === "startDate")
                            ? "none"
                            : "auto",
                      }, // This disables interaction based on conditions
                    }}
                    InputLabelProps={{
                      shrink: field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                      const hasFrequencyError = errors.frequency;
                      if (!hasFrequencyError && watch("startDate") !== "") {
                        calculateScheduler(); // Recalculate table values
                      } else {
                        setShowScheduler(false);
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name); // Trigger validation on blur
                      }, 200);
                    }}
                    onKeyDown={(e) => {
                      if (field.type === "number") {
                        handleNumberFieldKeyDownInRole(e);
                      }
                    }}
                    inputRef={(el) => {
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          {viewData && editItemId ? null : (
            <DialogActions className="mt-3">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSchedulerClick}
                endIcon={
                  showScheduler ? <ExpandLessTwoTone /> : <ExpandMoreTwoTone />
                }
              >
                View Pay Period
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {mode === "add" ? "Submit" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </DialogActions>
          )}
          {(showScheduler || viewData) && (
            <Box
              sx={{
                width: "100%",
                "& .actions": {
                  color: "text.secondary",
                },
                "& .textPrimary": {
                  color: "text.primary",
                },
              }}
            >
              <DataGrid
                rows={scheduleRows} // Place your rows here
                columns={columnsWithoutSorting} // Define your columns here
                slots={{
                  noRowsOverlay: EmptyBoxPayFreq,
                  pagination: CustomPagination,
                  loadingOverlay: LinearProgress,
                }}
                initialState={
                  page
                    ? { pagination: { paginationModel: { pageSize: 12 } } }
                    : undefined
                }
                disableColumnSorting
                onPaginationModelChange={!page ? setPaginationModel : undefined}
                paginationModel={!page ? paginationModel : undefined}
                pageSizeOptions={[12, 26, 52]}
                disableRowSelectionOnClick
                density="compact"
                autoHeight
                sx={{
                  mt: 2,
                  padding: 2,
                  height: 460,
                  // removing the cell border
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none !important",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
