import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { format } from "date-fns";

const PayFrequencyColumns = (handleEdit, handleDelete, roleAccess) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "PayFrequency" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "frequencyName",
      headerName: "Frequency Name",
      width: 250,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.frequencyName} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true)}
          >
            {row.frequencyName}
          </div>
        );
      },
    },
    {
      field: "fiscalYear",
      headerName: "Fiscal Year",
      width: 150,
    },
    {
      field: "frequency",
      headerName: "Frequency",
      width: 150,
    },
    {
      field: "currentPeriod",
      headerName: "Current Period",
      width: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "current",
      headerName: "Is Current",
      type: "boolean",
      width: 150,
    },
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "PayFrequency" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "PayFrequency" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={editAccess ? handleEdit(id) : undefined}
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }
        return actions;
      },
    },
  ];
};

export default PayFrequencyColumns;
