import { IconButton } from "@mui/material";
import { DownloadingTwoTone } from "@mui/icons-material";

export default function BillingColumns(handlePdfDownload) {
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 80,
    },
    {
      field: "paymentId",
      headerName: "Payment ID",
      width: 200,
    },
    {
      field: "invoice",
      headerName: "Invoice ID",
      width: 100,
    },
    {
      field: "pricingPlan",
      headerName: "Plan Name",
      width: 150,
    },
    {
      field: "paidDate",
      headerName: "Paid Date",
      width: 100,
    },
    {
      field: "amountToPay",
      headerName: "Amount",
      headerAlign: "right",
      align: "right",
      width: 100,
    },
    {
      field: "downloadInvoice",
      headerName: "Download Invoice",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <IconButton
          aria-label="download Invoice"
          color="success"
          title="Download Invoice"
          onClick={() => handlePdfDownload(params)} //  function to handle PDF download
        >
          <DownloadingTwoTone />
        </IconButton>
      ),
    },
  ];
}
