import { useFieldArray } from "react-hook-form";
import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import {
  is80CharLimit,
  isEmpty,
  isValueContainsSplChars,
} from "../../config/fieldConfig";

export default function FeaturesBox({
  viewData,
  editItemId,
  register,
  errors,
  control,
  setValue,
  trigger,
}) {
  const {
    fields: featureFields,
    insert,
    remove,
  } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  return (
    <>
      <Box className="bg-gray-100 border" sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Features
        </Typography>
        {featureFields.map((field, index) => (
          <Grid container spacing={2} key={index} pb={1}>
            <Grid item xs={8} sm={9} md={10.9} marginTop={1}>
              <TextField
                name={`dynamicFields.${index}.name`}
                label={`Feature`}
                type="text"
                required
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="off"
                InputProps={{
                  style: {
                    pointerEvents: viewData && editItemId ? "none" : "auto",
                  }, // remove the hover effect
                }}
                InputLabelProps={{
                  style: {
                    pointerEvents: "none",
                  },
                }}
                {...register(`dynamicFields.${index}.name`, {
                  required: `Feature is required`,
                  validate: {
                    isEmpty: (value) => isEmpty(value),
                    isValueContainsSplChars: (value) =>
                      isValueContainsSplChars(value),
                    is80CharLimit: (value) => is80CharLimit(value),
                  },
                })}
                error={!!errors?.dynamicFields?.[index]?.name} // Check if there's an error for this field
                helperText={errors?.dynamicFields?.[index]?.name?.message} // Display the error message
                onChange={(e) => {
                  setValue(`dynamicFields.${index}.name`, e.target.value);
                  trigger(`dynamicFields.${index}.name`);
                }}
                onBlur={() => {
                  trigger(`dynamicFields.${index}.name`);
                }}
              />
            </Grid>
            {viewData && editItemId ? null : (
              <Grid
                item
                xs={4}
                sm={3}
                md={1.1}
                marginTop={1}
                sx={{ textAlign: "right" }}
              >
                <IconButton
                  onClick={() => insert(index + 1, { name: "" })}
                  color="success"
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
                <IconButton
                  onClick={() => remove(index)}
                  color="error"
                  disabled={featureFields.length > 1 ? false : true}
                >
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Box>
    </>
  );
}
