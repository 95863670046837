import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CfTransBlueLogo, MFAImage } from "../../config/imageConfig";
import { onMfaVerify, onResendMfaCode } from "../../service/outerPagesService";

export default function MfaVerify({
  setIsAuthenticated,
  setRes,
  setUserRoleAccess,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const location = useLocation();
  const { email, values } = location.state || "";

  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendVisible, setResendVisible] = useState(true);

  useEffect(() => {
    // Function to reset resend visibility after timeout
    const resetResendVisibility = () => {
      try {
        setResendVisible(true); // Make link visible again
      } catch (error) {
        console.error("Error resetting resend visibility:", error);
      }
    };

    let timer;

    if (!resendVisible) {
      try {
        timer = setTimeout(resetResendVisibility, 30000); // 30 seconds delay
      } catch (error) {
        console.error("Error setting timeout:", error);
      }
    }

    // Cleanup function to clear the timeout
    return () => {
      try {
        if (timer) {
          clearTimeout(timer); // Cleanup timer if component unmounts or if resendVisible changes
        }
      } catch (error) {
        console.error("Error clearing timeout:", error);
      }
    };
  }, [resendVisible]);

  const onSubmit = async (data) => {
    setLoading(true);
    const { mfaCode } = data;

    try {
      await onMfaVerify(
        email,
        mfaCode,
        setUserRoleAccess,
        setIsAuthenticated,
        setRes,
        navigate,
        setLoading
      );
    } catch (error) {
      console.error("Error during MFA verification:", error);
    } finally {
      reset(); // Reset the form after submission
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    setResendVisible(false); // Hide link immediately
    try {
      await onResendMfaCode(values);
    } catch (error) {
      console.error("Failed to resend MFA code:", error);
    } finally {
      setResendLoading(false);
      reset(); // Reset the form after submission
    }
  };

  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setValue("mfaCode", value);
  };

  useEffect(() => {
    setIsAuthenticated(false); // To set frontend authentication
    setRes(false);
    sessionStorage.clear(); // To remove session storage for logout
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      {/* Left Grid */}
      <Grid
        item
        xs={false}
        md={7}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "white",
          pb: { xs: 5 },
          p: 2,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Link href={"/"}>
            <img
              src={CfTransBlueLogo}
              alt="compFact logo"
              style={{ width: "100%", maxWidth: 150 }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${MFAImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 400,
            width: "100%",
            maxWidth: 600,

            mb: 4,
          }}
        />
        <Typography
          variant="h4"
          color={"primary"}
          gutterBottom
          sx={{
            textAlign: "center",
            mb: 2,
          }}
        >
          Keep Your Account Secure
        </Typography>
      </Grid>

      {/* Right Grid*/}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        component={Paper}
        elevation={6}
        square
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%", // Ensure the form container takes full width
            maxWidth: "400px", // Optional: limit the maximum width of the form
            p: 2,
          }}
        >
          <Typography
            variant="h4"
            color={"primary"}
            gutterBottom
            textAlign="center"
          >
            Verify via email address
          </Typography>
          <Typography variant="body1" gutterBottom textAlign="center">
            A one-time password will be sent to your Email Id
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            sx={{ width: "100%" }}
          >
            <TextField
              margin="normal"
              size="small"
              required
              fullWidth
              autoFocus
              label="Enter OTP"
              placeholder="xxxxxx"
              autoComplete="off"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 6,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              error={!!errors.mfaCode}
              helperText={
                errors.mfaCode ? "Please enter a valid 6-digit code." : ""
              }
              {...register("mfaCode", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please enter a valid 6-digit code.",
                },
                onChange: handleChange,
              })}
            />

            <Box sx={{ display: "flex", justifyContent: "flex-end", my: 1 }}>
              {resendVisible && (
                <Link
                  variant="body2"
                  onClick={handleResendCode}
                  sx={{ cursor: "pointer" }}
                >
                  {resendLoading ? "Resending..." : "Resend code"}
                </Link>
              )}
            </Box>

            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={loading}
              loadingPosition="start"
              startIcon={<span />} // Placeholder or actual icon
              style={{
                backgroundColor: loading ? "#448aff" : undefined,
                color: loading ? "#fff" : undefined,
              }}
            >
              {!loading ? "Verify" : "Loading..."}
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
