import { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { ListTwoTone } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DateRangePicker from "../../common/DateRangePicker";

export default function AuditLogToolBar({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedOption,
  handleGetAll,
  handleGetMyDetails,
  handleGetMyReportee,
  stopRemount,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const menuItems = [
    { label: "Get All", action: handleGetAll },
    { label: "My Data", action: handleGetMyDetails },
    { label: "My Reportee", action: handleGetMyReportee },
  ].filter(Boolean);

  return (
    <GridToolbarContainer sx={{ flexWrap: "wrap" }}>
      <Typography variant="h5" color="primary">
        Audit Log
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        variant="text"
        size="small"
        startIcon={<ListTwoTone />}
        onClick={handleMenuOpen}
      >
        View
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, action }) => (
          <MenuItem
            key={label}
            onClick={() => {
              action();
              handleMenuClose();
            }}
            selected={selectedOption === label}
            sx={{
              color: selectedOption === label ? "secondary.main" : "inherit",
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>

      <div style={{ flex: 1 }} />

      <GridToolbarQuickFilter sx={{ pr: 5 }} />

      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        stopRemount={stopRemount}
      />
    </GridToolbarContainer>
  );
}
