import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { CameraAltOutlined, EditTwoTone } from "@mui/icons-material";
import SimpleImageViewer from "react-simple-image-viewer";
import JoditEditor from "jodit-react";
import toast from "react-hot-toast";
import { SCREENSHORT_ERROR } from "../../config/toastMessage";
import { transformHTML } from "../../config/fieldConfig";

export default function TicketForm({
  mode,
  editItemId,
  fields,
  editFormData,
  onAddForm,
  onEditForm,
  selectedImage,
  handleFileChangeLogo,
  handleAvatarClick,
  fileInputRef,
  resourceNames,
  beneficiariesOption,
  onClose,
  viewData,
  setViewData,
  setSelectedImage,
  selectedUser,
  setSelectedUser,
  isSuperAdmin,
  isMonitor,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
    setError,
    control,
    reset,
    watch,
  } = useForm();
  const [selectedUserName, setSelectedUserName] = useState("");

  // For image
  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedUser("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    reset();
    if (mode !== "edit") {
      setViewData();
      setSelectedImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Check if editFormData exists
      if (editFormData) {
        setValue("description", editFormData.description);
        setValue("screenName", editFormData.screenName);
        fields.forEach((field) => {
          setValue(field.name, editFormData[field.name]);
        });
        // setSelectedUser(editFormData.userId);
        // setSelectedUserName(editFormData.userName);
        setSelectedUserName(editFormData.userId);
        setSelectedUser(editFormData.userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const joditConfig = useMemo(
    () => ({
      readonly: viewData,
      removeButtons: ["fullsize"], // Remove the "fullSize" button
      limitChars: 1000,
      addNewLine: false,
    }),
    [viewData] // The empty dependency array ensures this object is created only once
  );

  const onSubmit = (formData) => {
    console.log(formData);

    // format the style for editor
    const descriptionHTMLFormate = transformHTML(formData.description);
    formData.description = descriptionHTMLFormate;
    // formData.userId= selectedUser;
    // Set the ID of the selected beneficiary as the value of the "beneficiaryName" field
    formData.userId = selectedUserName;
    delete formData.userName;

    if (selectedImage) {
      if (mode === "add") {
        onAddForm(formData);
      } else if (mode === "edit") {
        formData.id = editItemId;
        onEditForm(formData);
      }
      onClose();
    } else {
      toast.error(SCREENSHORT_ERROR);
    }
  };

  const handlePopoverOpen = (event) => {
    if (!isSuperAdmin || !isMonitor) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //overlay in logoUpload
  const handleOverlayEffect = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: hovered ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          zIndex: 1,
          cursor: "pointer",
        }}
        onClick={
          selectedImage && !selectedImage.includes("Image Not Found")
            ? handlePopoverOpen
            : null
        } // Open popover on overlay click
      >
        <CameraAltOutlined
          sx={{
            color: "#fff",
            fontSize: "2rem",
          }}
        />
      </div>
    );
  };
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        mt={mode === "edit" ? 1 : -5}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid
              item
              xs={12}
              key={index}
              // sm={6}
              sm={field.isEditor ? 12 : 6}
            >
              {field.isSubtitle && (
                <Typography variant="subtitle1" gutterBottom>
                  {field.subtitle}
                </Typography>
              )}

              {field.isEditor ? (
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <JoditEditor
                        value={field.value}
                        config={joditConfig}
                        onChange={(content) => {
                          // Remove HTML tags to get plain text length
                          const plainText = content.replace(/<[^>]*>/g, "");
                          // Remove spaces from plain text
                          const plainTextWithoutSpaces = plainText.replace(
                            /\s+/g,
                            ""
                          );
                          if (plainTextWithoutSpaces.length <= 1000) {
                            field.onChange(content); // Update content if within the limit
                          } else {
                            field.onChange(content); // Update content even if limit is exceeded
                            setError("description", {
                              type: "manual",
                              message: "Content cannot exceed 1000 characters.",
                            });
                          }
                        }}
                      />
                      {fieldState.error && (
                        <span style={{ color: "red" }}>
                          {fieldState.error.message}
                        </span>
                      )}
                    </>
                  )}
                />
              ) : field.name === "screenName" || field.name === "userId" ? (
                <Autocomplete
                  name={field.name}
                  // options={resourceNames}
                  options={
                    field.name === "screenName"
                      ? resourceNames
                      : beneficiariesOption
                  }
                  getOptionLabel={(option) => {
                    if (field.name === "userId") {
                      return option.userName;
                    } else if (field.name === "screenName") {
                      // Assuming you have a way to label the resourceNames options
                      return option; // Or some other way to get the label for resourceNames options
                    } else {
                      return "";
                    }
                  }}
                  value={
                    field.name === "userId"
                      ? beneficiariesOption.find(
                          (option) => option.id === selectedUser
                        )
                      : resourceNames.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                  }
                  onChange={(event, value) => {
                    if (field.name === "userId") {
                      console.log(value ? value.id : null);

                      // setSelectedUserName(value ? value.name : null);
                      setSelectedUserName(value ? value.id : null); // Update selectedUserName state
                      setSelectedUser(value ? value.id : null); // Update selectedUserName state
                      setValue("userId", value ? value.id : null);
                    } else {
                      setValue(field.name, value);
                    }
                  }} // Update the form value on change
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                  }}
                  onBlur={() => {
                    setTimeout(function () {
                      trigger(field.name); // Trigger validation on blur
                    }, 100);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={field.label}
                      variant="outlined"
                      required={field.required}
                      size="small"
                      fullWidth
                      InputLabelProps={{
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                      error={!!errors[field.name] && !params.inputProps.value}
                      helperText={
                        errors[field.name]?.message &&
                        (!params.inputProps.value
                          ? errors[field.name]?.message
                          : "")
                      }
                    />
                  )}
                />
              ) : (
                <TextField
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  required={field.required}
                  autoFocus={field.autoFocus}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onWheel={(event) => {
                    // Check if the input type is number to prevent scrolling
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      max:
                        field.type === "datetime-local"
                          ? "9999-12-31T23:59"
                          : undefined,
                      style: {
                        textTransform:
                          field.type === "datetime-local"
                            ? "uppercase"
                            : "none",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: field.shrink,
                    style: {
                      pointerEvents: "none",
                    },
                  }}
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                  }}
                  style={{
                    pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                  }}
                  onBlur={() => {
                    setTimeout(function () {
                      trigger(field.name); // Trigger validation on blur
                    }, 100);
                  }}
                />
              )}
            </Grid>
          ))}

          <Grid container spacing={2} px={2} py={3}>
            <InputLabel sx={{ pl: 2 }}>
              {isSuperAdmin || isMonitor ? "View Issue" : "Upload ScreenShot"} :
            </InputLabel>
            <Grid item xs={12}>
              {selectedImage && !selectedImage.includes("Image Not Found") ? (
                <div
                  style={{
                    position: "relative",
                    width: "auto",
                    height: "auto",
                    maxWidth: "10vw",
                    maxHeight: "13vh",
                    cursor: !viewData ? "pointer" : "default",
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  <img
                    src={selectedImage}
                    alt="Upload Img"
                    style={{
                      width: "auto",
                      height: "auto",
                      maxWidth: "10vw",
                      maxHeight: "13vh",
                      cursor:
                        isSuperAdmin || isMonitor || !viewData
                          ? "pointer"
                          : "default",
                    }}
                    onClick={(e) => {
                      if (isSuperAdmin || isMonitor) {
                        openImageViewer();
                      } else {
                        if (!viewData) {
                          console.log(isSuperAdmin, viewData);
                          handlePopoverOpen(e);
                          // Prevent triggering file input click when showing popover
                          e.stopPropagation();
                        }
                      }
                    }}
                  />
                  {!viewData &&
                    (!isSuperAdmin || !isMonitor) &&
                    handleOverlayEffect()}
                </div>
              ) : (
                <Avatar
                  variant="rounded"
                  onClick={() => {
                    console.log(isSuperAdmin, viewData);
                    if (!isSuperAdmin || !isMonitor) {
                      fileInputRef.current?.click();
                    }
                  }}
                  sx={{
                    position: "relative",
                    width: { xs: "15vw", sm: "12vw", md: "10vw" },
                    height: { xs: "15vh", sm: "12vh", md: "10vh" },
                    cursor: !viewData ? "pointer" : "default",
                    transition: "transform 0.3s ease-in-out",
                    overflow: "hidden",
                    "&:hover": {
                      transform:
                        !viewData && (!isSuperAdmin || !isMonitor)
                          ? "scale(1.05)"
                          : "none",
                    },
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                ></Avatar>
              )}
            </Grid>

            {isViewerOpen && (
              <SimpleImageViewer
                src={[selectedImage]} // Image to view
                currentIndex={0} // Index of the image
                onClose={closeImageViewer} // Close viewer
                disableScroll={false} // Optional: Disable scroll outside the viewer
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
              />
            )}
            <input
              type="file"
              accept="image/*"
              hidden
              ref={fileInputRef}
              onChange={handleFileChangeLogo}
              disabled={viewData}
            />
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  console.log(isSuperAdmin, viewData);
                  fileInputRef.current?.click(); // Trigger file input on "Change Image" click
                  handlePopoverClose(); // Close popover after action
                }}
              >
                <EditTwoTone sx={{ fontSize: 35, color: "blue", pr: 2 }} />{" "}
                Replace Image
              </MenuItem>
            </Popover>
          </Grid>
        </Grid>
        {viewData && editItemId ? null : (
          <DialogActions className="mt-3">
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        )}
      </Box>
    </>
  );
}
