import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { format } from "date-fns";

const Columns = (
  dynamicColumns,
  handleEdit,
  handleDelete,
  roleAccess,
  isNavigateToAllErrorsActive
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Customer" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "customerId",
      headerName: "Customer ID",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.customerId} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true, false)}
          >
            {row.customerId}
          </div>
        );
      },
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 100,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 100,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "renewalDate",
      headerName: "Renewal Date",
      width: 100,
      valueFormatter: ({ value }) => {
        if (value) {
          return format(new Date(value), "dd-MM-yyyy");
        }
        return "";
      },
    },
    {
      field: "fullCustomerName",
      headerName: "Full Cust Name",
      width: 170,
    },
    {
      field: "customerLevel",
      headerName: "Cust Level",
      width: 100,
    },
    {
      field: "subCustomerOf",
      headerName: "Sub Cust Of",
      width: 100,
    },
    {
      field: "relationshipType",
      headerName: "Relationship Type",
      width: 200,
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      width: 100,
    },
    {
      field: "terms",
      headerName: "Terms",
      width: 300,
    },
    {
      field: "active",
      headerName: "Is Active",
      type: "boolean",
      width: 100,
    },
    isNavigateToAllErrorsActive && {
      field: "description",
      headerName: "Error Description",
      width: 200,
    },
    ...dynamicColumns,
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Customer" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Customer" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={
                editAccess && !isNavigateToAllErrorsActive
                  ? handleEdit(id, false, false)
                  : handleEdit(id, false, true)
              }
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }

        return actions;
      },
    },
  ].filter(Boolean);
};

export default Columns;
