import { useState } from "react";
import ApiService from "../../apis/ApiService";
import {
  addSerialNumber,
  formatCurrencyAmount,
  formatDateDDMMYYYY,
} from "../config/fieldConfig";
import useFetchOnMount from "../config/useFetchOnMount";
import BillingColumns from "../pages/Billing/columns";
import { JWT_TOKEN } from "../config/sessionStorage";

export const PaymentHistoryService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");

  const fetchData = async () => {
    try {
      const TOKEN = JWT_TOKEN();
      setToken(TOKEN);

      const res = await ApiService.getPaymentHistory(TOKEN);
      console.log("res", res.data);

      if (res?.status === 200) {
        const formattedData = res.data.map((item) => ({
          ...item,
          paidDate: formatDateDDMMYYYY(item.paidDate),
          amountToPay: formatCurrencyAmount(
            item.amountToPay,
            item.currency || "INR"
          ),
        }));
        setTableData(formattedData);
      } else {
        // Handle non-200 status codes
        console.error("Failed to fetch data:", res?.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const handlePdfDownload = async (params) => {
    console.log("params", params);
    try {
      const response = await ApiService.downloadInvoicePDF(
        token,
        params.row.id
      );

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data");
        return;
      }

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${params.row.invoice}_${params.row.paidDate}.pdf`;
      a.click();

      // Revoke object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const columns = BillingColumns(handlePdfDownload);

  const rows = addSerialNumber(data);

  return {
    columns,
    rows,
  };
};
