export const FormFields = [
  {
    name: "commissionName",
    label: "Commission Name",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "credit",
    label: "Credit",
    type: "text",
    required: true,
  },
  {
    name: "portion",
    label: "Portion(%)",
    type: "number",
    required: true,
  },
  {
    isCondition: true,
  },
  {
    isTotalCondition: true,
  },
  {
    isDivider: true,
  },
  {
    isSubtitle: true,
    subtitle: "Crediting:",
  },
  {
    isCrediting: true,
  },
  {
    isDivider: true,
  },
  {
    isSubtitle: true,
    subtitle: "Calculation:",
  },
  {
    isCalculation: true,
  },
  {
    isTotalCalculation: true,
  },
  {
    name: "selectFrequency",
    label: "Select Frequency",
    type: "text",
    required: true,
  },
  {
    name: "rounding",
    label: "Rounding",
    type: "number",
    required: false,
  },
  {
    name: "active",
    label: "IsActive",
    type: "boolean",
    required: false,
  },
  {
    isEditor: true,
  },
];

export const ConditionFields = [
  {
    name: "conditionOption",
    label: "Condition Option",
    type: "text",
    required: true,
  },
  {
    isIf: true,
  },
  {
    name: "startBrace",
    type: "text",
    required: false,
  },
  {
    name: "conditionEntityName",
    label: "Condition Entity Name",
    type: "text",
    required: true,
  },
  {
    name: "field",
    label: "Field",
    type: "text",
    required: true,
  },
  {
    name: "symbols",
    label: "Symbols",
    type: "text",
    required: true,
  },
  {
    name: "conDescription",
    label: "Condition value",
    type: "text",
    required: true,
  },
  {
    name: "endBrace",
    type: "text",
    required: false,
  },
  {
    isConditionBtn: true,
  },
];

export const CalculationFields = [
  {
    name: "calcOption",
    label: "Calc Option",
    type: "text",
    required: true,
  },
  {
    isEqual: true,
  },
  {
    name: "calcEntityName",
    label: "Calc Entity Name",
    type: "text",
    required: true,
  },
  {
    name: "calcField",
    label: "Calc Field",
    type: "text",
    required: true,
  },
  {
    name: "calcSymbols",
    label: "Calc Symbols",
    type: "text",
    required: true,
  },
  {
    name: "calcDescription",
    label: "Calc value",
    type: "text",
    required: true,
  },
  {
    name: "percentage",
    type: "text",
    required: false,
  },
  {
    isCalculationBtn: true,
  },
];

export const CheckBoxFields = [
  {
    name: "roleUp",
    label: "RoleUp",
    type: "boolean",
  },
  {
    name: "split",
    label: "Split",
    type: "boolean",
  },
  {
    name: "tiered",
    label: "Tiered",
    type: "boolean",
  },
  {
    name: "attainment",
    label: "Attainment",
    type: "boolean",
  },
];
