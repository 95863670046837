import { useEffect } from "react";
import { Button, styled } from "@mui/material";
import { FileUploadOutlined } from "@mui/icons-material";
import { FILE_UPLOAD_TIME } from "../config/sessionStorage";

// Styled input for visually hidden file upload
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// Main component
export default function ImportButton({
  mode,
  handleUploadFile,
  fileInputRef,
  uploading,
  setUploadInProgress,
}) {
  const Time = FILE_UPLOAD_TIME();

  const isTimeBoolean = Time === "false" ? false : true;

  // Choose the service based on the provided mode
  const service = handleUploadFile;

  useEffect(() => {
    if (sessionStorage.getItem("isFileUpload") === "true") {
      setTimeout(() => {
        sessionStorage.setItem("isFileUpload", false);
      }, 5000); // Simulate 5 second delay
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      component="label" // Renders button as label to trigger file input
      variant="text"
      size="small"
      startIcon={<FileUploadOutlined />}
      disabled={isTimeBoolean ? isTimeBoolean : uploading} // Disable button when uploading is true
      mode={mode}
    >
      Import
      {/* Visually hidden file input */}
      <VisuallyHiddenInput
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          setUploadInProgress(true); // Set uploading state to true
          service(e)
            .then(() => setUploadInProgress(false))
            .catch(() => setUploadInProgress(false));
        }}
      />
    </Button>
  );
}
