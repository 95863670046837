import { useState } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { FileDownloadOutlined, ListTwoTone } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import AddButton from "../../common/AddButton";
import ImportButton from "../../common/ImportButton";
import { USER_ROLE } from "../../config/sessionStorage";

export default function ProductToolbar({
  handleUploadFile,
  addFormOpen,
  roleAccess,
  fileInputRef,
  handleGetAll,
  handleAllError,
  triggerNavigateToAllErrors,
  hasData,
  apiEndpoint,
  uploading,
  setUploadInProgress,
  selectedOption,
  handleSelectOption,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const apiRef = useGridApiContext();

  const USERROLE = USER_ROLE();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const readAccess = roleAccess.some(
    (item) => item.resource === "Product" && item.writeAccess
  );

  const handleExport = () => {
    apiRef.current.exportDataAsCsv({
      fileName:
        apiEndpoint === "/products/getErrorProducts"
          ? "Product_Error_Records"
          : "Product",
    });
  };

  const menuItems = [
    { label: "Get All", action: handleGetAll },
    USERROLE === "Admin" && {
      label: "All Errors",
      action: () => {
        handleSelectOption("All Errors");
        handleAllError(() => {
          triggerNavigateToAllErrors();
          handleMenuClose(); // Close the menu after navigation
        }, "false"); // Pass 'false' to set boolValue to 'false'
      },
    },
  ].filter(Boolean);

  return (
    <GridToolbarContainer>
      <Typography variant="h5" color="primary">
        Products
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {readAccess && (
        <ImportButton
          mode={"product"}
          handleUploadFile={handleUploadFile}
          fileInputRef={fileInputRef}
          uploading={uploading}
          setUploadInProgress={setUploadInProgress}
        />
      )}

      <Button
        variant="text"
        size="small"
        startIcon={<FileDownloadOutlined />}
        onClick={handleExport}
        disabled={!hasData}
      >
        Export
      </Button>

      {USERROLE === "Admin" && (
        <>
          <Button
            variant="text"
            size="small"
            startIcon={<ListTwoTone />}
            onClick={handleMenuOpen}
          >
            View
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {menuItems.map(({ label, action }) => (
              <MenuItem
                key={label}
                onClick={() => {
                  action();
                  handleMenuClose();
                }}
                selected={selectedOption === label}
                sx={{
                  color:
                    selectedOption === label ? "secondary.main" : "inherit",
                }}
              >
                {label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}

      <div style={{ flex: 1 }} />

      <GridToolbarQuickFilter />
      <AddButton
        dialogType={"Product"}
        addFormOpen={addFormOpen}
        roleAccess={roleAccess}
      />
    </GridToolbarContainer>
  );
}
