export const FormFields = [
  {
    name: "schedule",
    label: "Schedule",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "executeJobTask",
    label: "Execute JobTask",
    type: "text",
    required: true,
  },
  {
    name: "pojoType",
    label: "Pojo Type",
    type: "text",
    required: true,
  },
  {
    name: "path",
    label: "Path",
    type: "text",
    required: true,
  },
  {
    name: "day",
    label: "Select Day",
    type: "text",
    required: true,
  },
  {
    name: "date",
    label: "Select Date",
    type: "text",
    required: true,
  },
  {
    name: "customDays",
    label: "Custom Days",
    type: "text",
    required: true,
  },
  {
    name: "customPeriod",
    label: "Custom Period",
    type: "text",
    required: true,
  },
  {
    isSubtitle: true,
    subtitle: "at once",
  },
  {
    name: "time",
    label: "Schedule Time",
    type: "time",
    required: true,
    isTime: true,
  },
  {
    name: "month",
    label: "Month",
    type: "text",
    required: true,
  },
  {
    name: "selectedTimeZone",
    label: "Selected Time Zone",
    type: "text",
    required: true,
    shrink: true,
  },
];
