import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import "react-international-phone/style.css";
import countries from "iso-3166-1-alpha-2"; // Importing the countries object from the iso-3166-1-alpha-2 library
import {
  AddCircleOutlineTwoTone,
  CancelTwoTone,
  CloseTwoTone,
  EditTwoTone,
  SaveTwoTone,
} from "@mui/icons-material";
import {
  transformFields,
  isBeyond50Words,
  isDuplicate,
  isValidEmail,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
  handleNumberFieldKeyDown,
  fData,
  onError,
  validatePhoneNumber,
} from "../../config/fieldConfig";
import { COUNTRY_NAME } from "../../config/sessionStorage";
import CustomFieldDialog from "../../common/customFieldDialog";

export default function BeneficiaryForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  onAddForm,
  onEditForm,
  dynamicFormFields,
  handleAddCustomField,
  fields,
  openCustomFieldDialog,
  setOpenCustomFieldDialog,
  allRole,
  roleOptions,
  reportingToOptions,
  setRole,
  apiCall,
  newFields,
  setFields,
  viewData,
  allTableData,
  setViewData,
  roleAccess,
  userIdCheck,
  emailCheck,
  addEmployeeType,
  employeeTypeOptions,
  isEData,
  employeeTypeOptionsWithId,
  selectedRepo,
  setSelectedRepo,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    trigger,
    watch,
    reset,
    clearErrors,
    setFocus,
    formState: { errors },
  } = useForm();

  const [isEligible, setISEligibleChecked] = useState(false); // Default to false for add mode

  const [isAddingEmployeeType, setIsAddingEmployeeType] = useState(false);
  const [newEmployeeType, setNewEmployeeType] = useState("");

  // Create a ref for the phone input field
  const phoneInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // Track if loading
  const country_Name = COUNTRY_NAME();
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "in",
      countries: defaultCountries,
    });

  useEffect(() => {
    if (editFormData && inputRef.current) {
      setValue("mobileNumber", editFormData.mobileNumber);
      inputRef.current.value = editFormData.mobileNumber;

      setIsLoading(false); // Set loading to false after setting initial value
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, editFormData, setValue]);

  useEffect(() => {
    trigger("mobileNumber");

    // Find the country name based on the country code
    const countryEntry = defaultCountries.find(
      (count) => count[1] === country.iso2
    );
    if (!countryEntry) {
      console.log("Country not found");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleCountryChange = (e) => {
    const newCountry = e.target.value;
    const oldDialCode = inputRef.current && inputRef.current.value; // Get the current dial code
    let newDialCode = "";

    defaultCountries.forEach((c) => {
      const country = parseCountry(c);
      if (country.iso2 === newCountry) {
        newDialCode = country ? country.dialCode : "";
        return;
      }
    });

    // Remove the old dial code from the phone number
    let newPhoneNumber = inputValue.replace(`+${oldDialCode}`, "");

    // If the new dial code is not empty, add it to the beginning of the phone number
    if (newDialCode !== "") {
      newPhoneNumber = `+${newDialCode}`;
    }
    // Update the phone number state
    inputRef.current.value = newPhoneNumber;

    // Update the selected country
    setCountry(newCountry);
    setCountry(e.target.value);
    setValue("mobileNumber", newPhoneNumber);
    trigger("mobileNumber");
  };
  const editAccess = roleAccess.some(
    (item) => item.resource === "Beneficiary" && item.writeAccess
  );

  useEffect(() => {
    const foundCountry = defaultCountries.find(
      (country) => country[0] === country_Name
    );

    const countryCode = foundCountry ? foundCountry[2] : "";

    if (mode === "add" && inputRef.current && open) {
      setValue("mobileNumber", `+${countryCode ? countryCode : ""}`);
      // inputRef.current.value = inputValue;
      inputRef.current.value = `+${countryCode ? countryCode : ""}`;
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current, open]);

  useEffect(() => {
    if (mode === "add") {
      setSelectedRepo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    // To reset the form for add and edit dialog
    reset();
    setISEligibleChecked(false);
    setIsAddingEmployeeType(false);

    if (mode === "add") {
      setViewData();
    }

    // Filter fields based on newFields
    const filteredFields = fields.filter((field) => {
      return !newFields.some((newField) => newField.name === field.name);
    });

    setFields(filteredFields);

    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      setRole(editFormData.role);
      apiCall(editFormData.role);

      // Set form data with the provided editFormData for regular fields
      fields.forEach((field) => {
        if (
          field.isCustom && // Check if the field is custom
          editFormData.customFields && // Check if custom fields exist in editFormData
          editFormData.customFields[field.name] !== undefined &&
          editFormData.customFields[field.name] !== null
        ) {
          const customFieldValue = editFormData.customFields[field.name];

          setValue(field.name, customFieldValue);
        } else if (
          editFormData[field.name] !== undefined &&
          editFormData[field.name] !== null
        ) {
          setValue(field.name, editFormData[field.name]);
          setValue("reportingTo", editFormData.reportingToName);
          setValue("role", editFormData.role);
          setValue("mobileNumber", editFormData.mobileNumber);
          // Simulate changing the country to trigger update of inputValue
          if (country_Name) {
            //set the country value, flag and dialCode
            const countryInfo = {
              name: country_Name,
              iso2: countries.getCode(country_Name).toLowerCase(), // Use getCode method to get the country code
            };
            setCountry(countryInfo.iso2); // Passing ISO2 code directly
          } else {
            console.warn(`Country code not found for: ${country_Name}`);
            //  set a default value or handle the case where the country code is not found
            setCountry("in"); // set a default country
          }
          if (inputRef.current) {
            inputRef.current.value = editFormData.mobileNumber;
          }
        }
      });
      // Set the isEligible state based on the value from editFormData
      setISEligibleChecked(editFormData.eligible === true);

      // Set form data with the provided editFormData for dynamic fields
      dynamicFormFields.forEach((field) => {
        const nestedField = editFormData.customFields[field.name];
        setValue(field.name, nestedField);

        // Check if the nestedField is an object and has the property with the same name
        if (nestedField && nestedField.hasOwnProperty(field.name)) {
          setValue(field.name, nestedField[field.name]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormData, editItemId, mode, reset, setValue, open]);

  const onSubmit = async (formData) => {
    const mainFormData = { ...formData }; // Copy all form data to mainFormData
    const customFields = {}; // To store custom fields

    // Loop through the formData entries to find custom fields
    Object.entries(formData).forEach(([key, value]) => {
      const customField =
        fields.find((field) => field.name === key) ||
        dynamicFormFields.find((field) => field.name === key);

      if (customField && customField.isCustom) {
        customFields[key] = {
          [key]: value,
          dataType: customField.type, // Assuming type property in the field object represents dataType
          isMandatory: customField.required, // Assuming required property in the field object represents isMandatory
        };
        // Remove the custom field from mainFormData
        delete mainFormData[key];
      }
    });

    // Embed the customFields object within the mainFormData object
    if (Object.keys(customFields).length > 0) {
      mainFormData.customFields = customFields;
    }

    // Now you have structured mainFormData and customFields objects as per your requirements
    const userWithReportingTo = allTableData.find(
      (user) => user.userName === formData.reportingTo
    );

    const reportingToId = selectedRepo ? selectedRepo : null;

    const reportingToName = userWithReportingTo
      ? userWithReportingTo.userName
      : null;

    // Now you have structured mainFormData and customFields objects as per your requirements
    const userWithReportingTo1 = employeeTypeOptionsWithId.find(
      (employee) => employee.typeValue === formData.employeeType
    );
    if (userWithReportingTo1) {
      mainFormData.employeeTypeId = userWithReportingTo1.id;
    } else {
      mainFormData.employeeTypeId = null;
    }

    // Error handling for allRole
    let roleId = null;
    let roleName = null;
    if (Array.isArray(allRole)) {
      const userWithAllRole = allRole.find(
        (user) => user.roleName === formData.role
      );
      roleId = userWithAllRole ? userWithAllRole.id : null;
      roleName = userWithAllRole ? userWithAllRole.roleName : null;
    } else {
      console.error("allRole is not an array or is not defined properly");
    }

    mainFormData.role = roleId;
    mainFormData.reportingTo = reportingToId;
    // You can now proceed to handle the mainFormData as required, such as sending it to the server

    // Creating the desired object
    const transformedFields = transformFields(customFields);

    // Creating the desired object
    const transformedData = fData(mainFormData);

    // check if the type form is closed
    if (isAddingEmployeeType === false) {
      if (mode === "add") {
        onAddForm(transformedData, transformedFields);
      } else if (mode === "edit") {
        transformedData.id = editItemId;
        transformedData.password = editFormData.password;
        transformedData.organizationName = editFormData.organizationName;
        transformedData.reportingToName = reportingToName;
        transformedData.roleName = roleName;

        onEditForm(transformedData, transformedFields);
      }
      onClose();
    } else {
      setError("addNewEmployeeType", {
        type: "duplicate",
        message: `Please save or close the New Employee Type`,
      });
    }
  };

  const handleAddEmployeeType = () => {
    setIsAddingEmployeeType(true);
    setNewEmployeeType("");
  };

  const handleCancelAdd = () => {
    setIsAddingEmployeeType(false);
    setNewEmployeeType("");
    clearErrors("addNewEmployeeType");
  };

  const handleSaveEmployeeType = async (event) => {
    event.preventDefault(); // Prevent form submission

    const isValid = await trigger("addNewEmployeeType"); // Trigger validation for the field

    if (!isValid) {
      return; // If validation fails, do not proceed
    }

    // Convert all employee types to lowercase for case-insensitive comparison
    const lowerCaseOptions = employeeTypeOptions.map((type) =>
      type.toLowerCase()
    );
    const lowerCaseNewEmployeeType = newEmployeeType.toLowerCase();

    // Check if newEmployeeType already exists (case insensitive)
    if (
      newEmployeeType &&
      lowerCaseOptions.includes(lowerCaseNewEmployeeType)
    ) {
      setError("addNewEmployeeType", {
        type: "duplicate",
        message: `"${newEmployeeType}" already exists`,
      });
      return; // Exit function if it's a duplicate
    }

    // Proceed with adding the new employee type
    const value = { typeValue: newEmployeeType, typeName: "Beneficiary" };
    console.log(value);
    await addEmployeeType(value, setValue);

    // Clear the input and close the adding state after adding
    setNewEmployeeType("");
    setIsAddingEmployeeType(false);
    clearErrors("addNewEmployeeType");
  };

  // Watch startDate, endDate, and renewalDate for changes
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  // Validation function for endDate
  const validateEndDate = (value) => {
    if (value && !startDate)
      return "Start date is required if end date is provided";
    if (startDate && value && new Date(value) < new Date(startDate))
      return "End date must be greater than or equal to start date";
    return true;
  };

  // Validation function for startDate
  const validateStartDate = (value) => {
    if (endDate && !value)
      return "Start date is required if end date is provided";
    if (endDate && value && new Date(value) > new Date(endDate))
      return "Start date must be less than or equal to end date";
    return true;
  };

  // Effect to trigger validation based on changes in startDate, endDate, and renewalDate
  useEffect(() => {
    trigger(["startDate", "endDate"]);
  }, [startDate, endDate, trigger]);

  // console.log("outSide useEffect",errors);
  useEffect(() => {
    if (editItemId && isEData) {
      // console.log("inSide useEffect",errors);
      // Trigger validation for userId and email on error record form load
      trigger(["userId", "email"]).then(() => {
        // Focus on the first field with an error
        const errorFields = Object.keys(errors);
        // console.log("jk",errorFields);
        if (errorFields.length > 0) {
          setFocus(errorFields[0]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, setFocus, errors]);

  const fieldRefs = useRef({});

  return (
    <>
      <Dialog open={open} maxWidth="md">
        <DialogTitle>
          {mode === "add" ? "Add" : viewData && editItemId ? "View" : "Edit"}
          &nbsp;Beneficiary
          {viewData && editAccess ? (
            <IconButton
              aria-label="edit"
              style={{ position: "absolute", top: 10, right: 45 }}
              color="primary"
              title="Edit"
              onClick={() => setViewData(false)}
            >
              <EditTwoTone />
            </IconButton>
          ) : null}
          <IconButton
            aria-label="close"
            title="Close"
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={onClose}
          >
            <CloseTwoTone />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit, (errors) =>
              onError(fields, errors, fieldRefs)
            )}
          >
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid
                  item
                  xs={12}
                  sm={field.isHeading ? 0 : 6}
                  key={index}
                  marginTop={1}
                >
                  {field.name === "eligible" ? (
                    <FormControl
                      error={!!errors[field.name]}
                      component="fieldset"
                      variant="outlined"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={field.name}
                            checked={isEligible} // Use the state variable here
                            onChange={(e) =>
                              setISEligibleChecked(e.target.checked)
                            } // Update the state when the checkbox changes
                            disabled={viewData} // Disable the checkbox based on viewData condition
                          />
                        }
                        label={field.label}
                        required={field.required}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                      />
                      <FormHelperText>
                        {errors[field.name]?.message}
                      </FormHelperText>
                    </FormControl>
                  ) : field.name === "employeeType" ? (
                    isAddingEmployeeType ? (
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <TextField
                            label="Add New Employee Type"
                            name="addNewEmployeeType"
                            variant="outlined"
                            size="small"
                            autoFocus
                            fullWidth
                            required
                            value={newEmployeeType}
                            {...register("addNewEmployeeType", {
                              required: "Add New Employee Type is required",
                              validate: {
                                isEmpty: (value) => isEmpty(value),
                                isValueContainsSplChars: (value) =>
                                  isValueContainsSplChars(value),
                                isValueStartsWithNumber: (value) =>
                                  isValueStartsWithNumber(value, field.label),
                                isDuplicate: (value) => {
                                  if (field.name === "employeeType") {
                                    return isDuplicate(
                                      employeeTypeOptions,
                                      field.label,
                                      value,
                                      mode,
                                      [],
                                      field.name,
                                      isEData
                                    );
                                  } else {
                                    return true;
                                  }
                                },
                              },
                            })}
                            error={!!errors.addNewEmployeeType}
                            helperText={errors.addNewEmployeeType?.message}
                            onChange={(e) => {
                              setNewEmployeeType(e.target.value);
                              setValue("addNewEmployeeType", e.target.value);
                              setError("addNewEmployeeType");
                              trigger("addNewEmployeeType");
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                trigger("addNewEmployeeType");
                              }, 100);
                            }}
                            inputRef={(el) => {
                              if (el) {
                                fieldRefs.current[field.name] = el;
                              }
                            }} // Set ref
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <IconButton
                            aria-label="save"
                            color="success"
                            title="Save"
                            onClick={handleSaveEmployeeType}
                          >
                            <SaveTwoTone />
                          </IconButton>
                          <IconButton
                            aria-label="cancel"
                            color="primary"
                            title="Cancel"
                            onClick={handleCancelAdd}
                          >
                            <CancelTwoTone />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={10}>
                          <Autocomplete
                            name={field.name}
                            options={employeeTypeOptions}
                            value={
                              employeeTypeOptions.includes(watch(field.name))
                                ? watch(field.name)
                                : null
                            }
                            onChange={(event, value) => {
                              setValue(field.name, value);
                            }}
                            style={{
                              pointerEvents:
                                viewData && editItemId ? "none" : "auto",
                            }}
                            onBlur={() => {
                              setTimeout(() => {
                                trigger(field.name);
                              }, 200); // Trigger validation on blur
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={field.label}
                                variant="outlined"
                                required={field.required}
                                size="small"
                                fullWidth
                                InputLabelProps={{
                                  style: {
                                    pointerEvents: "none",
                                  },
                                }}
                                {...register(field.name, {
                                  required: field.required
                                    ? `${field.label} is required`
                                    : false,
                                })}
                                error={
                                  !!errors[field.name] &&
                                  !params.inputProps.value
                                } // Check if field is empty
                                helperText={
                                  errors[field.name]?.message &&
                                  (!params.inputProps.value
                                    ? errors[field.name]?.message
                                    : "")
                                } // Show helper text only when there's an error and field is empty
                                inputRef={(el) => {
                                  if (el) {
                                    fieldRefs.current[field.name] = el;
                                  }
                                }} // Set ref
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          {!viewData && editAccess ? (
                            <IconButton
                              aria-label="add"
                              color="primary"
                              title="Add Field"
                              onClick={handleAddEmployeeType}
                            >
                              <AddCircleOutlineTwoTone />
                            </IconButton>
                          ) : null}
                        </Grid>
                      </Grid>
                    )
                  ) : field.name === "role" || field.name === "reportingTo" ? (
                    <Autocomplete
                      name={field.name}
                      options={
                        field.name === "role" ? roleOptions : reportingToOptions
                      }
                      getOptionLabel={(option) => {
                        if (field.name === "reportingTo") {
                          return option.repoName;
                        } else if (field.name === "role") {
                          return option;
                        }
                      }}
                      value={
                        field.name === "role"
                          ? roleOptions.find(
                              (role) => role === watch(field.name)
                            ) || null
                          : reportingToOptions.find(
                              (option) => option.repoId === selectedRepo
                            ) || null
                      }
                      // Ensure a default null value if no value is selected
                      onChange={(event, value) => {
                        if (field.name === "reportingTo") {
                          if (value) {
                            setSelectedRepo(value.repoId);
                          } else {
                            // Handle the case where value is null
                            setSelectedRepo(null);
                          }
                        } else {
                          setValue(field.name, value);
                        }
                        if (field.name === "role") {
                          setRole(value);
                          apiCall(value);
                        }
                      }}
                      style={{
                        pointerEvents: viewData && editItemId ? "none" : "auto", // Remove hover effect when readOnly
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          trigger(field.name);
                        }, 200); // Trigger validation on blur
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.label}
                          variant="outlined"
                          required={field.required}
                          size="small"
                          fullWidth
                          InputLabelProps={{
                            style: {
                              pointerEvents: "none",
                            },
                          }}
                          {...register(field.name, {
                            required: field.required
                              ? `${field.label} is required`
                              : false,
                          })}
                          error={
                            !!errors[field.name] && !params.inputProps.value
                          } // Check if field is empty
                          helperText={
                            errors[field.name]?.message &&
                            (!params.inputProps.value
                              ? errors[field.name]?.message
                              : "")
                          } // Show helper text only when there's an error and field is empty
                          inputRef={(el) => {
                            if (el) {
                              fieldRefs.current[field.name] = el;
                            }
                          }} // Set ref
                        />
                      )}
                    />
                  ) : field.isHeading ? ( // Check if the current field is a heading
                    <>
                      {field.divider && <Divider className="my-5" />}
                      {/* Render a divider if field.divider is true  */}
                      <Typography variant="h6" gutterBottom>
                        {field.heading}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        {field.subtitle}
                      </Typography>
                    </> // Render heading if it's a heading field
                  ) : (
                    <>
                      <TextField
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        variant="outlined"
                        size="small"
                        multiline={field.multiline}
                        rows={field.rows}
                        autoFocus={
                          editItemId && isEData ? true : field.autoFocus
                        }
                        fullWidth
                        //textarea showing two line
                        sx={
                          field.name === "notes"
                            ? {
                                "& .MuiInputBase-root": {
                                  resize: "vertical", // Ensure resizing is allowed
                                  overflow: "auto", // Ensure overflow is handled properly
                                },
                              }
                            : {}
                        }
                        onWheel={(event) => {
                          // Check if the input type is number to prevent scrolling
                          if (field.type === "number") {
                            event.target.blur();
                          }
                        }}
                        disabled={
                          editItemId && !isEData && field.name === "email"
                        }
                        inputRef={(el) => {
                          // Assign ref conditionally based on the field name
                          if (field.name === "mobileNumber" && phoneInputRef) {
                            phoneInputRef.current = el;
                          }

                          // Also store in fieldRefs for general use
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Combined ref logic
                        autoComplete={field.autoComplete}
                        InputProps={{
                          style: {
                            pointerEvents:
                              viewData && editItemId ? "none" : "auto",
                          }, // remove the hover effect
                          pattern: field.type === "number" ? "\\d*" : undefined,
                          ...(field.name === "mobileNumber"
                            ? {
                                style: {
                                  pointerEvents: viewData ? "none" : "auto",
                                },
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{
                                      marginRight: "2px",
                                      marginLeft: "-8px",
                                    }}
                                  >
                                    <Select
                                      MenuProps={{
                                        style: {
                                          height: "300px",
                                          width: "360px",
                                          top: "10px",
                                          left: "-34px",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                      }}
                                      sx={{
                                        width: "max-content",
                                        fieldset: {
                                          display: "none",
                                        },
                                        '&.Mui-focused:has(div[aria-expanded="false"]) fieldset':
                                          {
                                            border: "none", // Ensure the border stays removed when focused
                                          },
                                        ".MuiSelect-select": {
                                          padding: "8px",
                                          paddingRight: "24px !important",
                                        },
                                        svg: {
                                          right: 0,
                                        },
                                      }}
                                      value={country.iso2}
                                      onChange={handleCountryChange} // Update the phone number when country changes
                                      renderValue={(value) => (
                                        <FlagImage
                                          iso2={value}
                                          style={{ display: "flex" }}
                                        />
                                      )}
                                    >
                                      {defaultCountries.map((c) => {
                                        const country = parseCountry(c);
                                        return (
                                          <MenuItem
                                            key={country.iso2}
                                            value={country.iso2}
                                          >
                                            <FlagImage
                                              iso2={country.iso2}
                                              style={{ marginRight: "8px" }}
                                            />
                                            <Typography marginRight="8px">
                                              {country.name}
                                            </Typography>
                                            <Typography color="gray">
                                              +{country.dialCode}
                                            </Typography>
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </InputAdornment>
                                ),
                                value: !isLoading
                                  ? inputRef.current?.value || ""
                                  : inputValue, // Use loading value or state value
                                onChange: (e) => {
                                  const value = e.target.value;
                                  handlePhoneValueChange(e);
                                  setIsLoading(true); // Set loading to false after setting initial value
                                  setValue(field.name, value); // Update the form value
                                  trigger(field.name); // Trigger validation on change
                                },
                                inputRef: inputRef,
                              }
                            : {}),
                        }}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                          ...(field.type === "email" && {
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email address",
                            },
                          }),
                          validate: {
                            isValidEmail: (value) =>
                              field.name === "email"
                                ? isValidEmail(value)
                                : true,
                            isBeyond50Words: (value) =>
                              field.name === "notes"
                                ? isBeyond50Words(value)
                                : true,
                            isEmpty: (value) =>
                              field.required ? isEmpty(value) : true,
                            isValueContainsSplChars: (value) =>
                              field.type === "text" &&
                              field.type !== "email" &&
                              field.type !== "number"
                                ? isValueContainsSplChars(value)
                                : true,
                            isValueStartsWithNumber: (value) =>
                              field.name === "jobCategory" ||
                              field.name === "territory"
                                ? isValueStartsWithNumber(value, field.label)
                                : true,
                            validatePhoneNumber: (value) =>
                              field.name === "mobileNumber"
                                ? validatePhoneNumber(value, country)
                                : true,
                            isDuplicate: (value) => {
                              if (field.name === "userId") {
                                return isDuplicate(
                                  userIdCheck,
                                  field.label,
                                  value,
                                  mode,
                                  editFormData,
                                  field.name,
                                  isEData
                                );
                              } else if (field.name === "email") {
                                return isDuplicate(
                                  emailCheck,
                                  field.label,
                                  value,
                                  mode,
                                  editFormData,
                                  field.name,
                                  isEData
                                );
                              } else {
                                return true;
                              }
                            },
                            ...(field.name === "endDate"
                              ? { validateEndDate }
                              : {}),
                            ...(field.name === "startDate"
                              ? { validateStartDate }
                              : {}),
                          },
                        })}
                        inputProps={{
                          max: "9999-12-31",
                          style: {
                            textTransform:
                              field.type === "date" ? "uppercase" : "none",
                          },
                          ...(field.type === "number"
                            ? { min: 1, step: 1 }
                            : {}),
                        }}
                        InputLabelProps={{
                          shrink: field.type === "date" ? true : field.shrink,
                          style: {
                            pointerEvents: "none",
                          },
                        }}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        onChange={(e) => {
                          setValue(field.name, e.target.value); // Update the form value
                          trigger(field.name); // Trigger validation on change
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            trigger(field.name);
                          }, 200); // Trigger validation on blur
                        }}
                        onKeyDown={(e) => {
                          if (field.type === "number") {
                            handleNumberFieldKeyDown(e);
                          }
                        }}
                      />
                      {!editItemId && field.name === "email" && (
                        <div style={{ fontSize: "12px", color: "#6c757d" }}>
                          User credentials will be sent to this email.
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              ))}
              {dynamicFormFields.map((field, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={`dynamic-${index}`}
                  marginTop={1}
                >
                  <TextField
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    InputProps={{
                      style: {
                        pointerEvents: viewData && editItemId ? "none" : "auto",
                      }, // remove the hover effect
                      inputProps: {
                        max: field.type === "date" ? "9999-12-31" : undefined,
                        style: {
                          textTransform:
                            field.type === "date" ? "uppercase" : "none",
                        },
                      },
                      pattern: field.type === "number" ? "\\d*" : undefined,
                    }}
                    InputLabelProps={{
                      shrink: field.type === "date" ? true : field.shrink,
                      style: {
                        pointerEvents: "none",
                      },
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      ...(field.type === "email" && {
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Invalid email address",
                        },
                      }),
                      validate: {
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        isValueContainsSplChars: (value) =>
                          field.type === "text" &&
                          field.type !== "number" &&
                          field.type !== "email"
                            ? isValueContainsSplChars(value)
                            : true,
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name);
                      }, 200); // Trigger validation on blur
                    }}
                    onKeyDown={(e) => {
                      if (field.type === "number") {
                        console.log(field.type);
                        handleNumberFieldKeyDown(e);
                      }
                    }}
                    inputRef={(el) => {
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Set ref
                  />
                </Grid>
              ))}
            </Grid>
            {viewData && editItemId ? null : (
              <DialogActions className="mt-3">
                <Button
                  onClick={() => setOpenCustomFieldDialog(true)}
                  variant="outlined"
                  color="primary"
                >
                  Add Custom Field
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  {mode === "add" ? "Submit" : "Update"}
                </Button>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Box>
        </DialogContent>

        {/* Custom Field Dialog */}
        <CustomFieldDialog
          open={openCustomFieldDialog}
          onClose={() => setOpenCustomFieldDialog(false)}
          onAddCustomField={handleAddCustomField}
        />
      </Dialog>
    </>
  );
}
