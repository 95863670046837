import { useEffect, useRef, useState } from "react";
import {
  PAGE_OPTIONS,
  addSerialNumber,
  handleApiCall,
} from "../config/fieldConfig";
import ApiService from "../../apis/ApiService";
import {
  IS_SUPER_ADMIN,
  IS_MONITOR,
  JWT_TOKEN,
  ROLE_ACCESS,
} from "../config/sessionStorage";
import Swal from "sweetalert2";
import { CustomNavigate } from "../config/navigation";
import { FormFields } from "../pages/Ticket/formFields";
import { resourceComponents } from "../../router";
import toast from "react-hot-toast";
import { IMAGE } from "../config/toastMessage";

export const TicketService = () => {
  // select the screen
  const [selectedOption, setSelectedOption] = useState(PAGE_OPTIONS[0]);
  const [token, setToken] = useState(null);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // show the logo in screen
  const [beneficiariesOption, setBeneficiariesOption] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const { navigateError403 } = CustomNavigate();
  const fileInputRef = useRef(null);
  const [viewData, setViewData] = useState(true); // view purpose only
  const [selectedUser, setSelectedUser] = useState(null);
  const roleAccess = ROLE_ACCESS();
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const stopRemount = useRef(true);
  const superAdmin = IS_SUPER_ADMIN();
  const isSuperAdmin = superAdmin === "false" ? false : true;

  const monitor = IS_MONITOR();
  const isMonitor = monitor === "false" ? false : true;

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);
    try {
      const ticketData = await ApiService.getTickets(TOKEN); //config values

      let extractedData;
      if (isSuperAdmin || isMonitor) {
        setData(ticketData);
      } else {
        const allBenRes = await ApiService.getBeneficiariesWithHierarchy(TOKEN);

        extractedData = allBenRes.map((item) => ({
          userName: `${item.userId} - ${item.userName}`,
          id: item.userId,
          name: item.userName,
        }));
        // Merge the userName into ticketData based on userId
        const mergedTicketData = ticketData.map((ticket) => {
          // Find the corresponding user in extractedData based on userId
          const user = extractedData.find((item) => item.id === ticket.userId);
          // If user found, add userName to ticket, else leave it as is

          return {
            ...ticket,
            userName: user ? user.name : "", // Handle if no user found
          };
        });
        setData(mergedTicketData);
      }
      setBeneficiariesOption(extractedData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // Apply the formatting and update table data
  const rows = addSerialNumber(data);

  const handleEdit = (id, viewData) => async () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id);
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    setSelectedUser(rowData.userId);
    // setSelectedUser(rowData.userName);
    const iconImage = rowData.byteImage;
    // Construct the base64 image URL
    const logo = `data:image/*;base64,${iconImage}`;
    setSelectedImage(logo);
    setViewData(viewData);
  };
  // Create a new object for filtered resourceComponents
  const filteredResourceComponents = Object.keys(
    resourceComponents || {}
  ).reduce((acc, key) => {
    // Find the corresponding roleAccess item for the current resource key
    const resourceAccess = roleAccess.find((item) => item.resource === key);

    // If menuManagement is true, add the resource to the accumulator
    if (resourceAccess && resourceAccess.menuManagement) {
      acc[key] = resourceComponents[key];
    }

    return acc;
  }, {});

  // Create an array of resource names
  let resourceNames;
  if (isSuperAdmin || isMonitor) {
    // Create an array of resource names
    resourceNames = Object.keys(resourceComponents || {});
  } else {
    // Create an array of resource names
    resourceNames = Object.keys(filteredResourceComponents || {});
  }

  const handleFileChangeLogo = (e) => {
    // Check if the event and file input are valid
    if (e && e.target && e.target.files && e.target.files.length > 0) {
      // Get the selected file
      const logoFile = e.target.files[0];

      // Check if the selected file is an image
      if (logoFile.type.startsWith("image/")) {
        // Create a new FileReader object to read the file content
        const reader = new FileReader();

        // Callback function executed when file reading is completed
        reader.onload = function (event) {
          // Get the base64-encoded content of the file
          const base64Content = event.target.result;
          setSelectedImage(base64Content);

          // Reset file input after processing
          if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        };

        // Read the content of the selected file as a data URL (base64-encoded)
        reader.readAsDataURL(logoFile);
      } else {
        toast.error(IMAGE);
      }
    } else {
      console.error("Invalid file input event:", e);
    }
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.log("File input ref does not exist"); // Debugging log
    }
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addTicket,
        edit: ApiService.updateTicket,
        delete: ApiService.deleteTicket,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(
          action === "delete" ? formData.id : formData,
          token,
          selectedImage
        ),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this ticket?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  const fields = FormFields;

  return {
    fields,
    PAGE_OPTIONS,
    stopRemount,
    setSelectedOption,
    selectedOption,
    roleAccess,
    rows,
    onAddForm,
    onEditForm,
    handleEdit,
    handleDelete,
    editFormData,
    editFormOpen,
    editItemId,
    setEditFormOpen,
    selectedImage,
    handleFileChangeLogo,
    handleAvatarClick,
    fileInputRef,
    resourceNames,
    beneficiariesOption,
    beneficiaries,
    setBeneficiaries,
    viewData,
    setViewData,
    selectedUser,
    setSelectedUser,
    setSelectedImage,
    loading,
    isSuperAdmin,
    isMonitor,
  };
};
