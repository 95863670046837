import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import ScheduleColumns from "./columns";
import { ScheduleService } from "../../service/scheduleService";
import ScheduleToolbar from "./toolbar";
import ScheduleForm from "./scheduleForm";

export default function Schedule() {
  const {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    fields,
    onAddForm,
    onEditForm,
    handleEdit,
    setEditFormOpen,
    handleDelete,
    open,
    addFormOpen,
    addFormClose,
    roleAccess,
    selectedZoneTime,
    loading,
  } = ScheduleService();

  const columns = ScheduleColumns(handleEdit, handleDelete, roleAccess);

  return (
    <>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: () => (
              <ScheduleToolbar
                addFormOpen={addFormOpen}
                roleAccess={roleAccess}
              />
            ),
            pagination: CustomPagination,
          }}
          autoHeight={Object.keys(rows).length >= 10}
          initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          loading={loading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
          }}
        />

        <ScheduleForm
          open={editFormOpen || open}
          onClose={editFormOpen ? () => setEditFormOpen(false) : addFormClose}
          mode={editFormOpen ? "edit" : "add"}
          editItemId={editFormOpen ? editItemId : undefined}
          editFormData={editFormOpen ? editFormData : undefined}
          onEditForm={editFormOpen ? onEditForm : undefined}
          onAddForm={!editFormOpen ? onAddForm : undefined}
          fields={fields}
          selectedZoneTime={selectedZoneTime}
        />
      </Box>
    </>
  );
}
