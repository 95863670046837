import { Box, IconButton, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  DeleteTwoTone,
  DownloadingTwoTone,
  EditTwoTone,
} from "@mui/icons-material";
import { CustomTooltip, formatDate } from "../../config/fieldConfig";

const AssignCommissionColumns = (
  handleEdit,
  handleDelete,
  roleAccess,
  handlePdfDownload
) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "AssignCommission" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "beneficiaryName",
      headerName: "Beneficiaries",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div
            style={{
              cursor: "pointer",
              textDecoration: "underline", // Optional: Add underline to text
            }}
            title={row.beneficiaryName} // Add the title attribute for the tooltip
            onClick={handleEdit(row.id, true)}
          >
            {row.beneficiaryName}
          </div>
        );
      },
    },
    {
      field: "commission",
      headerName: "Commission",
      width: 250,
      // Custom sort comparator
      sortComparator: (v1, v2) => {
        // Convert to strings if they are arrays
        const value1 = Array.isArray(v1) ? v1.join(", ") : v1;
        const value2 = Array.isArray(v2) ? v2.join(", ") : v2;

        // Use localeCompare for string comparison
        return value1.localeCompare(value2);
      },
      renderCell: ({ row }) => {
        // Join commission names with newline characters for default tooltip
        const commissionString = Array.isArray(row.commission)
          ? row.commission.join(", ")
          : row.commission;

        const tooltipText = Array.isArray(row.commission)
          ? row.commission.join("\n") // Join with newline characters for default tooltip
          : commissionString || "No Commission";

        return (
          <Typography
            variant="body2"
            gutterBottom
            title={tooltipText} // Use title for default tooltip
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {commissionString || "No Commission"}
          </Typography>
        );
      },
    },
    {
      field: "adjustments",
      headerName: "Adjustments",
      width: 250,
      sortComparator: (v1, v2) => {
        // Convert arrays to strings for comparison
        const value1 = Array.isArray(v1)
          ? v1
              .map((adjustment) => `${adjustment.sDate} - ${adjustment.eDate}`)
              .join(", ")
          : "";
        const value2 = Array.isArray(v2)
          ? v2
              .map((adjustment) => `${adjustment.sDate} - ${adjustment.eDate}`)
              .join(", ")
          : "";
        return value1.localeCompare(value2);
      },
      renderCell: (params) => {
        const { value } = params;
        if (Array.isArray(value) && value.length > 0) {
          const tooltipContent = (
            <Box
              sx={{
                "& table": {
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid black", // Outer table border
                },
                "& th, & td": {
                  border: "1px solid #ddd", // Inner cell borders
                  padding: "8px",
                  textAlign: "left",
                  maxWidth: "200px",
                  overflow: "hidden",
                },
                "& th": {
                  backgroundColor: "#f4f4f4",
                  fontWeight: "bold",
                },
                "& thead th, & tbody td": {
                  borderTop: "1px solid black", // Ensure top border is black
                  borderLeft: "1px solid black", // Ensure left border is black
                },
                "& tbody tr:last-child td": {
                  borderBottom: "1px solid black", // Ensure bottom border is black
                },
                "& tbody td:last-child, & thead th:last-child": {
                  borderRight: "1px solid black", // Ensure right border is black
                },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Adjustments</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {value.map((adjustment, index) => {
                    const { sDate, eDate, ...rest } = adjustment;
                    return (
                      <tr key={index}>
                        <td>
                          {Object.entries(rest)
                            .map(([key, val]) => `${key}: ${val}`)
                            .join(", ")}
                        </td>
                        <td>{formatDate(sDate)}</td>
                        <td>{formatDate(eDate)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          );
          return (
            <CustomTooltip content={tooltipContent}>
              <Typography
                variant="body2"
                gutterBottom
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                  cursor: "pointer",
                }}
              >
                {value
                  .map((adjustment) => {
                    const { sDate, eDate, ...rest } = adjustment;
                    return Object.entries(rest)
                      .map(([key, val]) => `${key}: ${val}`)
                      .join(", ");
                  })
                  .join(" | ")}
              </Typography>
            </CustomTooltip>
          );
        }

        return "";
      },
    },
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 150,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "AssignCommission" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "AssignCommission" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={editAccess ? handleEdit(id) : undefined}
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }
        return actions;
      },
    },
    {
      field: "downloadPDF",
      headerName: "Download PDF",
      align: "center",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <IconButton
          aria-label="download PDF"
          color="success"
          title="Download Pdf"
          onClick={() => handlePdfDownload(params)} // Assuming you have a function to handle PDF download
        >
          <DownloadingTwoTone />
        </IconButton>
      ),
    },
  ];
};

export default AssignCommissionColumns;
