import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CloseTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import { COMM_PERC_ERROR, SPLIT_ENTITY_ERROR } from "../../config/toastMessage";
import { handleNumberFieldKeyDown } from "../../config/fieldConfig";

export default function SplitForm({
  open,
  onClose,
  splitData,
  onSplitData,
  transactionEntity,
  setSplitChecked,
}) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    trigger,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      dynamicFields: [{ inputValue: "", selectedValue: "" }],
    },
  });

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: "dynamicFields",
  });
  useEffect(() => {
    const defaultFields = [{ inputValue: "", selectedValue: "" }];

    const fieldsToSet =
      Object.keys(splitData).length > 0
        ? Object.keys(splitData).map((key) => ({
            inputValue: splitData[key],
            selectedValue: key,
          }))
        : defaultFields;

    // Ensure there's at least one default row
    if (fieldsToSet.length === 0) {
      fieldsToSet.push({ inputValue: "", selectedValue: "" });
    }
    setValue("dynamicFields", fieldsToSet);
    reset({ dynamicFields: fieldsToSet });
  }, [splitData, setValue, reset]);

  useEffect(() => {
    reset(); // Reset the form with the default and dynamic fields
  }, [onClose, reset]);

  const onSubmit = (formData) => {
    const filteredData = formData.dynamicFields.filter(
      (field) => field.inputValue !== "" && field.selectedValue !== ""
    );
    const formattedData = filteredData.reduce((acc, field) => {
      acc[field.selectedValue] = field.inputValue;
      return acc;
    }, {});
    onSplitData(formattedData);
    onClose(false);
    if (Object.keys(formattedData).length === 0) {
      setSplitChecked(false);
    }
  };
  const getFilteredOptions = (currentIndex) => {
    const selectedValues = fields
      .filter((field, index) => index !== currentIndex && field.selectedValue)
      .map((field) => field.selectedValue);

    const filteredOptions = transactionEntity.filter(
      (option) => !selectedValues.includes(option)
    );

    return filteredOptions;
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>
        Split Commission
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={() => {
            onClose();
            if (Object.keys(splitData).length === 0) {
              setSplitChecked(false);
            }
          }}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          {/* for split */}
          {fields.map((field, setIndex) => (
            <Grid container spacing={2} key={setIndex} pb={1}>
              <Grid item xs={12} sm={4.2} marginTop={1}>
                <Autocomplete
                  fullWidth
                  options={getFilteredOptions(setIndex)} // Pass the filtered options
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name={`dynamicFields[${setIndex}].selectedValue`}
                      label="Split Entity"
                      variant="outlined"
                      size="small"
                      required={
                        !!watch(`dynamicFields[${setIndex}].inputValue`)
                      }
                      {...register(`dynamicFields[${setIndex}].selectedValue`, {
                        validate: (value) => {
                          const inputValue = watch(
                            `dynamicFields[${setIndex}].inputValue`
                          );
                          const selectedValue = watch(
                            `dynamicFields[${setIndex}].selectedValue`
                          );

                          if ((!inputValue || !selectedValue) && !value) {
                            if (
                              setIndex === 0 &&
                              !inputValue &&
                              !selectedValue
                            ) {
                              console.log(setIndex);
                            } else {
                              return SPLIT_ENTITY_ERROR;
                            }
                          }
                          return true; // Pass validation if the row is completely empty
                        },
                        required: !!watch(
                          `dynamicFields[${setIndex}].inputValue`
                        )
                          ? SPLIT_ENTITY_ERROR
                          : false,
                      })}
                      error={!!errors?.dynamicFields?.[setIndex]?.selectedValue}
                      helperText={
                        errors?.dynamicFields?.[setIndex]?.selectedValue
                          ?.message
                      }
                    />
                  )}
                  value={field.selectedValue || ""}
                  onChange={(event, value) => {
                    const newFields = [...fields];
                    newFields[setIndex].selectedValue = value;
                    setValue(`dynamicFields[${setIndex}].selectedValue`, value); // Set the value
                    trigger(`dynamicFields[${setIndex}].selectedValue`); // Trigger revalidation
                    setValue("dynamicFields", newFields);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} marginTop={1}>
                <TextField
                  name={`dynamicFields[${setIndex}].inputValue`}
                  label="Comm Percentage"
                  type="number"
                  required={!!watch(`dynamicFields[${setIndex}].selectedValue`)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (`dynamicFields[${setIndex}].inputValue`) {
                      console.log(field.type);
                      handleNumberFieldKeyDown(e);
                    }
                  }}
                  {...register(`dynamicFields[${setIndex}].inputValue`, {
                    validate: (value) => {
                      const inputValue = watch(
                        `dynamicFields[${setIndex}].inputValue`
                      );
                      const selectedValue = watch(
                        `dynamicFields[${setIndex}].selectedValue`
                      );
                      if ((!inputValue || !selectedValue) && !value) {
                        if (setIndex === 0 && !inputValue && !selectedValue) {
                          console.log(setIndex);
                        } else {
                          return COMM_PERC_ERROR;
                        }
                      }
                      return true; // Pass validation if the row is completely empty
                    },
                    required: !!watch(
                      `dynamicFields[${setIndex}].selectedValue`
                    )
                      ? COMM_PERC_ERROR
                      : false,
                  })}
                  error={!!errors?.dynamicFields?.[setIndex]?.inputValue}
                  helperText={
                    errors?.dynamicFields?.[setIndex]?.inputValue?.message
                  }
                  value={field.inputValue}
                  onChange={(e) => {
                    const newFields = [...fields];
                    newFields[setIndex].inputValue = e.target.value;
                    setValue(
                      `dynamicFields[${setIndex}].inputValue`,
                      e.target.value
                    ); // Set the value
                    trigger(`dynamicFields[${setIndex}].inputValue`); // Trigger revalidation
                    setValue("dynamicFields", newFields);
                  }}
                  inputProps={{ min: 1, step: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input[type=number]": {
                      appearance: "textfield", // Remove the default appearance
                      MozAppearance: "textfield", // Remove for Firefox
                      WebkitAppearance: "none", // Remove for Webkit browsers
                      "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                        {
                          display: "none", // Hide webkit spin buttons
                        },
                    },
                  }}
                />
              </Grid>
              <Grid item marginTop={1} xs={12} sm={2.6}>
                <IconButton
                  onClick={async () => {
                    const isValid = await trigger(); // Validate all fields
                    // Check if any row is completely empty
                    const isAnyRowEmpty = fields.some((_, index) => {
                      const inputValue = watch(
                        `dynamicFields[${index}].inputValue`
                      );
                      const selectedValue = watch(
                        `dynamicFields[${index}].selectedValue`
                      );
                      return !inputValue && !selectedValue;
                    });

                    if (isValid && !isAnyRowEmpty) {
                      insert(fields.length, {
                        inputValue: "",
                        selectedValue: "",
                      });
                    } else {
                      const inputValue = watch(
                        `dynamicFields[${setIndex}].inputValue`
                      );
                      const selectedValue = watch(
                        `dynamicFields[${setIndex}].selectedValue`
                      );

                      if (inputValue === "") {
                        setError(`dynamicFields[${setIndex}].inputValue`, {
                          type: "manual",
                          message: COMM_PERC_ERROR,
                        });
                      }
                      if (selectedValue === "") {
                        setError(`dynamicFields[${setIndex}].selectedValue`, {
                          type: "manual",
                          message: SPLIT_ENTITY_ERROR,
                        });
                      }
                      console.log(
                        "Please fill in all fields for each row before adding a new one."
                      );
                    }
                  }}
                  color="success"
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (fields.length > 1) {
                      // Clear errors first, then remove the row
                      clearErrors(`dynamicFields[${setIndex}]`);
                      remove(setIndex);
                    } else {
                      // Clear the row by updating it and clear errors
                      update(setIndex, { inputValue: "", selectedValue: "" });
                      clearErrors(`dynamicFields[${setIndex}]`);
                    }
                  }}
                  color="error"
                >
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <DialogActions className="mt-3">
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {splitData ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
