import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import clm from "country-locale-map";
import { LoadingButton } from "@mui/lab";
import { Vortex } from "react-loader-spinner";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material";
import Copyright from "../../common/Copyright";
import { encryptPwd, getPasswordErrorMessage } from "../../config/fieldConfig";
import { onSignUp } from "../../service/outerPagesService";
import { fields } from "./formFields";
import ApiService from "../../../apis/ApiService";
import useFetchOnMount from "../../config/useFetchOnMount";
import { resourceComponents } from "../../../router";

export default function SignUp() {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  // Show Password Start
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Currency Options
  const [options, setOptions] = useState([]);

  // email value
  const [email, setEmail] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [vToken, setVToken] = useState("");

  const [orgId, setOrgId] = useState("");
  const [userName, setUserName] = useState("");

  // Loading Button
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);

  const handleTogglePassword = () => setShowPassword((show) => !show);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  let navigate = useNavigate();

  const fetchData = async () => {
    try {
      const verifyToken = window.location.hash.slice(1);

      if (verifyToken) {
        // Perform token verification
        const res = await ApiService.verifiedToken(verifyToken);
        setVToken(verifyToken);
        console.log(res);

        if (
          res?.status === 200 &&
          res?.data?.status === "valid" &&
          res?.data?.email !== null &&
          res?.data?.country !== null &&
          res?.data?.subscriptionPlan !== null
        ) {
          setEmail(res.data.email);
          setSubscriptionPlan(res.data.subscriptionPlan);
          setOrgId(res.data.organizationId);
          setUserName(res.data.userName);

          const currencyValue = clm.getCurrencyByName(res.data.country);
          const validCurrencyOptions = [currencyValue].filter(
            (option) => option !== undefined
          );
          // Set options
          setOptions(validCurrencyOptions);
          setValue("currency", currencyValue);

          navigate("/sign_up");
        } else if (
          res?.status === 200 &&
          res?.data?.status === "Token Already verified"
        ) {
          navigate("/token_verified");
        } else {
          navigate("/access_denied");
        }
      } else {
        navigate("/access_denied");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShow(false);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  // Create an array of resource names
  const resourceNames = Object.keys(resourceComponents);

  // Admin Access
  const initialTableData = resourceNames.map((resource) => {
    return {
      resource,
      menuManagement: true,
      readAccess: true,
      writeAccess: true,
      deleteAccess: true,
    };
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Encrypt the password
      const encryptedPassword = encryptPwd(data.password);

      // Modify the data object
      data.password = encryptedPassword;
      data.confirmPwd = "";

      delete data.confirmPassword;

      // Prepare combined data
      const combinedData = { roleName: 1, userRoleAccess: initialTableData };

      // Call onSignUp
      await onSignUp(
        data,
        navigate,
        vToken,
        setLoading,
        combinedData,
        orgId,
        userName
      );
    } catch (error) {
      console.error("Error during form submission:", error);
      // Optionally, you can set an error state here to display a message to the user
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  return (
    <>
      {show && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
            zIndex: 9999, // Ensure it's above other content
          }}
        >
          <Vortex
            visible={true}
            height="80"
            width="80"
            ariaLabel="vortex-loading"
            wrapperStyle={{}}
            wrapperClass="vortex-wrapper"
            colors={["red", "green", "blue", "yellow", "orange", "purple"]}
          />
        </Box>
      )}

      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 7,
            py: 5,
            marginTop: 2,
            marginBottom: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ":hover": { boxShadow: 5 },
          }}
        >
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          <Alert severity="info" color="warning" sx={{ m: 1 }}>
            Please do not refresh the page until the signup completed.
          </Alert>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {fields.map((field) =>
              // Check if the field name is "currency"
              field.name === "currency" ? (
                <Autocomplete
                  key={field.name}
                  options={options}
                  value={
                    options.includes(watch(field.name))
                      ? watch(field.name)
                      : null
                  }
                  onChange={(event, newValue) => {
                    if (field.name === "currency") {
                      if (options.includes(newValue)) {
                        setValue("currency", newValue);
                        clearErrors("currency");
                      }
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      trigger(field.name); // Trigger validation on blur
                    }, 200);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      size="small"
                      required={field.required}
                      fullWidth
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                      })}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                    />
                  )}
                />
              ) : field.name === "userId" ||
                field.name === "subscriptionPlan" ? (
                <TextField
                  key={field.name}
                  margin="normal"
                  required={field.required}
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  value={
                    field.name === "userId" ? email || "" : subscriptionPlan
                  }
                  disabled
                />
              ) : (
                <TextField
                  key={field.name}
                  margin="normal"
                  required={field.required}
                  fullWidth
                  size="small"
                  label={field.label}
                  name={field.name}
                  autoComplete="off"
                  type={
                    field.name === "password"
                      ? showPassword
                        ? "text"
                        : "password"
                      : field.name === "confirmPassword"
                      ? showConfirmPassword
                        ? "text"
                        : "password"
                      : field.type
                  }
                  onWheel={(event) => {
                    // Check if the input type is number to prevent scrolling
                    if (field.type === "number") {
                      event.target.blur();
                    }
                  }}
                  inputProps={{
                    ...(field.type === "number" ? { min: 1, step: 1 } : {}),
                  }}
                  InputProps={
                    field.name === "password" ||
                    field.name === "confirmPassword"
                      ? {
                          endAdornment: (
                            <IconButton
                              aria-label={`toggle ${field.label} visibility`}
                              onClick={
                                field.name === "password"
                                  ? handleTogglePassword
                                  : handleToggleConfirmPassword
                              }
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                            >
                              {field.name === "password" ? (
                                showPassword ? (
                                  <VisibilityOffTwoTone />
                                ) : (
                                  <VisibilityTwoTone />
                                )
                              ) : showConfirmPassword ? (
                                <VisibilityOffTwoTone />
                              ) : (
                                <VisibilityTwoTone />
                              )}
                            </IconButton>
                          ),
                        }
                      : null
                  }
                  {...register(field.name, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    validate:
                      field.name === "password"
                        ? (value) => getPasswordErrorMessage(value)
                        : field.name === "confirmPassword"
                        ? (value) =>
                            value === getValues("password") ||
                            "Passwords do not match"
                        : null,
                    min: field.type === "number" && {
                      value: 1,
                      message: `${field.label} must be at least 1`,
                    },
                  })}
                  error={!!errors[field.name]}
                  helperText={errors[field.name]?.message}
                  onChange={(e) => {
                    setValue(field.name, e.target.value); // Update the form value
                    trigger(field.name); // Trigger validation on change
                    if (field.name === "password") {
                      // Revalidate confirmPassword if password changes
                      trigger("confirmPassword");
                    }
                  }}
                  onBlur={() => {
                    setTimeout(() => {
                      trigger(field.name); // Trigger validation on blur
                    }, 200);
                  }}
                />
              )
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Link href="/landing#pricing" target="_blank" variant="body2">
                View subscription plans
              </Link>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  {...register("terms", { required: true })}
                  color="primary"
                />
              }
              required
              onBlur={() => {
                setTimeout(() => {
                  trigger("terms"); // Trigger validation on blur
                }, 200);
              }}
              label={
                <Box component={"span"}>
                  I accept the{" "}
                  <Link href="/terms_conditions" target="_blank">
                    Terms &amp; Conditions
                  </Link>
                </Box>
              }
            />
            {errors.terms && (
              <FormHelperText error>
                Please accept the terms &amp; conditions
              </FormHelperText>
            )}

            <LoadingButton
              variant="contained"
              type="submit"
              fullWidth
              loading={loading}
              loadingPosition="start"
              startIcon={<></>}
              style={{
                backgroundColor: loading ? "#448aff" : undefined,
                color: loading ? "#fff" : undefined,
              }}
              sx={{ my: 1 }}
            >
              {!loading ? "Complete Registration" : "Loading..."}
            </LoadingButton>
          </Box>

          <Copyright sx={{ mt: 4, mb: 2 }} />
        </Box>
      </Container>
    </>
  );
}
