import { Link } from "react-router-dom";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";

export default function DesktopMenu({
  menuItems,
  currentPath,
  handleOpenMenu,
  currentMenu,
  anchorEl,
  handleCloseMenu,
}) {
  return (
    <>
      {menuItems
        .filter(
          (menu) =>
            menu.isAccess !== false &&
            (menu.children === null ||
              (menu.children &&
                menu.children.some((child) => child.isAccess !== false)))
        )
        .map((menu) => {
          const hasAccessibleChildren =
            menu.children &&
            menu.children.some((child) => child.isAccess !== false);

          const isActive =
            currentPath === menu.link ||
            (menu.children &&
              menu.children.some((child) => currentPath === child.link));

          return (
            <Box key={menu.text} style={{ marginRight: "10px" }}>
              {(menu.children === null || hasAccessibleChildren) && (
                <>
                  {menu.children ? (
                    <>
                      {hasAccessibleChildren && (
                        <Button
                          onClick={(event) => handleOpenMenu(event, menu)}
                          aria-controls={menu.text}
                          aria-haspopup="true"
                          style={{
                            color: "white", // Highlight active menu
                            fontWeight: isActive ? "bold" : "normal",
                          }}
                        >
                          {menu.text}
                        </Button>
                      )}

                      {currentMenu && currentMenu.text === menu.text && (
                        <Menu
                          id={menu.text}
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                        >
                          {menu.children
                            .filter((child) => child.isAccess !== false)
                            .map((child) => (
                              <Link
                                key={child.text}
                                to={child.link}
                                style={{ textDecoration: "none" }}
                              >
                                <MenuItem
                                  onClick={handleCloseMenu}
                                  sx={{
                                    // "#c5cae9"
                                    backgroundColor:
                                      currentPath === child.link
                                        ? "#e8eaf6"
                                        : "inherit",
                                    "&:hover": {
                                      backgroundColor:
                                        currentPath === "#f5f5f5",
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="inherit"
                                    noWrap
                                    color={
                                      currentPath === child.link
                                        ? "secondary"
                                        : "inherit"
                                    }
                                  >
                                    {child.text}
                                  </Typography>
                                </MenuItem>
                              </Link>
                            ))}
                        </Menu>
                      )}
                    </>
                  ) : (
                    <Link to={menu.link} style={{ textDecoration: "none" }}>
                      <Button
                        style={{
                          color: "white", // Highlight active menu
                          fontWeight: isActive ? "bold" : "normal",
                        }}
                      >
                        {menu.text}
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </Box>
          );
        })}
    </>
  );
}
