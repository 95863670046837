import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";
import {
  isBeyond50Words,
  isDuplicateWithCaseSensitive,
  isEmpty,
  isValueContainsSplChars,
  onError,
} from "../../config/fieldConfig";

const scopeOptions = ["Within a commission", "Across the commission"];

export default function VariableForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  onEditForm,
  onAddForm,
  fields,
  variableData,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  // Watch changes in the variableName field and update the variable field accordingly
  const variableName = watch("variableName");

  useEffect(() => {
    setValue("variable", variableName || "");
  }, [variableName, setValue]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    reset();
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Set form data with the provided editFormData
      fields.forEach((field) => {
        setValue(field.name, editFormData[field.name]);
      });
    }
  }, [editFormData, editItemId, fields, mode, reset, setValue, open]);

  const onSubmit = (formData) => {
    // Append the `$` symbol to the variable field if it exists
    if (formData.variableName) {
      formData.variable = `$${formData.variableName}`;
    }

    if (mode === "add") {
      onAddForm(formData);
    } else if (mode === "edit") {
      formData.id = editItemId;
      onEditForm(formData);
    }
    onClose();
  };

  const fieldRefs = useRef({});

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"}
        &nbsp;Variable
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit, (errors) =>
            onError(fields, errors, fieldRefs)
          )}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={12} key={index} marginTop={1}>
                {field.name === "scope" ? (
                  <Autocomplete
                    name={field.name}
                    options={scopeOptions}
                    value={
                      scopeOptions.includes(watch(field.name))
                        ? watch(field.name)
                        : null
                    }
                    // Ensure a default null value if no value is selected
                    onChange={(event, value) => {
                      setValue(field.name, value);
                    }} // Update the form value on change
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name); // Trigger validation on blur
                      }, 200);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.label}
                        variant="outlined"
                        required={field.required}
                        size="small"
                        fullWidth
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        error={!!errors[field.name] && !params.inputProps.value} // Check if field is empty
                        helperText={
                          errors[field.name]?.message &&
                          (!params.inputProps.value
                            ? errors[field.name]?.message
                            : "")
                        } // Show helper text only when there's an error and field is empty
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Set ref
                      />
                    )}
                  />
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    required={field.required}
                    rows={field.rows} // Minimum 3 rows
                    multiline={field.multiline}
                    autoFocus={field.autoFocus}
                    fullWidth
                    onWheel={(event) => {
                      // Check if the input type is number to prevent scrolling
                      if (field.type === "number") {
                        event.target.blur();
                      }
                    }}
                    InputLabelProps={
                      field.name === "variable"
                        ? { shrink: true, style: { pointerEvents: "none" } }
                        : {}
                    }
                    InputProps={{
                      startAdornment:
                        field.name === "variable" ? (
                          <InputAdornment position="start">$</InputAdornment>
                        ) : null,
                      readOnly: field.name === "variable" ? true : false,
                      style: {
                        pointerEvents:
                          field.name === "variable" ? "none" : "auto",
                      }, // remove the hover effect
                    }}
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      validate: {
                        isBeyond50Words: (value) =>
                          field.name === "description"
                            ? isBeyond50Words(value)
                            : true,
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        isDuplicate: (value) => {
                          const result =
                            field.name === "variableName"
                              ? isDuplicateWithCaseSensitive(
                                  value,
                                  variableData,
                                  field.name,
                                  editItemId
                                )
                              : true;
                          return result === true ? true : result;
                        },
                        isValueContainsSplChars: (value) =>
                          isValueContainsSplChars(value),
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        trigger(field.name); // Trigger validation on blur
                      }, 200);
                    }}
                    inputRef={(el) => {
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Set ref
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <DialogActions className="mt-3">
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
