export const fields = [
  {
    label: "Email ID",
    name: "email",
    type: "email",
    autoComplete: "on",
    required: true,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    autoComplete: "off",
    required: true,
  },
];
