import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import _ from "lodash";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import {
  BENEFICIARY_SCREEN,
  CUSTOMER_SCREEN,
  isOrder_Beneficiary,
  isOrder_Customer,
  isOrder_Product,
  isOrder_Transaction,
  mandatoryKey,
  normalizeString,
  PAGE_OPTIONS,
  PRODUCT_SCREEN,
  TRANSACTION_SCREEN,
} from "../config/fieldConfig";
import {
  EMPTY_FILE,
  FIELD_DELETE_CONFIRM,
  FIELD_MAP_CONFIRM,
  FIELD_MAP_FAILED1,
  FIELD_MAP_FAILED2,
  FILE_TYPE_ERROR,
  FILE_TYPE_XLXS,
  FILE_UPLOAD_ADD,
} from "../config/toastMessage";

export const FieldMappingService = () => {
  const [token, setToken] = useState(null);
  const [data, setData] = useState([]);
  const [items, setItems] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [ref, setRef] = useState({});
  const [pojoField, setPojoField] = useState();
  const [response, setResponse] = useState([]);
  const [fieldContents, setFieldContents] = useState({});
  const [pojoKey, setPojoKey] = useState([]);
  // Add custom fields
  const [inputFields, setInputFields] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [customFieldsValues, setCustomFieldsValues] = useState([]);
  const [isHidden, setIsHidden] = useState(true);
  const [isData, setIsData] = useState(false);
  const [custKeys, setCustKeys] = useState([]);

  const [nonKey, setNonKey] = useState([]);
  // show the page is edit or not
  const [isEdit, setIsEdit] = useState(true);
  const [isUploaded, setIsUploaded] = useState(true);
  // select the screen
  const [selectedOption, setSelectedOption] = useState(PAGE_OPTIONS[0]);
  const [originalConfig, setOriginalConfig] = useState([]);

  const [currentOrders, setCurrentOrders] = useState([]); // fields order
  const [isCustMandatory, setIsCustMandatory] = useState([]); // fields order
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [loadLeftSideData, setLoadLeftSideData] = useState(false); // loading for left side

  const roleAccess = ROLE_ACCESS();

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      handleDropdownChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  //reading the file heading
  const handleFileUpload = async (e) => {
    try {
      const result = await ApiService.getMappingsByPojo(selectedOption, token); //config values
      let combinedResult;
      let items;

      let res;

      // Check if the result is an array and has the expected structure
      if (
        Array.isArray(result) &&
        result.length > 0 &&
        result[0].customFields
      ) {
        combinedResult = transformToObject(
          result[0].customFields,
          result[0].mappingValue
        );
        res = combinedResult.transformed;
      }
      // Check if the result is an object with the expected structure
      else if (typeof result === "object" && !Array.isArray(result)) {
        // Transform the object as per your needs
        res = transformToObjectFromObject(result);
      } else {
        throw new Error("Unexpected data structure");
      }

      // Check if the result is an array and has the expected structure
      if (
        Array.isArray(result) &&
        result.length > 0 &&
        result[0].customFields
      ) {
        combinedResult = transformToObject(
          result[0].customFields,
          result[0].mappingValue
        );
        items = combinedResult.transformed;
      }
      // Check if the result is an object with the expected structure
      else if (typeof result === "object" && !Array.isArray(result)) {
        // Transform the object as per your needs
        items = transformToObjectFromObject(result);
      } else {
        throw new Error("Unexpected data structure");
      }

      const file = e.target.files[0];
      let fileType;
      switch (file.type) {
        case FILE_TYPE_XLXS:
          fileType = "xlsx";
          break;
        case "text/csv":
          fileType = "csv";
          break;
        default:
          toast.error(FILE_TYPE_ERROR);
          fileInputRef.current.value = ""; // Reset the file input
          return;
      }
      setSelectedFile(file);
      setSelectedFileType(fileType);
      if (file && fileType) {
        const reader = new FileReader(); //Create a new FileReader object to read the contents of the file
        reader.onload = (e) => {
          let numericValue;

          try {
            const binaryData = e.target.result; // Get binary data from the file
            const workbook = XLSX.read(binaryData, { type: "binary" }); // Use the XLSX library to read the binary data
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Assuming the first row contains the headings
            const firstRow = XLSX.utils.decode_range(sheet["!ref"]).s.r;
            const headings = Object.keys(sheet).filter((cell) => {
              return (
                sheet[cell].v !== undefined &&
                XLSX.utils.decode_cell(cell).r === firstRow
              );
            });

            let Heading = headings.map((cell) => sheet[cell].v);
            Heading = Heading.map((item) => item.trim());

            // Filter out the numeric value
            numericValue = Heading.filter(
              (item) => typeof item === "number"
            )[0];

            // Transform items that contain only numbers and spaces to numeric values
            const transformedHeading = Heading.map((item) => {
              // Remove spaces and check if the remaining string is numeric
              if (/^\d+(\s\d+)*$/.test(item) || /^\d/.test(item)) {
                // Convert to number, preserving original spacing format
                return parseFloat(item.replace(/\s/g, "")) || /^\d/.test(item);
              } else {
                return item;
              }
            });

            // Filter out the numeric value
            numericValue = transformedHeading.filter(
              (item) => typeof item === "number"
            )[0];

            // Filter out strings containing only numbers
            const filteredHeading = transformedHeading.filter(
              (item) => !/^\d+$/.test(item.replace(/\s/g, ""))
            );

            const tempHeading = filteredHeading.filter(
              (item) => item.trim() !== "" && !/^\d+$/.test(item)
            );

            // Define a regular expression to match any special character except _ and -
            const specialCharRegex = /[^a-zA-Z0-9\s_-]/;

            // Use filter to extract strings with special characters
            const specialCharValue = Heading.filter((heading) =>
              specialCharRegex.test(heading)
            );

            // Check if any of these strings are invalid and should trigger an error
            if (specialCharValue.length !== 0) {
              throw new Error(
                `${specialCharValue.join(
                  ", "
                )} cannot contain special characters.`
              );
            }

            // Normalize and check for duplicates, considering variations
            const duplicateCheck = {};
            const duplicateValues = []; // Initialize an array to collect duplicate values

            for (let i = 0; i < Heading.length; i++) {
              const heading = Heading[i]
                .trim()
                .toLowerCase()
                .replace(/\s/g, ""); // Normalize and remove spaces

              if (duplicateCheck[heading]) {
                duplicateValues.push(Heading[i]); // Add the original casing to the array
              } else {
                duplicateCheck[heading] = true;
              }
            }

            if (duplicateValues.length > 0) {
              const duplicatesString = duplicateValues.join(", "); // Join the duplicate values into a string
              throw new Error(`${duplicatesString} are duplicates.`);
            }

            let commonKeys;
            if (isEdit) {
              // Find common keys
              commonKeys = tempHeading.filter((heading) =>
                Object.keys(res).some(
                  // Object.keys(ref).some(
                  (key) =>
                    key.toLowerCase() === heading.toLowerCase() ||
                    key.toLowerCase() ===
                      heading.replace(/\s/g, "").toLowerCase()
                )
              );
            } else {
              // Find common keys
              commonKeys = tempHeading.filter((heading) =>
                Object.keys(items).some(
                  (key) =>
                    key.toLowerCase() === heading.toLowerCase() ||
                    key.toLowerCase() ===
                      heading.replace(/\s/g, "").toLowerCase()
                )
              );
            }

            // Create non-matching keys object
            const nonMatchingKeysObject = Object.fromEntries(
              tempHeading
                .filter((heading) => !commonKeys.includes(heading))
                .map((key) => [key, ""]) // Assuming default value for non-matching fields
            );

            const nonMatchingKeyrs = Object.keys(nonMatchingKeysObject);

            let commonKeysObjectWithoutSpaces;

            let nonMatchingFields;

            if (isEdit) {
              commonKeysObjectWithoutSpaces = Object.fromEntries(
                Object.keys(res)
                  // Object.keys(ref)
                  .filter((key) =>
                    tempHeading.some(
                      (heading) =>
                        heading.toLowerCase() === key.toLowerCase() ||
                        heading.replace(/\s/g, "").toLowerCase() ===
                          key.toLowerCase()
                    )
                  )
                  .map((key) => [
                    key,
                    tempHeading.find(
                      (heading) =>
                        heading.toLowerCase() === key.toLowerCase() ||
                        heading.replace(/\s/g, "").toLowerCase() ===
                          key.toLowerCase()
                    ) || "",
                  ])
              );
              // Iterate over keys in items
              for (const key in res) {
                // If the value is empty or undefined in items, replace it with the value from commonKeysObjectWithoutSpaces
                if (res[key] === "" || res[key] === undefined) {
                  res[key] = commonKeysObjectWithoutSpaces[key];
                }
              }

              // // Iterate over keys in items
              // for (const key in ref) {
              //   // If the value is empty or undefined in items, replace it with the value from commonKeysObjectWithoutSpaces
              //   if (ref[key] === "" || ref[key] === undefined) {
              //     ref[key] = commonKeysObjectWithoutSpaces[key];
              //   }
              // }
            } else {
              commonKeysObjectWithoutSpaces = Object.fromEntries(
                Object.keys(items)
                  .filter((key) =>
                    tempHeading.some(
                      (heading) =>
                        heading.toLowerCase() === key.toLowerCase() ||
                        heading.replace(/\s/g, "").toLowerCase() ===
                          key.toLowerCase()
                    )
                  )
                  .map((key) => [
                    key,
                    tempHeading.find(
                      (heading) =>
                        heading.toLowerCase() === key.toLowerCase() ||
                        heading.replace(/\s/g, "").toLowerCase() ===
                          key.toLowerCase()
                    ) || "",
                  ])
              );

              // Iterate over keys in items
              for (const key in items) {
                // If the value is empty or undefined in items, replace it with the value from commonKeysObjectWithoutSpaces
                if (items[key] === "" || items[key] === undefined) {
                  items[key] = commonKeysObjectWithoutSpaces[key];
                }
              }

              // Matching header mapped
              for (const key in items) {
                if (commonKeysObjectWithoutSpaces.hasOwnProperty(key)) {
                  items[key] = commonKeysObjectWithoutSpaces[key];
                }
              }

              // Iterate over the keys of the items object
              for (const key in items) {
                // Check if the key does not exist in the commonKeysObjectWithoutSpaces
                if (!(key in commonKeysObjectWithoutSpaces)) {
                  // Set the value of the key to an empty string
                  items[key] = "";
                }
              }
            }

            // Helper function to normalize field names
            if (headings.length > 0) {
              let mergedObject;
              if (isEdit) {
                // Merge the objects
                mergedObject = { ...res };
                // mergedObject = { ...ref };
              } else {
                mergedObject = { ...items };
              }

              // Remove all undefined fields except those in the response and pojoKey array
              const filteredObject = Object.fromEntries(
                Object.entries(mergedObject).filter(
                  ([key, value]) =>
                    value !== undefined ||
                    response.includes(key) ||
                    pojoKey.includes(key)
                )
              );

              // Filter out undefined values
              const value = Object.values(items).filter(
                (item) => item !== undefined
              );

              // Normalize function to remove spaces and convert to lowercase
              const normalize = (str) => str.toLowerCase().replace(/\s/g, "");

              // Normalize the values in the value array
              const normalizedValue = value.map(normalize);
              // Filter non-matching fields based on normalized values
              nonMatchingFields = tempHeading.filter((field) => {
                const normalizedField = normalize(field);
                return !normalizedValue.includes(normalizedField);
              });

              // Filter out the non-matching fields
              const filteredObject_1 = Object.fromEntries(
                Object.entries(filteredObject).filter(([key, value]) =>
                  nonMatchingKeyrs.includes(key)
                )
              );

              // Filter out the non-matching fields same file uplolad
              const filteredObj = Object.fromEntries(
                Object.entries(filteredObject).filter(
                  ([key, value]) =>
                    !response.includes(key) && !pojoKey.includes(key)
                )
              );

              // Filter out the matching fields
              const filteredObjs = Object.fromEntries(
                Object.entries(filteredObject).filter(
                  ([key, value]) =>
                    response.includes(key) || pojoKey.includes(key)
                )
              );

              if (
                Object.keys(filteredObject_1).length === 0 &&
                Object.keys(filteredObj).length !== 0
              ) {
                if (isEdit) {
                  // Iterate over the keys of items
                  for (let key in res) {
                    // for (let key in ref) {
                    // Check if the key exists in filteredObj and update its value to empty string if it exists
                    if (filteredObj.hasOwnProperty(key)) {
                      filteredObj[key] = "";
                    }
                  }
                } else {
                  // Iterate over the keys of items
                  for (let key in items) {
                    // Check if the key exists in filteredObj and update its value to empty string if it exists
                    if (filteredObj.hasOwnProperty(items)) {
                      filteredObj[key] = "";
                    }
                  }
                }

                // Find intersection of keys
                const matchingKeys = Object.keys({
                  ...filteredObjs,
                  ...filteredObj,
                });

                // Create a new object with only matching keys
                const filteredCombinedObj = Object.fromEntries(
                  matchingKeys.map((key) => [key, filteredObjs[key]])
                );

                Swal.fire({
                  text: FIELD_MAP_CONFIRM,
                  icon: "warning",
                  showCancelButton: true,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  confirmButtonText: "Yes",
                  cancelButtonText: "No",
                  width: "30%",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setItems(filteredCombinedObj);
                    setData(nonMatchingFields);
                  }
                });
              } else {
                if (isEdit) {
                  toast.success(FILE_UPLOAD_ADD);
                  setItems(filteredObject);
                  setData(nonMatchingKeyrs); // non matching
                  setFieldContents(commonKeysObjectWithoutSpaces);
                } else {
                  Swal.fire({
                    text: FIELD_MAP_CONFIRM,
                    icon: "warning",
                    showCancelButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    width: "30%",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setData(nonMatchingFields);
                      setItems(filteredObject);
                    }
                  });
                }
              }
            } else {
              setData([]);
              toast.error("The fields are irrelevant to the master column");
            }
          } catch (error) {
            if (error.message.startsWith(EMPTY_FILE)) {
              toast.error("File is empty");
            } else if (numericValue) {
              toast.error(` ${numericValue} can't be numeric value`);
            } else {
              toast.error(`${error.message}`);
            }
          }
        };
        reader.readAsBinaryString(file);
        fileInputRef.current.value = ""; // Reset the file input
      }
    } catch (error) {
      toast.error("An error occurred while processing file data: " + error);
    }
  };

  const transformToObject = (customFields, mappingValue) => {
    // Step 1: Get the keys where isMandatory is true
    const mandatoryKeys = Object.keys(customFields).filter(
      (key) => customFields[key].isMandatory
    );

    // Step 2: Create a transformed object
    const transformed = {};

    // Add keys from customFields with their values as their keys
    Object.keys(customFields).forEach((key) => {
      transformed[key] = customFields[key][key]; // Accessing the inner key
    });

    // Add keys from mappingValue with their original values
    Object.keys(mappingValue).forEach((key) => {
      transformed[key] = mappingValue[key];
    });
    return { mandatoryKeys, transformed };
  };

  // This function is to handle the case when result is an object
  const transformToObjectFromObject = (mappingValue) => {
    const transformed = {};

    // Add keys from mappingValue with their original values
    Object.keys(mappingValue).forEach((key) => {
      transformed[key] = mappingValue[key];
    });

    return transformed;
  };

  //download the Uploaded Template
  const downloadUploadedTemplate = async () => {
    try {
      // Fetch the file from the API
      const response = await ApiService.downloadMappingTemplate(
        token,
        selectedOption
      );
      // Handle Blob data
      if (response && response.data instanceof Blob) {
        const blob = response.data;
        // Function to trigger the download
        const downloadFile = (blob, fileName) => {
          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;

          // Append the link to the body (it needs to be in the DOM to work)
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Clean up by removing the link and revoking the Blob URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        };

        // Trigger the download
        downloadFile(blob, `${selectedOption}.xlsx`);
      } else {
        console.error(response);
      }
    } catch (error) {
      toast.error(`The file ${selectedOption}.xlsx could not be found.`);
    }
  };

  //getMappingsByPojo while selectedOption change
  const handleDropdownChange = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);
    setData([]);
    try {
      const result = await ApiService.getMappingsByPojo(selectedOption, TOKEN); //config values
      setOriginalConfig(result);
      let combinedResult;
      let res;

      // Check if the result is an array and has the expected structure
      if (
        Array.isArray(result) &&
        result.length > 0 &&
        result[0].customFields
      ) {
        combinedResult = transformToObject(
          result[0].customFields,
          result[0].mappingValue
        );
        res = combinedResult.transformed;
      }
      // Check if the result is an object with the expected structure
      else if (typeof result === "object" && !Array.isArray(result)) {
        // Transform the object as per your needs
        res = transformToObjectFromObject(result);
      } else {
        throw new Error("Unexpected data structure");
      }

      if (!res.code) {
        const pojoRes = await ApiService.getFieldsForPojo(selectedOption); //pojo
        setPojoField(pojoRes);

        const keysArray = Object.keys(pojoRes);
        setPojoKey(keysArray);

        // Function to get non-matching keys from res
        function getNonMatchingKeys(obj1, obj2) {
          return Object.keys(obj1).filter(
            (key) => !Object.keys(obj2).includes(key)
          );
        }
        // Extract keys from `res` that are not present in `pojoRes`
        const diffKeys = Object.keys(res).filter((key) => !(key in pojoRes));
        setCustKeys(diffKeys);

        // Get non-matching keys from res
        const nonMatchingKeys = getNonMatchingKeys(res, pojoRes);
        setResponse(nonMatchingKeys);

        // Check if PojoClass has any non-empty values
        const Editing = Object.values(res).some((value) => value !== "");

        const getHeadingsWithNoValues = (headings, res) => {
          // Normalize the headings
          const normalizedHeadings = headings.map(normalizeString);

          const normalizedResKeys = Object.values(res).map((key) =>
            key.toLowerCase().replace(/\s+/g, "")
          );
          // Find headings not in res
          const missingHeadings = normalizedHeadings.filter(
            (heading) => !normalizedResKeys.includes(heading.toLowerCase())
          );

          // Find headings that correspond to empty values in `res`
          const matchedHeadings = missingHeadings
            .map((key) => {
              const normalizedKey = normalizeString(key);
              const index = normalizedHeadings.indexOf(normalizedKey);
              return index !== -1 ? headings[index] : null;
            })
            .filter((heading) => heading !== null);

          return matchedHeadings;
        };

        let headingsWithNoValues = [];
        try {
          // Fetch the file from the API for showing the fields in right side
          const response = await ApiService.downloadMappingTemplate(
            TOKEN,
            selectedOption
          );

          // Check if the response is a Blob
          if (response.data instanceof Blob) {
            const blob = response.data;

            // Convert Blob to ArrayBuffer
            const arrayBuffer = await blob.arrayBuffer();

            // Create a workbook from the ArrayBuffer
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            // Extract the first sheet
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON format
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Extract and log the headers (first row)
            let headers = data[0];
            // Filter out empty or whitespace-only headers
            headers = headers.filter((header) => header.trim() !== "");
            if (headers) {
              headingsWithNoValues = getHeadingsWithNoValues(headers, res);
              if (Editing) {
                setData(headingsWithNoValues);
                // Convert the array into an object with keys and empty string values
                const headingsObject = headingsWithNoValues.reduce(
                  (acc, key) => {
                    acc[key] = "";
                    return acc;
                  },
                  {}
                );
                setIsUploaded(true);
                setItems(headingsObject);
              }
            } else {
              console.log("Headings could not be retrieved.");
            }
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          setIsUploaded(false);
          console.error("Error during file read:", error);
        }
        if (Editing) {
          setIsEdit(false);
          setItems(res);
          setRef(res);
          setFieldContents({});
          setIsCustMandatory(combinedResult.mandatoryKeys);
        } else {
          setIsEdit(true);
          setItems(res);
          setFieldContents(res);
          setRef(res);
          setCustomFieldsValues(customFieldsValues);
          setIsCustMandatory(combinedResult.mandatoryKeys);
        }
        setLoadLeftSideData(false);
        // setIsUploaded(true);
      } else if (res.code === "ERR_NETWORK") {
        toast.error(res.message);
      }
    } catch (error) {
      setLoadLeftSideData(false);
      setIsUploaded(false);
      console.log(error);
    }
  };

  const handleDragStart = (event, key, dropDataValue) => {
    setIsData(dropDataValue);
    event.dataTransfer.setData("text/plain", key);
    event.dataTransfer.setData("source", "allowed-source"); // Mark the source
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (event, fieldKey, index) => {
    try {
      event.preventDefault();
      let droppedItem = event.dataTransfer.getData("text/plain");
      const source = event.dataTransfer.getData("source");
      const fileValue = data.find((item) => item === droppedItem);
      if (source) {
        if (fileValue) {
          const isDroppedItemPresent =
            Object.values(fieldContents).includes(droppedItem);
          if (isDroppedItemPresent) {
            // Function to check if droppedItem is a key in fieldContents
            function isDroppedItemInFieldContents(droppedItem, fieldContents) {
              const keys = Object.keys(fieldContents);
              return keys.includes(droppedItem);
            }

            // Check if droppedItem is a key in fieldContents
            const isDroppedItemInContents = isDroppedItemInFieldContents(
              droppedItem,
              fieldContents
            );

            if (isDroppedItemInContents) {
              if (items[fieldKey] === "") {
                setFieldContents({
                  ...fieldContents,
                  [fieldKey]: droppedItem,
                });
                setItems({
                  ...items,
                  [fieldKey]: droppedItem,
                });
                setData(data.filter((item) => item !== droppedItem));
              } else {
                const errorMessage = isDroppedItemPresent && `"${droppedItem}"`;
                toast.error(`"${errorMessage}" is already mapped`);
              }
            } else {
              const normalizedPojoKey = pojoKey.map((key) =>
                key.toLowerCase().replace(/\s/g, "")
              );
              const normalizedKey = droppedItem
                .toLowerCase()
                .replace(/\s/g, "");
              if (
                (normalizedPojoKey.includes(normalizedKey) ||
                  nonKey.includes(droppedItem)) &&
                fieldKey === ""
              ) {
                toast.error(`"${droppedItem}" is already exist`);
              } else {
                if (droppedItem) {
                  setFieldContents({
                    ...fieldContents,
                    [fieldKey]: droppedItem,
                  });
                  setItems({
                    ...items,
                    [fieldKey]: droppedItem,
                  });
                  setData(data.filter((item) => item !== droppedItem));
                }
              }
            }
          } else {
            if (droppedItem) {
              if (!items[droppedItem]) {
                // Check if sourceKey is included in items values
                const sourceKeyIncluded =
                  Object.values(items).includes(droppedItem);
                if (!sourceKeyIncluded) {
                  if (fieldKey) {
                    setFieldContents({
                      ...fieldContents,
                      [fieldKey]: droppedItem,
                    });
                    setItems({
                      ...items,
                      [fieldKey]: droppedItem,
                    });
                    setData(data.filter((item) => item !== droppedItem));
                  } else {
                    const normalizedPojoKey = pojoKey.map((key) =>
                      key.toLowerCase().replace(/\s/g, "")
                    );
                    const normalizedKey = droppedItem
                      .toLowerCase()
                      .replace(/\s/g, "");
                    if (normalizedPojoKey.includes(normalizedKey) === false) {
                      setItems((prevItems) => {
                        // Convert the items object to an array of [key, value] pairs
                        const itemsArray = Object.entries(prevItems);

                        // Find the index of the empty key
                        const emptyKeyIndex = itemsArray.findIndex(
                          ([key]) => key === ""
                        );

                        // Create a new array with the dropped item in place of the empty key
                        const updatedItemsArray = itemsArray.map(
                          ([key, value], index) => {
                            if (index === emptyKeyIndex) {
                              return [droppedItem, droppedItem]; // Place droppedItem in the empty key position
                            }
                            return [key, value];
                          }
                        );

                        // If no empty key was found, append the dropped item to the end
                        if (emptyKeyIndex === -1) {
                          updatedItemsArray.push([droppedItem, droppedItem]);
                        }

                        // Rebuild the object from the updated array of entries
                        const updatedItems =
                          Object.fromEntries(updatedItemsArray);
                        setItems(updatedItems);
                        // Update the nonKey state
                        setNonKey((prevNonKey) => [...prevNonKey, droppedItem]);
                        // Remove the droppedItem from the data if it exists
                        setData((prevData) =>
                          prevData.filter((item) => item !== droppedItem)
                        );
                        return updatedItems;
                      });
                    } else {
                      toast.error(`"${droppedItem}" is already exist`);
                    }
                  }
                } else {
                  const normalizedPojoKey = pojoKey.map((key) =>
                    key.toLowerCase().replace(/\s/g, "")
                  );
                  const normalizedKey = droppedItem
                    .toLowerCase()
                    .replace(/\s/g, "");
                  if (
                    (normalizedPojoKey.includes(normalizedKey) === true ||
                      nonKey.includes(normalizedKey) === true) &&
                    fieldKey === ""
                  ) {
                    // toast.error(`${fieldContents[droppedItem]} is already exist`)
                    toast.error(`"${droppedItem}" is already exist`);
                  } else {
                    if (items[fieldKey] === "") {
                      setItems((prevItems) => {
                        // Convert the items object to an array of [key, value] pairs
                        const itemsArray = Object.entries(prevItems);

                        // Find the index of the empty key
                        const emptyKeyIndex = itemsArray.findIndex(
                          ([key]) => key === ""
                        );

                        // Create a new array with the dropped item in place of the empty key
                        const updatedItemsArray = itemsArray.map(
                          ([key, value], index) => {
                            if (index === emptyKeyIndex) {
                              return [droppedItem, droppedItem]; // Place droppedItem in the empty key position
                            }
                            return [key, value];
                          }
                        );

                        // If no empty key was found, append the dropped item to the end
                        if (emptyKeyIndex === -1) {
                          updatedItemsArray.push([droppedItem, droppedItem]);
                        }

                        // Rebuild the object from the updated array of entries
                        const updatedItems =
                          Object.fromEntries(updatedItemsArray);

                        // setFieldContents(updatedItems)
                        setItems(updatedItems);
                        // Update the nonKey state
                        setNonKey((prevNonKey) => [...prevNonKey, droppedItem]);

                        // Remove the droppedItem from the data if it exists
                        setData((prevData) =>
                          prevData.filter((item) => item !== droppedItem)
                        );
                        return updatedItems;
                      });
                    } else {
                      setItems({
                        ...items,
                        [fieldKey]: droppedItem,
                      });
                      setFieldContents({
                        ...fieldContents,
                        [fieldKey]: droppedItem,
                      });
                      setData(data.filter((item) => item !== droppedItem));
                    }
                  }
                }
              } else {
                if (fieldContents[droppedItem] && items[droppedItem]) {
                  if (isEdit) {
                    const normalizedPojoKey = pojoKey.map((key) =>
                      key.toLowerCase().replace(/\s/g, "")
                    );
                    const normalizedKey = fieldContents[droppedItem]
                      .toLowerCase()
                      .replace(/\s/g, "");
                    if (
                      (normalizedPojoKey.includes(normalizedKey) === true ||
                        nonKey.includes(normalizedKey) === true) &&
                      fieldKey === ""
                    ) {
                      // toast.error(`${fieldContents[droppedItem]} is exist`)
                      toast.error(`"${droppedItem}" is already exist`);
                    } else {
                      if (data.includes(fieldContents[droppedItem])) {
                        setItems({
                          ...items,
                          [fieldKey]: droppedItem,
                        });
                        setFieldContents({
                          ...fieldContents,
                          [fieldKey]: droppedItem,
                        });
                        setData(data.filter((item) => item !== droppedItem));
                      } else {
                        if (isData) {
                          setItems({
                            ...items,
                            [fieldKey]: droppedItem,
                          });
                          setFieldContents({
                            ...fieldContents,
                            [fieldKey]: droppedItem,
                          });
                          setData(data.filter((item) => item !== droppedItem));
                        } else {
                          setItems({
                            ...items,
                            [fieldKey]: items[droppedItem],
                            [droppedItem]: "",
                          });
                          setFieldContents({
                            ...fieldContents,
                            [fieldKey]: fieldContents[droppedItem],
                            [droppedItem]: "",
                          });
                        }
                      }
                    }
                  } else {
                    if (data.includes(items[droppedItem])) {
                      setItems({
                        ...items,
                        [fieldKey]: droppedItem,
                      });
                      setFieldContents({
                        ...fieldContents,
                        [fieldKey]: droppedItem,
                      });
                      setData(data.filter((item) => item !== droppedItem));
                    } else {
                      if (isData) {
                        setItems({
                          ...items,
                          [fieldKey]: droppedItem,
                        });
                        setFieldContents({
                          ...fieldContents,
                          [fieldKey]: droppedItem,
                        });
                        setData(data.filter((item) => item !== droppedItem));
                      } else {
                        setItems({
                          ...items,
                          [fieldKey]: items[droppedItem],
                          [droppedItem]: "",
                        });
                        setFieldContents({
                          ...fieldContents,
                          [fieldKey]: fieldContents[droppedItem],
                          [droppedItem]: "",
                        });
                      }
                    }
                  }
                } else if (!fieldContents[droppedItem]) {
                  if (isData) {
                    if (nonKey.includes(droppedItem) && fieldKey === "") {
                      toast.error("field is already exist");
                    } else {
                      setItems({
                        ...items,
                        [fieldKey]: droppedItem,
                      });
                      setFieldContents({
                        ...fieldContents,
                        [fieldKey]: droppedItem,
                      });
                      setData(data.filter((item) => item !== droppedItem));
                    }
                  } else {
                    const normalizedPojoKey = pojoKey.map((key) =>
                      key.toLowerCase().replace(/\s/g, "")
                    );
                    const normalizedKey = items[droppedItem]
                      .toLowerCase()
                      .replace(/\s/g, "");
                    if (
                      normalizedPojoKey.includes(normalizedKey) === false &&
                      fieldKey === ""
                    ) {
                      if (nonKey.includes(items[droppedItem]) === false) {
                        setItems((prevItems) => {
                          // Convert the items object to an array of [key, value] pairs
                          const itemsArray = Object.entries(prevItems);

                          // Find the index of the empty key
                          const emptyKeyIndex = itemsArray.findIndex(
                            ([key]) => key === ""
                          );

                          // Create a new array with the dropped item in place of the empty key
                          const updatedItemsArray = itemsArray.map(
                            ([key, value], index) => {
                              if (index === emptyKeyIndex) {
                                return [items[droppedItem], items[droppedItem]]; // Place droppedItem in the empty key position
                              }
                              return [key, value];
                            }
                          );

                          // If no empty key was found, append the dropped item to the end
                          if (emptyKeyIndex === -1) {
                            updatedItemsArray.push([
                              items[droppedItem],
                              items[droppedItem],
                            ]);
                          }

                          // Rebuild the object from the updated array of entries
                          const updatedItems =
                            Object.fromEntries(updatedItemsArray);
                          setItems(updatedItems);
                          updatedItems[droppedItem] = "";
                          // Update the nonKey state
                          setNonKey((prevNonKey) => [
                            ...prevNonKey,
                            items[droppedItem],
                          ]);
                          return updatedItems;
                        });
                      } else {
                        toast.error(`"${items[droppedItem]}" is already exist`);
                      }
                    } else if (
                      normalizedPojoKey.includes(normalizedKey) &&
                      fieldKey === ""
                    ) {
                      toast.error(`"${items[droppedItem]}" is already exist`);
                    } else {
                      setItems({
                        ...items,
                        [fieldKey]: items[droppedItem],
                        [droppedItem]: "",
                      });
                      setFieldContents({
                        ...fieldContents,
                        [fieldKey]: fieldContents[droppedItem],
                        [droppedItem]: "",
                      });
                    }
                  }
                }
              }
            }
          }
        } else {
          // Normalize the pojoKey array
          const normalizedPojoKey = pojoKey.map((key) =>
            key.toLowerCase().replace(/\s/g, "")
          );
          const normalizedKey = items[droppedItem]
            .toLowerCase()
            .replace(/\s/g, "");
          setNonKey([...nonKey, items[droppedItem]]);
          // duplicate label check right to left
          if (nonKey.find((n) => n === droppedItem)) {
            if (nonKey.includes(droppedItem) && fieldKey !== "") {
              if (items[fieldKey] === "") {
                setItems((prevItems) => {
                  // Create a new items object to avoid mutating the state directly
                  const updatedItems = { ...prevItems };

                  updatedItems[fieldKey] = updatedItems[droppedItem];

                  // Set the dropped item to an empty string
                  updatedItems[droppedItem] = "";
                  setFieldContents(updatedItems);
                  return updatedItems;
                });
              } else {
                // Update the items state
                setItems((prevItems) => {
                  // Create a new items object to avoid mutating the state directly
                  const updatedItems = { ...prevItems };
                  const temp = updatedItems[fieldKey];
                  updatedItems[fieldKey] = updatedItems[droppedItem]; // Set the dropped item to an empty string
                  updatedItems[droppedItem] = temp;
                  setFieldContents(updatedItems);
                  return updatedItems;
                });
              }
            } else {
              const fromKey = fieldKey; // Swaps 'Role' and the empty key
              const toKey = droppedItem;
              setItems((prevItems) => {
                // Convert items to an array of entries
                const itemsArray = Object.entries(prevItems);

                // Find the indexes of the keys to be swapped
                const fromIndex = itemsArray.findIndex(
                  ([key]) => key === fromKey
                );
                const toIndex = itemsArray.findIndex(([key]) => key === toKey);

                // Swap the elements if valid indexes are found
                if (fromIndex >= 0 && toIndex >= 0) {
                  [itemsArray[fromIndex], itemsArray[toIndex]] = [
                    itemsArray[toIndex],
                    itemsArray[fromIndex],
                  ];
                }

                // Convert the array back to an object
                const updatedItems = Object.fromEntries(itemsArray);

                return updatedItems;
              });
            }
          }
          // duplicate label check up to down
          else if (normalizedPojoKey.find((n) => n !== normalizedKey)) {
            // && nonKey.includes(items[droppedItem]) === false
            if (
              normalizedPojoKey.includes(normalizedKey) === false &&
              fieldKey === ""
            ) {
              if (nonKey.includes(items[droppedItem]) === false) {
                if (isEdit) {
                  setItems((prevItems) => {
                    // Convert the items object to an array of [key, value] pairs
                    const itemsArray = Object.entries(prevItems);

                    // Find the index of the empty key
                    const emptyKeyIndex = itemsArray.findIndex(
                      ([key]) => key === ""
                    );

                    // Create a new array with the dropped item in place of the empty key
                    const updatedItemsArray = itemsArray.map(
                      ([key, value], index) => {
                        if (index === emptyKeyIndex) {
                          return [
                            fieldContents[droppedItem],
                            fieldContents[droppedItem],
                          ]; // Place droppedItem in the empty key position
                        }
                        return [key, value];
                      }
                    );
                    // If no empty key was found, append the dropped item to the end

                    if (emptyKeyIndex === -1) {
                      updatedItemsArray.push([
                        fieldContents[droppedItem],
                        fieldContents[droppedItem],
                      ]);
                    }
                    if (emptyKeyIndex === -1) {
                      updatedItemsArray.push([
                        items[droppedItem],
                        items[droppedItem],
                      ]);
                    }
                    // Rebuild the object from the updated array of entries
                    const updatedItems = Object.fromEntries(updatedItemsArray);

                    setItems(updatedItems);
                    setFieldContents(updatedItems);
                    updatedItems[droppedItem] = "";
                    // Update the nonKey state
                    setNonKey((prevNonKey) => [
                      ...prevNonKey,
                      fieldContents[droppedItem],
                    ]);
                    return updatedItems;
                  });
                } else {
                  setItems((prevItems) => {
                    // Convert the items object to an array of [key, value] pairs
                    const itemsArray = Object.entries(prevItems);

                    // Find the index of the empty key
                    const emptyKeyIndex = itemsArray.findIndex(
                      ([key]) => key === ""
                    );

                    // Create a new array with the dropped item in place of the empty key
                    const updatedItemsArray = itemsArray.map(
                      ([key, value], index) => {
                        if (index === emptyKeyIndex) {
                          return [items[droppedItem], items[droppedItem]]; // Place droppedItem in the empty key position
                        }
                        return [key, value];
                      }
                    );
                    if (emptyKeyIndex === -1) {
                      updatedItemsArray.push([
                        items[droppedItem],
                        items[droppedItem],
                      ]);
                    }

                    // Rebuild the object from the updated array of entries
                    const updatedItems = Object.fromEntries(updatedItemsArray);

                    setItems(updatedItems);
                    setFieldContents(updatedItems);
                    updatedItems[droppedItem] = "";
                    // Update the nonKey state
                    setNonKey((prevNonKey) => [
                      ...prevNonKey,
                      items[droppedItem],
                    ]);
                    return updatedItems;
                  });
                }
              } else {
                toast.error(`"${fieldContents[droppedItem]}" is already exist`);
              }
            } else if (fieldKey !== "") {
              if (isEdit) {
                if (items[fieldKey]) {
                  setItems({
                    ...items,
                    [fieldKey]: items[droppedItem],
                    [droppedItem]: fieldKey,
                  });
                  setFieldContents({
                    ...fieldContents,
                    [fieldKey]: fieldContents[droppedItem],
                    [droppedItem]: fieldKey,
                  });
                } else {
                  setItems({
                    ...items,
                    [fieldKey]: items[droppedItem],
                    [droppedItem]: "",
                  });
                  setFieldContents({
                    ...fieldContents,
                    [fieldKey]: fieldContents[droppedItem],
                    [droppedItem]: "",
                  });
                }
              } else {
                setItems({
                  ...items,
                  [fieldKey]: items[droppedItem],
                  [droppedItem]: "",
                });
                setFieldContents({
                  ...fieldContents,
                  [fieldKey]: fieldContents[droppedItem],
                  [droppedItem]: "",
                });
              }
            } else {
              let normalizedKey;
              if (isEdit) {
                normalizedKey = fieldContents[droppedItem]
                  .toLowerCase()
                  .replace(/\s/g, "");
              } else {
                normalizedKey = items[droppedItem]
                  .toLowerCase()
                  .replace(/\s/g, "");
              }

              // toast.error(`${droppedItem} is duplicated`)
              if (nonKey.includes(fieldContents[droppedItem])) {
                toast.error(`"${fieldContents[droppedItem]}" is already exist`);
              } else {
                if (!normalizedPojoKey.includes(normalizedKey)) {
                  if (isEdit) {
                    setItems((prevItems) => {
                      // setFieldContents((prevItems) => {
                      // Convert the items object to an array of [key, value] pairs
                      const itemsArray = Object.entries(prevItems);

                      // Find the index of the empty key
                      const emptyKeyIndex = itemsArray.findIndex(
                        ([key]) => key === ""
                      );

                      // Create a new array with the dropped item in place of the empty key
                      const updatedItemsArray = itemsArray.map(
                        ([key, value], index) => {
                          if (index === emptyKeyIndex) {
                            return [
                              fieldContents[droppedItem],
                              fieldContents[droppedItem],
                            ]; // Place droppedItem in the empty key position
                          }
                          return [key, value];
                        }
                      );

                      // If no empty key was found, append the dropped item to the end
                      if (emptyKeyIndex === -1) {
                        updatedItemsArray.push([
                          items[droppedItem],
                          items[droppedItem],
                        ]);
                      }

                      // Rebuild the object from the updated array of entries
                      const updatedItems =
                        Object.fromEntries(updatedItemsArray);

                      setItems(updatedItems);
                      setFieldContents(updatedItems);
                      updatedItems[droppedItem] = "";
                      // Update the nonKey state
                      setNonKey((prevNonKey) => [
                        ...prevNonKey,
                        items[droppedItem],
                      ]);

                      return updatedItems;
                    });
                  } else {
                    setFieldContents((prevItems) => {
                      // Convert the items object to an array of [key, value] pairs
                      const itemsArray = Object.entries(prevItems);

                      // Find the index of the empty key
                      const emptyKeyIndex = itemsArray.findIndex(
                        ([key]) => key === ""
                      );

                      // Create a new array with the dropped item in place of the empty key
                      const updatedItemsArray = itemsArray.map(
                        ([key, value], index) => {
                          if (index === emptyKeyIndex) {
                            return [
                              fieldContents[droppedItem],
                              fieldContents[droppedItem],
                            ]; // Place droppedItem in the empty key position
                          }
                          return [key, value];
                        }
                      );

                      // If no empty key was found, append the dropped item to the end
                      if (emptyKeyIndex === -1) {
                        updatedItemsArray.push([
                          fieldContents[droppedItem],
                          fieldContents[droppedItem],
                        ]);
                      }

                      // Rebuild the object from the updated array of entries
                      const updatedItems =
                        Object.fromEntries(updatedItemsArray);

                      setItems(updatedItems);
                      setFieldContents(updatedItems);
                      updatedItems[droppedItem] = "";
                      // Update the nonKey state
                      setNonKey((prevNonKey) => [
                        ...prevNonKey,
                        fieldContents[droppedItem],
                      ]);

                      return updatedItems;
                    });
                  }
                } else {
                  if (isEdit) {
                    toast.error(
                      `"${fieldContents[droppedItem]}" is already exist`
                    );
                  } else {
                    toast.error(`"${items[droppedItem]}" is already exist`);
                  }
                }
              }
            }
          } else {
            setItems({
              ...items,
              [fieldKey]: items[droppedItem],
              [droppedItem]: "",
            });
            setFieldContents({
              ...fieldContents,
              [fieldKey]: fieldContents[droppedItem],
              [droppedItem]: "",
            });
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred while handling drop: " + error);
    }
  };

  // for swap the field right
  const EditHandleDrop = (e, targetKey) => {
    try {
      if (isEdit) {
        e.preventDefault();
        const source = e.dataTransfer.getData("source");

        if (source) {
          const sourceKey = e.dataTransfer.getData("text/plain");

          const newItems = { ...fieldContents };

          const draggedValue = newItems[sourceKey];
          // Normalize the pojoKey array
          const normalizedPojoKey = pojoKey.map((key) =>
            key.toLowerCase().replace(/\s/g, "")
          );
          if (draggedValue === undefined) {
            const tempValue = newItems[targetKey];

            if (tempValue !== undefined && data.indexOf(sourceKey) >= 0) {
              // Swap the values of sourceKey and targetKey
              newItems[targetKey] = sourceKey;
              data[data.indexOf(sourceKey)] = tempValue;

              const normalizedKey = targetKey.toLowerCase().replace(/\s/g, "");
              if (
                (data.includes(tempValue) || nonKey.includes(sourceKey)) &&
                !draggedValue &&
                !normalizedPojoKey.includes(normalizedKey)
              ) {
                setItems(newItems);
                setFieldContents(newItems);
              } else {
                setFieldContents(newItems);
              }
            } else {
              const newItems = { ...items };

              if (tempValue) {
                newItems[targetKey] = sourceKey;
                newItems[sourceKey] = tempValue;
              } else {
                // newItems[targetKey] = sourceKey;
                // newItems[sourceKey] = targetKey;
                newItems[targetKey] = sourceKey;
                data[data.indexOf(sourceKey)] = items[targetKey];
              }

              setItems(newItems);
              setFieldContents(newItems);
              // toast.error(`"${targetKey}" is already mapped`);
            }
          } else {
            if (data.includes(sourceKey)) {
              if (data.includes(newItems[targetKey])) {
                data[data.indexOf(sourceKey)] = newItems[targetKey];
                newItems[targetKey] = sourceKey;
                setFieldContents(newItems);
              } else {
                if (data.includes(sourceKey)) {
                  if (isData) {
                    data[data.indexOf(sourceKey)] = newItems[targetKey];
                    newItems[targetKey] = sourceKey;
                    setFieldContents(newItems);
                  } else {
                    newItems[sourceKey] = newItems[targetKey];
                    newItems[targetKey] = draggedValue;
                    setFieldContents(newItems);
                  }
                } else {
                  newItems[sourceKey] = newItems[targetKey];
                  newItems[targetKey] = draggedValue;
                  setFieldContents(newItems);
                }
              }
            } else {
              if (nonKey.includes(targetKey)) {
                const newItems = { ...items };
                // Swap values between sourceKey and targetKey
                newItems[sourceKey] = newItems[targetKey];
                newItems[targetKey] = draggedValue;
                setFieldContents(newItems);
                setItems(newItems);
              } else {
                if (nonKey.includes(sourceKey)) {
                  // Swap values between sourceKey and targetKey
                  newItems[sourceKey] = newItems[targetKey];
                  newItems[targetKey] = draggedValue;
                  setFieldContents(newItems);
                  setItems(newItems);
                } else {
                  // Swap values between sourceKey and targetKey
                  newItems[sourceKey] = newItems[targetKey];
                  newItems[targetKey] = draggedValue;
                  setFieldContents(newItems);
                  const newItems1 = { ...items };
                  // Swap values between sourceKey and targetKey
                  newItems1[sourceKey] = newItems1[targetKey];
                  newItems1[targetKey] = draggedValue;
                  setItems(newItems1);
                }
              }
            }
          }
        }
      } else {
        e.preventDefault();
        const source = e.dataTransfer.getData("source");
        if (source) {
          const sourceKey = e.dataTransfer.getData("text/plain");

          const newItems = { ...items };

          const draggedValue = newItems[sourceKey];

          if (draggedValue === undefined) {
            const tempValue = newItems[targetKey];

            if (tempValue !== undefined && data.indexOf(sourceKey) >= 0) {
              // Swap the values of sourceKey and targetKey
              newItems[targetKey] = sourceKey;
              data[data.indexOf(sourceKey)] = tempValue;
              setItems(newItems);
            } else {
              toast.error(`"${targetKey}" is already exist`);
            }
          } else {
            const tempValue = newItems[targetKey];

            if (tempValue !== undefined && data.indexOf(sourceKey) >= 0) {
              // Swap the values of sourceKey and targetKey
              if (draggedValue) {
                if (
                  newItems[sourceKey] === newItems[targetKey] ||
                  newItems[sourceKey] === draggedValue
                ) {
                  if ((newItems[sourceKey] === newItems[targetKey]) === false) {
                    if (data.includes(newItems[sourceKey])) {
                      newItems[targetKey] = newItems[sourceKey];
                      data[data.indexOf(newItems[sourceKey])] = tempValue;
                      setItems(newItems);
                    } else {
                      if (isData) {
                        newItems[targetKey] = sourceKey;
                        data[data.indexOf(sourceKey)] = tempValue;
                      } else {
                        newItems[sourceKey] = newItems[targetKey];
                        newItems[targetKey] = draggedValue;
                      }
                    }
                  } else {
                    newItems[targetKey] = sourceKey;
                    data[data.indexOf(sourceKey)] = tempValue;
                  }
                } else {
                  newItems[targetKey] = newItems[sourceKey];
                  newItems[sourceKey] = tempValue;
                }
              } else {
                // Check if sourceKey is included in items values
                const sourceKeyIncluded =
                  Object.values(items).includes(sourceKey);
                if (!sourceKeyIncluded) {
                  newItems[targetKey] = sourceKey;
                  data[data.indexOf(sourceKey)] = tempValue;
                } else {
                  toast.error(`"${sourceKey}" is already exist`);
                }
              }
              setItems(newItems);
            } else {
              // Swap values between sourceKey and targetKey
              newItems[sourceKey] = newItems[targetKey];
              newItems[targetKey] = draggedValue;
              setItems(newItems);
            }
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred while handling drop: " + error);
    }
  };
  // for drop field right to left side
  const handleDropFileHeading = (event, item) => {
    try {
      event.preventDefault();

      const droppedItemId = event.dataTransfer.getData("text/plain");

      if (droppedItemId) {
        if (fieldContents[droppedItemId] === undefined) {
          // Use filter to create a new array without the item with the specified value
          const inputFieldss = inputFields.filter(
            (item) => item.value !== droppedItemId
          );
          // Print the updated inputFields
          setInputFields(inputFieldss);
          if (items[droppedItemId] === undefined) {
            setData([...data, droppedItemId]);
          }
          if (items[droppedItemId] === "") {
            setData([...data]);
          } else {
            // Function to check if item exists in data array (case insensitive)
            function isItemInArray(item, array) {
              if (item) {
                return (
                  array.filter(
                    (element) => element.toLowerCase() === item.toLowerCase()
                  ).length > 0
                );
              }
            }

            // Check if droppedItemId is inside data array (case insensitive)
            const isInArray = isItemInArray(items[droppedItemId], data);
            if (isInArray === false) {
              setData([...data, items[droppedItemId]]);

              const filteredItems = nonKey.filter(
                (item) => item !== droppedItemId
              );
              setNonKey(filteredItems);
              if (
                nonKey.find((n) => n === droppedItemId) &&
                !droppedItemId.includes(custKeys)
              ) {
                delete items[droppedItemId];
                // Add an empty key-value pair
                items[""] = "";
              } else {
                items[droppedItemId] = ""; // empty the value
              }
            } else {
              setData([...data]);
            }
          }
        } else {
          if (isEdit === true) {
            //submit
            if (fieldContents[droppedItemId]) {
              setData([...data, fieldContents[droppedItemId]]);
              fieldContents[droppedItemId] = "";
              items[droppedItemId] = "";
            }
          } else {
            if (items[droppedItemId]) {
              setData([...data, items[droppedItemId]]);
              fieldContents[droppedItemId] = "";
              items[droppedItemId] = "";
            }
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred while handling drop: " + error);
    }
  };

  function mergeObjects(value, transformed) {
    for (let key in transformed) {
      if (value.hasOwnProperty(key)) {
        transformed[key].dataType = value[key].dataType;
        transformed[key].isMandatory = value[key].isMandatory;
      }
    }
    return transformed;
  }

  const transformFields = (fields) => {
    let value;
    // Check if the result is an array and has the expected structure
    if (
      Array.isArray(originalConfig) &&
      originalConfig.length > 0 &&
      originalConfig[0].customFields
    ) {
      value = originalConfig[0].customFields;
    }
    // Check if the result is an object with the expected structure
    else if (
      typeof originalConfig === "object" &&
      !Array.isArray(originalConfig)
    ) {
      // Transform the object as per your needs
      value = transformToObjectFromObject(originalConfig);
    } else {
      throw new Error("Unexpected data structure");
    }
    const transformed = {};

    Object.keys(fields).forEach((key) => {
      if (typeof fields[key] === "string") {
        transformed[key] = {
          [key]: fields[key],
          dataType: "text",
          isMandatory: false,
        };
      } else if (typeof fields[key] === "object") {
        transformed[key] = {
          [key]: key,
          dataType: fields[key].dataType || "text",
          isMandatory:
            fields[key].isMandatory !== undefined
              ? fields[key].isMandatory
              : false,
        };
      }
    });

    let result = mergeObjects(value, transformed);
    return result;
  };

  useEffect(() => {
    let orders = [];
    switch (selectedOption) {
      case PRODUCT_SCREEN:
        orders = isOrder_Product;
        break;
      case CUSTOMER_SCREEN:
        orders = isOrder_Customer;
        break;
      case TRANSACTION_SCREEN:
        orders = isOrder_Transaction;
        break;
      case BENEFICIARY_SCREEN:
        orders = isOrder_Beneficiary;
        break;
      default:
        orders = [];
        break;
    }

    // Update state or perform any side effects
    setCurrentOrders(orders);
  }, [selectedOption]);

  // console.log(trimmedPojoClass);
  function trimObjectValues(obj) {
    // Check if the object is an array or object
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];

        // If the value is a string, trim it
        if (typeof value === "string") {
          obj[key] = value.trim();
        }
        // If the value is an object or array, recursively trim its values
        else if (typeof value === "object") {
          trimObjectValues(value);
        }
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    try {
      let mergedFields = {};

      mergedFields = { ...pojoField, ...items };

      const checkedFields = {};

      // Check if productId and transactionId have values
      let mandatory = [...mandatoryKey, ...isCustMandatory];

      // Iterate over each field in mergedFields
      Object.keys(mergedFields).forEach((field) => {
        // Check if the field is part of the mandatoryKey array
        if (mandatory.includes(field)) {
          // Validate the field value
          checkedFields[field] =
            mergedFields[field] !== "" &&
            mergedFields[field] !== null &&
            mergedFields[field] !== undefined;
        }
      });

      // Function to convert internal field names to display names using lodash
      function convertFieldName(field) {
        return _.startCase(_.camelCase(field)); // Converts camelCase or snake_case to Title Case
      }

      const displayNames = Object.keys(checkedFields).filter(
        (field) => !checkedFields[field]
      );

      displayNames.sort((a, b) => {
        const indexA = currentOrders.indexOf(a);
        const indexB = currentOrders.indexOf(b);

        if (indexA === -1) return 1; // a is not in currentOrders, so it goes to the end
        if (indexB === -1) return -1; // b is not in currentOrders, so it goes to the end

        return indexA - indexB; // Sort based on the order in currentOrders
      });

      // Convert the field names to display names
      const fieldsWithFalseValues = displayNames.map(convertFieldName);
      if (isEdit === true) {
        //submit
        if (fieldsWithFalseValues.length === 0) {
          // Separate objects for values in PojoClass and not in PojoClass

          let inPojoClass = Object.fromEntries(
            Object.entries(mergedFields)
              .filter(([key, _]) => pojoField.hasOwnProperty(key))
              .map(([key, value]) => [key, value === undefined ? "" : value])
          );

          const notInPojoClass = Object.fromEntries(
            Object.entries(mergedFields).filter(
              ([key, _]) => !pojoField.hasOwnProperty(key)
            )
          );

          const mergedObject = Object.assign(
            {},
            ...updatedArray,
            notInPojoClass
          );

          // Function to filter mergedObject based on keys in response
          function filterObjectByKeys(obj, keys) {
            const filteredObject = {};
            Object.keys(obj).forEach((key) => {
              if (keys.includes(key) || obj[key] !== "") {
                filteredObject[key] = obj[key];
              }
            });
            return filteredObject;
          }

          // Get matching key fields in mergedObject
          const matchingFields = filterObjectByKeys(mergedObject, response);

          // Iterate over the keys of matchingFields
          for (let key in matchingFields) {
            // If the value is undefined, replace it with an empty string
            if (matchingFields[key] === undefined) {
              matchingFields[key] = "";
            }
          }

          const transformedFields = transformFields(matchingFields);

          trimObjectValues(inPojoClass);
          trimObjectValues(transformedFields);
          const transformedObject = {
            mappingValue: inPojoClass,
            customFields: transformedFields,
          };

          // Attempt to save mapping configuration
          ApiService.saveMappings(transformedObject, selectedOption, token)
            .then(async (res) => {
              if (res && res.status === 200) {
                setData([]);
                setIsHidden(true);
                toast.success(res.data);
                const formData = new FormData();
                formData.append("file", selectedFile);

                // Now upload the file
                await ApiService.uploadMappingTemplate(
                  formData,
                  token,
                  selectedFileType,
                  selectedOption
                );
                await handleDropdownChange();
              } else {
                toast.error(FIELD_MAP_FAILED1);
              }
            })
            .catch((error) => {
              console.error("An error occurred:", error);
              toast.error(FIELD_MAP_FAILED2);
            });
        } else {
          toast.error(
            `The following fields are mandatory. Please map and submit.\n${fieldsWithFalseValues
              .map((field, index) => `${index + 1}. ${field}`)
              .join("\n")}`
          );
        }
      } else {
        //edit
        if (fieldsWithFalseValues.length === 0) {
          // Separate objects for values in PojoClass and not in PojoClass
          const inPojoClass = Object.fromEntries(
            Object.entries(items).filter(([key, _]) =>
              pojoField.hasOwnProperty(key)
            )
          );

          const notInPojoClass = Object.fromEntries(
            Object.entries(items).filter(
              ([key, _]) => !pojoField.hasOwnProperty(key)
            )
          );

          // Filter out the key-value pairs where the value is not an empty string
          const filteredObject = {};
          for (const key in notInPojoClass) {
            if (notInPojoClass[key] !== "") {
              filteredObject[key] = notInPojoClass[key];
            }
          }

          const mergedObject = Object.assign(
            {},
            ...updatedArray,
            notInPojoClass
          );

          // Function to filter mergedObject based on keys in response
          function filterObjectByKeys(obj, keys) {
            const filteredObject = {};
            Object.keys(obj).forEach((key) => {
              if (keys.includes(key) || obj[key] !== "") {
                filteredObject[key] = obj[key];
              }
            });
            return filteredObject;
          }

          // Get matching key fields in mergedObject
          const matchingFields = filterObjectByKeys(mergedObject, response);

          // Iterate over the keys of matchingFields
          for (let key in matchingFields) {
            // If the value is undefined, replace it with an empty string
            if (matchingFields[key] === undefined) {
              matchingFields[key] = "";
            }
          }

          const transformedFields = transformFields(matchingFields);
          trimObjectValues(inPojoClass);
          trimObjectValues(transformedFields);

          const transformedObject = {
            mappingValue: inPojoClass,
            customFields: transformedFields,
          };
          // Attempt to save mapping configuration
          ApiService.saveMappings(transformedObject, selectedOption, token)
            .then(async (res) => {
              if (res && res.status === 200) {
                setData([]);
                setIsHidden(true);
                toast.success(res.data);
                const formData = new FormData();
                formData.append("file", selectedFile);
                // Now upload the file
                await ApiService.uploadMappingTemplate(
                  formData,
                  token,
                  selectedFileType,
                  selectedOption
                );
                await handleDropdownChange();
              } else {
                toast.error(FIELD_MAP_FAILED1);
              }
            })
            .catch((error) => {
              console.error("An error occurred:", error);
              toast.error(FIELD_MAP_FAILED2);
            });
        } else {
          toast.error(
            `The following fields are mandatory. Please map and submit.\n${fieldsWithFalseValues
              .map((field, index) => `${index + 1}. ${field}`)
              .join("\n")}`
          );
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle unexpected errors here
      toast.error(FIELD_MAP_FAILED2);
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
    setIsHidden(!isHidden);
  };

  const handleAddFields = (event) => {
    event.preventDefault();

    const filteredKeys = Object.keys(items).filter(
      (label) => !currentOrders.includes(label)
    );
    setNonKey(filteredKeys);
    if (data.length === 0) {
      toast.error("There are no fields in the file to map");
    } else {
      // Check if the empty key "" already exists
      if ("" in items) {
        toast.error("Please give the field label");
      } else {
        // If the empty key doesn't exist, add it to the items
        setItems({ ...items, "": "" });
      }
    }
  };

  const handleRemoveCustomField = (keyToRemove) => {
    if (items[keyToRemove]) {
      Swal.fire({
        text: FIELD_DELETE_CONFIRM,
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        width: "30%",
      }).then((result) => {
        if (result.isConfirmed) {
          setItems((prevItems) => {
            // Create a copy of the items without the key that needs to be removed
            const updatedItems = { ...prevItems };
            delete updatedItems[keyToRemove]; // Remove the key
            return updatedItems;
          });
          if (isEdit === true) {
            setFieldContents((prevItems) => {
              // Create a copy of the items without the key that needs to be removed
              const updatedItems = { ...prevItems };
              delete updatedItems[keyToRemove]; // Remove the key
              return updatedItems;
            });
          }
          setData([...data, items[keyToRemove]]);
        }
      });
    } else {
      setItems((prevItems) => {
        // Create a copy of the items without the key that needs to be removed
        const updatedItems = { ...prevItems };
        delete updatedItems[keyToRemove]; // Remove the key
        return updatedItems;
      });
    }
  };

  const handleInputChange = (index, event) => {
    try {
      const fileValue = data.find((item) => item === event.target.value);

      if (fileValue) {
        // Find the key with the specified value
        let foundKey = null;
        for (const key in items) {
          if (items[key] === event.target.value) {
            foundKey = key;
            break;
          }
        }
        if (foundKey !== null) {
          toast.error(`"${event.target.value}" already exits`);
        } else {
          const newInputFields = [...inputFields];
          // Convert inputFields to an array of values

          const valuesToCheck = inputFields.map((field) => field.value);

          // Function to check if values are in inputFields
          function checkValuesInInputFields() {
            for (const valueToCheck of valuesToCheck) {
              const isValuePresent = inputFields.some(
                (field) => field.value === valueToCheck
              );

              if (isValuePresent && valueToCheck !== "") {
                console.log(`${valueToCheck} is already in inputFields`);
                // toast.error(`${valueToCheck} is already in mapped`);
              } else {
                const itemsKeysLowerCase = Object.keys(items).map((key) =>
                  key.toLowerCase()
                );

                const isValuePresent = itemsKeysLowerCase.includes(
                  event.target.value.toLowerCase()
                );

                let updatedArray = [];
                if (isValuePresent) {
                  toast.error(`"${event.target.value}" is already exist`);
                } else {
                  newInputFields[index].value = event.target.value;
                  updatedArray = inputFields.map((item) => ({
                    [item.value]: item.value,
                  }));
                }

                setData(
                  data.filter((item) => item !== newInputFields[index].value)
                ); // Remove the dropped item from the items array
                setUpdatedArray(updatedArray);
              }
            }
          }
          // Call the function to check values
          checkValuesInInputFields();
        }
      }
    } catch (error) {
      toast.error("An error occurred while handling input change: " + error);
    }
  };

  return {
    PAGE_OPTIONS,
    selectedOption,
    fileInputRef,
    data,
    items,
    fieldContents,
    inputFields,
    isEdit,
    setInputFields,
    handleButtonClick,
    setSelectedOption,
    handleFileUpload,
    handleDropFileHeading,
    handleDragStart,
    handleDragOver,
    handleDrop,
    EditHandleDrop,
    handleSubmit,
    handleAddFields,
    handleRemoveCustomField,
    handleInputChange,
    setFieldContents,
    roleAccess,
    stopRemount,
    setUpdatedArray,
    pojoKey,
    handleDropdownChange,
    currentOrders,
    isCustMandatory,
    downloadUploadedTemplate,
    isUploaded,
    custKeys,
    loadLeftSideData,
    setLoadLeftSideData,
  };
};
