import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Dialog, DialogActions } from "@mui/material";
import { ArrowDropDownTwoTone } from "@mui/icons-material";
import { format } from "date-fns";
import "../styles/DatePicker.css";

export default function DateRangePicker({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  stopRemount,
}) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const handleChange = (update) => {
    // Update temporary dates without affecting the chip display
    setTempStartDate(update[0]);
    setTempEndDate(update[1]);
  };

  const handleConfirm = () => {
    // Update startDate and endDate with confirmed values
    const sDate = format(tempStartDate, "yyyy-MM-dd");
    const eDate = format(tempEndDate, "yyyy-MM-dd");
    setStartDate(sDate);
    setEndDate(eDate);
    stopRemount.current = true;
    setIsPickerOpen(false);
  };

  const handleCancel = () => {
    // Revert to original dates and close the picker
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setIsPickerOpen(false);
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <Box
        className={className}
        onClick={onClick}
        ref={ref}
        sx={{
          borderBottom: "0.1px solid #A5A9B1",
          cursor: "pointer",
          ":hover": { borderBottom: 1 },
        }}
      >
        {value ? value : "Select Date Range"}
        <ArrowDropDownTwoTone />
      </Box>
    )
  );

  const formattedStartDate = tempStartDate
    ? format(tempStartDate, "MMM d, yyyy")
    : "";
  const formattedEndDate = tempEndDate
    ? format(tempEndDate, "MMM d, yyyy")
    : "";
  const displayValue =
    formattedStartDate && formattedEndDate
      ? `${formattedStartDate} - ${formattedEndDate}`
      : "Select Date Range";

  const isOkButtonDisabled = !tempStartDate || !tempEndDate; // Disable OK if start or end date is missing

  return (
    <>
      <ExampleCustomInput
        value={displayValue}
        onClick={() => setIsPickerOpen(true)}
      />

      {/* Dialog for Date Picker with OK and Cancel Buttons */}
      <Dialog open={isPickerOpen}>
        <Box sx={{ p: 1, pb: 0 }}>
          <DatePicker
            selected={tempStartDate}
            onChange={handleChange}
            startDate={tempStartDate}
            endDate={tempEndDate}
            selectsRange
            inline
            monthsShown={2}
            dateFormat="MMM d, yyyy"
            showMonthDropdown
            showYearDropdown
          />
        </Box>

        {/* OK and Cancel Buttons */}
        <DialogActions sx={{ pt: 0 }}>
          <Button
            variant="outlined"
            onClick={handleCancel}
            size="small"
            sx={{ fontSize: 11 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            sx={{ fontSize: 11 }}
            size="small"
            disabled={isOkButtonDisabled} // Disable button when no dates are selected
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
