import { Box, Typography } from "@mui/material";

export default function TermsConditions() {
  return (
    <>
      <Box sx={{ m: 5 }}>
        <Typography variant="h5" gutterBottom align={"center"}>
          Terms and Conditions of CompFact
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ textIndent: 30, py: 1 }}>
          These Terms and Conditions govern your use of the Sales Commission
          Software provided by CompFact, a India corporation us. By accessing or
          using the Software, you agree to be bound by these Terms. If you do
          not agree to these Terms, please do not use the Software.
        </Typography>
        <Typography variant="h6" gutterBottom>
          License
        </Typography>
        <Typography variant="body1" gutterBottom>
          Subject to these Terms, we grant you a non-exclusive,
          non-transferable, revocable license to use the Software for your
          internal business purposes.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You may not sublicense, sell, rent, lease, distribute, or otherwise
          transfer the Software or any part thereof.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Access and Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your account. You
          agree to accept responsibility for all activities that occur under
          your account or password.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You must promptly notify us of any unauthorized use of your account or
          any other breach of security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Fees and Payments
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to pay all fees associated with the use of the Software as
          set forth in the pricing plan provided by us.
        </Typography>
        <Typography variant="body1" gutterBottom>
          All fees are non-refundable.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Data Privacy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We will collect and process personal data in accordance with our
          Privacy Policy, which is incorporated into these Terms by reference.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to comply with all applicable data protection laws in
          connection with your use of the Software.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Intellectual Property
        </Typography>
        <Typography variant="body1" gutterBottom>
          All rights, title, and interest in and to the Software, including all
          intellectual property rights therein, are owned by us or our
          licensors.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree not to remove, alter, or obscure any copyright, trademark,
          service mark, or other proprietary rights notices incorporated in or
          accompanying the Software.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          To the fullest extent permitted by applicable law, we shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses, arising out of your access to or use of the
          Software.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Indemnification
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree to indemnify and hold us harmless from and against any and
          all claims, damages, liabilities, costs, and expenses, including
          attorneys' fees, arising out of or in connection with your use of the
          Software or your breach of these Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Modifications to Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting the revised Terms on our
          website. Your continued use of the Software after the posting of the
          revised Terms constitutes your acceptance of the changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Termination
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may terminate your access to the Software at any time for any
          reason or no reason, without prior notice.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms shall be governed by and construed in accordance with the
          laws of India, without regard to its conflicts of law principles.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Entire Agreement
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms constitute the entire agreement between you and us
          regarding your use of the Software and supersede all prior or
          contemporaneous understandings and agreements, whether written or
          oral.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any questions about these Terms, please contact us at
          contactus@compfact.com.
        </Typography>

        {/* <Typography variant="h6" gutterBottom>
          Terms and Conditions of Sales Commission Software
        </Typography>
        <Typography gutterBottom paragraph>
          You agree to use the Software only for lawful purposes and in
          accordance with these Terms. You shall not use the Software in any way
          that could damage, disable, overburden, impair, or interfere with any
          other party's use of the Software. Additionally, you shall not attempt
          to gain unauthorized access to any part of the Software, accounts,
          computer systems, or networks connected to the Software through
          hacking, password mining, or any other means. You are solely
          responsible for ensuring that your use of the Software complies with
          all applicable laws, regulations, and third-party rights.
        </Typography> */}
      </Box>
    </>
  );
}
