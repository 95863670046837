import { useState } from "react";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN } from "../config/sessionStorage";
import { addSerialNumber, formatDateDDMMYYYY } from "../config/fieldConfig";
import useFetchOnMount from "../config/useFetchOnMount";
import ScheduleStatusColumns from "../pages/ScheduleStatus/columns";

export const ScheduleStatusService = () => {
  const [data, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();

    try {
      const res = await ApiService.getAllJobProgress(TOKEN);

      // Format the response
      const formattedData = res?.data.map((item) => {
        return {
          ...item,
          startDate: formatDateDDMMYYYY(item?.startDate),
          endDate: formatDateDDMMYYYY(item?.endDate),
          nextRunDate: formatDateDDMMYYYY(item?.nextRunDate),
        };
      });
      setTableData(formattedData || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const rows = addSerialNumber(data);

  const columns = ScheduleStatusColumns();

  return {
    rows,
    columns,
    loading,
  };
};
