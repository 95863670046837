import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import {
  AddCircleOutlineTwoTone,
  CloseTwoTone,
  RemoveCircleOutlineTwoTone,
} from "@mui/icons-material";
import { handleNumberFieldKeyDown } from "../../config/fieldConfig";

export default function TieredForm({
  open,
  onClose,
  tieredData,
  onTieredData,
  setTieredChecked,
}) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dynamicFields: [{ from: "", to: "", commissionRate: "" }],
    },
  });

  const { fields, insert, remove, update } = useFieldArray({
    control,
    name: "dynamicFields",
  });

  useEffect(() => {
    // Define default values
    const defaultFields = { from: "", to: "", commissionRate: "" };

    const fieldsToSet =
      tieredData.length > 0
        ? tieredData.map((item) => ({
            from: item.from,
            to: item.to,
            commissionRate: item.commissionRate,
          }))
        : [defaultFields];

    // Ensure there's at least one default row
    if (fieldsToSet.length === 0) {
      fieldsToSet.push(defaultFields);
    }

    // Update the form data using setValue
    setValue("dynamicFields", fieldsToSet);
    reset({ dynamicFields: fieldsToSet });
  }, [tieredData, setValue, reset]);

  useEffect(() => {
    reset(); // Reset the form with the default and dynamic fields
  }, [onClose, reset]);

  const onSubmit = (formData) => {
    const numberOfItems = watch("dynamicFields")?.length || 0;

    const resultArray = [];

    for (let i = 0; i < numberOfItems; i++) {
      const from = watch(`dynamicFields[${i}].from`);
      const to = watch(`dynamicFields[${i}].to`);
      const commissionRate = watch(`dynamicFields[${i}].commissionRate`);

      const newItem = {
        from,
        to,
        commissionRate,
      };

      // Only add the item if at least one of its properties is not empty
      if (from || to || commissionRate) {
        resultArray.push(newItem);
      }
    }
    onTieredData(resultArray);
    onClose(false);
    if (resultArray.length === 0) {
      setTieredChecked(false);
    }
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        Tiered Commission
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={() => {
            onClose();
            if (tieredData.length === 0) {
              setTieredChecked(false);
            }
          }}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate>
          {/* for tiered */}
          {fields.map((field, setIndex) => (
            <Grid container spacing={2} key={setIndex}>
              {["from", "to", "commissionRate"].map((property) => (
                <Grid item xs={12} sm={3} marginTop={1} key={property} pb={1}>
                  <TextField
                    fullWidth
                    name={`dynamicFields[${setIndex}].${property}`}
                    placeholder={`${
                      property.charAt(0).toUpperCase() + property.slice(1)
                    }%`}
                    label={property.charAt(0).toUpperCase() + property.slice(1)}
                    variant="outlined"
                    size="small"
                    type="number"
                    autoComplete="off"
                    {...register(`dynamicFields[${setIndex}].${property}`, {
                      validate: (value) => {
                        const from = watch(`dynamicFields[${setIndex}].from`);
                        const to = watch(`dynamicFields[${setIndex}].to`);
                        const commissionRate = watch(
                          `dynamicFields[${setIndex}].commissionRate`
                        );
                        if ((!from || !to || !commissionRate) && !value) {
                          if (
                            setIndex === 0 &&
                            !from &&
                            !to &&
                            !commissionRate
                          ) {
                            console.log(setIndex);
                          } else {
                            return `${
                              property.charAt(0).toUpperCase() +
                              property.slice(1)
                            } is required`;
                          }
                        }
                        return true; // Pass validation if the row is completely empty
                      },
                    })}
                    onKeyDown={(e) => {
                      if (`dynamicFields[${setIndex}].inputValue`) {
                        console.log(
                          property,
                          `dynamicFields[${setIndex}].${property}`,
                          `dynamicFields[${setIndex}].property`
                        );
                        handleNumberFieldKeyDown(e);
                      }
                    }}
                    error={!!errors?.dynamicFields?.[setIndex]?.[property]}
                    helperText={
                      errors?.dynamicFields?.[setIndex]?.[property]?.message
                    }
                    inputProps={{ min: 1, step: 1 }}
                    value={field[property]}
                    onChange={(e) => {
                      const newFields = [...fields];
                      newFields[setIndex][property] = e.target.value;
                      setValue(
                        `dynamicFields[${setIndex}].${property}`,
                        e.target.value
                      ); // Set the value
                      trigger(`dynamicFields[${setIndex}].${property}`); // Trigger revalidation
                      setValue("dynamicFields", newFields);
                    }}
                  />
                </Grid>
              ))}

              <Grid item marginTop={1}>
                <IconButton
                  onClick={async () => {
                    const isValid = await trigger(); // Validate all fields

                    // Check if any row is completely empty
                    const isAnyRowEmpty = fields.some((_, index) => {
                      const from = watch(`dynamicFields[${index}].from`);
                      const to = watch(`dynamicFields[${index}].to`);
                      const commissionRate = watch(
                        `dynamicFields[${index}].commissionRate`
                      );
                      return !from && !to && !commissionRate;
                    });

                    if (isValid && !isAnyRowEmpty) {
                      insert(fields.length, {
                        from: "",
                        to: "",
                        commissionRate: "",
                      });
                    } else {
                      const from = watch(`dynamicFields[${setIndex}].from`);
                      const to = watch(`dynamicFields[${setIndex}].to`);
                      const commissionRate = watch(
                        `dynamicFields[${setIndex}].commissionRate`
                      );
                      if (from === "") {
                        setError(`dynamicFields[${setIndex}].from`, {
                          type: "manual",
                          message: "From is required",
                        });
                      }
                      if (to === "") {
                        setError(`dynamicFields[${setIndex}].to`, {
                          type: "manual",
                          message: "To is required",
                        });
                      }
                      if (commissionRate === "") {
                        setError(`dynamicFields[${setIndex}].commissionRate`, {
                          type: "manual",
                          message: "CommissionRate is required",
                        });
                      }
                      console.log(
                        "Please fill in all fields for each row before adding a new one."
                      );
                    }
                  }}
                  color="success"
                >
                  <AddCircleOutlineTwoTone />
                </IconButton>
                <IconButton
                  onClick={() => {
                    if (fields.length > 1) {
                      // Clear errors first, then remove the row
                      clearErrors(`dynamicFields[${setIndex}]`);
                      remove(setIndex);
                    } else {
                      console.log(setIndex);

                      // Clear the row by updating it and clear errors
                      update(setIndex, {
                        from: "",
                        to: "",
                        commissionRate: "",
                      });
                      clearErrors(`dynamicFields[${setIndex}]`);
                    }
                  }}
                  color="error"
                >
                  <RemoveCircleOutlineTwoTone />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <DialogActions className="mt-3">
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              {tieredData.length !== 0 ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
