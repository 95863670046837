import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  CssBaseline,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import toast from "react-hot-toast";
import moment from "moment-timezone";
import { fields } from "./formFields";
import axiosClient from "../../../apis/Api";
import Captcha from "../../common/Captcha";
import Copyright from "../../common/Copyright";
import {
  isValidEmail,
  isEmpty,
  validatePhoneNumber,
  isValueContainsSplChars,
  onError,
} from "../../config/fieldConfig";
import { onRegister, usePricingFetch } from "../../service/outerPagesService";
import { CAPTCHA_FAIL } from "../../config/toastMessage";
import { CfTransBlueLogo, ImgRegister } from "../../config/imageConfig";

export default function Register() {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    reset,
    clearErrors,
    watch,
  } = useForm();

  const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null); // State to store the ReCAPTCHA value

  const recaptchaRef = useRef(null);

  const pricingPlanOptions = usePricingFetch();

  const location = useLocation();

  // Loading Button
  const [loading, setLoading] = useState(false);

  // timeZone
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState("");

  // pricing
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(
    location.state?.selectedSubscriptionPlan || "Basic"
  ); // get selected pricing value through landing screen

  useEffect(() => {
    register("email", {
      validate: isValidEmail,
    });
  }, [register]);

  // CountryPhone Start
  const { inputValue, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      defaultCountry: "in",
      countries: defaultCountries,
    });

  const navigate = useNavigate();

  // Inside your functional component
  const phoneInputRef = useRef(null); // Create a ref for the phone input field
  const [isSubmitted, setIsSubmitted] = useState(false);

  // form reset
  useEffect(() => {
    reset(); // Reset the entire form
    fields.forEach((field) => {
      setValue(field.name, ""); // Clear the form value
      clearErrors(field.name); // Clear any existing errors for the field
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  useEffect(() => {
    const initialCountry = country.iso2.toUpperCase();
    const initialTimezones = moment.tz
      .names()
      .filter((zone) =>
        moment.tz.zone(zone).countries().includes(initialCountry)
      );

    // Convert timezones to their UTC offsets with descriptive names
    const timezonesWithOffsets = initialTimezones.map((zone) => {
      const offset = moment.tz(zone).format("Z"); // Format the offset as UTC+/-HH:mm
      const description = zone.replace("_", " ").replace("/", "/"); // Replace underscores and slashes for better readability
      return `UTC${offset} ${description}`;
    });
    setTimezones(timezonesWithOffsets);

    if (timezonesWithOffsets.length > 0) {
      const firstTimezone = timezonesWithOffsets[0];
      setSelectedTimezone(firstTimezone);
      setValue("timeZone", firstTimezone);
      trigger("timeZone");
    }
    setValue("subscriptionPlan", selectedSubscriptionPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (isSubmitted) {
      setTimeout(() => {
        const firstErrorKey = Object.keys(errors)[0];
        const firstErrorField = errors[firstErrorKey]?.ref;

        if (errors && firstErrorField) {
          if (errors.phone && Object.keys(errors).length === 1) {
            phoneInputRef.current.focus();
          } else {
            firstErrorField.focus();
          }
        }
        setIsSubmitted(false); // Reset after focusing
      }, 0); // Use a short delay to allow state update to propagate
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted, errors.phone]);

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      country: country.name,
      phone: inputValue,
    };

    delete formData.timeZone;

    const domain = axiosClient.defaults.apiUrl;

    if (isCaptchaChecked === true) {
      setLoading(true);
      try {
        await onRegister(
          formData,
          navigate,
          domain,
          setLoading,
          selectedTimezone,
          captchaValue,
          recaptchaRef
        );
      } catch (error) {
        // Handle error here
        console.error("Registration error:", error);
      } finally {
        setLoading(false); // Ensure loading state is reset even if there was an error
      }
    } else {
      toast.error(CAPTCHA_FAIL);
    }
  };

  const handleCaptchaChange = (isCaptchaChecked, value) => {
    setCaptchaValue(value); // Store the ReCAPTCHA value
    setIsCaptchaChecked(isCaptchaChecked);
  };

  const handleCountryChange = (e) => {
    const newCountry = e.target.value.toUpperCase();
    const newTimezones =
      moment.tz
        .names()
        .filter((zone) =>
          moment.tz.zone(zone).countries().includes(newCountry)
        ) || [];
    setTimezones(newTimezones);

    const defaultTimezone = newTimezones.length > 0 ? newTimezones[0] : "";
    setSelectedTimezone(defaultTimezone); // Set the first timezone as the default
    setValue("timeZone", defaultTimezone); // Update the form value
    setCountry(e.target.value);
    clearErrors("timeZone"); // Clear any errors for the timeZone field

    const newCountrya = e.target.value;
    // const oldDialCode = inputRef.current && inputRef.current.value; // Get the current dial code
    let newDialCode = "";

    defaultCountries.forEach((c) => {
      const country = parseCountry(c);
      if (country.iso2 === newCountrya) {
        newDialCode = country ? country.dialCode : "";
        return;
      }
    });

    // Remove the old dial code from the phone number
    let newPhoneNumber = inputValue.replace(`+${inputValue}`, "");

    // If the new dial code is not empty, add it to the beginning of the phone number
    if (newDialCode !== "") {
      newPhoneNumber = `+${newDialCode}`;
    }
    // Update the phone number state
    setValue("phone", newPhoneNumber);
  };

  const fieldRefs = useRef({});

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        {/* Left Grid */}
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "white", // imgLogo bgColor "#f5f5f5"
            p: 2,
            // Ensure this grid takes full width on small screens
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Link href={"/"}>
              <img
                src={CfTransBlueLogo}
                alt="Logo"
                style={{ width: "100%", maxWidth: 150 }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${ImgRegister})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              maxWidth: 500,
              height: 300,
              mb: 4,
            }}
          />
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              textAlign: "center",
              fontSize: { xs: 28, sm: 32, md: 40 },
              fontWeight: 500,
              mb: 2,
            }}
          >
            Register with CompFact
          </Typography>
          <Typography
            gutterBottom
            px={{ xs: 2, sm: 4, md: 10 }}
            fontSize={{ xs: 14, sm: 16, md: 17 }}
            variant="body1"
            textAlign="center"
          >
            Manage the complexities of sales compensation, enhance transparency,
            and motivate sales teams through accurate and timely commission
            payments
          </Typography>
        </Grid>

        {/* Right Grid */}
        <Grid item xs={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 2,
              mx: 7,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button href="/sign_in" variant="outlined">
                  Sign In
                </Button>
              </Grid>
            </Grid>
            <Typography component="h1" variant="h5">
              Register Here
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit, (errors) =>
                onError(fields, errors, fieldRefs)
              )}
              sx={{ mt: 1 }}
            >
              {fields.map((field) =>
                field.name === "timeZone" ||
                field.name === "subscriptionPlan" ? (
                  <Autocomplete
                    key={field.name}
                    options={
                      field.name === "timeZone" ? timezones : pricingPlanOptions
                    }
                    value={
                      timezones.includes(watch(field.name))
                        ? watch(field.name)
                        : pricingPlanOptions.includes(watch(field.name))
                        ? watch(field.name)
                        : null
                    }
                    onChange={(event, newValue) => {
                      if (field.name === "timeZone") {
                        setSelectedTimezone(newValue);
                        clearErrors("timeZone");
                      } else if (field.name === "subscriptionPlan") {
                        setSelectedSubscriptionPlan(newValue);
                        clearErrors("subscriptionPlan");
                      }
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        size="small"
                        required={field.required}
                        fullWidth
                        label={field.label}
                        name={field.name}
                        autoComplete={field.autoComplete}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                        })}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        inputRef={(el) => {
                          if (el) {
                            fieldRefs.current[field.name] = el;
                          }
                        }} // Set ref
                      />
                    )}
                  />
                ) : (
                  <TextField
                    key={field.name}
                    margin="normal"
                    size="small"
                    required={field.required}
                    fullWidth
                    label={field.label}
                    name={field.name}
                    // inputRef={field.name === "phone" ? phoneInputRef : null} // Conditionally assign inputRef
                    inputRef={(el) => {
                      // Assign ref conditionally based on the field name
                      if (field.name === "phone" && phoneInputRef) {
                        phoneInputRef.current = el;
                      }

                      // Also store in fieldRefs for general use
                      if (el) {
                        fieldRefs.current[field.name] = el;
                      }
                    }} // Combined ref logic
                    type={field.type}
                    InputProps={
                      field.name === "phone"
                        ? {
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  marginRight: "2px",
                                  marginLeft: "-8px",
                                }}
                              >
                                <Select
                                  MenuProps={{
                                    style: {
                                      height: "300px",
                                      width: "360px",
                                      top: "10px",
                                      left: "-34px",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                  }}
                                  sx={{
                                    width: "max-content",
                                    fieldset: {
                                      display: "none",
                                    },
                                    '&.Mui-focused:has(div[aria-expanded="false"]) fieldset':
                                      {
                                        border: "none", // Ensure the border stays removed when focused
                                      },
                                    ".MuiSelect-select": {
                                      padding: "8px",
                                      paddingRight: "24px !important",
                                    },
                                    svg: {
                                      right: 0,
                                    },
                                  }}
                                  value={country.iso2}
                                  onChange={handleCountryChange}
                                  renderValue={(value) => (
                                    <FlagImage
                                      iso2={value}
                                      style={{ display: "flex" }}
                                    />
                                  )}
                                >
                                  {defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                      <MenuItem
                                        key={country.iso2}
                                        value={country.iso2}
                                      >
                                        <FlagImage
                                          iso2={country.iso2}
                                          style={{ marginRight: "8px" }}
                                        />
                                        <Typography marginRight="8px">
                                          {country.name}
                                        </Typography>
                                        <Typography color="gray">
                                          +{country.dialCode}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </InputAdornment>
                            ),
                            value: inputValue,
                            onChange: (e) => {
                              const value = e.target.value;
                              handlePhoneValueChange(e);
                              setValue(field.name, value); // Update the form value
                              trigger(field.name); // Trigger validation on change
                            },
                          }
                        : {}
                    }
                    {...register(field.name, {
                      required: field.required
                        ? `${field.label} is required`
                        : false,
                      pattern: field.name === "email" ? isValidEmail : null,
                      validate: {
                        isValidEmail: (value) =>
                          field.name === "email" ? isValidEmail(value) : true,
                        isEmpty: (value) =>
                          field.required ? isEmpty(value) : true,
                        validatePhoneNumber: (value) =>
                          field.name === "phone"
                            ? validatePhoneNumber(value, country)
                            : true,
                        isValueContainsSplChars: (value) =>
                          field.type === "text" &&
                          field.type !== "email" &&
                          field.type !== "number"
                            ? isValueContainsSplChars(value)
                            : true,
                      },
                    })}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]?.message}
                    onChange={(e) => {
                      setValue(field.name, e.target.value); // Update the form value
                      trigger(field.name); // Trigger validation on change
                    }}
                    onBlur={() => {
                      trigger(field.name); // Trigger validation on blur
                    }}
                  />
                )
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Link href="/landing#pricing" target="_blank" variant="body2">
                  View subscription plans
                </Link>
              </Box>

              <Captcha
                onCaptchaChange={handleCaptchaChange}
                recaptchaRef={recaptchaRef}
              />

              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={loading}
                loadingPosition="start"
                startIcon={<></>}
                onClick={(e) => {
                  setIsSubmitted(true);
                }}
                style={{
                  backgroundColor: loading ? "#448aff" : undefined,
                  color: loading ? "#fff" : undefined,
                }}
              >
                {!loading ? "Submit" : "Loading..."}
              </LoadingButton>

              <Copyright sx={{ mt: 3 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
