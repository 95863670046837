import { Typography } from "@mui/material";

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DateRangePicker from "../../common/DateRangePicker";

export default function UserActivityToolBar({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  stopRemount,
}) {
  return (
    <GridToolbarContainer sx={{ flexWrap: "wrap" }}>
      <Typography variant="h5" color="primary">
        User Activity
      </Typography>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />

      <div style={{ flex: 1 }} />

      <GridToolbarQuickFilter sx={{ pr: 5 }} />

      <DateRangePicker
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        stopRemount={stopRemount}
      />
    </GridToolbarContainer>
  );
}
