import { useEffect, useRef, useState } from "react";
import ApiService from "../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  ORG_ID,
  TIME_ZONE,
  USER_ID,
  USER_NAME,
} from "../config/sessionStorage";
import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { formatCurrency, formatCurrencySymbol } from "../config/fieldConfig";

export const DashboardService = () => {
  // Total Commission
  const [totalCommission, setTotalCommission] = useState("");

  //Trends Commission
  const [trendsCommission, setTrendsCommission] = useState("");
  const [trendsLabelsCom, setTrendsLabelsCom] = useState([]);
  const [trendsValuesCom, setTrendsValuesCom] = useState([]);

  // State variables to store Top 10 SalesReps
  const [beneficiaryNames, setBeneficiaryNames] = useState([]);
  const [totalNetPayouts, setTotalNetPayouts] = useState([]);
  const [salesRepData, setSalesRepData] = useState("");

  // Commission by Products
  const [productNames, setProductNames] = useState([]);
  const [totalNetProductPayouts, setTotalNetProductPayouts] = useState([]);

  // Commission by Customers
  const [customerNames, setCustomerNames] = useState([]);
  const [totalNetCustomerPayouts, setTotalNetCustomerPayouts] = useState([]);

  const order = "desc";

  const count = 10;
  const customCount = 5;

  // Get today's date
  const today = new Date();

  // Calculate the date of the month before last
  const dateOfLastMonth = subMonths(today, 1);
  const endOfLastMonth = endOfMonth(dateOfLastMonth);

  // Format the date as yyyy-mm-dd
  const formattedEndDate = format(endOfLastMonth, "yyyy-MM-dd");

  // Calculate the date three months ago
  const dateThreeMonthsAgo = subMonths(today, 3);

  // Get the start date of that month
  const startOfThreeMonthsAgo = startOfMonth(dateThreeMonthsAgo);

  // Format the date as yyyy-mm-dd
  const formatedStartDate = format(startOfThreeMonthsAgo, "yyyy-MM-dd");

  // States for start and end dates
  const [startDate, setStartDate] = useState(formatedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const [reportingTo, setReportingTo] = useState([]);

  const orgId = ORG_ID();
  const curName = CURRENCY_NAME();
  const timeZone = TIME_ZONE();
  const userName = USER_NAME();
  const userId = USER_ID();

  const menu = [`${userId} - ${userName}`, "My Reportees"];

  const salesRepsColumns = [
    {
      field: "beneficiaryName",
      headerName: "Beneficiary",
      width: 200,
    },
    {
      field: "totalNetPayout",
      headerName: `Net Payout (${curName})`,
      headerAlign: "right",
      align: "right",
      width: 150,
    },
  ];

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData(startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleGetMyDetailsApplyClick = (salesRe) => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "ApplyClick", salesRe);
  };

  const handleGetMyReportee = () => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "My Reportees");
  };

  const handleGetMyDetails = () => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "My Data");
  };

  const fetchAndSetData = async (fetchFunctions) => {
    const [
      totalCommSalesRep,
      topCommissionProducts,
      topCustomers,
      topSalesReps,
    ] = await Promise.all(fetchFunctions);
    setTotalCommission(formatCurrencySymbol(totalCommSalesRep || 0));
    // Extract productName and totalNetPayout into separate arrays
    const [productNames, productPayouts] = [
      topCommissionProducts.map((product) => product.productName),
      topCommissionProducts.map((product) => product.totalNetPayout),
    ];

    // Set state variables with extracted arrays
    setProductNames(productNames);
    setTotalNetProductPayouts(productPayouts);

    // Extract customerName and totalNetPayout into separate arrays
    const customerNames = topCustomers.map((customer) => customer.customerName);
    const customerPayouts = topCustomers.map(
      (customer) => customer.totalNetPayout
    );
    // Update state variables with extracted arrays
    setCustomerNames(customerNames);
    setTotalNetCustomerPayouts(customerPayouts);

    // Destructure the data directly
    const [beneficiaryNames, totalNetPayouts] = [
      topSalesReps.map((salesRep) => salesRep.beneficiaryName),
      topSalesReps.map((salesRep) => salesRep.totalNetPayout),
    ];

    setSalesRepData(topSalesReps);
    setBeneficiaryNames(beneficiaryNames);
    setTotalNetPayouts(totalNetPayouts);
  };

  const fetchData = async (startDate, endDate, selectedDropdown, salesRe) => {
    try {
      const TOKEN = JWT_TOKEN();

      //To show the reportingTo names
      const response = await ApiService.getActiveBeneficiaries(TOKEN);

      // Transform beneficiary data
      const transformedData = response.map((beneficiary) => ({
        userId: beneficiary.userId,
        userName: beneficiary.userName,
      }));

      // Filter out the entry with userId "T4"
      const filteredData = transformedData.filter(
        (beneficiary) => beneficiary.userId !== userId
      );
      setReportingTo(filteredData);

      // trends commission
      const trendsComRange = await ApiService.getCommissionTrends(
        TOKEN,
        startDate,
        endDate
      );

      const trendsLabels = Object.keys(trendsComRange);
      const trendsValues = Object.values(trendsComRange);

      if (trendsValues.every((value) => value === 0)) {
        setTrendsLabelsCom([]);
        setTrendsValuesCom([]);
        setTrendsCommission({});
      } else {
        setTrendsLabelsCom(trendsLabels);
        setTrendsValuesCom(trendsValues);
        setTrendsCommission(trendsComRange);
      }

      if (selectedDropdown === "My Data" || !selectedDropdown) {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByProduct(
            TOKEN,
            order,
            customCount,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByCustomer(
            TOKEN,
            order,
            customCount,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForUser(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "My Reportees") {
        await fetchAndSetData([
          ApiService.getReporteesTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByProduct(
            TOKEN,
            order,
            customCount,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByCustomer(
            TOKEN,
            order,
            customCount,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForReportees(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "ApplyClick") {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            null,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByProduct(
            null,
            order,
            customCount,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByCustomer(
            null,
            order,
            customCount,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getCreditTransForUser(
            null,
            order,
            count,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Convert salesRepData object to an array
  const salesRepsRows = Object.values(salesRepData).map((row, index) => ({
    id: index + 1,
    beneficiaryName: row.beneficiaryName,
    totalNetPayout: formatCurrency(parseFloat(row.totalNetPayout)),
  }));

  // Transform salesRepsRows data into the format expected by PieChart
  const salesRepPieChartData = salesRepsRows.map((row) => ({
    id: row.id,
    value: row.totalNetPayout, // Use totalNetPayout as the value
    label: row.beneficiaryName, // Use beneficiaryName as the label
  }));

  return {
    // Total Commissions
    totalCommission,
    //Trends Commission
    trendsCommission,
    trendsLabelsCom,
    trendsValuesCom,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    beneficiaryNames,
    totalNetPayouts,
    salesRepPieChartData,
    //  Commission by Product
    productNames,
    totalNetProductPayouts,
    // Commission by Customers
    customerNames,
    totalNetCustomerPayouts,
    // Date
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    fetchData,
    curName,
    timeZone,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetMyDetailsApplyClick,
    reportingTo,
    stopRemount,
    menu,
  };
};
