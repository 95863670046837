export const fields = [
  {
    label: "User ID",
    name: "userId",
    type: "email",
    required: true,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    required: true,
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    required: true,
  },
  {
    label: "No of Beneficiary",
    name: "beneficiary",
    type: "number",
    required: true,
  },
  {
    label: "Currency",
    name: "currency",
    type: "text",
    required: true,
  },
  {
    label: "Subscription Plan",
    name: "subscriptionPlan",
    required: true,
  },
];
