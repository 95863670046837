import { Box } from "@mui/material";

export default function EmptyBox() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        No data to display
      </Box>
    </>
  );
}
