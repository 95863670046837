import { useState } from "react";
import { JWT_TOKEN, ORG_ID } from "../config/sessionStorage";
import ApiService from "../../apis/ApiService";
import {
  addSerialNumber,
  formatCurrencyAmount,
  formatDateDDMMYYYY,
} from "../config/fieldConfig";
import useFetchOnMount from "../config/useFetchOnMount";
import BillingColumns from "../pages/Billing/columns";

export const BillingService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [subscriptionStartDate, setSubscriptionStartDate] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState("");
  const [renewalPrice, setRenewalPrice] = useState("");
  const [pricingPlan, setPricingPlan] = useState("");
  const [pricingMonth, setPricingMonth] = useState("");
  const [noOfBeneficiary, setNoOfBeneficiary] = useState(1);
  const [currentCurrency, setCurrentCurrency] = useState("");

  const fetchData = async () => {
    const orgId = ORG_ID();

    try {
      const TOKEN = JWT_TOKEN();
      setToken(TOKEN);

      const res = await ApiService.getPaymentHistoryForOrgId(TOKEN, orgId);
      console.log("Pay res", res.data);

      if (res?.status === 200) {
        const formattedData = res.data.map((item) => ({
          ...item,
          amountToPay: formatCurrencyAmount(
            item.amountToPay,
            item.currency || "INR"
          ),
          paidDate: formatDateDDMMYYYY(item.paidDate),
        }));
        setTableData(formattedData);

        if (res?.data?.length > 0) {
          const firstPayment = res.data[0];

          setSubscriptionStartDate(firstPayment?.paidDate || "");
          setSubscriptionEndDate(firstPayment?.subscriptionEndDate || "");
          setRenewalPrice(
            formatCurrencyAmount(
              firstPayment?.amountToPay,
              firstPayment?.currency || "INR"
            ) || ""
          );
          setPricingPlan(firstPayment?.pricingPlan);
          setPricingMonth(firstPayment?.month);
          setNoOfBeneficiary(firstPayment?.beneficiary);
          setCurrentCurrency(firstPayment?.currency);
        } else {
          setSubscriptionEndDate("");
          setRenewalPrice("");
        }
      } else {
        // Handle non-200 status codes
        console.error("Failed to fetch data:", res?.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const handlePdfDownload = async (params) => {
    console.log("params", params);
    try {
      const response = await ApiService.downloadInvoicePDF(
        token,
        params.row.id
      );

      // Check if response data is valid
      if (!response.data) {
        console.error("Error: Empty response data");
        return;
      }

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `${params.row.invoice}_${params.row.paidDate}.pdf`;
      a.click();

      // Revoke object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const columns = BillingColumns(handlePdfDownload);

  const rows = addSerialNumber(data);

  return {
    columns,
    rows,
    currentCurrency,
    subscriptionStartDate,
    subscriptionEndDate,
    renewalPrice,
    pricingPlan,
    pricingMonth,
    noOfBeneficiary,
    fetchData,
  };
};
