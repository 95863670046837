import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";

const VariableColumns = (handleEdit, handleDelete, roleAccess) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Variable" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "variableName",
      headerName: "Variable Name",
      width: 170,
    },
    {
      field: "variable",
      headerName: "Variable",
      width: 170,
    },
    {
      field: "scope",
      headerName: "Scope",
      width: 170,
    },
    {
      field: "description",
      headerName: "Description",
      width: 310,
    },
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Variable" && item.writeAccess
        );

        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Variable" && item.deleteAccess
        );

        // Define the ids for which the delete action should be excluded
        const idsWithoutDelete = [1, 2]; // Replace with your actual ids

        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={editAccess ? handleEdit(id) : undefined}
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }
        // Exclude delete option if id is in idsWithoutDelete array
        if (deleteAccess && !idsWithoutDelete.includes(id)) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }
        return actions;
      },
    },
  ];
};

export default VariableColumns;
