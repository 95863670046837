import { Outlet } from "react-router-dom";
import { Box, Container, CssBaseline } from "@mui/material";
import { ORG_NAME, USER_NAME } from "../../config/sessionStorage";
import { CfTransLogo } from "../../config/imageConfig";
import Logout from "../../pages/Logout/index";
import { menus, settings } from "./menus";
import TopNavBar from "./topNavBar";
import BreadcrumbBar from "./breadcrumb";

export default function HorizontalLayout({
  setIsAuthenticated,
  setRes,
  arrayOfObjects,
  role,
  isMonitor,
  isSuperAdmin,
}) {
  return (
    <>
      <Container disableGutters maxWidth="xl">
        <CssBaseline />
        {/* Top Navigation Bar */}
        <TopNavBar
          cfTransLogo={CfTransLogo}
          orgName={ORG_NAME()}
          userName={USER_NAME()}
          logout={Logout}
          menus={menus}
          settings={settings}
          setIsAuthenticated={setIsAuthenticated}
          setRes={setRes}
          arrayOfObjects={arrayOfObjects}
          role={role}
          isMonitor={isMonitor}
          isSuperAdmin={isSuperAdmin}
        />
        {/* Breadcrumb display */}
        <BreadcrumbBar menus={menus} />
        {/* Outlet the page component display */}
        <Box sx={{ m: 2, boxShadow: 3 }}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
}
