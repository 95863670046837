import { useEffect, useRef, useState } from "react";
import ApiService from "../../apis/ApiService";
import {
  CURRENCY_NAME,
  JWT_TOKEN,
  ORG_ID,
  TIME_ZONE,
  USER_ID,
  USER_NAME,
} from "../config/sessionStorage";
import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import {
  addSerialNumber,
  formatCurrency,
  formatCurrencySymbol,
  formatDateDDMMYYYY,
} from "../config/fieldConfig";
import { IndividualDashboardColumns } from "../pages/IndividualDashboard/columns";

export const IndividualDashboardService = () => {
  // Total Commission for SalesRep
  const [totalCommSalesRep, setTotalCommSalesRep] = useState("");

  // Top Commission Product SalesRep
  const [topCommissionProductsSalesRep, setTopCommissionProductsSalesRep] =
    useState([]);

  // Top Commission Customer SalesRep
  const [customerData, setCustomerData] = useState([]);

  // Not calculated table data
  const [tableData, setTableData] = useState([]);

  const [totalTransCount, setTotalTransCount] = useState("" || 0);
  const [processTransCount, setProcessTransCount] = useState("" || 0);
  const [salesRepData, setSalesRepData] = useState("");

  const currency = CURRENCY_NAME() || "";
  const timeZone = TIME_ZONE() || "";

  const order = "desc";

  const count = 5;
  const tableCount = 10;

  // Get today's date
  const currentDate = new Date();

  // Calculate the date of the month before last
  const dateOfLastMonth = subMonths(currentDate, 1);
  const endOfLastMonth = endOfMonth(dateOfLastMonth);

  // Format the date as yyyy-mm-dd
  const formattedEndDate = format(endOfLastMonth, "yyyy-MM-dd");

  // Calculate the date three months ago
  const dateThreeMonthsAgo = subMonths(currentDate, 3);

  // Get the start date of that month
  const startOfThreeMonthsAgo = startOfMonth(dateThreeMonthsAgo);

  // Format the date as yyyy-mm-dd
  const formatedStartDate = format(startOfThreeMonthsAgo, "yyyy-MM-dd");

  // States for start and end dates
  const [startDate, setStartDate] = useState(formatedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  const [reportingTo, setReportingTo] = useState([]);

  const orgId = ORG_ID();
  const userName = USER_NAME();
  const userId = USER_ID();

  const menu = [`${userId} - ${userName}`, "My Reportees"];

  const salesRepsColumns = [
    {
      field: "beneficiaryName",
      headerName: "Beneficiary",
      width: 200,
    },
    {
      field: "totalNetPayout",
      headerName: `Net Payout (${currency})`,
      headerAlign: "right",
      align: "right",
      width: 150,
    },
  ];

  // Convert salesRepData object to an array
  const salesRepsRows = Object.values(salesRepData).map((row, index) => ({
    id: index + 1,
    beneficiaryName: row.beneficiaryName,
    totalNetPayout: formatCurrency(parseFloat(row.totalNetPayout)),
  }));

  const handleGetMyDetailsApplyClick = (salesRe) => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "ApplyClick", salesRe);
  };

  const handleGetMyReportee = () => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "My Reportees");
  };

  const handleGetMyDetails = () => {
    stopRemount.current = true;
    fetchData(startDate, endDate, "My Data");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can handle the submission of the start date and end date values
    fetchData();
  };

  // To display Table Data & take Id and Token via Session Storage
  const stopRemount = useRef(true);

  useEffect(() => {
    console.log(startDate);

    if (stopRemount.current) {
      stopRemount.current = false;
      fetchData(startDate, endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const fetchAndSetData = async (fetchFunctions) => {
    const [
      totalCommSalesRep,
      topCommissionProducts,
      topCustomers,
      notCalcTransData,
      topSalesReps,
    ] = await Promise.all(fetchFunctions);

    setTotalCommSalesRep(formatCurrencySymbol(totalCommSalesRep || 0));

    const formattedTopCommissionProducts = topCommissionProducts.map((row) => ({
      value: row.totalNetPayout,
      label: row.productName,
    }));

    setTopCommissionProductsSalesRep(formattedTopCommissionProducts);

    setCustomerData(topCustomers);
    setTableData(notCalcTransData?.notProcessedTransactionRecords || []);

    setTotalTransCount(notCalcTransData?.totalTransactionCount || 0);
    setProcessTransCount(notCalcTransData?.processedTransactionCount || 0);

    setSalesRepData(topSalesReps);
  };

  const fetchData = async (startDate, endDate, selectedDropdown, salesRe) => {
    try {
      const TOKEN = JWT_TOKEN();

      const response = await ApiService.getActiveBeneficiaries(TOKEN);

      // Transform beneficiary data
      const transformedData = response.map((beneficiary) => ({
        userId: beneficiary.userId,
        userName: beneficiary.userName,
      }));

      // Filter out the entry with userId "T4"
      const filteredData = transformedData.filter(
        (beneficiary) => beneficiary.userId !== userId
      );
      setReportingTo(filteredData);

      if (selectedDropdown === "My Data" || !selectedDropdown) {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByProduct(
            TOKEN,
            order,
            0,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualSalesByCustomer(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getIndividualUnProcessedTrans(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForUser(
            TOKEN,
            order,
            tableCount,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "My Reportees") {
        await fetchAndSetData([
          ApiService.getReporteesTotalCommission(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByProduct(
            TOKEN,
            order,
            0,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesSalesByCustomer(
            TOKEN,
            order,
            count,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getReporteesUnProcessedTrans(
            TOKEN,
            startDate,
            endDate,
            null,
            null
          ),
          ApiService.getCreditTransForReportees(
            TOKEN,
            order,
            tableCount,
            startDate,
            endDate,
            null,
            null
          ),
        ]);
      } else if (selectedDropdown === "ApplyClick") {
        await fetchAndSetData([
          ApiService.getIndividualTotalCommission(
            null,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByProduct(
            null,
            order,
            0,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualSalesByCustomer(
            null,
            order,
            count,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getIndividualUnProcessedTrans(
            null,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
          ApiService.getCreditTransForUser(
            null,
            order,
            tableCount,
            startDate,
            endDate,
            salesRe,
            orgId
          ),
        ]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = IndividualDashboardColumns({ currency });

  // Function to format specific fields in each object of transData
  function formatFields(tableData) {
    return tableData.map((item) => ({
      ...item,
      transactionDate: formatDateDDMMYYYY(item.transactionDate),
      salesAmount: formatCurrency(item.salesAmount),
    }));
  }

  // Apply the formatting and update table data
  const formattedData = formatFields(tableData);

  const rows = addSerialNumber(formattedData);

  return {
    // Date
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    handleSubmit,
    // Total Commission SalesRep
    totalCommSalesRep,
    // Top Commission Product SalesRep
    topCommissionProductsSalesRep,
    // Top Commission Customer SalesRep
    customerData,
    // Not calculated table data
    rows,
    columns,
    // Top 10 SalesReps
    salesRepsColumns,
    salesRepsRows,
    totalTransCount,
    processTransCount,
    currency,
    timeZone,
    handleGetMyReportee,
    handleGetMyDetails,
    handleGetMyDetailsApplyClick,
    reportingTo,
    menu,
    fetchData,
    stopRemount,
  };
};
