import { GridActionsCellItem } from "@mui/x-data-grid";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";

const RoleColumns = (handleEdit, handleDelete, roleAccess) => {
  const readAccess = roleAccess.some(
    (item) => item.resource === "Role" && item.writeAccess
  );
  return [
    {
      field: "sNo",
      headerName: "S.No.",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "roleName",
      headerName: "Role Name",
      width: 270,
    },
    {
      field: "reportingTo",
      headerName: "Reporting To",
      width: 270,
    },
    {
      field: "rank",
      headerName: "Level",
      width: 200,
    },
    readAccess && {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      align: "left",
      headerAlign: "left",
      cellClassName: "actions",
      width: 100,
      getActions: ({ id }) => {
        const editAccess = roleAccess.some(
          (item) => item.resource === "Role" && item.writeAccess
        );
        const deleteAccess = roleAccess.some(
          (item) => item.resource === "Role" && item.deleteAccess
        );
        const actions = [];

        if (editAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<EditTwoTone />}
              label="Edit"
              color="primary"
              onClick={editAccess ? handleEdit(id) : undefined}
              title={editAccess ? "Edit" : "No Edit Access"}
            />
          );
        }

        if (deleteAccess) {
          actions.push(
            <GridActionsCellItem
              icon={<DeleteTwoTone />}
              label="Delete"
              color="error"
              onClick={deleteAccess ? handleDelete(id) : undefined}
              title={deleteAccess ? "Delete" : "No Delete Access"}
            />
          );
        }
        return actions;
      },
    },
  ];
};

export default RoleColumns;
