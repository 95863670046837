import { useState } from "react";
import { Typography, Menu, MenuItem, Button, Grid } from "@mui/material";
import { FileDownloadOutlined, ListTwoTone } from "@mui/icons-material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
} from "@mui/x-data-grid";
import AddButton from "../../common/AddButton";
import ImportButton from "../../common/ImportButton";
import { USER_ROLE } from "../../config/sessionStorage";
import DateRangePicker from "../../common/DateRangePicker";

export default function TransactionToolbar({
  handleUploadFile,
  handleGetMyReportee,
  handleGetMyDetails,
  handleGetAll,
  addFormOpen,
  roleAccess,
  fileInputRef,
  handleAllError,
  triggerNavigateToAllErrors,
  hasData,
  isReportingTo,
  apiEndpoint,
  uploading,
  setUploadInProgress,
  selectedOption,
  handleSelectOption,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  stopRemount,
  boolValue,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const apiRef = useGridApiContext();

  const USERROLE = USER_ROLE();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const readAccess = roleAccess.some(
    (item) => item.resource === "Transaction" && item.writeAccess
  );

  const handleExport = () => {
    apiRef.current.exportDataAsCsv({
      fileName:
        apiEndpoint === "/transactions/getErrorTrans"
          ? "Transaction_Error_Records"
          : "Transaction",
    });
  };

  const menuItems = [
    { label: "Get All", action: handleGetAll },
    { label: "My Data", action: handleGetMyDetails },
    { label: "My Reportee", action: handleGetMyReportee },
    USERROLE === "Admin" && {
      label: "All Errors",
      action: () => {
        handleSelectOption("All Errors");
        handleAllError(() => {
          triggerNavigateToAllErrors();
          handleMenuClose(); // Close the menu after navigation
        }, "false"); // Pass 'false' to set boolValue to 'false'
      },
    },
  ].filter(Boolean);

  return (
    <GridToolbarContainer>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h5" color="primary">
                Transactions
              </Typography>
            </Grid>
            <Grid item>
              <GridToolbarColumnsButton />
            </Grid>
            <Grid item>
              <GridToolbarFilterButton />
            </Grid>
            <Grid item>
              <GridToolbarDensitySelector />
            </Grid>
            {readAccess && (
              <Grid item>
                <ImportButton
                  mode={"transaction"}
                  handleUploadFile={handleUploadFile}
                  fileInputRef={fileInputRef}
                  uploading={uploading}
                  setUploadInProgress={setUploadInProgress}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                variant="text"
                size="small"
                startIcon={<FileDownloadOutlined />}
                onClick={handleExport}
                disabled={!hasData}
              >
                Export
              </Button>
            </Grid>
            {(isReportingTo || USERROLE === "Admin") && (
              <Grid item>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<ListTwoTone />}
                  onClick={handleMenuOpen}
                >
                  View
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {menuItems.map(({ label, action }) => (
                    <MenuItem
                      key={label}
                      onClick={() => {
                        action();
                        handleMenuClose();
                      }}
                      selected={selectedOption === label}
                      sx={{
                        color:
                          selectedOption === label
                            ? "secondary.main"
                            : "inherit",
                      }}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            )}
            <div style={{ flex: 1 }} />
            <Grid item>
              <GridToolbarQuickFilter />
            </Grid>
            <Grid item>
              <AddButton
                dialogType={"Transaction"}
                addFormOpen={addFormOpen}
                roleAccess={roleAccess}
              />
            </Grid>
          </Grid>
        </Grid>
        {boolValue === "false" && (
          <Grid pt={0.5}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  stopRemount={stopRemount}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}
