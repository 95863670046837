export const FormFields = [
  {
    name: "title",
    label: "Plan Name",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "price",
    label: "Cost/user",
    type: "number",
    required: true,
  },
  {
    name: "notes",
    label: "Notes",
    type: "text",
    required: false,
  },
  {
    name: "frequency",
    label: "Frequency",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "text",
    multiline: true,
    rows: 1,
    required: true,
  },
];
