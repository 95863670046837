import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import {
  isDuplicate,
  isEmpty,
  isValueContainsSplChars,
  isValueStartsWithNumber,
} from "../../config/fieldConfig";

export default function TypeForm({
  mode,
  editItemId,
  fields,
  editFormData,
  productTypeOptions,
  selectedOption,
  setShowForm,
  onClose,
  setEditFormOpen,
  onAddForm,
  onEditForm,
}) {
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [newProductType, setNewProductType] = useState("");

  useEffect(() => {
    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Check if editFormData exists
      if (editFormData) {
        fields.forEach((field) => {
          setValue(field.name, editFormData[field.name]);
          if (field.name === "typeValue") {
            setNewProductType(editFormData.typeValue);
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    if (mode === "add") {
      setNewProductType("");
      setValue("typeValue", "");
      clearErrors("typeValue");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const handleCancelAdd = () => {
    setNewProductType("");
    if (mode === "add") {
      setShowForm(false);
    } else {
      setEditFormOpen(false);
    }
  };

  const onSubmit = () => {
    const value = {
      typeValue: newProductType,
      typeName: selectedOption,
    };

    if (mode === "add") {
      onAddForm(value);
      onClose();
    } else if (mode === "edit") {
      value.id = editFormData.id;
      onEditForm(value);
      setEditFormOpen(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        mt={mode === "edit" ? 1 : 5}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {fields.map((field, index) => (
            <Grid item xs={12} key={index} sm={mode === "edit" ? 12 : 6}>
              {field.name === "typeValue" ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={3}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {selectedOption} Type:
                  </Grid>
                  <Grid item md={5}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      variant="outlined"
                      size="small"
                      autoFocus
                      fullWidth
                      required
                      {...register(field.name, {
                        required: field.required
                          ? `${field.label} is required`
                          : false,
                        validate: {
                          isEmpty: (value) => isEmpty(value),
                          isValueContainsSplChars: (value) =>
                            isValueContainsSplChars(value),
                          isValueStartsWithNumber: (value) =>
                            isValueStartsWithNumber(value, field.label),
                          isDuplicate: (value) => {
                            if (field.name === "typeValue") {
                              return isDuplicate(
                                productTypeOptions,
                                field.label,
                                value,
                                mode,
                                editFormData,
                                field.name,
                                false
                              );
                            } else {
                              return true;
                            }
                          },
                        },
                      })}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]?.message}
                      onChange={(e) => {
                        setValue(field.name, e.target.value); // Update the form value
                        trigger(field.name); // Trigger validation on change
                        setNewProductType(e.target.value);
                      }}
                      onBlur={() => {
                        setTimeout(function () {
                          trigger(field.name);
                        }, 200);
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Stack direction="row" spacing={2}>
                      <Button variant="contained" color="primary" type="submit">
                        {mode === "add" ? "Submit" : "Update"}
                      </Button>
                      <Button variant="outlined" onClick={handleCancelAdd}>
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
