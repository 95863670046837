import { useEffect, useMemo, useState } from "react";
import { Box, Checkbox, FormControlLabel, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { accessColumns } from "./columnRow";
import EmptyBox from "../../common/EmptyBox";
import CustomPagination from "../../common/CustomPagination";
import AccessToolbar from "./toolbar";
import { AccessService } from "../../service/accessService";

export default function Access() {
  const {
    data,
    dataId,
    selectedRole,
    dynamicOptions,
    headerCheckboxes,
    handleSetRole,
    handleSwitchChange,
    handleCheckboxChange,
    onUpdateForm,
    handleHeaderCheckboxChange,
    loading,
    rows,
  } = AccessService();

  const renderSwitch = (params) => (
    <Switch
      checked={params.value}
      onChange={() => handleSwitchChange(params.id)}
      disabled={params.row.resource === "Dashboard"}
      color="success"
    />
  );

  const [page, setPage] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0, // 0-based index for the first page
    pageSize: 10,
  });

  //  Reset pagination to the first page when selectedRole changes
  useEffect(() => {
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
    setPage(false);
  }, [selectedRole]);

  useEffect(() => {
    setPage(true);
  }, [paginationModel]);

  const renderCheckbox = (params, columnName) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={params.row[columnName]}
          onChange={() => handleCheckboxChange(params.id, columnName)}
          disabled={
            params.row.resource === "Dashboard" || // Disable if resource is 'Dashboard'
            !params.row.menuManagement ||
            (columnName === "deleteAccess" && !params.row.writeAccess)
          }
          color="success"
        />
      }
      label={
        columnName.charAt(0).toUpperCase() +
        columnName.slice(1).replace("Access", "")
      }
    />
  );

  //Table header checkbox
  const renderCheckboxHeader = (headerName, field) => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ marginTop: "-1px" }}
    >
      <div style={{ lineHeight: "1rem", marginBottom: "-5px" }}>
        {headerName}
      </div>
      <Checkbox
        size="small"
        sx={{ transform: "scale(0.8)", padding: 0 }}
        checked={headerCheckboxes[field]}
        onClick={(event) => {
          event.stopPropagation();
          handleHeaderCheckboxChange(field, !headerCheckboxes[field]);
        }}
        color="success"
      />
    </Box>
  );

  // Use useMemo directly to create toolbarSlot
  const toolbarSlot = useMemo(() => {
    return selectedRole !== null
      ? () => (
          <AccessToolbar
            handleSetRole={handleSetRole}
            selectedRole={selectedRole}
            dynamicOptions={dynamicOptions}
            data={data}
            onUpdateForm={onUpdateForm}
            dataId={dataId}
            rows={rows}
            loading={loading}
          />
        )
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole, dynamicOptions]);

  return (
    <>
      <Box>
        <DataGrid
          rows={data}
          columns={accessColumns.map((column) => ({
            ...column,
            sortable: false, // Disable sorting for each column
            renderCell: (params) => {
              if (column.renderCell === "switch") {
                return renderSwitch(params);
              } else if (
                ["readAccess", "writeAccess", "deleteAccess"].includes(
                  column.field
                )
              ) {
                return renderCheckbox(params, column.field);
              } else if (["id"].includes(column.field)) {
                return params.id;
              } else {
                return params.row.resource;
              }
            },
            renderHeader: (params) => {
              if (
                [
                  "menuManagement",
                  "readAccess",
                  "writeAccess",
                  "deleteAccess",
                ].includes(column.field)
              ) {
                return renderCheckboxHeader(
                  params.colDef.headerName,
                  column.field
                );
              } else {
                return params.colDef.headerName;
              }
            },
          }))}
          disableSelectionOnClick
          slots={{
            noRowsOverlay: EmptyBox,
            toolbar: toolbarSlot, // Use memoized toolbar slot
            pagination: CustomPagination,
          }}
          autoHeight={Object.keys(data).length >= 10}
          initialState={
            page
              ? { pagination: { paginationModel: { pageSize: 10 } } }
              : undefined
          }
          onPaginationModelChange={!page ? setPaginationModel : undefined}
          paginationModel={!page ? paginationModel : undefined}
          pageSizeOptions={[10, 25, 50]}
          disableRowSelectionOnClick
          loading={loading}
          density="compact"
          sx={{
            p: 1,
            height: 530,
            // removing the cell border
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-columnHeader": {
              "& .MuiDataGrid-iconButtonContainer": {
                display: "none", // Hide sort icons
              },
            },
            "& .MuiDataGrid-menuIcon": {
              display: "none", // Hide column menu icon if desired
            },
          }}
        />
      </Box>
    </>
  );
}
