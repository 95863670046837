export const FormFields = [
  {
    name: "customerId",
    label: "Customer ID",
    type: "text",
    autoFocus: true,
    required: true,
  },
  {
    name: "customerName",
    label: "Customer Name",
    type: "text",
    required: true,
  },
  {
    name: "fullCustomerName",
    label: "Full Customer Name",
    type: "text",
    required: false,
  },
  {
    name: "subCustomerOf",
    label: "Sub Customer Of",
    type: "text",
    required: false,
  },
  {
    name: "startDate",
    label: "Start Date",
    type: "date",
    shrink: true,
    required: false,
  },
  {
    name: "endDate",
    label: "End Date",
    type: "date",
    shrink: true,
    required: false,
  },
  {
    name: "renewalDate",
    label: "Renewal Date",
    type: "date",
    shrink: true,
    required: false,
  },
  {
    name: "leadSource",
    label: "Lead Source",
    type: "text",
    required: false,
  },
  {
    name: "customerLevel",
    label: "Customer Level",
    type: "text",
    required: true,
  },
  {
    name: "relationshipType",
    label: "Relationship Type",
    type: "text",
    required: true,
  },
  {
    name: "terms",
    label: "Terms",
    type: "text",
    multiline: true,
    rows: 1,
    required: false,
  },
  {
    name: "active",
    label: "Is Active",
    type: "boolean",
    required: false,
  },
];
