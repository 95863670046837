import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../apis/ApiService";
import { CustomNavigate } from "../config/navigation";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import { addSerialNumber, handleApiCall } from "../config/fieldConfig";
import { FormFields } from "../pages/Role/formFields";
import useFetchOnMount from "../config/useFetchOnMount";

export const RoleService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const [roleConfigNames, setRoleConfigNames] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const { navigateError403 } = CustomNavigate();

  const [open, setOpen] = useState(false);
  // const [loading, setLoading] = useState(false);

  const roleAccess = ROLE_ACCESS();

  const addFormOpen = () => {
    setOpen(true);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  const fields = FormFields;

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const tableData = await ApiService.getRoles(TOKEN);
      setTableData(tableData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  // To display Form reportingTo dropdown tableData
  useEffect(() => {
    apiCall("", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // This will run whenever data changes

  const apiCall = async (role, level) => {
    // Access tableData in a useEffect callback
    if (Array.isArray(data)) {
      const extractedData = data.map((item) => ({
        roleName: item.roleName,
        id: item.id,
        level: item.rank,
      }));

      // Extract role names into a new array
      const roleNames = extractedData.map((item) => item.roleName);
      setRoleConfigNames(roleNames);

      // Remove duplicates based on roleName
      const uniqueDynamicOptions = extractedData.reduce((acc, option) => {
        const existingOption = acc.find(
          (accOption) => accOption.roleName === option.roleName
        );
        if (!existingOption) {
          acc.push(option);
        }
        return acc;
      }, []);

      const selectedRole = uniqueDynamicOptions.find(
        (option) => option.roleName === role
      );

      // const options =
      //   role === ""
      //     ? uniqueDynamicOptions
      //     : uniqueDynamicOptions.filter((option) => option.roleName !== role);

      // const updatedOptions = selectedRole
      //   ? role === "Admin"
      //  // ?uniqueDynamicOptions.filter((option) => option.level === 1)
      //      ? uniqueDynamicOptions.filter((option) => option.roleName === "Admin")
      //     : uniqueDynamicOptions.filter(
      //         (option) => option.level < selectedRole.level
      //       )
      //   : options;

      const options =
        level === ""
          ? uniqueDynamicOptions
          : uniqueDynamicOptions.filter((option) => option.level !== level);
      const updatedOptions = selectedRole
        ? level === 1
          ? uniqueDynamicOptions.filter((option) => option.roleName === "Admin")
          : uniqueDynamicOptions.filter(
              (option) => option.level < selectedRole.level
            )
        : options;

      setDynamicOptions(updatedOptions);
    } else {
      console.error("Expected data to be an array");
      setDynamicOptions([]); // Optionally, you can reset the state if data is not an array
    }
  };

  const rows = addSerialNumber(data);

  const handleEdit = (id) => () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    setEditItemId(id); // Set the edit form state and data
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    apiCall(rowData.roleName, rowData.rank);
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // setLoading(true);
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addRole,
        edit: ApiService.updateRole,
        delete: ApiService.deleteRole,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this role?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  return {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    dynamicOptions,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    open,
    addFormOpen,
    addFormClose,
    fields,
    roleAccess,
    roleConfigNames,
    loading,
    apiCall,
  };
};
