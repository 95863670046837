import {
  Box,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDownTwoTone,
  KeyboardArrowUpTwoTone,
} from "@mui/icons-material";

export default function RenderMobileView({
  drawerOpen,
  handleToggleDrawer,
  menuItems,
  currentPath,
  openSubMenu,
  setOpenSubMenu,
}) {
  const handleToggleSubMenu = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  return (
    <>
      <Drawer anchor="left" open={drawerOpen} onClose={handleToggleDrawer}>
        <List>
          {menuItems.map((menu, index) => {
            const hasAccessChildren =
              menu.children &&
              menu.children.some((child) => child.isAccess !== false);

            if (!menu.isAccess && (!menu.children || !hasAccessChildren)) {
              return null; // Hide the menu if both the menu and its children have isAccess set to false
            }
            const isActive = menu.link === currentPath; // Check if the menu is active

            return (
              <Box key={menu.text}>
                {menu.children ? (
                  // Render ListItemButton for menu items with children
                  <ListItemButton
                    onClick={() => handleToggleSubMenu(index)}
                    sx={{
                      backgroundColor: isActive ? "#c5cae9" : "inherit",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography color={isActive ? "secondary" : "inherit"}>
                          {menu.text}
                        </Typography>
                      }
                    />
                    {menu.children && (
                      <>
                        {openSubMenu === index ? (
                          <KeyboardArrowUpTwoTone />
                        ) : (
                          <KeyboardArrowDownTwoTone />
                        )}
                      </>
                    )}
                  </ListItemButton>
                ) : (
                  // Render ListItemButton for menu items without children
                  <ListItemButton
                    component={Link}
                    to={menu.link}
                    onClick={handleToggleDrawer}
                    sx={{
                      backgroundColor: isActive ? "#c5cae9" : "inherit",
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography color={isActive ? "secondary" : "inherit"}>
                          {menu.text}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                )}

                {/* Submenu */}
                {menu.children && openSubMenu === index && (
                  <List component="div" disablePadding>
                    {menu.children.map(
                      (child) =>
                        child.isAccess !== false && (
                          <ListItemButton
                            key={child.text}
                            component={Link}
                            to={child.link}
                            onClick={handleToggleDrawer}
                            sx={{
                              backgroundColor:
                                currentPath === child.link
                                  ? "#c5cae9"
                                  : "inherit",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              },
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  color={
                                    currentPath === child.link
                                      ? "secondary"
                                      : "inherit"
                                  }
                                >
                                  {child.text}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )
                    )}
                  </List>
                )}
              </Box>
            );
          })}
        </List>
      </Drawer>
    </>
  );
}
