import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ApiService from "../../../apis/ApiService";
import { ORG_ID, ORG_NAME, USER_NAME } from "../../config/sessionStorage";
import { STRIPE_PUBLISHABLE_KEY } from "../../config/envConfig";
import { useNavigate } from "react-router-dom";
import FullScreenLoader from "../../common/LoadingScreen";

// Wrapper component for loading Stripe and handling errors
const StripePay = ({ open, onClose, handleClose, payData, fetchData }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        // Initialize Stripe with the publishable key
        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
        if (stripe) {
          setStripePromise(stripe);
        } else {
          throw new Error("Stripe failed to initialize");
        }
      } catch (error) {
        console.error("Error loading Stripe:", error);
        setLoadingError(true);
      }
    };

    initializeStripe();
  }, []);

  if (loadingError) {
    return (
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          Stripe Payment
          <IconButton
            aria-label="close"
            title="Close"
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={onClose}
          >
            <CloseTwoTone />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "25vh",
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        Stripe Payment
        <IconButton
          aria-label="close"
          title="Close"
          style={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              payData={payData}
              onClose={onClose}
              handleClose={handleClose}
              fetchData={fetchData}
            />
          </Elements>
        ) : (
          <Typography variant="h6">Loading payment processor...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

const CheckoutForm = ({ payData, onClose, handleClose, fetchData }) => {
  const stripe = useStripe();

  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Create a token
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        throw new Error(error.message);
      }
      console.log(token);

      // Send token.id to server for further processing
      const sendToken = { token: token?.id };
      const res = await ApiService.createCardToken(sendToken); // Send token to server to store or process
      console.log(res);

      if (res?.status === 200) {
        const values = {
          stripeToken: res?.data?.token,
          userName: USER_NAME(),
          paymentId: "",
          paidDate: "",
          amountToPay: payData?.amountToPay,
          orgName: ORG_NAME(),
          orgId: ORG_ID(),
          month: payData?.month,
          upiId: "",
          pricingPlan: payData?.pricingPlan,
          orderId: "",
          orderReceiptId: "",
          beneficiary: payData?.beneficiary,
          renewalDate: payData?.renewalDate,
          cgst: payData?.cgst,
          sgst: payData?.sgst,
          currency: payData?.currency,
          totalAmount: payData?.totalAmount,
          discountAmount: payData?.discountAmount,
          subscriptionEndDate: payData?.subscriptionEndDate,
          discount: payData?.discountPercentage,
        };

        const stripeRes = await ApiService.charge(values);
        console.log(stripeRes);

        if (stripeRes?.status === 200 && stripeRes?.data?.success === true) {
          onClose();
          handleClose();
          fetchData();
          navigate("/billing"); // Navigate to the billing page
          toast.success(stripeRes?.data?.message);
        } else {
          throw new Error(
            stripeRes?.data?.error || "Failed to process payment"
          );
        }
      } else {
        onClose();
        throw new Error("Failed to create card token");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    } finally {
      setLoading(false);
      elements.getElement(CardElement).clear(); // Reset the CardElement form
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace, sans-serif",
        "::placeholder": {
          color: "#5c6270",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return (
    <>
      <FullScreenLoader loading={loading} />
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          maxWidth: { xs: "100%", sm: 550 },
          margin: "auto",
          px: { xs: 2, sm: 3 },
          py: { xs: 3, sm: 5 },
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Checkout Payment
        </Typography>

        <Box
          sx={{
            width: "100%",
            ".StripeElement": {
              width: "100%",
              padding: "10px",
              minHeight: "40px",
              boxSizing: "border-box",
              border: "2px solid #aab7c4",
              borderRadius: 1.5,
            },
            "@media (max-width: 600px)": {
              ".StripeElement": {
                padding: "8px",
              },
            },
          }}
        >
          <CardElement options={cardElementOptions} />
        </Box>

        <Button
          variant="contained"
          type="submit"
          disabled={!stripe || loading}
          fullWidth
        >
          {loading ? "Processing..." : `Pay ${payData.amountToPay}`}
        </Button>
      </Box>
    </>
  );
};

export default StripePay;
