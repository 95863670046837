import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { CloseTwoTone } from "@mui/icons-material";
import { isEmpty } from "../../config/fieldConfig";

export default function ScheduleForm({
  open,
  onClose,
  mode,
  editItemId,
  editFormData,
  onAddForm,
  onEditForm,
  fields,
  selectedZoneTime,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm();

  const options = [
    "Minutes",
    "Hourly",
    "Daily",
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Quarterly",
    "Half-Yearly",
    "Yearly",
    "Custom",
  ];

  const taskOptions = ["Import", "Export", "Calculation"];

  // dropdowns for selected screen
  const PAGE_OPTIONS = ["Product", "Beneficiary", "Customer", "Transaction"];

  const dayOptions = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const MonthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const CustomOptions = ["days", "hours", "min"];

  useEffect(() => {
    reset();

    // If an editItemId is provided, fetch the data for editing
    if (mode === "edit" && editItemId !== null) {
      // Set form data with the provided editFormData
      fields.forEach((field) => {
        // Check if the value exists in editFormData before setting
        if (editFormData.hasOwnProperty(field.name)) {
          setValue(field.name, editFormData[field.name]);
        }
      });
    }
  }, [editFormData, editItemId, fields, mode, reset, setValue, open]);

  const onSubmit = (formData) => {
    // You can now proceed to handle the mainFormData as required, such as sending it to the server
    if (mode === "add") {
      onAddForm(formData);
    } else if (mode === "edit") {
      formData.id = editItemId;
      onEditForm(formData);
    }
    onClose();
  };

  const watchedFields = watch(); // Watch all fields to track changes

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        {mode === "add" ? "Add" : "Edit"}
        &nbsp;Schedule
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: "8px", right: "8px" }}
          onClick={onClose}
        >
          <CloseTwoTone />
        </IconButton>
      </DialogTitle>

      <DialogContent style={{ width: 415 }}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          mt={1}
        >
          <Grid container spacing={2}>
            {fields.map(
              (field, index) =>
                (field.name === "schedule" ||
                  field.name === "executeJobTask" ||
                  field.name === "selectedTimeZone" ||
                  (field.name === "day" &&
                    watchedFields["schedule"] === "Weekly") ||
                  (field.name === "month" &&
                    (watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly")) ||
                  (field.name === "customPeriod" &&
                    watchedFields["schedule"] === "Custom") ||
                  (field.name === "date" &&
                    (watchedFields["schedule"] === "Monthly" ||
                      watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly" ||
                      watchedFields["schedule"] === "Bi-Weekly")) ||
                  (field.name === "time" &&
                    (watchedFields["schedule"] === "Minutes" ||
                      watchedFields["schedule"] === "Hourly" ||
                      watchedFields["schedule"] === "Daily" ||
                      watchedFields["schedule"] === "Weekly" ||
                      watchedFields["schedule"] === "Bi-Weekly" ||
                      watchedFields["schedule"] === "Monthly" ||
                      watchedFields["schedule"] === "Quarterly" ||
                      watchedFields["schedule"] === "Half-Yearly" ||
                      watchedFields["schedule"] === "Yearly" ||
                      watchedFields["schedule"] === "Custom")) ||
                  (field.name === "pojoType" &&
                    watchedFields["executeJobTask"] === "Export") ||
                  (field.name === "customDays" &&
                    watchedFields["schedule"] === "Custom")) && (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sm={
                      field.name === "customDays" ||
                      field.name === "customPeriod"
                        ? 6
                        : 12
                    }
                  >
                    {field.name === "schedule" ||
                    field.name === "executeJobTask" ||
                    (field.name === "day" &&
                      watchedFields["schedule"] === "Weekly") ||
                    (field.name === "month" &&
                      (watchedFields["schedule"] === "Quarterly" ||
                        watchedFields["schedule"] === "Half-Yearly" ||
                        watchedFields["schedule"] === "Yearly")) ||
                    (field.name === "pojoType" &&
                      watchedFields["executeJobTask"] === "Export") ||
                    (field.name === "customPeriod" &&
                      watchedFields["schedule"] === "Custom") ||
                    (field.name === "date" &&
                      (watchedFields["schedule"] === "Monthly" ||
                        watchedFields["schedule"] === "Quarterly" ||
                        watchedFields["schedule"] === "Half-Yearly" ||
                        watchedFields["schedule"] === "Yearly" ||
                        watchedFields["schedule"] === "Bi-Weekly")) ? (
                      <Autocomplete
                        name={field.name}
                        options={
                          field.name === "schedule"
                            ? options
                            : field.name === "executeJobTask"
                            ? taskOptions
                            : field.name === "pojoType"
                            ? PAGE_OPTIONS
                            : field.name === "day"
                            ? dayOptions
                            : field.name === "month"
                            ? MonthOptions
                            : field.name === "date"
                            ? Array.from(
                                { length: 31 },
                                (_, index) => index + 1
                              )
                            : field.name === "customPeriod"
                            ? CustomOptions
                            : null
                        }
                        onChange={(event, value) => {
                          setValue(field.name, value);
                          if (field.name === "schedule") {
                            setValue("day", "");
                            setValue("month", "");
                            setValue("path", "");
                            setValue("date", "");
                            setValue("executeJobTask", "");
                            setValue("time", "");
                            setValue("pojoType", "");
                            clearErrors("executeJobTask");
                            clearErrors("time");
                            clearErrors("day");
                            clearErrors("month");
                            clearErrors("path");
                            clearErrors("date");
                            clearErrors("pojoType");
                          }
                        }}
                        value={
                          options.includes(watch(field.name))
                            ? watch(field.name)
                            : taskOptions.includes(watch(field.name))
                            ? watch(field.name)
                            : PAGE_OPTIONS.includes(watch(field.name))
                            ? watch(field.name)
                            : dayOptions.includes(watch(field.name))
                            ? watch(field.name)
                            : MonthOptions.includes(watch(field.name))
                            ? watch(field.name)
                            : Array.from(
                                { length: 31 },
                                (_, index) => index + 1
                              ).includes(Number(watch(field.name))) // Convert to number
                            ? watch(field.name)
                            : CustomOptions.includes(watch(field.name))
                            ? watch(field.name)
                            : null
                        }
                        onBlur={() => {
                          setTimeout(() => {
                            trigger(field.name); // Trigger validation on blur
                          }, 200);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={field.label}
                            variant="outlined"
                            required={field.required}
                            autoFocus={field.autoFocus}
                            size="small"
                            fullWidth
                            {...register(field.name, {
                              required: field.required
                                ? `${field.label} is required`
                                : false,
                            })}
                            error={
                              !!errors[field.name] && !params.inputProps.value
                            }
                            helperText={
                              errors[field.name]?.message &&
                              (!params.inputProps.value
                                ? errors[field.name]?.message
                                : "")
                            }
                          />
                        )}
                      />
                    ) : (field.name === "time" &&
                        (watchedFields["schedule"] === "Minutes" ||
                          watchedFields["schedule"] === "Hourly" ||
                          watchedFields["schedule"] === "Daily" ||
                          watchedFields["schedule"] === "Weekly" ||
                          watchedFields["schedule"] === "Bi-Weekly" ||
                          watchedFields["schedule"] === "Monthly" ||
                          watchedFields["schedule"] === "Quarterly" ||
                          watchedFields["schedule"] === "Half-Yearly" ||
                          watchedFields["schedule"] === "Yearly" ||
                          watchedFields["schedule"] === "Custom")) ||
                      (field.name === "pojoType" &&
                        watchedFields["executeJobTask"] === "Export") ||
                      field.name === "selectedTimeZone" ||
                      (field.name === "customDays" &&
                        watchedFields["schedule"] === "Custom") ? (
                      <TextField
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        required={field.required}
                        variant="outlined"
                        size="small"
                        multiline={field.multiline}
                        maxRows={field.maxRows}
                        value={
                          field.name === "selectedTimeZone"
                            ? selectedZoneTime
                            : null
                        }
                        onWheel={(event) => {
                          // Check if the input type is number to prevent scrolling
                          if (field.type === "number") {
                            event.target.blur();
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            pointerEvents: "selectedTimeZone" ? "none" : "auto",
                          },
                          shrink:
                            field.name === "selectedTimeZone" ||
                            field.name === "time",
                        }}
                        style={{
                          pointerEvents:
                            field.name === "selectedTimeZone" ? "none" : "auto",
                        }}
                        fullWidth
                        {...register(field.name, {
                          required: field.required
                            ? `${field.label} is required`
                            : false,
                          validate: {
                            isEmpty: (value) =>
                              field.required ? isEmpty(value) : true,
                          },
                        })}
                        error={!!errors[field.name]}
                        helperText={errors[field.name]?.message}
                        onChange={(e) => {
                          setValue(field.name, e.target.value); // Update the form value
                          trigger(field.name); // Trigger validation on change
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            trigger(field.name); // Trigger validation on blur
                          }, 200);
                        }}
                      />
                    ) : field.isSubtitle &&
                      watchedFields["schedule"] === "Custom" ? (
                      <Typography variant="subtitle1" mt={-1}>
                        {watchedFields["customDays"]}
                        {watchedFields["customPeriod"]} {field.subtitle}
                      </Typography>
                    ) : null}
                  </Grid>
                )
            )}
          </Grid>

          <DialogActions className="mt-3">
            <Button variant="contained" color="primary" type="submit">
              {mode === "add" ? "Submit" : "Update"}
            </Button>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
