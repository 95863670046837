import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList({
  right,
  setRight,
  left,
  setLeft,
  editFormData,
  commissionListOptions,
  viewData,
  submitted,
  error,
  formSubmit,
}) {
  const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newLeft = not(left, leftChecked); // Remove checked items from left
    const newRight = right.concat(leftChecked); // Add checked items to right
    const newChecked = not(checked, leftChecked); // Remove checked items from checked

    setLeft(newLeft);
    setRight(newRight);
    setChecked(newChecked);
  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked); // Add checked items to left
    const newRight = not(right, rightChecked); // Remove checked items from right
    const newChecked = not(checked, rightChecked); // Remove checked items from checked

    setLeft(newLeft);
    setRight(newRight);
    setChecked(newChecked);
  };

  useEffect(() => {
    // Set the left list with all commission options
    setLeft(commissionListOptions);

    // Edit logic for Transfer List display
    if (editFormData && editFormData.commission) {
      // Exclude commissions that are already in editFormData.commission
      const commissionsToExclude = editFormData.commission || [];
      const newLeft = commissionListOptions.filter(
        (option) => !commissionsToExclude.includes(option)
      );

      // Set the right list with commissions from editFormData.commission
      setRight(commissionsToExclude);

      // Set the left list with commissions excluding those in editFormData.commission
      setLeft(newLeft);
    }
  }, [commissionListOptions, editFormData, setLeft, setRight]);

  const customList = (title, items) => {
    let hasError;
    if (left.length !== 0 && formSubmit && items.length === 0 && error) {
      hasError = true;
    } else {
      hasError = title === "Chosen" && submitted && items.length === 0;
    }
    return (
      <>
        <Card sx={{ border: hasError ? "1px solid red" : "none" }}>
          <CardHeader
            sx={{ px: 2, py: 0 }}
            avatar={
              <Checkbox
                sx={{
                  height: "1rem",
                  transform: "scale(0.8)",
                }}
                onClick={handleToggleAll(items)}
                checked={
                  numberOfChecked(items) === items.length && items.length !== 0
                }
                indeterminate={
                  numberOfChecked(items) !== items.length &&
                  numberOfChecked(items) !== 0
                }
                disabled={items.length === 0 || viewData}
                inputProps={{
                  "aria-label": "all items selected",
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <List
            sx={{
              width: 200,
              height: 230,
              bgcolor: "background.paper",
              overflow: "auto",
            }}
            dense
            disablePadding
            component="div"
            role="list"
          >
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItemButton
                  key={value}
                  role="listitem"
                  onClick={viewData ? null : handleToggle(value)} // Disable onClick when viewData is true
                >
                  <ListItemIcon>
                    <div style={{ opacity: viewData ? 0.5 : 1 }}>
                      {/* Apply opacity to visually indicate disabled state */}
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        disabled={viewData}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        sx={{
                          height: "1rem",
                          transform: "scale(0.8)",
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value} />
                </ListItemButton>
              );
            })}
          </List>
        </Card>
        {hasError && (
          // Conditionally render error message only for the "Chosen" list
          <FormHelperText error>This field is required.</FormHelperText>
        )}
      </>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList("Choices", left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Chosen", right)}</Grid>
    </Grid>
  );
}
