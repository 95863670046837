import { useState } from "react";
import Swal from "sweetalert2";
import ApiService from "../../apis/ApiService";
import { JWT_TOKEN, ROLE_ACCESS } from "../config/sessionStorage";
import { CustomNavigate } from "../config/navigation";
import { addSerialNumber, handleApiCall } from "../config/fieldConfig";
import { FormFields } from "../pages/PayFrequency/formFields";
import useFetchOnMount from "../config/useFetchOnMount";

export const PayFrequencyService = () => {
  const [data, setTableData] = useState([]);
  const [token, setToken] = useState("");
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({}); // State to store edit form data
  const [frequencyNameCheck, setFrequencyNameCheck] = useState("");
  const [frequencyCheck, setFrequencyCheck] = useState("");
  const [viewData, setViewData] = useState(true); // view purpose only
  const [calculateViewData, setCalculateViewData] = useState(true); // view purpose only
  const [frequencyWithId, setFrequencyWithId] = useState([]);
  const [loading, setLoading] = useState(true); // Initial loading state is true

  const { navigateError403 } = CustomNavigate();

  const roleAccess = ROLE_ACCESS();

  const [open, setOpen] = useState(false);
  const addFormOpen = () => {
    setOpen(true);
  };

  const addFormClose = () => {
    setOpen(false);
  };

  const fields = FormFields;

  const fetchData = async () => {
    const TOKEN = JWT_TOKEN();
    setToken(TOKEN);

    try {
      const tableData = await ApiService.getPaymentFrequencies(TOKEN);

      const frequencyData = await ApiService.getFrequencies(TOKEN);
      setFrequencyWithId(frequencyData);

      // Map over transferData and replace employeeType ID with typeValue
      const updatedTransferData = tableData.map((transfer) => {
        // Find the corresponding employeeTypeData entry
        const matchedfrequency = frequencyData.find(
          (frequency) => frequency.id === parseInt(transfer.frequency) // Ensure both are numbers
        );

        // Return a new object with the updated employeeType
        return {
          ...transfer,
          frequency: matchedfrequency
            ? matchedfrequency.frequency
            : transfer.frequency, // Use typeValue or fallback to original
        };
      });

      setTableData(updatedTransferData);

      const frequencyNameId = tableData?.map(
        (paymentFrequency) => paymentFrequency.frequencyName
      );

      const frequencyId = tableData?.map(
        (paymentFrequency) => paymentFrequency.frequency
      );
      setFrequencyCheck(frequencyId);
      setFrequencyNameCheck(frequencyNameId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Call the custom hook with fetchData
  useFetchOnMount(fetchData);

  const rows = addSerialNumber(data);

  const handleEdit = (id, viewData) => () => {
    const rowData = data.find((row) => row.id === id); // Find the row data based on the id
    // Check if any 'calculated' field is true
    const hasTrueCalculated = rowData.paymentPeriod.some(
      (period) => period.calculated
    );

    setEditItemId(id); // Set the edit form state and data
    setEditFormOpen(true);
    setEditFormData(rowData); // Set edit form data
    setViewData(viewData);
    setCalculateViewData(hasTrueCalculated);
  };

  // Function for handling form submissions
  const onSubmitForm = async (formData, action) => {
    try {
      // Determine the appropriate API action based on the action type
      const apiAction = {
        add: ApiService.addPayFrequencies,
        edit: ApiService.updatePayFrequency,
        delete: ApiService.deletePayFrequency,
      }[action];

      // Call the API action and handle the response
      await handleApiCall(
        // Pass appropriate parameters based on the action type
        apiAction(action === "delete" ? formData.id : formData, token),
        // Construct success message based on action type
        `${action.charAt(0).toUpperCase()}${action.slice(1)}ed`,
        // Pass form data if action is not delete, otherwise pass null
        action !== "delete" ? formData : null,
        fetchData,
        navigateError403
      );
    } catch (error) {
      // Log error and navigate to error page
      console.error(`Error ${action} form data:`, error);
      navigateError403();
    }
  };

  // Function to handle addition form submission
  const onAddForm = (formData) => onSubmitForm(formData, "add");

  // Function to handle edit form submission
  const onEditForm = (formData) => onSubmitForm(formData, "edit");

  // Function to handle delete form submission
  const handleDelete = (id) => () => {
    Swal.fire({
      text: "Are you sure you want to delete this payFrequency?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      width: "auto",
    }).then((result) => {
      result.isConfirmed && onSubmitForm({ id }, "delete");
    });
  };

  return {
    rows,
    editFormOpen,
    editItemId,
    editFormData,
    handleEdit,
    handleDelete,
    onAddForm,
    onEditForm,
    setEditFormOpen,
    open,
    addFormOpen,
    addFormClose,
    fields,
    roleAccess,
    frequencyNameCheck,
    frequencyCheck,
    viewData,
    setViewData,
    calculateViewData,
    setCalculateViewData,
    data,
    frequencyWithId,
    loading,
  };
};
